import React from 'react';
import { Fragment } from 'react';
import Camera from '../Camera';
import ContractActions from '../../actions/Contract';
import { NotificationManager } from 'react-notifications';
import Loader from '../Loader';
import { branch } from 'baobab-react/higher-order';

const stepsTranslations = {
  'Captura el frente de tu ID': {
    es: 'Captura el frente de tu ID',
    en: 'Capture the front of your ID',
  },
  'Captura el reverso de tu ID': {
    es: 'Captura el reverso de tu ID',
    en: 'Capture the back of your ID',
  },
  'Captura tu selfie': {
    es: 'Captura tu selfie',
    en: 'Capture your selfie',
  },
};
class CameraModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirming: false,
      isSupported: true,
      showVideoCamera: false,
      photoID: null,
      photoBackID: null,
      photoSelfie: null,
      // TODO: Translate ids types
      ids: ['INE / IFE', 'Pasaporte', 'Otro'],
      idSelected: 'INE / IFE',
      activeStep: 0,
      activeCamera: false,
      stepsTakePicture: [
        'Captura el frente de tu ID',
        'Captura el reverso de tu ID',
        'Captura tu selfie',
      ],
      activeStepTakePicture: 1,
      stepsCompleted: new Set(),
      checkStepper: new Set(),
      constraints: {
        audio: false,
        video: {
          width: { min: 640, ideal: 1280, max: 1920 },
          height: { min: 480, ideal: 720, max: 1080 },
          aspectRatio: 1.777777778,
          facingMode: 'environment',
        },
      },
      loading: false,
      verifyFaces: false,
      hasMrz: false,
      ineSubType: '',
      flipImage: true,
    };
  }

  componentDidMount = () => {
    const { stepsTakePicture } = this.state;
    this.setState({ allStepsTakePicture: stepsTakePicture });
  };
  refVideoElement = (input) => {
    this.videoElement = input;
  };

  onInputChange = (id) => {
    this.setState({ idSelected: id });
  };

  initializeState = () => {
    this.setState({
      stepsCompleted: new Set(),
      checkStepper: new Set(),
      photoID: null,
      photoSelfie: null,
      photoBackID: null,
    });
  };

  getPhotoTypeId = () => {
    const { idSelected } = this.state;
    if (idSelected === 'INE / IFE') {
      return 'INEIFE';
    }

    if (idSelected === 'Pasaporte') {
      return 'PASSPORT';
    }

    return 'OTHER';
  };

  getStepsToTakePicture = (keyState) => {
    const { idSelected, allStepsTakePicture } = this.state;
    if (keyState === 'activeStep') {
      this.initializeState();
    }
    let stepsTakePictureModified =
      idSelected !== 'INE / IFE'
        ? allStepsTakePicture.filter(
            (step) => step !== 'Captura el reverso de tu ID',
          )
        : allStepsTakePicture;

    return stepsTakePictureModified;
  };

  handleNextStep = async (keyState) => {
    const {
      activeStepTakePicture,
      activeStep,
      checkStepper,
      stepsTakePicture,
    } = this.state;
    const { translations, lang } = this.props;
    const { notifications } = translations[lang].Modals.CameraModal;
    let nextStep =
      keyState === 'activeStep' ? activeStep + 1 : activeStepTakePicture + 1;
    if (keyState !== 'activeStep') {
      checkStepper.add(nextStep - 1);
    }
    const stepsTakePictureModified = this.getStepsToTakePicture(keyState);
    this.setState({
      [keyState]: nextStep,
      checkStepper,
      stepsTakePicture: stepsTakePictureModified,
    });

    if (
      (nextStep === 5 && stepsTakePicture.length === 3) ||
      (nextStep === 4 && stepsTakePicture.length === 2)
    ) {
      this.setState({ loading: true, verifyFaces: true });
      const response = await this.verifyFacesMatch();
      if (!response.data || !response.data.success) {
        this.setState({
          [keyState]: nextStep - 1,
          loading: false,
          verifyFaces: false,
        });
        return NotificationManager.warning(
          notifications.notifBody1,
          notifications.notifTitle1,
          10000,
        );
      }
      NotificationManager.success(
        notifications.notifBody2,
        notifications.notifTitle2,
        10000,
      );
      this.setState({ loading: false });
      this.closeModal(response);
    }
  };

  handleBackStep = (keyState) => {
    const { activeStepTakePicture, activeStep, checkStepper } = this.state;
    let backStep =
      keyState === 'activeStep' ? activeStep - 1 : activeStepTakePicture - 1;
    keyState =
      activeStepTakePicture === 1 ? 'activeStep' : 'activeStepTakePicture';
    checkStepper.delete(backStep);
    this.setState({ [keyState]: backStep, checkStepper });
  };

  openCamera = (e, newConstraints) => {
    const { constraints } = this.state;
    const { translations, lang } = this.props;
    const { notifications } = translations[lang].Modals.CameraModal;
    const mediaDevices =
      navigator.mediaDevices ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia;
    if (!mediaDevices || !mediaDevices.getUserMedia) {
      NotificationManager.warning(
        notifications.notifBody3,
        notifications.notifTitle3,
        5000,
      );
      this.setState({ isSupported: false });
      return;
    }
    mediaDevices
      .getUserMedia(newConstraints || constraints)
      .then(this.handleVideo)
      .catch(this.videoError);
    this.setState({ showVideoCamera: true });
  };

  closeCamera = () => {
    this.videoElement.srcObject.getTracks().forEach((track) => track.stop());
    this.setState({ showVideoCamera: false });
  };

  handleVideo = (stream) => {
    this.videoElement.srcObject = stream;
    this.videoElement.play();
  };

  videoError = (err) => {
    //...TODO
  };

  handleFlipImage = () => {
    const { flipImage } = this.state;
    this.setState({ flipImage: !flipImage });
  };

  handleFlipCamera = () => {
    const { constraints } = this.state;
    const newConstraints = { ...constraints };
    const mediaStream = this.videoElement.srcObject;
    if (mediaStream != null && mediaStream.active) {
      var tracks = mediaStream.getVideoTracks();
      tracks.forEach((track) => {
        track.stop();
      });
    }

    this.videoElement.srcObject = null;
    newConstraints.video.facingMode =
      constraints.video.facingMode === 'environment' ? 'user' : 'environment';
    this.setState({ flipImage: newConstraints.video.facingMode === 'user' });
    this.openCamera(newConstraints);
  };

  verifyFrontId = async (photo) => {
    const type = this.getPhotoTypeId();
    const response = await ContractActions.verifyFrontId({
      type,
      idImage: photo.dataUrl,
    });
    if (response?.data?.success && type === 'INEIFE') {
      const {
        data: { data },
      } = response;
      this.setState({
        hasMrz: data.metadata.hasMrz,
        ineSubType: data.metadata.subType,
      });
    }
    return response;
  };

  verifyBackId = async (photo) => {
    const { hasMrz, ineSubType } = this.state;
    const { contract } = this.props;
    const response = await ContractActions.verifyBackId({
      idImage: photo.dataUrl,
      contractId: contract.id,
      hasMrz,
      subType: ineSubType,
    });
    return response;
  };

  verifySelfie = async (photo) => {
    const response = await ContractActions.verifySelfie({
      selfieImage: photo.dataUrl,
    });
    return response;
  };

  verifyFacesMatch = async () => {
    const { photoID, photoSelfie } = this.state;
    const { contract } = this.props;
    const response = await ContractActions.verifyFacesMatch({
      idImage: photoID.dataUrl,
      selfieImage: photoSelfie.dataUrl,
      contractId: contract.id,
    });
    return response;
  };

  sendVerificationToBackend = async (type, imageData) => {
    const { translations, lang } = this.props;
    const { notifications } = translations[lang].Modals.CameraModal;
    let response = null;
    if (type === 'photoID') {
      response = await this.verifyFrontId(imageData);
    }

    if (type === 'photoBackID') {
      response = await this.verifyBackId(imageData);
    }

    if (type === 'photoSelfie') {
      response = await this.verifySelfie(imageData);
    }

    if (!response.data.success) {
      NotificationManager.warning(
        notifications.notifBody4,
        notifications.notifTitle4,
        10000,
      );
      this.setState({ loading: false, showVideoCamera: false });
    }
    return response;
  };

  handleCapture = async (type) => {
    this.setState({ loading: true });
    const { activeStepTakePicture, stepsCompleted, checkStepper } = this.state;
    const width = this.videoElement.videoWidth;
    const height = this.videoElement.videoHeight;
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext('2d');
    context.drawImage(this.videoElement, 0, 0, width, height);
    const dataURL = canvas.toDataURL();
    const imageData = { dataUrl: dataURL, width, height };
    this.videoElement.srcObject.getTracks().forEach((track) => track.stop());

    const response = await this.sendVerificationToBackend(type, imageData);
    if (!response.data.success) return;

    stepsCompleted.add(activeStepTakePicture);
    checkStepper.add(activeStepTakePicture);
    this.setState({
      [type]: imageData,
      showVideoCamera: false,
      activeStepTakePicture: activeStepTakePicture + 1,
      loading: false,
    });
  };

  stepperStyles = (index) => {
    const { activeStepTakePicture, checkStepper } = this.state;
    let styles = {
      background: 'white',
      color: 'black',
    };
    if (
      activeStepTakePicture === index + 1 ||
      Array.from(checkStepper).includes(index + 1)
    ) {
      styles = {
        background: '#2851e5',
        color: 'white',
      };
    }

    return styles;
  };

  getIconIds = (id) => {
    if (id === 'INE - IFE') {
      return <i className="far fa-id-card icon_id"></i>;
    }

    if (id === 'Pasaporte') {
      return <i className="fas fa-passport icon_id"></i>;
    }

    return <i className="far fa-file-alt icon_id"></i>;
  };

  enableButtonContinue = (index) => {
    const {
      stepsTakePicture,
      photoID,
      photoBackID,
      photoSelfie,
      stepsCompleted,
    } = this.state;

    if (
      stepsTakePicture.length === 3 &&
      photoID &&
      photoBackID &&
      photoSelfie
    ) {
      return false;
    }

    if (stepsTakePicture.length === 2 && photoID && photoSelfie) {
      return false;
    }

    if (!Array.from(stepsCompleted).includes(index + 1)) {
      return !Array.from(stepsCompleted).includes(index + 1);
    }
  };

  continueButton = (index) => {
    const { idSelected, activeStep, activeStepTakePicture } = this.state;
    const { translations, lang } = this.props;
    const { buttons } = translations[lang].Modals.CameraModal;
    return activeStep === 0 ? (
      <button
        className="primary"
        type="button"
        onClick={() => this.handleNextStep('activeStep')}
        disabled={!idSelected}
      >
        {buttons.button1}
      </button>
    ) : (
      <div className="action_buttons_camera">
        <button
          className="primary"
          type="button"
          onClick={() => this.handleBackStep('activeStepTakePicture')}
          style={{ width: 150 }}
        >
          {buttons.button2}
        </button>
        <button
          className="primary"
          type="button"
          onClick={() => this.handleNextStep('activeStepTakePicture')}
          disabled={this.enableButtonContinue(index)}
          style={{ width: 150 }}
        >
          {activeStepTakePicture === 4 ? buttons.button3 : buttons.button4}
        </button>
      </div>
    );
  };

  showAllPhotos = () => {
    const { activeStepTakePicture, stepsTakePicture } = this.state;
    //Verifica el step actual y también el total de pasos para la verificación
    if (
      (activeStepTakePicture === 4 && stepsTakePicture.length === 3) ||
      (activeStepTakePicture === 3 && stepsTakePicture.length === 2)
    ) {
      return true;
    }
  };

  closeModal = (response) => {
    if (this.videoElement) {
      this.closeCamera();
    }
    this.props.closeCameraModal(response);
  };

  changeNameDocId = (text) => {
    const { idSelected } = this.state;
    if (text.includes('ID') && idSelected !== 'Otro') {
      const lastIndex = text.lastIndexOf(' ');
      text = text.substring(0, lastIndex);
      return text + ' ' + idSelected;
    }

    return text;
  };

  render() {
    const {
      showVideoCamera,
      activeStep,
      ids,
      idSelected,
      stepsTakePicture,
      activeStepTakePicture,
      photoID,
      photoBackID,
      photoSelfie,
      constraints,
      checkStepper,
      loading,
      verifyFaces,
      flipImage,
    } = this.state;
    const { translations, lang } = this.props;
    const { texts, buttons } = translations[lang].Modals.CameraModal;
    return (
      <div className="modal-container">
        <div
          className={`modal verification_modal ${
            this.showAllPhotos() ? 'active_verify_photos' : ''
          }`}
        >
          <div className="header_modal">
            {activeStep === 0 ? (
              <>
                <h2>{texts.h2}</h2>
                <small>{texts.small1}</small>
              </>
            ) : null}
          </div>
          <div className="body_modal">
            {activeStep === 0 ? (
              <div className="all_verification_types">
                {ids.map((id) => (
                  <p
                    key={id}
                    className={`verification_type_id ${
                      idSelected === id ? 'select_active_id' : 'not_active_id'
                    }`}
                    onClick={() => this.onInputChange(id)}
                  >
                    {this.getIconIds(id)}
                    {id}
                  </p>
                ))}
                {this.continueButton()}
              </div>
            ) : activeStep === 1 ? (
              //MUESTRA EL AVANCE DEL STEPPER
              <div className="container_steps_verification">
                {/* STEPPET TO VERIFY */}
                <div className="steps_to_verify_camera">
                  {stepsTakePicture.map((stepPicture, index) => {
                    const stepTranslation =
                      stepsTranslations[stepPicture][lang];
                    return (
                      <div className="div_step_take_picture">
                        <div
                          className="circulo"
                          style={this.stepperStyles(index)}
                        >
                          {Array.from(checkStepper).includes(index + 1) ? (
                            <i className="fas fa-check"></i>
                          ) : (
                            index + 1
                          )}
                        </div>
                        <small> {stepTranslation} </small>
                      </div>
                    );
                  })}
                </div>
                {/* END STEPPER TO VERIFY */}

                {/* SHOW CAMERA BUTTON TO OPEN */}
                <div className="button_open_camera">
                  {stepsTakePicture.map((stepPicture, index) => {
                    const stepTranslation =
                      stepsTranslations[stepPicture][lang];
                    return activeStepTakePicture === index + 1 &&
                      !showVideoCamera ? (
                      <Fragment key={index}>
                        <h3> {stepTranslation} </h3>
                        <div
                          className="capture_photo"
                          onClick={this.openCamera}
                        >
                          <i className="fas fa-camera"></i>
                          <small>{texts.small2}</small>
                        </div>
                        {this.continueButton(index)}
                      </Fragment>
                    ) : null;
                  })}
                </div>
                {/* END SHOW CAMERA BUTTON TO OPEN */}
                {showVideoCamera ? (
                  <Camera
                    refVideoElement={this.refVideoElement}
                    constraints={constraints}
                    closeCamera={this.closeCamera}
                    handleCapture={this.handleCapture}
                    activeStepTakePicture={activeStepTakePicture}
                    handleFlipImage={this.handleFlipImage}
                    stepsTakePicture={stepsTakePicture}
                    loading={loading}
                    handleFlipCamera={this.handleFlipCamera}
                    flipImage={flipImage}
                  />
                ) : null}

                {/* MUESTRA LAS FOTOS Y EL BOTON DE VERIFICAR */}
                <div className="container_verification_images">
                  {this.showAllPhotos() ? (
                    <div className="show_images">
                      <small>{texts.small3}</small>
                      <div className="my_images_verification">
                        <img alt="Frente" src={photoID?.dataUrl} />
                        {photoBackID && (
                          <img alt="Reverso" src={photoBackID?.dataUrl} />
                        )}
                        <img alt="Selfie" src={photoSelfie?.dataUrl} />
                      </div>
                      {idSelected === 'INE / IFE' && (
                        <div className="verification_validate_by">
                          <div className="renapo">
                            <img alt="Renapo" src="/renapo.png" />
                            <small>{texts.small4}</small>
                          </div>
                          <div className="ine">
                            <img alt="INE" src="/ine.png" />
                            <small>{texts.small5}</small>
                          </div>
                        </div>
                      )}

                      {this.continueButton()}
                    </div>
                  ) : verifyFaces && loading ? (
                    <div className="center_loading">
                      <Loader />
                      <p>{texts.p1}</p>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
          {!showVideoCamera && (
            <div className="footer_modal">
              <button
                className="color-grey"
                type="button"
                onClick={this.closeModal}
              >
                {buttons.button5}
              </button>
            </div>
          )}
        </div>
        <div className="background-screen" />
      </div>
    );
  }
}

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  CameraModal,
);
