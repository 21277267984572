import React from 'react';
import { branch } from 'baobab-react/higher-order';
import { Redirect } from 'react-router-dom';
import {
  LineChart,
  Line,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import SuperUserActions from '../../actions/SuperUser';
import { NotificationManager } from 'react-notifications';

class Graphs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      monthlyContracts: [],
      monthlyUsers: [],
      contractsInfo: [],
      usersInfo: [],
      yearContracts: '',
      yearUsers: '',
      contractsCount: '',
      usersCount: '',
    };
  }

  async componentDidMount() {
    const records = await SuperUserActions.getConservancyRecords();
    if (records.status !== 200) {
      NotificationManager.error(`Hubo un error`, 'Éxito', 3000);
    } else {
      this.setState({ records: records.data });
    }

    // Get contracts info
    const contractsInfo = await SuperUserActions.getContractsInfo();
    //Validate the api response
    if (contractsInfo.status !== 200) {
      NotificationManager.error(
        `Hubo un error al obtener contratos`,
        'Éxito',
        3000,
      );
    } else {
      this.setState({ contractsInfo: contractsInfo.data });
    }

    // Get user info
    const usersInfo = await SuperUserActions.getUsersInfo();
    // Validate the api response
    if (usersInfo.status !== 200) {
      NotificationManager.error(
        `Hubo un error al obtener usuarios`,
        'Éxito',
        3000,
      );
    } else {
      this.setState({ usersInfo: usersInfo.data });
    }
  }

  // Get contracts by month and year
  async handleMonthlyContracts(value) {
    const { yearContracts } = this.state;
    //Get contracts by month
    const monthlyContracts = await SuperUserActions.getContractsByMonth(
      yearContracts + value,
    );
    // Validate api response
    if (monthlyContracts.status !== 200) {
      NotificationManager.error(
        `Hubo problemas al mostrar gráfica de contratos`,
        'Atención',
        3000,
      );
    } else {
      this.setState({ monthlyContracts: monthlyContracts.data });
    }

    //Get total contract
    const contractsCount = await SuperUserActions.getContractCount(
      yearContracts + value,
    );
    // Validate api response
    if (contractsCount.status !== 200) {
      NotificationManager.error(
        `Hubo problemas al mostrar el total`,
        'Atención',
        3000,
      );
    } else {
      this.setState({ contractsCount: contractsCount.data });
    }
  }

  // Get users by month and year
  async handleMonthlyUsers(value) {
    const { yearUsers } = this.state;
    // Get users by month
    const monthlyUsers = await SuperUserActions.getUsersByMonth(
      yearUsers + value,
    );
    // Validate api response
    if (monthlyUsers.status !== 200) {
      NotificationManager.error(
        `Hubo problemas al mostrar la gráfica de usuarios`,
        'Atención',
        3000,
      );
    } else {
      this.setState({ monthlyUsers: monthlyUsers.data });
    }

    //Get total number of users
    const usersCount = await SuperUserActions.getUserCount(yearUsers + value);
    // Validate api response
    if (usersCount.status !== 200) {
      NotificationManager.error(
        `Hubo problemas al mostrar el total de usuarios`,
        'Atención',
        3000,
      );
    } else {
      this.setState({ usersCount: usersCount.data });
    }
  }

  handleYearContracts(event) {
    this.setState({ yearContracts: event.target.value });
  }

  handleYearUsers(event) {
    this.setState({ yearUsers: event.target.value });
  }

  render() {
    const { session, location } = this.props;
    const {
      monthlyContracts,
      monthlyUsers,
      contractsInfo,
      usersInfo,
      contractsCount,
      usersCount,
    } = this.state;
    if (!session) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: {
              from: location.pathname,
            },
          }}
        />
      );
    }
    return (
      <div className="graphs_container">
        <div className="contracts_sp">
          <label>CONTRATOS CREADOS POR MES: </label>
          <b>{contractsCount}</b>
          <LineChart
            width={500}
            height={200}
            data={monthlyContracts}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Line dataKey="cantidad" stroke="#3366ff" />
          </LineChart>
          <div className="select_year">
            <p className="">Selecciona año a consultar:</p>
            <select
              onChange={this.handleYearContracts.bind(this)}
              className="input_nc"
            >
              <option value="2017">2017</option>
              <option value="2018">2018</option>
              <option value="2019">2019</option>
              <option value="2020">2020</option>
              <option value="2021">2021</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
            </select>
          </div>
          <button
            className="secondary"
            type="button"
            style={{
              width: 80,
              marginLeft: 5,
              color: 'blue',
            }}
            onClick={() => this.handleMonthlyContracts('-01')}
          >
            Ene
          </button>
          <button
            className="secondary"
            type="button"
            style={{
              width: 80,
              marginLeft: 5,
              color: 'blue',
            }}
            onClick={() => this.handleMonthlyContracts('-02')}
          >
            Feb
          </button>
          <button
            className="secondary"
            type="button"
            style={{
              width: 80,
              marginLeft: 5,
              color: 'blue',
            }}
            onClick={() => this.handleMonthlyContracts('-03')}
          >
            Mar
          </button>
          <button
            className="secondary"
            type="button"
            style={{
              width: 80,
              marginLeft: 5,
              color: 'blue',
            }}
            onClick={() => this.handleMonthlyContracts('-04')}
          >
            Abr
          </button>
          <button
            className="secondary"
            type="button"
            style={{
              width: 80,
              marginLeft: 5,
              color: 'blue',
            }}
            onClick={() => this.handleMonthlyContracts('-05')}
          >
            May
          </button>
          <button
            className="secondary"
            type="button"
            style={{
              width: 80,
              marginLeft: 5,
              color: 'blue',
            }}
            onClick={() => this.handleMonthlyContracts('-06')}
          >
            Jun
          </button>

          <button
            className="secondary"
            type="button"
            style={{
              width: 80,
              marginLeft: 5,
              color: 'blue',
            }}
            onClick={() => this.handleMonthlyContracts('-07')}
          >
            Jul
          </button>

          <button
            className="secondary"
            type="button"
            style={{
              width: 80,
              marginLeft: 5,
              color: 'blue',
            }}
            onClick={() => this.handleMonthlyContracts('-08')}
          >
            Ago
          </button>
          <button
            className="secondary"
            type="button"
            style={{
              width: 80,
              marginLeft: 5,
              color: 'blue',
            }}
            onClick={() => this.handleMonthlyContracts('-09')}
          >
            Sep
          </button>
          <button
            className="secondary"
            type="button"
            style={{
              width: 80,
              marginLeft: 5,
              color: 'blue',
            }}
            onClick={() => this.handleMonthlyContracts('-10')}
          >
            Oct
          </button>
          <button
            className="secondary"
            type="button"
            style={{
              width: 80,
              marginLeft: 5,
              color: 'blue',
            }}
            onClick={() => this.handleMonthlyContracts('-11')}
          >
            Nov
          </button>
          <button
            className="secondary"
            type="button"
            style={{
              width: 80,
              marginLeft: 5,
              color: 'blue',
            }}
            onClick={() => this.handleMonthlyContracts('-12')}
          >
            Dic
          </button>
          <div className="table_contracts">
            <p>INFORMACION DE CONTRATOS</p>
            <div className="head_table">
              <small className="other_status"></small>
              <small className="other_status">
                En la semana{' '}
                <b>{contractsInfo.weekStart + ' - ' + contractsInfo.weekEnd}</b>
              </small>
              <small className="other_status">
                En el mes anterior<b> ({contractsInfo.currentMonth}) </b>
              </small>
              <small className="other_status">TOTAL</small>
            </div>
            <div className="body_table">
              <small className="other_status">Creados</small>
              <small className="other_status">
                {contractsInfo.createdLastWeek}
              </small>
              <small className="other_status">
                {contractsInfo.createdLastMonth}
              </small>
              <small className="other_status">
                {' '}
                <b>{contractsInfo.createdTotal}</b>
              </small>

              <small className="other_status">Firmados completamente</small>
              <small className="other_status">
                {contractsInfo.readyLastWeek}
              </small>
              <small className="other_status">
                {contractsInfo.readyLastMonth}
              </small>
              <small className="other_status">
                <b>{contractsInfo.readyTotal}</b>
              </small>

              <small className="other_status">Firmados parcialmente</small>
              <small className="other_status">
                {contractsInfo.createdLastWeek - contractsInfo.readyLastWeek}
              </small>
              <small className="other_status">
                {contractsInfo.createdLastMonth - contractsInfo.readyLastMonth}
              </small>
              <small className="other_status">
                <b>{contractsInfo.createdTotal - contractsInfo.readyTotal}</b>
              </small>
            </div>
          </div>
        </div>
        <div className="users_sp">
          <label>USUARIOS REGISTRADOS POR MES: </label>
          <b>{usersCount}</b>
          <LineChart
            width={500}
            height={200}
            data={monthlyUsers}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Line dataKey="cantidad" stroke="#3366ff" />
          </LineChart>

          <div className="select_year">
            <p>Selecciona año a consultar:</p>
            <select
              className="input_nc"
              onChange={this.handleYearUsers.bind(this)}
            >
              <option value="2017">2017</option>
              <option value="2018">2018</option>
              <option value="2019">2019</option>
              <option value="2020">2020</option>
              <option value="2021">2021</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
            </select>
          </div>
          <button
            className="secondary"
            type="button"
            style={{
              width: 80,
              marginLeft: 5,
              color: 'blue',
            }}
            onClick={() => this.handleMonthlyUsers('-01')}
          >
            Ene
          </button>
          <button
            className="secondary"
            type="button"
            style={{
              width: 80,
              marginLeft: 5,
              color: 'blue',
            }}
            onClick={() => this.handleMonthlyUsers('-02')}
          >
            Feb
          </button>
          <button
            className="secondary"
            type="button"
            style={{
              width: 80,
              marginLeft: 5,
              color: 'blue',
            }}
            onClick={() => this.handleMonthlyUsers('-03')}
          >
            Mar
          </button>
          <button
            className="secondary"
            type="button"
            style={{
              width: 80,
              marginLeft: 5,
              color: 'blue',
            }}
            onClick={() => this.handleMonthlyUsers('-04')}
          >
            Abr
          </button>
          <button
            className="secondary"
            type="button"
            style={{
              width: 80,
              marginLeft: 5,
              color: 'blue',
            }}
            onClick={() => this.handleMonthlyUsers('-05')}
          >
            May
          </button>
          <button
            className="secondary"
            type="button"
            style={{
              width: 80,
              marginLeft: 5,
              color: 'blue',
            }}
            onClick={() => this.handleMonthlyUsers('-06')}
          >
            Jun
          </button>

          <button
            className="secondary"
            type="button"
            style={{
              width: 80,
              marginLeft: 5,
              color: 'blue',
            }}
            onClick={() => this.handleMonthlyUsers('-07')}
          >
            Jul
          </button>

          <button
            className="secondary"
            type="button"
            style={{
              width: 80,
              marginLeft: 5,
              color: 'blue',
            }}
            onClick={() => this.handleMonthlyUsers('-08')}
          >
            Ago
          </button>
          <button
            className="secondary"
            type="button"
            style={{
              width: 80,
              marginLeft: 5,
              color: 'blue',
            }}
            onClick={() => this.handleMonthlyUsers('-09')}
          >
            Sep
          </button>
          <button
            className="secondary"
            type="button"
            style={{
              width: 80,
              marginLeft: 5,
              color: 'blue',
            }}
            onClick={() => this.handleMonthlyUsers('-10')}
          >
            Oct
          </button>
          <button
            className="secondary"
            type="button"
            style={{
              width: 80,
              marginLeft: 5,
              color: 'blue',
            }}
            onClick={() => this.handleMonthlyUsers('-11')}
          >
            Nov
          </button>
          <button
            className="secondary"
            type="button"
            style={{
              width: 80,
              marginLeft: 5,
              color: 'blue',
            }}
            onClick={() => this.handleMonthlyUsers('-12')}
          >
            Dic
          </button>
          <div className="table_users">
            <p> INFORMACION DE USUARIOS</p>
            <div className="head_table">
              <small className="other_status"></small>
              <small className="other_status">
                En la semana
                <b>
                  {' '}
                  {contractsInfo.weekStart + ' - ' + contractsInfo.weekEnd}{' '}
                </b>
              </small>
              <small className="other_status">
                En el mes anterior<b> ({contractsInfo.currentMonth}) </b>
              </small>
              <small className="other_status">TOTAL</small>
            </div>
            <div className="body_table">
              <small className="other_status">Dados de alta</small>
              <small className="other_status">
                {usersInfo.registeredLastWeek}
              </small>
              <small className="other_status">
                {usersInfo.registeredLastMonth}
              </small>
              <small className="other_status">
                {' '}
                <b>{usersInfo.registeredTotal}</b>
              </small>
              <small className="other_status">Dados de alta</small>
              <small className="other_status">
                {usersInfo.registeredLastWeek}
              </small>
              <small className="other_status">
                {usersInfo.registeredLastMonth}
              </small>
              <small className="other_status">
                {' '}
                <b>{usersInfo.registeredTotal}</b>
              </small>
              <small className="other_status">Activos</small>
              <small className="other_status">{usersInfo.activeLastWeek}</small>
              <small className="other_status">
                {usersInfo.activeLastMonth}
              </small>
              <small className="other_status">
                <b>{'N/A'}</b>
              </small>
              <small className="other_status">Han creado 1+ contratos</small>
              <small className="other_status">
                {usersInfo.createdLastWeek}
              </small>
              <small className="other_status">
                {usersInfo.createdLastMonth}
              </small>
              <small className="other_status">
                <b>{usersInfo.createdTotal}</b>
              </small>
              <small className="other_status">Han firmado 1+ contratos</small>
              <small className="other_status">{usersInfo.signedLastWeek}</small>
              <small className="other_status">
                {usersInfo.signedLastMonth}
              </small>
              <small className="other_status">
                <b>{usersInfo.signedTotal}</b>
              </small>
              <small className="other_status">Inactivos</small>
              <small className="other_status">
                {usersInfo.registeredLastWeek - usersInfo.activeLastWeek}
              </small>
              <small className="other_status">
                {usersInfo.registeredLastMonth - usersInfo.activeLastMonth}
              </small>
              <small className="other_status">
                <b>{'N/A'}</b>
              </small>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default branch(
  {
    session: ['session'],
  },
  Graphs,
);

/*
              <tr>
                <small>Usuarios creados: <b>{countInfo.users}</b></small>
              </tr>
              <tr>
                <small>Usuarios que han creado por lo menos un
                  contrato: <b>{countInfo.usersCreated}</b>
                </small>
              </tr>
              <tr>
                <small>Usuarios que han firmado por lo menos un
                  contrato: <b>{countInfo.usersSigned}</b>
                </small>
              </tr>
              */
