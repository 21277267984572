export const MyAPITranslations = {
  es: {
    texts: {
      formLabel1: 'Solicitud de API',
      p1: 'Nuestro API te permite crear documentos automáticamente y firmarlos sin salir de tu página. Para solicitar la conexión con este RFC, da click en el botón de abajo.',
    },
    inputs: {},
    buttons: {
      button1: 'Solicitar',
    },
    notifications: {
      notifTitle1: 'Exito',
      notifBody1: 'Hemos avisado a nuestro equipo de tu solicitud',
      notifTitle2: 'Atención',
      notifBody2:
        'No hemos podido avisar a nuestro equipo de tu solicitud, intenta nuevamente',
    },
  },
  en: {
    texts: {
      formLabel1: 'API request',
      p1: 'Our API allows you to create documents automatically and sign them without leaving your page. To request the connection with this RFC, click on the button below.',
    },
    inputs: {},
    buttons: {
      button1: 'Request',
    },
    notifications: {
      notifTitle1: 'Success',
      notifBody1: 'We have notified our team of your request',
      notifTitle2: 'Attention',
      notifBody2:
        'We have not been able to notify our team of your request, please try again',
    },
  },
};
