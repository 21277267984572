import React from 'react';
import { Redirect } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
// Actions
import Nav from '../../components/Landing/Nav';
import { LoginForm } from './LoginForm';
import { branch } from 'baobab-react/higher-order';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cer: null,
      message: null,
      key: null,
      passphrase: '',
      demo: false,
      loading: false,
      register: false,
      showmodalsife: false,
    };
  }

  componentDidMount() {
    localStorage.removeItem('redirect');
    const pathname = window.history.state
      ? window.history.state.state.from
      : '/';
    if (pathname.includes('/contratos') || pathname.includes('/public')) {
      localStorage.setItem('redirect', pathname);
    }
  }

  /**
   * Redirects to Demo
   */
  handleDemo = () => {
    this.setState({ demo: true });
  };

  // Show modal register sife
  showModalLoginSife = () => {
    this.setState({ showmodalsife: true });
  };

  hideModal = () => {
    this.setState({ showmodalsife: false });
    // this.setState({ showModalSigned: false });
  };

  render() {
    const { location, handleDemo, translations, lang } = this.props;
    const { demo, loading, message } = this.state;

    const translation = translations[lang].Login;
    const { texts, buttons } = translation;

    return (
      <>
        {demo ? (
          <Redirect
            to={{
              pathname: '/demo',
              state: {
                from: location.pathname,
              },
            }}
          />
        ) : null}

        <NotificationContainer />
        <Nav lang={this.props.lang} />
        <div className="container_landing">
          <div className="login_wrapper">
            <div className="login_form">
              <LoginForm
                message={message}
                showModalLoginSife={this.showModalLoginSife}
                loading={loading}
                translation={translation}
              />
            </div>
            <div className="info_login_form">
              <div className="container_info_login">
                <h1>{texts.h1}</h1>
                <h3>{texts.h3}</h3>
                <div>
                  <label>
                    <i className="fas fa-check" /> &nbsp;
                    {texts.label1}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </label>
                  <label>
                    <i className="fas fa-check" /> &nbsp;
                    {texts.label2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </label>
                  <label>
                    <i className="fas fa-check" /> &nbsp;{texts.label3}
                  </label>
                </div>
                <br />
                <button
                  className="login_button"
                  type="button"
                  onClick={handleDemo || this.handleDemo}
                  style={{
                    marginTop: '12px',
                  }}
                >
                  <b>{buttons.testDemoUser}</b>
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  Login,
);
// export default Login;
