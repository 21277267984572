export const IframeToSignTranslations = {
  es: {
    texts: {},
    inputs: {},
    buttons: {
      button1: 'Cerrar',
    },
    notifications: {
      notifTitle1: 'Exito',
      notifBody1: 'Documento firmado con éxito.\n Deslice para ver su firma.',
      notifTitle2: 'Exito',
      notifBody2:
        'Adendum firmado con éxito.\n Espere unos segundos y deslice para ver su firma.',
    },
  },
  en: {
    texts: {},
    inputs: {},
    buttons: {
      button1: 'Close',
    },
    notifications: {
      notifTitle1: 'Success',
      notifBody1:
        'Document signed successfully.\n Scroll to see your signature.',
      notifTitle2: 'Success',
      notifBody2:
        'Addendum signed successfully.\n Wait a few seconds and scroll to see your signature.',
    },
  },
};
