import React from 'react';
import CerFields from '../../containers/_common/CerFields';
import ViewPdf from '../../containers/_common/ViewPdf';
import { branch } from 'baobab-react/higher-order';

class AuthorizeTokenModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responseToAuthorize: '',
      openNewViewInputs: false,
    };
  }

  openNewIputs = () => {
    this.setState({ openNewViewInputs: true });
  };

  getDataFromInputs = (data) => {
    this.setState(data);
  };

  canSign = () => {
    const { key, cer, passphrase } = this.state;
    return passphrase?.length && key && cer;
  };

  onSelectChange = (event) => {
    const { value } = event.target;
    if (value === '1') {
      this.setState({ responseToAuthorize: value });
      return;
    }

    this.setState({ responseToAuthorize: value });
  };

  onTextAreaChange = (event) => {
    const { value } = event.target;
    this.setState({ comments: value });
  };

  returnSteps = () => {
    const { responseToAuthorize, openNewViewInputs } = this.state;
    if (openNewViewInputs) {
      return this.setState({ openNewViewInputs: false });
    }
    const { closeModalToSign } = this.props;
    if (responseToAuthorize === '1' || responseToAuthorize === '2') {
      return this.setState({ responseToAuthorize: '' });
    }

    closeModalToSign();
  };

  handleButton(userToSign) {
    const { responseToAuthorize, openNewViewInputs } = this.state;
    const { sendResponse, width, lang, translations } = this.props;
    const isNotMobile = width?.width > 992;
    const { texts, buttons, inputs } =
      translations[lang].Modals.AuthorizeTokenModal;

    if (userToSign.deniedReason) {
      return <p style={{ color: '#f95c5e' }}>{texts.p1}</p>;
    }

    if (userToSign.authorized) {
      return <p style={{ color: '#7ed321' }}>{texts.p2}</p>;
    }

    if (responseToAuthorize === '1') {
      return isNotMobile ? (
        <button
          className="primary"
          type="button"
          disabled={!this.canSign()}
          onClick={() => sendResponse(this)}
        >
          {buttons.button1}
        </button>
      ) : (
        <button
          className="primary"
          type="button"
          disabled={openNewViewInputs ? !this.canSign() : false}
          onClick={() =>
            openNewViewInputs ? sendResponse(this) : this.openNewIputs()
          }
        >
          {openNewViewInputs ? buttons.button2 : buttons.button3}
        </button>
      );
    }

    if (responseToAuthorize === '2') {
      return (
        <button
          className="primary"
          type="button"
          onClick={() => sendResponse(this)}
        >
          buttons.button4
        </button>
      );
    }

    return (
      <div className="dropdown_token_authorize">
        <select
          name="signType"
          onChange={this.onSelectChange}
          defaultValue=""
          className="input_nc"
        >
          <option value="" disabled>
            {inputs.inputSelectOptDefault}
          </option>
          <option value="1">{inputs.inputSelectOpt1}</option>
          <option value="2">{inputs.inputSelectOpt2}</option>
        </select>
        <i className="fas fa-chevron-down"></i>
      </div>
    );
  }

  render() {
    const { responseToAuthorize, openNewViewInputs } = this.state;
    const { translations, lang, userToSign, contractUrl, width } = this.props;
    const { texts, buttons, inputs } =
      translations[lang].Modals.AuthorizeTokenModal;
    return (
      <div className="modal-container-sign">
        <div className="modal-sign contract_preview_authorizer">
          {!openNewViewInputs || width?.width > 992 ? (
            <ViewPdf contractUrl={contractUrl} />
          ) : null}
          <div
            className="body_modal token_modal"
            style={{ textAlign: 'center' }}
          >
            {responseToAuthorize === '1' ? (
              width?.width > 992 ? (
                <CerFields getDataFromInputs={this.getDataFromInputs} />
              ) : openNewViewInputs ? (
                <div className="mobile_version">
                  <div className="information">
                    <h3>{texts.h3}</h3>
                    <p>{texts.p3}</p>
                  </div>
                  <CerFields getDataFromInputs={this.getDataFromInputs} />
                </div>
              ) : null
            ) : null}
            {responseToAuthorize === '2' ? (
              <textarea
                className="text_area_comments"
                id="description"
                name="description"
                placeholder={inputs.textareaPlaceholder1}
                onChange={this.onTextAreaChange}
                rows={3}
              />
            ) : null}
          </div>

          <div
            className="footer_modal"
            style={{ paddingRight: 10, paddingLeft: 10, marginBottom: 10 }}
          >
            <button
              className="color-grey"
              type="button"
              onClick={this.returnSteps}
            >
              {buttons.button5}
            </button>
            {this.handleButton(userToSign)}
          </div>
        </div>
        <div className="background-screen" />
      </div>
    );
  }
}

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  AuthorizeTokenModal,
);
