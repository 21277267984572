import React from 'react';

const Loader = ({ totalLoading }) => (
  <>
    <div className="sk-chase">
      <div className="sk-chase-dot" />
      <div className="sk-chase-dot" />
      <div className="sk-chase-dot" />
      <div className="sk-chase-dot" />
      <div className="sk-chase-dot" />
      <div className="sk-chase-dot" />
      <br></br>
    </div>
    {totalLoading ? <p>{totalLoading}%</p> : null}
  </>
);

export default Loader;
