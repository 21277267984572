export const ChipsDisplayMassiveTranslations = {
  es: {
    texts: {
      span1: 'ó',
      label1: 'Máximo de 10 etiquetas por documento.',
    },
    inputs: {
      inputLabel1: 'Etiquetas',
      inputLabelItalic1: 'Opcional',
      inputPlaceholder1: 'Usar mis etiquetas',
      inputPlaceholder2: 'Crear nuevas etiquetas',
    },
    buttons: {},
    notifications: {},
  },
  en: {
    texts: {
      label1: 'Maximum of 10 tags per document.',
    },
    inputs: {
      inputLabel1: 'Tags',
      inputLabelItalic1: 'Optional',
      inputPlaceholder1: 'Use my tags',
      span1: 'or',
      inputPlaceholder2: 'Create new tags',
    },
    buttons: {},
    notifications: {},
  },
};
