import { branch } from 'baobab-react/higher-order';
import React from 'react';

const SelectDateDisplay = ({
  Checkbox,
  checkDate,
  handleCheckDate,
  onInputChange,
  loading,
  date,
  translations,
  lang,
}) => {
  const { texts, inputs } =
    translations[lang].NewContractMassive.SelectDateDisplay;
  return (
    <div className="expires_massive">
      <label className="label_nc" htmlFor="date">
        {texts.label1}
      </label>
      <div className="inputs_date_massive">
        <div className="inputs_date_massive_checkbox">
          <Checkbox
            color="red"
            size="3"
            tickSize="1"
            borderThickness="2"
            checked={checkDate}
            onChange={handleCheckDate}
          />
          <span>{inputs.checkboxDiv1}</span>
        </div>
        <div className="inputs_date_massive">
          <div className="inputs_date_massive_checkbox">
            <Checkbox
              color="red"
              size="3"
              tickSize="1"
              borderThickness="2"
              checked={!checkDate}
              onChange={handleCheckDate}
            />
            <span>{inputs.checkboxDiv2}</span>
          </div>
        </div>
      </div>
      {checkDate ? (
        <input
          id="date"
          name="date"
          onChange={onInputChange}
          value={date}
          disabled={loading || !checkDate}
          type="date"
          className="input_nc"
        />
      ) : null}
    </div>
  );
};

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  SelectDateDisplay,
);
