export function validateIconSuperAdmin(contract) {
  if (contract.allPartiesSigned && contract.status === 'ready') {
    return <i className="fas fa-check-circle" style={{ color: '#7ED321' }} />;
  }

  if (contract.hasOwnProperty('authorizedAt') && !contract.signed) {
    return <i className="far fa-flag" style={{ color: '#ffb432' }} />;
  }

  if (contract.status === 'waitingOthers') {
    return <i className="far fa-clock" style={{ color: '#e19614' }} />;
  }
  if (contract.status === 'byMe') {
    return <i className="fas fa-check" style={{ color: '#7ED321' }} />;
  }
  if (contract.status === 'copied') {
    return <i className="far fa-copy" style={{ color: '#6C6C6C' }} />;
  }
  if (contract.is_valid === 0 && contract.id !== '0') {
    return <i className="fas fa-ban" style={{ color: '#D0021B' }} />;
  }

  if (contract.status === 'authPending') {
    return <i className="far fa-flag" style={{ color: '#ffb432' }} />;
  }

  if (contract.status === 'noAuthorized') {
    return <i className="far fa-flag" style={{ color: '#f95c5e' }} />;
  }
}
