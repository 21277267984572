import React from 'react';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';
import { branch } from 'baobab-react/higher-order';
import { NotificationManager } from 'react-notifications';
// Actions
import SuperUserActions from '../../actions/SuperUser';
import { downloadExcel } from '../../utils/downloadFilesUtils';

class ApiUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apiValidated: [],
      apiRequests: [],
      tokenUsers: [],
    };
  }

  async componentDidMount() {
    //Request apivalidate
    const apiValidated = await SuperUserActions.getApiValidated();
    if (apiValidated.status !== 200) {
      NotificationManager.error('Hubo un error al validar', 'Atención', 10000);
    } else {
      this.setState({ apiValidated: apiValidated.data });
    }

    //Request api requests
    const apiRequests = await SuperUserActions.getApiRequests();
    if (apiRequests.status !== 200) {
      NotificationManager.error(
        'Hubo un error al obtener solicitudes',
        'Atención',
        10000,
      );
    } else {
      this.setState({ apiRequests: apiRequests.data });
    }

    // Request get token users
    const tokenUsers = await SuperUserActions.getTokenUsers();
    if (tokenUsers.status !== 200) {
      NotificationManager.error(
        'Hubo un error al obtener reactivación de tokens',
        'Atención',
        10000,
      );
    } else {
      this.setState({ tokenUsers: tokenUsers.data });
    }
  }

  handleForm = (event) => {
    event.preventDefault();
  };

  async handleAuthorize(userId) {
    const response = await SuperUserActions.updateApiUser(userId);
    if (response.status !== 200) {
      NotificationManager.error(
        'No se pudo autorizar usuario',
        'Atención',
        10000,
      );
    } else {
      NotificationManager.success(
        'Usuario autorizado exitosamente',
        'Éxito',
        10000,
      );
    }
  }

  async handleRenewToken(tokenId, legalRfc) {
    const { location, history } = this.props;
    const response = await SuperUserActions.updateToken(tokenId, legalRfc);
    if (response.status !== 200) {
      NotificationManager.error(
        'No se pudo reactivar el token',
        'Atención',
        10000,
      );
    } else {
      NotificationManager.success(response.data.message, 'Éxito', 10000);
      return history.push({
        pathname: '/perfil',
        state: {
          from: location.pathname,
        },
      });
    }
  }

  /*
  compareDates = (current, expires) => {
    const d1 = new Date(current);
    const d2 = new Date(expires);
    return d1 === d2;
  };
   */

  render() {
    const { session, location, myApiUsers } = this.props;
    const { apiValidated, apiRequests, tokenUsers } = this.state;
    if (!session) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: {
              from: location.pathname,
            },
          }}
        />
      );
    }
    return (
      <div className="container_api_users">
        <p>Usuarios de API</p>
        <div className="my_api_users border_api">
          {myApiUsers.length
            ? myApiUsers.map((apiUser) => (
                <>
                  <input
                    disabled
                    type="text"
                    name="legalRfc"
                    defaultValue={`Código: ${apiUser.code}`}
                    className="input_nc"
                    maxLength="16"
                    placeholder="apiUser Number"
                  />
                  <input
                    disabled
                    type="text"
                    name="legalName"
                    defaultValue={`Contratos: ${apiUser.contracts}`}
                    className="input_nc"
                    style={{ textTransform: 'capitalize' }}
                    placeholder="Titular de tarjeta"
                  />
                  <input
                    disabled
                    type="text"
                    name="email"
                    defaultValue={`Usados: ${apiUser.email}`}
                    className="input_nc"
                    placeholder="Fecha de expiración"
                  />
                  <i
                    className="fas fa-user-check"
                    style={{
                      color: apiUser.validated ? '#7ED321' : '#D0021B',
                    }}
                  />
                </>
              ))
            : null}
        </div>
        <p>API Templates</p>
        <div className="api_templates border_api">
          <div className="header_api_templates">
            <small>RFC</small>
            <small>Fecha de autorizacion</small>
            <small>Plantilla</small>
            <small># de documentos creados</small>
          </div>
          <div className="body_api_templates">
            {apiValidated.length
              ? apiValidated.map((user, index) => (
                  <>
                    <small>
                      {index === 0
                        ? user.legalRfc
                        : apiValidated[index].legalRfc ===
                          apiValidated[index - 1].legalRfc
                        ? null
                        : user.legalRfc}
                    </small>
                    <small>
                      {index === 0
                        ? moment(user.createdAt).format('DD MMM YYYY h:mm a')
                        : apiValidated[index].createdAt ===
                          apiValidated[index - 1].createdAt
                        ? null
                        : moment(user.createdAt).format('DD MMM YYYY h:mm a')}
                    </small>
                    <small>{user.templateId}</small>
                    <small>{user.count}</small>
                  </>
                ))
              : null}
          </div>
          <button
            type="submit"
            className="primary"
            onClick={() => downloadExcel(apiValidated, 'usuarios_api_template')}
          >
            Descargar Excel
          </button>
        </div>

        <p>Renovación de Token</p>
        <div className="renovate_token border_api">
          <div className="header_renovate_token">
            <small>Usuario</small>
            <small>Fecha de vigencia</small>
            <small></small>
          </div>
          <div className="body_renovate_token">
            {tokenUsers.length
              ? tokenUsers.map((req) => (
                  <>
                    <small>{req.legalRfc}</small>
                    <small>
                      {moment(req.createdAt)
                        .add(1, 'year')
                        .format('DD MMM YYYY h:mm a')}
                    </small>
                    <small>
                      <button
                        type="submit"
                        className="primary"
                        onClick={this.handleRenewToken.bind(
                          this,
                          req.id,
                          req.legalRfc,
                        )}
                      >
                        Renovar
                      </button>
                    </small>
                  </>
                ))
              : null}
          </div>
          <button
            type="submit"
            className="primary"
            onClick={() =>
              downloadExcel(tokenUsers, 'usuarios_activacion_token')
            }
          >
            Descargar Excel
          </button>
        </div>

        <p>Solicitudes API</p>
        <div className="api_request border_api">
          <div className="header_renovate_token">
            <small>Usuario</small>
            <small>Fecha de solicitud</small>
            <small></small>
          </div>
          <div className="body_renovate_token">
            {apiRequests.length
              ? apiRequests.map((req) => (
                  <tr key={req.id}>
                    <td className="pb-0">{req.Usuario}</td>
                    <td className="pb-0">
                      {moment(req.FechaSolicitud).format('DD MMM YYYY h:mm a')}
                    </td>
                    <td className="pb-0">
                      <button
                        type="submit"
                        className="primary"
                        onClick={this.handleAuthorize.bind(this, req.userId)}
                        disabled={req.Autorizado}
                      >
                        Autorizar
                      </button>
                    </td>
                  </tr>
                ))
              : null}
          </div>
          <button
            type="submit"
            className="primary"
            onClick={() => downloadExcel(apiRequests, 'usuarios_solicitud_api')}
          >
            Descargar Excel
          </button>
        </div>

        <div style={{ marginTop: 30 }}>
          <Link
            to={{
              pathname: '/nuevo/apiUser',
              state: {
                from: location.pathname,
              },
            }}
          >
            <span className="add mt-5 mb-10">
              {' '}
              Nuevo apiUser <i className="fas fa-plus-circle" />
            </span>
          </Link>
        </div>
      </div>
    );
  }
}

export default branch(
  {
    session: ['session'],
    myApiUsers: ['myApiUsers'],
  },
  ApiUsers,
);
