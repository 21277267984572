import React from 'react';
import { branch } from 'baobab-react/higher-order';
import { Redirect } from 'react-router-dom';
import SuperUserActions from '../../actions/SuperUser';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import Loader from '../../components/Loader';
class ContractsForMe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      loading: false,
    };
  }

  createContracts = async () => {
    const { total } = this.state;
    this.setState({ loading: true });
    const response = await SuperUserActions.createContracts({ total });
    const { data } = response;
    if (!data.success) {
      this.setState({ loading: false });
      return NotificationManager.error(data.message, 'Error', 3000);
    }
    this.setState({ loading: false });
    return NotificationManager.success(data.message, 'Éxito', 3000);
  };

  onInputChange = (e) => {
    this.setState({ total: e.target.value });
  };

  render() {
    const { session, location } = this.props;
    const { loading } = this.state;
    if (!session) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: {
              from: location.pathname,
            },
          }}
        />
      );
    }

    return (
      <>
        <div className="row">
          <div className="col-md-12 align-center">
            {loading ? (
              <>
                <div style={{ marginTop: 20 }} className="row center-xs mt-1">
                  <Loader />
                </div>
                <small>Creando documentos...</small>
              </>
            ) : (
              <>
                <h4>Agrega documentos para ti</h4>
                <input
                  style={{ padding: 7 }}
                  id="total"
                  name="total"
                  placeholder="Total de documentos"
                  type="number"
                  min="1"
                  onChange={this.onInputChange}
                />
                <br />
                <button
                  style={{ width: 250 }}
                  type="button"
                  className="primary"
                  onClick={this.createContracts}
                  disabled={!this.state.total}
                >
                  Crear documentos
                </button>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default branch(
  {
    session: ['session'],
  },
  ContractsForMe,
);
