import { endpointHandler } from '../createEndpoint';

const {
  listLabelEP,
  getThisSignersEP,
  getThisLabelsEP,
  createLabelEP,
  assignEP,
  listRootEP,
  myLabelsEP,
  rootLabelsEP,
  removeContractLabelsEP,
} = endpointHandler();

// GET
export const listLabelService = (reqConfig, urlVar) => {
  return listLabelEP.get(reqConfig, urlVar);
};

export const getThisSignersService = (reqConfig, urlVar) => {
  return getThisSignersEP.get(reqConfig, urlVar);
};

export const getThisLabelsService = (reqConfig, urlVar) => {
  return getThisLabelsEP.get(reqConfig, urlVar);
};

export const listRootService = (reqConfig, urlVar) => {
  return listRootEP.get(reqConfig, urlVar);
};

export const myLabelsService = (reqConfig, urlVar) => {
  return myLabelsEP.get(reqConfig, urlVar);
};

export const rootLabelsService = (reqConfig, urlVar) => {
  return rootLabelsEP.get(reqConfig, urlVar);
};

// POST
export const createLabelService = (data, reqConfig, urlVar) => {
  return createLabelEP.post(data, reqConfig, urlVar);
};

export const assignService = (data, reqConfig, urlVar) => {
  return assignEP.post(data, reqConfig, urlVar);
};

export const removeContractLabelsService = (data, reqConfig, urlVar) => {
  return removeContractLabelsEP.post(data, reqConfig, urlVar);
};
