import React from 'react';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import APIActions from '../actions/API';
import Signature from '../utils/signature';
import Loader from '../components/Loader';
import { branch } from 'baobab-react/dist-modules/higher-order';
import { downloadContractToViewAPIService } from '../API/services/apiServices';
import CerFields from './_common/CerFields';
import { sifeAuthOptions, sifeAuthUrl } from '../API/instance';
import IframeToSign from './ContractDetails/IframeToSign';

class PublicSign extends React.Component {
  /**
   * Builds the component
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      token: null,
      legalRfc: null,
      cer: null,
      key: null,
      contract: {},
      passphrase: '',
      loading: false,
      confirming: false,
      secondaryEmail: null,
      typeButton: null,
      loadbutton: false,
      isValid: false,
      created: false,
      errors: '',
      email: '',
      signed: false,
      contractUrl: '',
      width: window.innerWidth,
      openNewViewInputs: false,
    };
  }

  async componentDidMount() {
    const { match, location } = this.props;
    let token = location.search;
    this.resizePreview();
    if (!token) {
      this.sendPostMessage('No contiene token');
      this.setState({ errors: 'No contiene token' });
      return NotificationManager.warning(
        'No contiene token',
        'Advertencia',
        120000,
      );
    }

    token = token.replace('?token=', '');
    const legalRfc = token.split('&legalRfc=')[1];
    [token] = token.split('&legalRfc=');
    this.setState({ token });
    this.setState({ legalRfc });
    // Get contract to sign

    try {
      const contract = await APIActions.view(match.params.contractId, token);
      if (!contract || contract.error) {
        NotificationManager.warning(contract.error, 'Advertencia', 120000);
        return this.sendPostMessage('Request mal estructurada');
      }
      this.setState({ loading: true });
      await this.downloadFileToView(contract, 'contract');
      this.setState({ contract, loading: false });

      const user = await APIActions.getUser(legalRfc, token);
      this.setState({ typeButton: user });
      if (user) {
        this.setState({ email: user ? user.secondaryEmail : null });
        const response = await APIActions.getSigner(
          user.id,
          contract.id,
          token,
        );
        if (response.data) {
          this.setState({ signed: response.data.signed });
        }
      }
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
      return NotificationManager.warning(
        'Hubo un error',
        'Advertencia',
        120000,
      );
    }
  }

  resizePreview = () => {
    window.addEventListener('resize', () => {
      this.setState({ width: window.innerWidth });
    });
  };

  downloadFileToView = async (doc, docType, keyState) => {
    const response = await downloadContractToViewAPIService(
      {
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          const percentage =
            Math.max((progressEvent.loaded * 100) / progressEvent.total) || 0;
          this.setState({ totalLoading: percentage.toFixed(0), loading: true });
          if (percentage === 100) {
            this.setState({ loading: false, totalLoading: 0 });
          }
        },
      },
      { id: `${doc.id}-${docType}` },
    );

    if (response.status >= 500 || response.status === 404) {
      NotificationManager.error(
        'Error al descargar el documento a visualizar',
        'Atención',
        10000,
      );
      return;
    }
    const objUrl = URL.createObjectURL(response.data);
    this.setState({ contractUrl: objUrl, loading: false });
  };

  openNewIputs = () => {
    this.setState({ openNewViewInputs: true });
  };

  /**
   * Shows the inputs
   */
  handleCancel = () => {
    this.setState({ confirming: false });
  };

  /**
   * Shows the inputs
   */
  showConfirmation = () => {
    this.setState({ confirming: true });
  };

  getDataFromInputs = (data) => {
    this.setState(data);
  };

  canSign = () => {
    const { key, cer, passphrase } = this.state;
    return passphrase?.length && key && cer;
  };

  handleConfirm = async (modalState) => {
    const { contract, legalRfc, token, secondaryEmail } = this.state;
    const credentials = modalState.state;
    this.setState({ loading: true });
    let cuteSign;
    try {
      cuteSign = await Signature(
        credentials,
        contract.lastHash || contract.hash,
        legalRfc,
      );
    } catch (error) {
      this.sendPostMessage(error.message);
      NotificationManager.warning(error.message, 'Atención', 10000);
      this.setState({ loading: false });
      return;
    }

    try {
      const ctc = await APIActions.sign(
        token,
        credentials.cer,
        cuteSign,
        legalRfc,
        contract.id,
        secondaryEmail,
      );
      if (ctc?.error) {
        this.sendPostMessage(ctc.error);
        this.setState({ loading: false });
        return NotificationManager.warning(ctc.error, 'Atención', 10000);
      }
      ctc.allPartiesSigned = 1;
      this.sendPostMessage(`${ctc.title} firmado con éxito`);
      await this.downloadFileToView(contract, 'contract');
      this.setState({ contract: ctc });
      return this.setState({ loading: false });
    } catch (error) {
      this.sendPostMessage('Error al firmar contrato');
      NotificationManager.warning(
        'Error al firmar documento',
        'Atención',
        10000,
      );
      this.setState({ loading: false });
    }
  };

  sendPostMessage = (string) => {
    window.parent.postMessage(string, '*');
  };

  handleSifeMessage = async (e, type) => {
    if (/^react-devtools/gi.test(e.data.source)) {
      e.target.removeEventListener('message', this.handleSifeMessage);
      return null;
    }

    const messageReply = { success: false };
    try {
      // This if is executed if the doc signature is successful
      if (e.data.message === 'DOC_SIGNED') {
        this.setState({ loading: true, created: false });
        const contract = await APIActions.view(
          this.props.match.params.contractId,
          this.state.token,
        );
        this.sendPostMessage(`${this.state.contract.title} firmado con éxito`);
        contract.allPartiesSigned = 1;
        await this.downloadFileToView(contract, 'contract');
        this.setState({ contract, loading: false });
        if (!contract) {
          this.sendPostMessage('Request mal estructurada');
          this.setState({ errors: 'Request mal estructurada' });
          return NotificationManager.warning(
            'Request mal estructurada',
            'Advertencia',
            120000,
          );
        }
      }

      if (Object.keys(e.data).length === 6) {
        // if (Object.keys(e.data).length === 4) {
        const user = await APIActions.getUser(
          this.state.legalRfc,
          this.state.token,
        );
        if (!user) {
          const register = await APIActions.registerUserSife({
            user: e.data,
            token: this.state.token,
          });
          if (register?.data?.success) {
            this.setState({ loadbutton: true });
            // If the registration is successful, we get the registered user
            const getUser = await APIActions.getUser(
              this.state.legalRfc,
              this.state.token,
            );
            e.source.postMessage({ ...messageReply, success: true }, e.origin);
            return this.setState({ typeButton: getUser });
          }
        }
        if (e.data?.rfc !== this.state.legalRfc) {
          this.setState({ loadbutton: false });
          this.setState({
            errors:
              'El rfc de la url no es el mismo con el que iniciaste sesión',
          });
          return NotificationManager.warning(
            'El rfc de la url no es el mismo con el que iniciaste sesión',
            'Advertencia',
            5000,
          );
        }

        if (e.data?.email !== this.state.email) {
          this.setState({ loadbutton: false });
          this.setState({
            errors:
              'El email de inicio de sesión es diferente al registrado en contratos',
          });
          return NotificationManager.warning(
            'El email de inicio de sesión es diferente al registrado en contratos',
            'Advertencia',
            5000,
          );
        }

        this.setState({ loadbutton: true });
        e.source.postMessage({ ...messageReply, success: true }, e.origin);
        return this.setState({ typeButton: user });
        // }
      }
      this.setState({ isValid: true });
      return;
    } catch (error) {
      console.log(error);
      e.target.removeEventListener('message', this.handleSifeMessage);
      return NotificationManager.warning('Hubo un error', 'Advertencia', 5000);
    }
  };

  createContractSife = async () => {
    try {
      const res = await APIActions.createContractSifeAPI(
        this.state.contract,
        this.state.typeButton,
      );
      if (res.status === 201) {
        this.setState({ created: true });
      }
    } catch (error) {
      return NotificationManager.warning(
        'Hubo un error al crear el documento en sife',
        'Advertencia',
        5000,
      );
    }
  };

  // ESTE ES PARA CONTRATOS
  onLoadFrame = async (type) => {
    let eventMethod = window.addEventListener
      ? 'addEventListener'
      : 'attachEvent';
    let eventer = window[eventMethod];
    let messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

    eventer(messageEvent, this.handleSifeMessage);
  };

  // Window modal in contracts, its used for login, register and sign with sife.
  windowOpen = ({ url, title, w, h, type }) => {
    // Fixes dual-screen position Most browsers      Firefox

    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.screen.width;
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : window.screen.height;

    const left = width / 2 - w / 2;
    const top = height / 2 - h / 2;
    const newWindow = window.open(
      url,
      title,
      `
      scrollbars=yes,
      width=${w}, 
      height=${h}, 
      top=${top}, 
      left=${left}
      `,
    );
    // FIXME: for some reason loading executes onunload and not onload
    newWindow.onunload = this.onLoadFrame(type);
    newWindow.onload = function () {
      // console.log("heeeeeyyy");
    };

    if (window.focus) newWindow.focus();
    // setWindowObjRef(newWindow);
  };

  userDoesntExist = () => {
    return (
      <div>
        <button
          className="primary"
          type="button"
          onClick={() =>
            this.windowOpen({
              url: `${sifeAuthUrl}${
                sifeAuthOptions.login
              }${sifeAuthOptions.lang()}`,
              title: 'Sora Contratos',
              w: 700,
              h: 800,
            })
          }
        >
          Firmar con SIFE
        </button>
        <button
          className="primary"
          type="button"
          onClick={this.showConfirmation}
        >
          Firmar con E-FIRMA
        </button>
      </div>
    );
  };

  sifeButtons = () => {
    const { loadbutton } = this.state;
    return loadbutton ? (
      <button
        className="primary"
        style={{
          background: '#0fcea9',
          fontWeight: 'bold',
          color: 'white',
        }}
        type="button"
        onClick={() => this.createContractSife()}
      >
        Firmar documento
      </button>
    ) : (
      <button
        className="primary"
        type="button"
        onClick={() =>
          this.windowOpen({
            url: `${sifeAuthUrl}${
              sifeAuthOptions.login
            }${sifeAuthOptions.lang()}`,
            title: 'Sora Contratos',
            w: 700,
            h: 800,
          })
        }
      >
        Firmar con SIFE
      </button>
    );
  };

  efirmaButtons = () => {
    const { width, openNewViewInputs } = this.state;
    const isNotMobile = width > 992;
    return isNotMobile ? (
      <button
        className="primary"
        type="button"
        disabled={!this.canSign()}
        onClick={() => this.handleConfirm(this)}
      >
        Firmar Documento
      </button>
    ) : (
      <button
        className="primary"
        type="button"
        disabled={openNewViewInputs ? !this.canSign() : false}
        onClick={() =>
          openNewViewInputs ? this.handleConfirm(this) : this.openNewIputs()
        }
      >
        {openNewViewInputs ? 'Firmar' : 'Ir a firmar'}
      </button>
    );
  };

  updateStateSignContractApi = () => {
    this.setState({ created: false });
  };

  render() {
    const {
      loading,
      confirming,
      contract,
      typeButton,
      loadbutton,
      // errors,
      signed,
      contractUrl,
      width,
      openNewViewInputs,
      created,
    } = this.state;
    const { totalLoading } = this.props;
    return (
      <>
        <NotificationContainer />
        <div className="modal-container-sign">
          <div className="modal-sign contract_preview_authorizer">
            {loading ? (
              <div className="center_loading">
                <Loader totalLoading={totalLoading} />
              </div>
            ) : contractUrl ? (
              <>
                {!openNewViewInputs || width > 992 ? (
                  <iframe src={contractUrl} title="preview" frameBorder="0" />
                ) : null}
                <div className="body_modal">
                  {width > 992 ? (
                    (typeButton && !typeButton.typeUser) || confirming ? (
                      contract.allPartiesSigned === 1 || signed ? null : (
                        <CerFields getDataFromInputs={this.getDataFromInputs} />
                      )
                    ) : null
                  ) : openNewViewInputs ? (
                    <div className="mobile_version">
                      <div className="information">
                        <h3>Firmar Documentos</h3>
                        <p>
                          Para firmar el documento "{contract.title}" ingresa tu
                          certificado, .key y tu contraseña
                        </p>
                      </div>
                      <CerFields getDataFromInputs={this.getDataFromInputs} />
                    </div>
                  ) : null}
                </div>
                <div className="footer_modal">
                  {contract.allPartiesSigned === 1 ? null : (
                    <button
                      className="color-grey"
                      type="button"
                      onClick={() =>
                        openNewViewInputs
                          ? this.setState({ openNewViewInputs: false })
                          : this.handleCancel()
                      }
                    >
                      Regresar
                    </button>
                  )}

                  {contract.allPartiesSigned === 1 || signed ? (
                    <p>Firmado</p>
                  ) : !typeButton && !confirming ? (
                    this.userDoesntExist()
                  ) : !typeButton?.typeUser ? (
                    this.efirmaButtons()
                  ) : typeButton.typeUser || loadbutton ? (
                    this.sifeButtons()
                  ) : null}
                </div>
                <div className="space-between"></div>
              </>
            ) : (
              <iframe title="preview" frameBorder="0" />
            )}
          </div>
          <div className="background-screen" />
        </div>
        {created ? (
          <IframeToSign
            contract={contract}
            signer={null}
            updateStateSignContract={this.updateStateSignContractApi}
            type="api_contract"
          />
        ) : null}
      </>
    );
  }
}

export default branch(
  {
    totalLoading: ['totalLoading'],
    contractUrl: ['contractUrl'],
  },
  PublicSign,
);
