import React from 'react';
import { branch } from 'baobab-react/higher-order';
import ProfileActions from '../../actions/Profile';
import { NotificationManager } from 'react-notifications';
import AuthorizeTokenModal from '../../components/Modals/AuthorizeTokenModal.js';
import { profileServices } from '../../API/services/profileServices';
import Signature from '../../utils/signature';
import Loader from '../../components/Loader';
import { getLocationUtils } from '../../utils/locationUtils';
import GenericConfirmModal from '../../components/Modals/GenericConfirmModal';

class ContractAuthorization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTypeForm: 'newUsers',
      authorizedUsers: [],
      authorizedUsersRegistered: [],
      pendingByMe: [],
      originRequest: '',
      originExist: false,
      confirming: false,
      modalToSign: false,
      userToSign: null,
      contractUrl: null,
      loading: false,
      totalLoading: 0,
      cannotSign: false,
      locationUser: null,
      showAlertLocation: false,
      newMessageAlert: false,
    };
  }

  async componentDidMount() {
    const {
      session: { user },
      translations,
      lang,
    } = this.props;
    const { notifications } = translations[lang].Profile.ContractAuthorization;
    this.setState({ loading: true });
    const responseUsers = await ProfileActions.getMyUsers(user.id);
    if (!responseUsers.data.success) {
      return NotificationManager.error(
        notifications.notifBody1,
        notifications.notifTitle1,
        10000,
      );
    }
    const { data } = responseUsers;

    this.setState({
      authorizedUsersRegistered: data.users,
      pendingByMe: data.pendingByMe,
      originExist: data.origin,
      confirming: true,
      loading: false,
    });

    /* getLocationUtils(
      this.onSuccessLocation,
      this.onErrorLocation,
      this.verifyIfUserHasLocation,
    ); */
    this.setState({ loadInfo: false });
  }

  onSuccessLocation = (position) => {
    const {
      coords: { latitude, longitude },
    } = position;
    this.setState({
      locationUser: {
        latitude,
        longitude,
      },
    });
    this.setState({ showAlertLocation: false, cannotSign: false });
  };

  onErrorLocation = (error) => {
    this.setState({
      cannotSign: true,
      showAlertLocation: false,
      newMessageAlert: true,
    });
  };

  verifyIfUserHasLocation = (result) => {
    if (result.state === 'prompt') {
      this.setState({ showAlertLocation: true, cannotSign: true });
    }
  };

  onClickSelectForm = (formType) => {
    this.setState({ showTypeForm: formType });
  };

  onClickAddAuthorizedUser = () => {
    const { authorizedUsers } = this.state;
    const authorizedUser = { identifier: '' };
    this.setState({ authorizedUsers: [...authorizedUsers, authorizedUser] });
  };

  inputChangeUser = (event, id) => {
    const { name, value } = event.target;
    const { authorizedUsers } = this.state;
    const allUsers = [...authorizedUsers];
    allUsers[id][name] = value;
    this.setState({ authorizedUsers: allUsers });
  };

  inputOriginChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  removeUser = (e) => {
    const { authorizedUsers } = this.state;
    const allUsers = [...authorizedUsers];
    const filteredUsers = allUsers.filter((user) => user !== e);

    this.setState({ authorizedUsers: filteredUsers });
  };

  repeatedUsersReview = () => {
    const { authorizedUsers, authorizedUsersRegistered } = this.state;

    const repeatedUsers = authorizedUsersRegistered.filter((authRegistered) => {
      const index = authorizedUsers.find(
        (auth) => authRegistered.identifier === auth.identifier,
      );
      if (index) {
        return true;
      }
      return false;
    });
    const repeatedIdentifier = repeatedUsers.map(
      (user) => '\n' + user.identifier,
    );
    return repeatedIdentifier;
  };

  onClickRegisterUsers = async () => {
    const { authorizedUsers, originRequest } = this.state;
    const { session, translations, lang } = this.props;
    const { notifications } = translations[lang].Profile.ContractAuthorization;
    this.setState({ loading: true });
    const repeatedUsers = this.repeatedUsersReview();
    if (repeatedUsers.length) {
      this.setState({ loading: false });
      return NotificationManager.error(
        notifications.notifBody2(repeatedUsers.toString()),
        notifications.notifTitle2,
        10000,
      );
    }
    const response = await ProfileActions.registerUsersToDownloadContracts({
      authorizedUsers,
      origin: originRequest,
    });
    if (!response.data || !response.data.success) {
      this.setState({ loading: false });
      return NotificationManager.error(
        //TODO: translate reponse
        response.data?.message || 'Hubo un error al registrar usuarios',
        'Atención',
        10000,
      );
    }
    const responseUsers = await ProfileActions.getMyUsers(session.user.id);
    this.setState({
      loading: false,
      originExist: true,
      authorizedUsers: [],
      authorizedUsersRegistered: responseUsers.data.users,
      showTypeForm: 'myUsers',
    });

    return NotificationManager.success(
      notifications.notifBody3,
      notifications.notifTitle3,
      10000,
    );
  };

  addUserStatus = (deniedReason, authorized) => {
    const { translations, lang } = this.props;
    const { texts } = translations[lang].Profile.ContractAuthorization;
    if (deniedReason) {
      return <p style={{ color: '#f95c5e' }}>{texts.p1}</p>;
    }

    if (authorized) {
      return <p style={{ color: '#7ed321' }}>{texts.p2}</p>;
    }

    return <p style={{ color: '#e19614' }}>{texts.p3}</p>;
  };

  openModalToSign = async (userToSign) => {
    this.setState({ loading: true });
    if (userToSign.fileUrl || userToSign.signedCopyUrl) {
      this.setState({ userToSign });
      await this.downloadContract(userToSign, 'authorized_users');
      return;
    }
    const response = await ProfileActions.createContractToAuthorizeDownload({
      userToSign,
    });
    userToSign.fileUrl = response.data.file.Location;
    userToSign.hash = response.data.file.hash;
    if (response.data.success) {
      this.setState({ userToSign });
      await this.downloadContract(userToSign, 'authorized_users');
    }
  };

  closeModalToSign = () => {
    this.setState({ modalToSign: false });
  };

  downloadContract = async (doc, docType) => {
    const { translations, lang } = this.props;
    const { notifications } = translations[lang].Profile.ContractAuthorization;
    const response = await profileServices.downloadContractToViewProfileService(
      {
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          const percentage =
            Math.max((progressEvent.loaded * 100) / progressEvent.total) || 0;
          this.setState({ totalLoading: percentage.toFixed(0), loading: true });
          if (percentage === 100) {
            this.setState({ loading: false, totalLoading: 0 });
          }
        },
      },
      { id: `${doc.id};${docType}` },
    );
    if (response.status >= 500 || response.status === 404) {
      NotificationManager.error(
        notifications.notifBody4,
        notifications.notifTitle4,
        10000,
      );
      return this.setState({ modalToSign: false, loading: false });
    }
    const objUrl = URL.createObjectURL(response.data);
    this.setState({ contractUrl: objUrl, modalToSign: true, loading: false });
  };

  sendDenyReason = async (comments) => {
    const { userToSign } = this.state;
    const { translations, lang } = this.props;
    const { notifications } = translations[lang].Profile.ContractAuthorization;
    //RECHAZARON LA PETICIÓN
    const response = await ProfileActions.sendResponseToAuthorize({
      comments,
      idToEdit: userToSign.id,
      type: 'denied',
    });
    if (!response.data.success) {
      return NotificationManager.error(
        notifications.notifBody5,
        notifications.notifTitle5,
        10000,
      );
    }
    this.setState({ modalToSign: false });
    userToSign.deniedReason = comments;
    return NotificationManager.success(
      notifications.notifBody6,
      notifications.notifTitle6,
      10000,
    );
  };

  signContract = async (modalState) => {
    const { userToSign, locationUser } = this.state;
    const {
      translations,
      lang,
      session: { user },
    } = this.props;

    const { notifications } = translations[lang].Profile.ContractAuthorization;

    const credentials = modalState.state;
    credentials.type = 'login_signers';

    this.setState({ modalToSign: false, loading: true });
    NotificationManager.info(
      notifications.notifBody7,
      notifications.notifTitle7,
      10000,
    );
    try {
      const { cuteSign, legalRfc, cerBuffer } = await Signature(
        credentials,
        userToSign.hash,
      );

      if (!cuteSign) {
        this.setState({ loading: false });
        return NotificationManager.error(
          notifications.notifBody8,
          notifications.notifTitle8,
          10000,
        );
      }

      if (user.legalRfc !== legalRfc) {
        this.setState({ loading: false });
        return NotificationManager.error(
          notifications.notifBody9,
          notifications.notifTitle9,
          10000,
        );
      }

      // if (!locationUser) {
      //   this.setState({ loading: false });
      //   return NotificationManager.warning(
      //     notifications.notifBody10,
      //     notifications.notifTitle10,
      //     5000,
      //   );
      // }

      const response = await ProfileActions.sendResponseToAuthorize({
        comments: '',
        idToEdit: userToSign.id,
        type: 'accept',
        url: userToSign.fileUrl,
        cuteSign,
        cer: credentials.cer,
        cerBuffer,
        locationUser,
      });
      if (response.data.success) {
        NotificationManager.success(
          notifications.notifBody11,
          notifications.notifTitle11,
          10000,
        );
        userToSign.authorized = true;
        await this.downloadContract(userToSign, 'authorized_users');
        this.setState({ loading: false });
        return;
      }
    } catch (error) {
      console.log('Hubo un error', error);
      return NotificationManager.error(
        notifications.notifBody12,
        notifications.notifTitle12,
        10000,
      );
    }
  };

  sendResponseToAuthorize = async (modalState) => {
    const { comments, responseToAuthorize } = modalState.state;
    //RECHAZARON LA PETICIÓN
    if (comments && responseToAuthorize === '2') {
      return this.sendDenyReason(comments);
    }

    //Aceptaron y firmaron el contrato
    await this.signContract(modalState);
  };

  render() {
    const {
      showTypeForm,
      authorizedUsers,
      authorizedUsersRegistered,
      pendingByMe,
      originRequest,
      originExist,
      confirming,
      modalToSign,
      userToSign,
      contractUrl,
      loading,
      totalLoading,
      newMessageAlert,
      showAlertLocation,
    } = this.state;
    const { width, translations, lang } = this.props;
    const { texts, inputs, buttons } =
      translations[lang].Profile.ContractAuthorization;
    return (
      <div className="container_request_docs">
        <div className="menu_request_docs">
          <div
            className={`menu_request_doc ${
              showTypeForm === 'myUsers' ? 'active_menu' : ''
            }`}
            onClick={() => this.onClickSelectForm('myUsers')}
          >
            <small>{texts.small1}</small>
          </div>
          <div
            className={`menu_request_doc space ${
              showTypeForm === 'newUsers' ? 'active_menu' : ''
            }`}
            onClick={() => this.onClickSelectForm('newUsers')}
          >
            <small>{texts.small2}</small>
          </div>
          <div
            className={`menu_request_doc ${
              showTypeForm === 'pendingUsers' ? 'active_menu' : null
            }`}
            onClick={() => this.onClickSelectForm('pendingUsers')}
          >
            <small>{texts.small3}</small>
          </div>
        </div>
        {confirming ? (
          <div className="main_request_docs">
            {showTypeForm === 'myUsers' ? (
              <div className="list_all_users">
                <table className="table_list_requested_users">
                  <thead>
                    <tr>
                      <th>{texts.th1}</th>
                      <th>{texts.th2}</th>
                      <th>{texts.th3}</th>
                      <th>{texts.th4}</th>
                    </tr>
                  </thead>
                  <tbody className="scrollable">
                    {authorizedUsersRegistered.map((user, index) => (
                      <tr key={index}>
                        <td> {user.identifier} </td>
                        <td> {user.legalName} </td>
                        <td> {user.email} </td>
                        <td className="status_my_user">
                          {this.addUserStatus(
                            user.deniedReason,
                            user.authorized,
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : null}

            {showTypeForm === 'newUsers' ? (
              <div className="my_users_request_documents">
                {loading ? (
                  <div className="center_loading">
                    <Loader totalLoading={totalLoading} />
                  </div>
                ) : (
                  <form className="form_request_document">
                    {originExist ? null : (
                      <input
                        type="text"
                        name="originRequest"
                        value={originRequest}
                        className="origin_input"
                        required
                        onChange={(e) => this.inputOriginChange(e)}
                        placeholder={inputs.inputPlaceholder1}
                      />
                    )}
                    <table className="table_request_documents">
                      <tbody>
                        {authorizedUsers.length ? (
                          authorizedUsers.map((recipient, index) => (
                            <tr key={index}>
                              <td>{index + 1}.</td>
                              <td>
                                <input
                                  type="text"
                                  name="identifier"
                                  value={recipient.identifier.toUpperCase()}
                                  style={{ textTransform: 'uppercase' }}
                                  required
                                  className="rfc_requested"
                                  minLength="12"
                                  maxLength="13"
                                  onChange={(e) =>
                                    this.inputChangeUser(e, index)
                                  }
                                  placeholder={inputs.inputPlaceholder2}
                                />
                              </td>
                              <td className="actions">
                                <i
                                  className={`fas fa-minus-circle`}
                                  style={{
                                    color: '#f95c5e',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => this.removeUser(recipient)}
                                />
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td>{texts.td1}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <div className="add_and_finish_request">
                      <span
                        className="add"
                        onClick={this.onClickAddAuthorizedUser}
                      >
                        {buttons.spanButton1}
                      </span>
                      <span className="add" onClick={this.onClickRegisterUsers}>
                        {buttons.spanButton2}
                      </span>
                    </div>
                  </form>
                )}
              </div>
            ) : null}

            {showTypeForm === 'pendingUsers' ? (
              <div className="list_all_users">
                {pendingByMe.length ? (
                  loading ? (
                    <div className="center_loading">
                      <Loader totalLoading={totalLoading} />
                      <p style={{ marginTop: 40 }}>{texts.p4}</p>
                    </div>
                  ) : (
                    <table className="table_list_requested_users">
                      <thead>
                        <tr>
                          <th>{texts.th5}</th>
                          <th>{texts.th6}</th>
                          <th>{texts.th7}</th>
                          <th style={{ textAlign: 'center' }}>{texts.th8}</th>
                        </tr>
                      </thead>
                      <tbody className="scrollable">
                        {pendingByMe.map((user, index) => (
                          <tr key={index}>
                            <td> {user.legalRfc} </td>
                            <td> {user.legalName} </td>
                            <td> {user.email} </td>
                            <td style={{ textAlign: 'center' }}>
                              <i
                                style={{
                                  color: user.authorized
                                    ? '#7ed321'
                                    : user.comments
                                    ? '#f95c5e'
                                    : '#e19614',
                                  fontSize: 18,
                                }}
                                className="fas fa-file-signature"
                                onClick={() => this.openModalToSign(user)}
                              ></i>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )
                ) : (
                  <div style={{ textAlign: 'center' }}>
                    <p>{texts.p5}</p>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        ) : (
          <div className="center_loading">
            <Loader totalLoading={totalLoading} />
            <p>{texts.p6}</p>
          </div>
        )}

        {modalToSign ? (
          <AuthorizeTokenModal
            userToSign={userToSign}
            contractUrl={contractUrl}
            closeModalToSign={this.closeModalToSign}
            sendResponse={this.sendResponseToAuthorize}
            width={width}
          />
        ) : null}

        {showAlertLocation ? (
          <GenericConfirmModal
            title={
              newMessageAlert
                ? texts.genericModalTitle1
                : texts.genericModalTitle2
            }
            message={
              newMessageAlert
                ? texts.genericModalBody1
                : texts.genericModalBody2
            }
            okButton={buttons.genericModalConfirmButton1}
            cancelButton={buttons.genericModalCancelButton1}
            cancelAction={() => this.setState({ showAlertLocation: false })}
            acceptAction={() => this.setState({ showAlertLocation: false })}
          />
        ) : null}
      </div>
    );
  }
}

export default branch(
  {
    session: ['session'],
    width: ['width'],
    translations: ['translations'],
    lang: ['lang'],
  },
  ContractAuthorization,
);
