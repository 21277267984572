export const DropzoneTranslations = {
  es: {
    texts: {
      span1: 'Da click para seleccionar\narchivo o documento',
    },
    inputs: {
      label1: 'Documento',
    },
    buttons: {},
  },
  en: {
    texts: {
      span1: 'Click to select\nfile or document',
    },
    inputs: {
      label1: 'Document',
    },
    buttons: {},
  },
};
