// import { hot } from 'react-hot-loader';
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { root } from 'baobab-react/higher-order';
import state from '../state';
// Containers
import Dashboard from './Dashboard';
import Registration from './Landing/Registration';
import Login from './Landing/Login';
import Demo from './Landing/Demo';
import Activation from './Landing/Activation';
import PublicSign from './PublicSign';
import ActivationLogin from './Landing/ActivationLogin';
import '../less/index.less';
import PublicInfo from './Landing/PublicInfo';
import TranslationsActions from '../actions/TranslationsActions';

// Main Application Router
const Application = () => {
  useEffect(() => {
    const language = localStorage.getItem('lang');
    if (language) {
      TranslationsActions.switchLanguage(language);
    } else {
      TranslationsActions.switchLanguage('es');
      localStorage.setItem('lang', 'es');
    }
  }, []);
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/activate/:legalRfc" component={Activation} />
        <Route path="/public/sign/:contractId" component={PublicSign} />
        <Route path="/first-login" component={ActivationLogin} />
        <Route path="/login" component={Login} />
        <Route path="/register" component={Registration} />
        <Route path="/verify/:document/:id" component={PublicInfo} />
        <Route path="/demo" component={Demo} />
        <Route path="/" component={Dashboard} />
      </Switch>
    </BrowserRouter>
  );
};
export default root(state, Application);
