import React from 'react';
import { branch } from 'baobab-react/higher-order';
import { Redirect } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Contracts from '../Contracts';

import NewContract from '../NewContract';
import NewContractMassive from '../NewContractMassive';
import SessionActions from '../../actions/Session';
import AlertModal from '../../components/Modals/AlertModal';
import CredentialsModal from '../../components/Modals/CredentialsModal';
import { NotificationManager } from 'react-notifications';
import { signersOrAdminsLoginUtils } from '../../utils/signersLoginUtils';
import Loader from '../../components/Loader';
import GenericConfirmModal from '../../components/Modals/GenericConfirmModal';
import ProfileActions from '../../actions/Profile';
import MySignerOrMyAdmins from '../Profile/MySignerOrMyAdmins';

class Signer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signer: {
        legalRfc: '',
        legalName: '',
        email: '',
        canCreateContract: false,
        canSee: false,
        canCreateSigner: false,
        canCreateAdmin: false,
        canSign: false,
        showLogoutModal: false,
        loading: false,
      },
      modalInfoAlert: true,
    };
    this.idleTimer = null;
    this.onAction = this.onAction.bind(this);
    this.onActive = this.onActive.bind(this);
    this.onIdle = this.onIdle.bind(this);
  }

  async componentDidMount() {
    const { session, match } = this.props;
    const { user } = session;
    const { representing } = user;
    const alertInfo = localStorage.getItem('alert_info');
    if (alertInfo) {
      this.setState({ modalInfoAlert: false });
    }
    const signer = representing.find(
      (found) => found.legalRfc === match.params.legalRfc,
    );
    this.setState({ signer });
    await ProfileActions.signers(signer.rootUser);
    await ProfileActions.admins(signer.rootUser);
  }

  componentDidUpdate(prevProps, prevState) {
    const { session, match } = this.props;
    const { user } = session;
    const { representing } = user;
    const signer = representing.find(
      (found) => found.legalRfc === match.params.legalRfc,
    );
    if (signer !== prevState.signer) {
      this.setState({ signer });
    }
  }

  onAction(e) {
    //
  }

  onActive(e) {
    //
  }

  onIdle(e) {
    const { showLogoutModal } = this.state;
    this.setState({ showLogoutModal: !showLogoutModal });
  }

  handleLogoutModal() {
    SessionActions.logout();
  }

  handleSubmit = async (credentials) => {
    const response = await signersOrAdminsLoginUtils(credentials, this.props);
    const { error, message } = response;
    if (error) {
      this.setState({ loading: false });
      // TODO: translate response
      return NotificationManager.error(message, 'Advertencia', 5000);
    }
  };

  onClickCloseModal = () => {
    localStorage.setItem('alert_info', true);
    this.setState({ modalInfoAlert: false });
  };

  render() {
    const { signer, showLogoutModal, loading, modalInfoAlert } = this.state;
    const {
      history,
      match,
      session,
      location,
      mySigners,
      myAdmins,
      translations,
      lang,
    } = this.props;
    const { texts, buttons } = translations[lang].Signer;
    if (!session) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: {
              from: location.pathname,
            },
          }}
        />
      );
    }
    return (
      <div className="admin_signer_container">
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={1000 * 60 * 1200}
          stopOnIdle
        />
        <h1>{texts.h1(signer.legalName)}</h1>
        {session.user.typeUser && signer.protectedUser ? (
          <>
            {loading ? (
              <div className="center_loading">
                <Loader />
                <p>{texts.p1}</p>
              </div>
            ) : (
              <div className="page padded">
                <CredentialsModal handleSubmit={this.handleSubmit} />
              </div>
            )}
          </>
        ) : (
          <div className="page padded">
            <Tabs defaultIndex={0}>
              <TabList>
                {signer.canSee ? <Tab>{texts.tab1}</Tab> : false}
                {signer.canCreateContract ? <Tab>{texts.tab2}</Tab> : false}
                <Tab>{texts.tab3}</Tab>
                <Tab>{texts.tab4}</Tab>
              </TabList>

              {/* Contracts */}
              {signer.canSee ? (
                <TabPanel>
                  <Contracts signer={signer} history={history} match={match} />
                </TabPanel>
              ) : (
                false
              )}

              {/* NEW CONTRACT */}
              {signer.canCreateContract ? (
                <TabPanel>
                  <NewContract signer={signer} history={history} />
                </TabPanel>
              ) : (
                false
              )}

              {/* SIGNERS */}
              <TabPanel>
                {signer.canCreateSigner ? (
                  <MySignerOrMyAdmins
                    mySigners={mySigners}
                    user={session.user}
                    history={history}
                    location={location}
                    signer={signer}
                    type="signer"
                  />
                ) : null}
                {signer.canCreateAdmin ? (
                  <MySignerOrMyAdmins
                    mySigners={myAdmins}
                    user={session.user}
                    history={history}
                    location={location}
                    signer={signer}
                    type="admin"
                  />
                ) : null}
              </TabPanel>

              <TabPanel>
                <NewContractMassive signerOrAdmin={signer} />
              </TabPanel>
            </Tabs>
          </div>
        )}
        {session.user.typeUser && signer.protectedUser && modalInfoAlert ? (
          <GenericConfirmModal
            title={texts.genericModalTitle1}
            message={texts.genericModalBody1(signer.legalName)}
            okButton={buttons.genericModalConfirmButton1}
            cancelButton={buttons.genericModalCancelButton1}
            cancelAction={() => this.onClickCloseModal()}
            acceptAction={() => this.onClickCloseModal()}
            type={'alert'}
          />
        ) : null}
        {showLogoutModal ? (
          <AlertModal
            message={texts.alertModalBody1}
            header={texts.alertModalTitle1}
            okButton={() => this.handleLogoutModal()}
          />
        ) : null}
      </div>
    );
  }
}

export default branch(
  {
    contracts: ['contracts'],
    session: ['session'],
    mySigners: ['mySigners'],
    myAdmins: ['myAdmins'],
    translations: ['translations'],
    lang: ['lang'],
  },
  Signer,
);
