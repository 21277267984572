import React from 'react';
import ViewPdf from '../../containers/_common/ViewPdf';
import { branch } from 'baobab-react/higher-order';

class ConfirmContractModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleSubmit() {
    const { handleConfirmContract } = this.props;
    handleConfirmContract();
  }

  handleCancel() {
    const { handleCancel } = this.props;
    handleCancel();
  }

  render() {
    const { contract, signers, recipients, authorizers, translations, lang } =
      this.props;
    const { texts, buttons } = translations[lang].Modals.ConfirmContractModal;
    return (
      <div className="modal-container-sign">
        <div className="modal-sign confirm_contract_modal">
          <div className="header_modal">
            <h2 style={{ fontWeight: 400, paddingLeft: 10 }}>
              {texts.h2}
              <b>{contract.title}</b>
            </h2>
          </div>
          <div className="body_modal page" style={{ paddingLeft: 10 }}>
            <div className="participants_list">
              <div className="confirm_signers">
                <small style={{ fontWeight: 'bold' }}>
                  {signers?.length ? texts.small1 : texts.small2}
                </small>
                <ul>
                  {signers.length > 3
                    ? texts.ul1(signers.length)
                    : signers.length === 0
                    ? texts.small2
                    : signers.map((signer) => (
                        <li key={signer.legalRfc}>
                          {signer.legalRfc} -{signer.legalName} - {signer.email}
                        </li>
                      ))}
                </ul>
              </div>
              <div className="confirm_authorizers">
                <small style={{ fontWeight: 'bold' }}>
                  {authorizers?.length ? texts.small3 : texts.small4}
                </small>{' '}
                <ul>
                  {authorizers
                    ? authorizers.length > 3
                      ? texts.ul2(authorizers.length)
                      : authorizers.length === 0
                      ? texts.small4
                      : authorizers.map((authorizer) => (
                          <li key={authorizer.legalRfc}>
                            {authorizer.legalRfc} -{authorizer.legalName} -
                            {authorizer.email}
                          </li>
                        ))
                    : null}
                </ul>
              </div>
              <div className="confirm_recipients">
                <small style={{ fontWeight: 'bold' }}>
                  {recipients.length ? texts.small5 : texts.small6}
                </small>
                <ul>
                  {recipients
                    ? recipients.length > 3
                      ? texts.ul3(recipients.length)
                      : recipients.length === 0
                      ? texts.small6
                      : recipients.map((recipient) => (
                          <li key={recipient.legalRfc}>
                            {recipient.legalRfc} - {recipient.legalName} -
                            {recipient.email}
                          </li>
                        ))
                    : null}
                </ul>
              </div>
            </div>
          </div>
          <ViewPdf contractUrl={URL.createObjectURL(contract.file)} />
          <div className="footer_modal">
            <button
              className="color-grey"
              type="button"
              onClick={this.handleCancel}
            >
              {buttons.button1}
            </button>
            <button
              type="submit"
              className="primary"
              onClick={this.handleSubmit}
            >
              {buttons.button2}
            </button>
          </div>
        </div>
        <div className="background-screen" />
      </div>
    );
  }
}

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  ConfirmContractModal,
);
