import React from 'react';

export const Tooltip = ({
  style,
  title,
  info,
  textCheckbox,
  showTooltipReqVer,
  showTooltipReqCreateSign,
}) => {
  return (
    <div id="tooltip_modal" className="tooltip_modal">
      <small className="tooltip_title">{title}</small>
      <br />
      <br />
      <small className="tooltip_info">{info}</small>
    </div>
  );
};
