import React from 'react';
import SuperUserActions from '../../../actions/SuperUser';
import { NotificationManager } from 'react-notifications';
import { emailRegex, rfcRegex } from '../../../utils/constants';
import Loader from '../../../components/Loader';
import { cleanText } from '../../../utils/cleanText';

class ChangeSignerSuperAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signer: null,
      loading: false,
      dataToUpdate: {
        legalRfc: '',
        email: '',
        legalName: '',
      },
    };
  }

  componentDidMount() {
    const { signerToUpdate } = this.props;
    this.setState({
      dataToUpdate: {
        legalRfc: signerToUpdate.legalRfc,
        legalName: signerToUpdate.legalName,
        email: signerToUpdate?.hasOwnProperty('userId')
          ? signerToUpdate.emailSend
          : signerToUpdate.email,
      },
    });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    const { dataToUpdate } = this.state;

    if (name === 'email') {
      return this.setState({
        dataToUpdate: {
          ...dataToUpdate,
          [name]: cleanText({ removeSpace: true, string: value }).toLowerCase(),
        },
      });
    }
    if (name === 'legalRfc') {
      return this.setState({
        dataToUpdate: {
          ...dataToUpdate,
          [name]: cleanText({
            removeSpace: true,
            string: value,
          }).toUpperCase(),
        },
      });
    }

    return this.setState({
      dataToUpdate: {
        ...dataToUpdate,
        [name]: cleanText({ removeSpace: false, string: value }),
      },
    });
  };

  handleUpdate = async () => {
    const { dataToUpdate } = this.state;
    const { contract, signerToUpdate, searchContractsById, setExternalData } =
      this.props;
    try {
      if (!signerToUpdate) {
        return NotificationManager.error(
          'No se encontró el firmante para actualizar',
          'Error',
          5000,
        );
      }

      if (!emailRegex.test(dataToUpdate.email)) {
        return NotificationManager.error(
          'El email no es válido',
          'Error',
          5000,
        );
      }

      if (!dataToUpdate.legalName) {
        return NotificationManager.error(
          'El nombre del firmante no es válido',
          'Error',
          5000,
        );
      }

      if (!dataToUpdate.legalRfc || dataToUpdate?.legalRfc?.length > 13) {
        return NotificationManager.error(
          'El RFC del firmante no es válido',
          'Error',
          5000,
        );
      }

      if (
        signerToUpdate?.legalRfc === dataToUpdate?.legalRfc &&
        (signerToUpdate?.emailSend || signerToUpdate?.email) ===
          dataToUpdate?.email &&
        signerToUpdate?.legalName === dataToUpdate?.legalName
      ) {
        return NotificationManager.error(
          'No se detectaron cambios en el firmante',
          'Error',
          5000,
        );
      }

      if (signerToUpdate?.legalRfc === dataToUpdate?.legalRfc) {
        return NotificationManager.error(
          'Si el rfc del firmante no ha cambiado, no es necesario actualizarlo. El nombre, el usuario puede cambiarlo en su perfil y el correo unicamente se uso como referencia para enviar el contrato.',
          'Error',
          10000,
        );
      }

      this.setState({ loading: true });
      const response = await SuperUserActions.assignNewSignerToDoc({
        newSigner: dataToUpdate,
        oldSigner: signerToUpdate,
        contractId: contract.id,
      });

      if (!response?.data?.success) {
        this.setState({ loading: false });
        return NotificationManager.error(
          response?.data?.message || 'Error al actualizar firmante',
          'Error',
          5000,
        );
      }
      searchContractsById();
      this.setState({ loading: false });
      setExternalData({ modalToChangeSigner: false, signerToUpdate: null });
      return NotificationManager.success(
        'Firmante asignado con éxito',
        'Éxito',
        5000,
      );
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
      return NotificationManager.error(
        'Hubo un error al actualizar el firmante',
        'Error',
        5000,
      );
    }
  };

  render() {
    const { handleCancel } = this.props;
    const { loading, dataToUpdate } = this.state;
    return (
      <div className="modal-container mtm-20">
        <div className="modal" style={{ width: 900 }}>
          <h2 style={{ marginLeft: 18 }}>Editar firmante</h2>

          <div className="row" style={{ paddingBottom: 20 }}>
            {loading ? (
              <div className="center_loading" style={{ width: '100%' }}>
                <Loader />
                <p>Agregando usuario...</p>
              </div>
            ) : (
              <>
                <div className="col-sm-4">
                  <small>RFC</small>
                  <input
                    type="legalRfc"
                    name="legalRfc"
                    className="input_nc"
                    value={dataToUpdate?.legalRfc || ''}
                    onChange={(e) => this.handleChange(e)}
                    required
                    placeholder="RFC"
                    autoComplete="nope"
                  />
                </div>
                <div className="col-sm-4">
                  <small>Nombre</small>
                  <input
                    type="text"
                    name="legalName"
                    className="input_nc"
                    value={dataToUpdate?.legalName || ''}
                    minLength="10"
                    onChange={(e) => this.handleChange(e)}
                    placeholder="Nombre Completo"
                  />
                </div>
                <div className="col-sm-4">
                  <small>Email</small>
                  <input
                    type="email"
                    name="email"
                    className="input_nc"
                    value={dataToUpdate?.email || ''}
                    onChange={(e) => this.handleChange(e)}
                    required
                    placeholder="email"
                    autoComplete="nope"
                  />
                </div>
              </>
            )}
          </div>
          <div
            className="col-sm-12"
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <button
              type="button"
              className="secondary"
              onClick={handleCancel}
              style={{ color: 'black' }}
            >
              Regresar
            </button>
            <button
              type="submit"
              className="primary"
              onClick={() => this.handleUpdate()}
            >
              Editar
            </button>
          </div>
        </div>
        <div className="background-screen" />
      </div>
    );
  }
}

export default ChangeSignerSuperAdmin;
