export const MyInfoTranslations = {
  es: {
    texts: {
      spanTitle1: 'Información de la cuenta',
      spanTitle2: 'Configurar los documentos de la cuenta',
      small1: 'HOJA DE FIRMADO',
      p1: 'Mostrar RFC del firmante en hoja de firmado',
      small2:
        'Todos los documentos creados a nombre de tu cuenta mostrarán el RFC del firmante en la hoja de firmado',
    },
    inputs: {
      inputLabel1: 'Nombre Completo',
      inputLabel2: 'RFC',
      inputLabel3: 'Correo electrónico',
      inputLabel4: 'Imagen de perfil',
      inputLabel5: 'Subir imagen',
    },
    buttons: {
      button1: 'Guardar',
      button2: 'Actualizar',
      linkLabel1: 'Mi Gestión',
      button3: 'Gestión',
    },
    notifications: {
      notifTitle1: 'Exito',
      notifText1: 'Datos actualizados correctamente',
      notifTitle2: 'Exito',
      notifText2: 'Imagen actualizada exitosamente',
      notifTitle3: 'Advertencia',
      notifText3: 'Hubo un error al cargar la imagen',
    },
  },
  en: {
    texts: {
      spanTitle1: 'Account information',
      spanTitle2: 'Configure the account documents',
      small1: 'SIGNATURE SHEET',
      p1: 'Show RFC of the signer in the signature page',
      small2:
        'All documents created on behalf of your account will show the RFC of the signer in the signature page',
    },
    inputs: {
      inputLabel1: 'Full name',
      inputLabel2: 'RFC',
      inputLabel3: 'Email',
      inputLabel4: 'Profile image',
      inputLabel5: 'Upload image',
    },
    buttons: {
      button1: 'Save',
      button2: 'Update',
      linkLabel1: 'My Management',
      button3: 'Management',
    },
    notifications: {
      notifTitle1: 'Success',
      notifText1: 'Data updated correctly',
      notifTitle2: 'Success',
      notifText2: 'Image updated successfully',
      notifTitle3: 'Warning',
      notifText3: 'There was an error uploading the image',
    },
  },
};
