import React from 'react';
import { branch } from 'baobab-react/higher-order';
import { Link } from 'react-router-dom';
import Checkbox from 'react-simple-checkbox';
import { NotificationManager } from 'react-notifications';
// Logic
import Pagination from '../components/Pagination';
// Actions
import ContractActions from '../actions/Contract';
import LabelActions from '../actions/LabelActions';
import VideoActions from '../actions/Videos';
import generateShortId from '../utils/makeId';
import Loader from '../components/Loader';
import state from '../state';

import FilterToolbarDisplay from './Contracts/FilterToolbarDisplay';
import ContractListDisplay from './Contracts/ContractListDisplay';

import GenericConfirmModal from '../components/Modals/GenericConfirmModal';
import Signature from '../utils/signature';
import GenericSignModal from '../components/Modals/GenericSignModal';
import { getLocationUtils } from '../utils/locationUtils';
import {
  massiveDownloadFilesUtils,
  getIndexesAndContractActiveUtils,
  setContractsInStateUtils,
  getContractsToSign,
  activateAllContractsToSign,
  contractsToDownloadExcelUtils,
} from '../utils/contractsContainerUtils';
import { activeAllContractsToDownloadUtils } from '../utils/contractsContainerUtils';
import ButtonsActionsFilesDisplay from './Contracts/ButtonsActionsFilesDisplay';
import { downloadExcel } from '../utils/downloadFilesUtils';
import SelectDateModal from '../components/Modals/SelectDateModal';
import moment from 'moment';

function getModifiableList(contracts) {
  const newList = [];
  contracts.map((contract) => newList.push(contract));
  return newList;
}

class Contracts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contracts: [],
      currentLabels: [],
      currentStatus: '',
      myStatus: [
        {
          key: 'waitingOthers',
          content: 'Esperando a otros firmantes',
          icon: 'far fa-clock',
          translationId: 'li1',
        },
        {
          key: 'forMe',
          content: 'Listo para mi firma',
          icon: 'fas fa-pen-nib',
          translationId: 'li2',
        },
        {
          key: 'byMe',
          content: 'Firmado por mí',
          icon: 'fas fa-check',
          translationId: 'li3',
        },
        {
          key: 'ready',
          content: 'Firmado por todas las partes',
          icon: 'fas fa-check-circle',
          translationId: 'li4',
        },
        {
          key: 'copied',
          content: 'Copiados',
          icon: 'far fa-copy',
          translationId: 'li5',
        },
        {
          key: 'cancelled',
          content: 'Cancelados',
          icon: 'fas fa-ban',
          translationId: 'li6',
        },
      ],
      titleOrder: false,
      createdAtOrder: false,
      expiresOnOrder: false,
      legalNameOrder: false,
      showContractsVideo: false,
      loading: true,
      currentPage: null,
      totalPages: null,
      getcontract: true,
      showDeletePopUp: false,
      contract: null,
      showCancelPopUp: false,
      contractSelected: null,
      contractAuthorizer: [],
      contractsSign: [],
      showModal: false,
      contractsForMe: [],
      responses: [],
      successSign: false,
      locationUser: null,
      cannotSign: false,
      showAlertLocation: false,
      newMessageAlert: false,
      loadingDownload: false,
      rootLabels: [],
      filteredContracts: [],
      searchQuery: '',
      modalSelectDate: false,
      fromDate: null,
      toDate: null,
      downloadType: null,
      filteredLabels: new Set(),
    };
    this.onSort = this.onSort.bind(this);
    this.filterList = this.filterList.bind(this);
  }

  async componentDidMount() {
    const { signer, session, lang, translations } = this.props;
    const { notifications } = translations[lang].Contracts;
    const pathname = window.history?.state?.state?.from;
    if (!pathname?.includes('/contratos/')) {
      localStorage.removeItem('currentPage');
    }
    if (!signer) {
      try {
        const allMyDocuments = await ContractActions.list();

        this.setState({ contracts: allMyDocuments ?? [] });
        this.setState({ contractsSign: allMyDocuments ?? [] });

        await LabelActions.list();
        await LabelActions.myLabels(session.user.id);
        this.setState({ loading: false });
        this.setState({ getcontract: false });
        VideoActions.showVideo('contracts').then(async (canView) => {
          if (canView === true) this.showContractsVideo();
        });
        // const locationUser = getLocationUtils(
        //   this.onSuccessLocation,
        //   this.onErrorLocation,
        //   this.verifyIfUserHasLocation,
        // );

        // if (locationUser) {
        //   this.setState({ cannotSign: true });
        //   return NotificationManager.warning(
        //     notifications.notifBody1,
        //     notifications.notifTitle1,
        //     10000,
        //   );
        // }

        return;
      } catch (error) {
        this.setState({ loading: false });
        return NotificationManager.error(
          notifications.notifBody2,
          notifications.notifTitle2,
          10000,
        );
        // });
      }
    }

    let listContracts = [],
      hash = {};
    const idLabels = signer.protectedDocs
      ? JSON.parse(signer.protectedDocs)
      : null;
    const contracts = await ContractActions.listAdmin(
      signer.rootUser,
      signer.legalRfc,
    );
    const labels = await LabelActions.listRoot(signer.rootUser);
    await LabelActions.rootLabels(signer.rootUser);
    if (idLabels) {
      const labelsList = labels.filter((label) => {
        return idLabels.indexOf(label.id.toString()) > -1;
      });

      listContracts = labelsList.map((label) => {
        return contracts.find((contract) => contract.id === label.contract);
      });

      //Verifica si al listar algun contrato, alguno no venga como undefined
      //Si viene, filtramos por los que esten cargados
      if (listContracts.includes(undefined)) {
        listContracts = listContracts.filter((contract) => contract);
      }
    }

    //Si no tiene ningún id de etiqueta asignado se muestra todos los contratos
    const filteredContracts = idLabels ? listContracts : contracts;
    let hashedContracts = filteredContracts.filter((o) =>
      hash[o.id] ? false : (hash[o.id] = true),
    );

    if (hashedContracts?.length) {
      hashedContracts = hashedContracts.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
      );
    }

    this.setState({
      loading: false,
      getcontract: false,
      contracts: hashedContracts,
    });
    return state.select(['contracts']).set(hashedContracts);
  }

  changeStatusAlert = () => {
    this.setState({ showAlertLocation: true });
  };

  //ESTO LO DEJO COMENTADO PARA UN FUTURO CASO.

  // componentDidUpdate(prevProps, prevState) {
  //   const { location } = this.props;
  //   const fromSigner =
  //     location.state && location.state.from.includes('/contracts/on');
  //   const fromAdmin =
  //     location.state && location.state.from.includes('/admin/of');
  //   if (
  //     (fromAdmin || fromSigner) &&
  //     prevState.contracts.length !== this.state.contracts.length
  //   ) {
  //     this.setState({ contracts: [] });
  //     state.select(['contracts']).set([]);
  //   }
  // }

  componentWillUnmount() {
    state.select(['contracts']).set([]);
    this.setState({ contracts: [] });
  }

  onPageChanged = (data) => {
    const { contracts } = this.props;
    const { currentStatus, filteredContracts, currentLabels, searchQuery } =
      this.state;
    const { currentPage, totalPages, pageLimit } = data;
    const offset = (currentPage - 1) * pageLimit;
    const currentContracts =
      currentStatus || currentLabels.length || searchQuery
        ? filteredContracts.slice(offset, offset + pageLimit)
        : contracts.slice(offset, offset + pageLimit);
    this.setState({
      currentPage,
      contracts: currentContracts,
      totalPages,
    });
  };

  /**
   * Gets the repeated count of a contract
   * @param arr
   * @returns {[[], []]}
   */
  getCounts = (arr) => {
    const a = [];
    const b = [];
    let prev;
    arr.sort();
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] !== prev) {
        a.push(arr[i]);
        b.push(1);
      } else {
        // eslint-disable-next-line no-plusplus
        b[b.length - 1]++;
      }
      prev = arr[i];
    }
    return [a, b];
  };

  totalNumberResults = (data) => {
    let cont = 0;
    let contracts = [];
    // eslint-disable-next-line
    data.map((con) => {
      cont++;
      if (cont <= 10) {
        return contracts.push(con);
      }
    });
    this.setState({ contracts: contracts });
  };
  /**
   * Handles the search bar input
   * @param event
   */
  filterList = (event) => {
    const { currentStatus, currentLabels } = this.state;
    const { contracts, translations, lang } = this.props;
    const { texts } = translations[lang].Contracts;
    const value = event.target.value;
    const lastChar = value[value.length - 1];
    // this.setState({searchQuery:''},()=>this.setState({searchQuery:event.target.value}));
    if (lastChar === '\\') return;
    if (currentStatus || currentLabels.length)
      this.setState({ currentStatus: '', currentLabels: [] });
    this.setState({ searchQuery: value });
    let updatedList = contracts.filter(
      (contract) =>
        contract?.title?.toLowerCase().search(value.toLowerCase()) !== -1 ||
        contract?.legalName?.toLowerCase().search(value.toLowerCase()) !== -1 ||
        contract?.id?.toString().search(value) !== -1,
    );
    if (updatedList.length >= 1) {
      this.setState({ filteredContracts: updatedList });
      this.totalNumberResults(updatedList);
    } else {
      this.setState({
        contracts: [
          {
            id: 0,
            title: texts.noResults,
            legalName: '',
            validUntil: null,
            createdAt: null,
            status: null,
          },
        ],
        filteredContracts: [],
      });
    }
  };

  noRowResult = () => {
    const { translations, lang } = this.props;
    const { texts } = translations[lang].Contracts;
    this.setState({
      contracts: [
        {
          id: 0,
          title: texts.noResults,
          legalName: '',
          validUntil: null,
          createdAt: null,
          status: null,
        },
      ],
      filteredContracts: [
        {
          id: 0,
          title: texts.noResults,
          legalName: '',
          validUntil: null,
          createdAt: null,
          status: null,
          expiresOn: 'Error',
          isValid: 1,
        },
      ],
    });
  };

  /**
   * Filter Labels
   * @param value
   * @param checked
   */
  filterLabel = (value, checked) => {
    const { filteredLabels } = this.state;
    const { contracts, labels } = this.props;

    this.setState({ currentStatus: '', searchQuery: '' });
    checked ? filteredLabels.add(value) : filteredLabels.delete(value);
    const arrayFilteredLabels = [...Array.from(filteredLabels)];
    this.setState({ currentLabels: arrayFilteredLabels });
    const firstValue = checked
      ? value
      : arrayFilteredLabels[arrayFilteredLabels.length - 1];
    const verifyExistLabels = labels.filter(
      (label) => label.content.search(firstValue) !== -1,
    );
    if (!verifyExistLabels.length) {
      return this.noRowResult();
    }

    //Search labes contract
    const labelsList = labels.filter((myLabel) => {
      const index = arrayFilteredLabels.findIndex(
        (label) => myLabel.content === label,
      );
      if (index === -1) {
        return false;
      }
      return true;
    });

    //List ocurrences (list ocurrences by contract id and return object);
    const ocurrences = labelsList.reduce((acc, curr) => {
      const ocurrence = typeof acc[curr.contract] == 'undefined';
      ocurrence ? (acc[curr.contract] = 1) : (acc[curr.contract] += 1);
      return acc;
    }, {});

    //Get max ocurrences and filter by max ocurrence to get ids
    if (Object.keys(ocurrences).length === 0) return null;
    const max = Math.max(...Object.values(ocurrences));
    //If max is "<" to length arrayFilteredLabels, its means no results
    if (max < arrayFilteredLabels?.length) return this.noRowResult();
    const ids = Object.keys(ocurrences).filter(
      (obj) => ocurrences[obj] === max,
    );

    //Search contract by max ids with ocurrences
    const newList = contracts.filter((contract) => {
      const index = ids.findIndex((id) => contract.id === Number(id));
      if (index === -1) {
        return false;
      }
      return true;
    });

    if (!newList.length) {
      return this.noRowResult();
    }

    this.setState({ filteredContracts: newList });
    this.totalNumberResults(newList);
  };

  /**
   * Filter by contract status
   */
  filterStatus = (value, checked) => {
    let { currentStatus } = this.state;
    const { contracts, translations, lang } = this.props;
    const { texts } = translations[lang].Contracts;
    let newList = [];
    if (checked) {
      newList = contracts.filter((contract) => contract.status === value);
      currentStatus = value;
      this.setState({ currentStatus, currentLabels: [], searchQuery: '' });
      if (newList.length >= 1) {
        this.setState({ filteredContracts: newList });
        this.totalNumberResults(newList);
      } else {
        this.setState({
          contracts: [
            {
              id: 0,
              title: texts.noResults,
              legalName: '',
              validUntil: null,
              createdAt: null,
              status: null,
              expiresOn: 'Error',
              isValid: 1,
            },
          ],
          filteredContracts: [
            {
              id: 0,
              title: texts.noResults,
              legalName: '',
              validUntil: null,
              createdAt: null,
              status: null,
              expiresOn: 'Error',
              isValid: 1,
            },
          ],
        });
      }
    } else {
      this.setState({ currentStatus: '', filteredContracts: [] });
      let cont = 0;
      let contracts = [];
      // eslint-disable-next-line
      this.props.contracts.map((con) => {
        cont++;
        if (cont <= 10) {
          return contracts.push(con);
        }
      });
      this.setState({ contracts: contracts });
    }
  };

  /**
   * Handles the Sorting of contracts
   * @param event
   * @param sortKey
   */
  onSort = (event, sortKey) => {
    const name = `${sortKey}Order`;
    const { contracts } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    const order = this.state[name];
    const newList = getModifiableList(contracts);
    if (order) {
      if (sortKey === 'title' || sortKey === 'legalName') {
        newList.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
      }
      if (sortKey === 'createdAt' || sortKey === 'expiresOn') {
        newList.sort((a, b) => new Date(a[sortKey]) - new Date(b[sortKey]));
      }
    } else {
      if (sortKey === 'title' || sortKey === 'legalName') {
        newList.sort((a, b) => b[sortKey].localeCompare(a[sortKey]));
      }
      if (sortKey === 'createdAt' || sortKey === 'expiresOn') {
        newList.sort((a, b) => new Date(b[sortKey]) - new Date(a[sortKey]));
      }
    }
    this.setState({ contracts: newList });
    this.setState({ [name]: !order });
  };

  /**
   * Gets the labels for each contract
   * @param labels
   * @param contractId
   * @returns {[]}
   */
  labelsForThisContract = (labels, contractId) => {
    const newList = [];
    labels.map((label) =>
      label.contract === contractId ? newList.push(label) : false,
    );
    return newList;
  };

  showContractsVideo = () => {
    this.setState({ showContractsVideo: true });
  };

  hideContractsVideo = () => {
    this.setState({ showContractsVideo: false });
  };

  handleDeletePopUp = () => {
    const { showDeletePopUp } = this.state;
    this.setState({ showDeletePopUp: !showDeletePopUp });
  };

  async softDeleteContract() {
    const { session, translations, lang } = this.props;
    const { notifications } = translations[lang].Contracts;
    try {
      const { user } = session;
      let contractId = document.getElementById('button-hidden').value;
      await LabelActions.removeContractLabels(contractId);
      await ContractActions.softDeleteContract(contractId, user);

      NotificationManager.success(
        notifications.notifBody3,
        notifications.notifTitle3,
        10000,
      );
      await ContractActions.list();
      await LabelActions.list();
      await LabelActions.myLabels();
      this.setState({ loading: false });
      this.setState({ getcontract: false });
    } catch (error) {
      NotificationManager.error(
        notifications.notifBody4,
        notifications.notifTitle4,
        10000,
      );
    }
  }

  redirectTo = async (history, user) => {
    // TRAER FIRMANTES Y SIGNERS para comparar que es!!!
    const { translations, lang } = this.props;
    const { notifications } = translations[lang].Contracts;
    try {
      let contractId = document.getElementById('button-hidden').value;
      const recipients = await ContractActions.getThisRecipients(contractId);
      if (recipients.length <= 0) {
        return history.push({
          pathname: `/contratos/${contractId}`,
          state: {
            from: '/contratos',
          },
        });
      }
      const recipient = recipients.map((rec) => rec.legalRfc);
      if (!recipient.includes(user.legalRfc)) {
        return history.push({
          pathname: `/contratos/${contractId}`,
          state: {
            from: '/contratos',
          },
        });
      }
      history.push({
        pathname: `/public/contracts/${contractId}`,
        state: {
          from: '/contratos',
        },
      });
    } catch (error) {
      NotificationManager.error(
        notifications.notifBody5,
        notifications.notifTitle5,
        10000,
      );
    }
    // });
  };

  handleCancelPopUp = () => {
    const { showCancelPopUp } = this.state;
    this.setState({ showCancelPopUp: !showCancelPopUp });
  };

  async cancelContract() {
    const { translations, lang } = this.props;
    const { notifications } = translations[lang].Contracts;
    try {
      let contractId = document.getElementById('button-hidden').value;
      await ContractActions.cancelContract(contractId);
      NotificationManager.success(
        notifications.notifBody6,
        notifications.notifTitle6,
        10000,
      );
      this.setState({ showCancelPopUp: false });

      const contracts = await ContractActions.list();
      await LabelActions.list();
      await LabelActions.myLabels();
      this.setState({ contracts });
    } catch (error) {
      NotificationManager.error(
        notifications.notifBody7,
        notifications.notifTitle7,
        10000,
      );
    }
    // });
  }

  hideModal = () => {
    this.setState({ showModal: false });
  };

  showModal = () => {
    this.setState({ showModal: true });
  };

  onSign = (modalState) => {
    const allContractsSelected = this.state.contractsForMe;
    const {
      session: { user },
      admin,
      translations,
      lang,
    } = this.props;
    const { notifications } = translations[lang].Contracts;

    NotificationManager.info(
      notifications.notifBody8,
      notifications.notifTitle8,
      8000,
    );
    const credentials = modalState.state;
    const signerUser = { ...user };
    signerUser.representing = admin ? admin?.representing : null;
    signerUser.representedRfc = admin ? admin?.legalRfc : null;
    // this.hideModal();
    for (let i = 0; i < allContractsSelected.length; i++) {
      const contract = allContractsSelected[i];
      Signature(credentials, contract.lastHash || contract.hash)
        .then((cuteSign) => {
          this.signContract({
            contract,
            credentials,
            user: signerUser,
            cuteSign,
          });
        })
        .catch((error) =>
          // TODO: translate response
          NotificationManager.warning(error.message, 'Atención', 10000),
        );
    }
  };

  signContract(data) {
    const { contract, user } = data;
    const { contracts, contractsForMe, responses, locationUser } = this.state;
    const { translations, lang } = this.props;
    const { notifications } = translations[lang].Contracts;

    this.setState({ loading: true });
    ContractActions.sign({ ...data, locationUser })
      .then(async (response) => {
        if (response !== 1) {
          this.setState({ loading: false });
          // TODO: translate response
          return NotificationManager.error(response.message, 'Atención', 10000);
        }
        responses.push(response);
        contractsForMe.forEach((contractForMe) => {
          const allContracts = [...this.props.contracts];
          if (contractForMe.id === contract.id) {
            const index = contracts.findIndex(
              (contractIndex) => contractIndex.id === contract.id,
            );
            const indexGlobal = allContracts.findIndex(
              (contractIndexGlobal) => contractIndexGlobal.id === contract.id,
            );
            let contractSigned = {
              ...contractForMe,
              signed: 1,
              status:
                contract.participants === contract.lastSignedTurn + 1
                  ? 'ready'
                  : 'byMe',
              isActive: false,
              allPartiesSigned:
                contract.participants === contract.lastSignedTurn + 1
                  ? true
                  : false,
            };
            contracts.splice(index, 1, contractSigned);
            allContracts.splice(indexGlobal, 1, contractSigned);
            this.setState({ contracts: contracts });
            state.select(['contracts']).set(allContracts);
          }
        });

        if (responses.length === contractsForMe.length) {
          this.setState({ loading: false });
          this.setState({ successSign: true });
          try {
            await ContractActions.sendEmailToUserWhenSignMassive(
              user,
              contractsForMe.length,
            );
          } catch (error) {
            console.log(error);
          }

          return NotificationManager.success(
            notifications.notifBody11(responses.length),
            notifications.notifTitle11,
            10000,
          );
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
        NotificationManager.error(
          notifications.notifBody12,
          notifications.notifTitle12,
          10000,
        );
      });
  }

  // getContractsIsActive = (contracts, checked, event) => {
  //   const contractsActive = contracts.map((contract) => {
  //     const contractActive = { ...contract };
  //     if (contract.status === 'forMe') {
  //       contractActive.isActive = checked
  //         ? event.target.checked
  //         : event.target.checked;
  //     } else {
  //       contractActive.isActive = false;
  //     }

  //     return contractActive;
  //   });
  //   return contractsActive;
  // };

  onCheckboxChange = () => {
    // Get the checkbox
    const {
      contracts,
      session: { user },
    } = this.props;
    const { currentLabels, currentStatus, searchQuery, filteredContracts } =
      this.state;
    let contractsToSign = [];
    if (
      currentStatus === 'forMe' ||
      currentLabels.length ||
      searchQuery !== ''
    ) {
      contractsToSign = getContractsToSign(filteredContracts, user);
    } else {
      contractsToSign = getContractsToSign(contracts, user);
    }

    //LIMIT FILES TO SIGN
    const top = 500;
    if (contractsToSign.length > top) {
      contractsToSign.length =
        contractsToSign.length - (contractsToSign.length - top);
    }

    this.setState({
      contractsForMe: contractsToSign,
    });

    this.setState({ showModal: true });
  };

  onClickCheckboxContractRow = (event, contract) => {
    const { contracts } = this.props;
    const contractsState = this.state.contracts;
    const { currentStatus, filteredContracts, currentLabels, searchQuery } =
      this.state;
    const allContracts = [...contracts];

    const { contractActive, indexGlobal, indexLocal } =
      getIndexesAndContractActiveUtils(contract, contractsState, allContracts);

    const contractCheckbox = document.getElementById(`myCheck${contract.id}`);
    const checkBoxAllContracts = document.getElementById('myCheckOnly');
    checkBoxAllContracts.checked = false;
    const activeButton = contractCheckbox.checked ? true : false;
    contractActive.isActive = activeButton;

    const data = {
      allContracts,
      indexGlobal,
      indexLocal,
      contractActive,
      contractsState,
    };
    // REVISA UNICAMENTE CUANDO ESTA UN FILTRO SELECCIONADO EN FIRMA (FOR ME)
    if (
      currentStatus === 'forMe' ||
      currentLabels.length ||
      searchQuery !== ''
    ) {
      const indexFiltered = filteredContracts.findIndex(
        (cont) => cont.id === contractActive.id,
      );
      filteredContracts.splice(indexFiltered, 1, contractActive);
      this.setState({ filteredContracts });
      return setContractsInStateUtils(data);
    }
    setContractsInStateUtils(data);
  };

  onClickSelectAllCheckBox = (event) => {
    const { checked } = document.getElementById('myCheckOnly');
    const data = { props: this.props, state: this.state };
    const {
      activateContracts,
      contractsToSend: { type },
    } = activateAllContractsToSign(data, checked);

    const filteredContracts = activateContracts.filter(
      (contract) =>
        (contract.requestDocument && !contract.allDocsUploaded) ||
        (contract.requestVerification && !contract.verified),
    );

    const contractsToSign = activateContracts.filter((contract) => {
      const index = filteredContracts.findIndex(
        (contractToDelete) => contract.id === contractToDelete.id,
      );
      if (index === -1) {
        return true;
      }
      return false;
    });

    if (type === 'props') {
      this.totalNumberResults(contractsToSign);
      return state.select(['contracts']).set(contractsToSign);
    }
    this.setState({ filteredContracts: contractsToSign });
    this.totalNumberResults(contractsToSign);
  };

  canSignAllContracts = () => {
    const { contracts } = this.props;
    const { currentLabels, currentStatus, searchQuery, filteredContracts } =
      this.state;
    if (
      currentStatus === 'forMe' ||
      currentLabels.length ||
      searchQuery !== ''
    ) {
      return filteredContracts.filter((contract) => contract.isActive);
    }
    const contractsActive = contracts.filter((contract) => contract.isActive);
    return contractsActive.length > 0;
  };

  onSuccessLocation = (position) => {
    const {
      coords: { latitude, longitude },
    } = position;
    this.setState({
      locationUser: {
        latitude,
        longitude,
      },
    });
    this.setState({ showAlertLocation: false });
    this.setState({ cannotSign: false });
  };

  onErrorLocation = (error) => {
    this.setState({ cannotSign: true });
    this.setState({ showAlertLocation: false });
    this.setState({ newMessageAlert: true });
  };

  verifyIfUserHasLocation = (result) => {
    if (result.state === 'prompt') {
      this.setState({ showAlertLocation: true });
      this.setState({ cannotSign: true });
    }
  };

  onClickDownloadFiles = async (contractsActive) => {
    const { activeAllDownload } = this.state;
    const { session, signer, translations, lang } = this.props;
    const { notifications } = translations[lang].Contracts;

    this.setState({ loadingDownload: true });
    const top = 300;
    if (contractsActive.length > top) {
      contractsActive.length =
        contractsActive.length - (contractsActive.length - top);
    }
    if (!activeAllDownload) {
      const { data } = await ContractActions.massiveDownloadFiles(
        contractsActive,
        signer,
      );
      if (data.error) {
        this.setState({ loadingDownload: false });
        return NotificationManager.error(
          notifications.notifBody13,
          notifications.notifTitle13,
          10000,
        );
      }
      this.setState({ loadingDownload: false });
      return massiveDownloadFilesUtils(data, session);
    }
    const contractsActiveToSign = contractsActive.filter(
      (contract) =>
        contract.isDownload &&
        contract.status === 'ready' &&
        !contract.downloadedPdf,
    );
    const { data } = await ContractActions.massiveDownloadFiles(
      contractsActiveToSign,
      signer,
    );
    if (data.error) {
      this.setState({ loadingDownload: false });
      return NotificationManager.error(
        notifications.notifBody14,
        notifications.notifTitle14,
        10000,
      );
    }
    this.setState({ loadingDownload: false });
    const nameZip = signer ? signer.legalRfc : session.user.legalRfc;
    return massiveDownloadFilesUtils(data, nameZip);
  };

  onClickSelectAllPdfToDownload = () => {
    const { activeAllDownload } = this.state;
    this.setState({ activeAllDownload: !activeAllDownload }, () => {
      const {
        activeAllDownload,
        filteredContracts,
        currentStatus,
        searchQuery,
        currentLabels,
      } = this.state;
      const { contracts } = this.props;
      let contractsSelected = [];
      const contractsActive = activeAllContractsToDownloadUtils(
        contracts,
        activeAllDownload,
      );
      if (
        currentStatus === 'ready' ||
        currentLabels.length ||
        searchQuery !== ''
      ) {
        contractsSelected = contractsActive.filter(
          (contract) => contract.status === 'ready' && !contract.downloadedPdf,
        );
        if (!this.state.activeAllDownload) {
          contractsSelected = activeAllContractsToDownloadUtils(
            filteredContracts,
            activeAllDownload,
          );
        }
        this.setState({ filteredContracts: contractsSelected });
        this.totalNumberResults(contractsSelected);
      }
      state.select(['contracts']).set(contractsActive);
      if (currentStatus === '') {
        this.totalNumberResults(contractsActive);
      }
    });
  };

  onClickDownloadFileByFile = (contract) => {
    this.setState({ activeAllDownload: false });
    const { contracts } = this.props;
    const contractsState = this.state.contracts;
    const { filteredContracts, currentStatus } = this.state;
    const allContracts = [...contracts];

    const { contractActive, indexGlobal, indexLocal } =
      getIndexesAndContractActiveUtils(contract, contractsState, allContracts);

    const activeButton = contract.isDownload ? false : true;
    contractActive.isDownload = activeButton;
    const data = {
      allContracts,
      indexGlobal,
      indexLocal,
      contractActive,
      contractsState,
    };
    //REVISA UNICAMENTE CUANDO ESTA UN FILTRO SELECCIONADO EN DESCARGAS (READY)
    if (currentStatus === 'ready') {
      const indexFiltered = filteredContracts.findIndex(
        (cont) => cont.id === contractActive.id,
      );
      filteredContracts.splice(indexFiltered, 1, contractActive);
      this.setState({ filteredContracts });
      return setContractsInStateUtils(data);
    }
    setContractsInStateUtils(data);
  };

  canDownloadPdf = () => {
    const { contracts } = this.props;

    const contractsActive = contracts.filter(
      (contract) => contract.isDownload && contract.status === 'ready',
    );

    return contractsActive;
  };

  resetAllDownloads = async () => {
    const { signer } = this.props;
    const response = await ContractActions.resetAllDownloads(signer);
    if (response.data.status) {
      // TODO: translate response
      return NotificationManager.error(response.data.message, 'Error', 10000);
    }

    const { contracts } = this.props;
    const contractsReset = [...contracts];
    const allContractsReset = contractsReset.map((contract) => {
      const contractReset = { ...contract };
      contractReset.downloadedPdf = false;
      return contractReset;
    });

    state.select(['contracts']).set(allContractsReset);
    // TODO: translate response
    return NotificationManager.success(response.data.message, 'Éxito', 10000);
  };

  onClickDownloadExcel = async (excelDownloadType) => {
    this.setState({ loading: true });
    const { contracts, translations, lang } = this.props;
    const { notifications } = translations[lang].Contracts;
    const {
      filteredContracts,
      currentLabels,
      currentStatus,
      searchQuery,
      fromDate,
      toDate,
    } = this.state;

    const contractsSelected =
      currentLabels.length || currentStatus !== '' || searchQuery !== ''
        ? filteredContracts
        : contracts;

    if (excelDownloadType === 'bySigners') {
      const ids = contractsSelected.map((contract) => contract?.id);

      const contractsSigners = await ContractActions.getExcelReport({
        contractIds: ids,
        fromDate,
        toDate: moment(toDate).add(1, 'day').format('YYYY-MM-DD'),
      });
      if (!contractsSigners?.data?.success) {
        // TODO: translate response
        return NotificationManager.error(
          contractsSigners?.data,
          'Atención',
          5000,
        );
      }
      downloadExcel(contractsSigners.data.data, 'contrato_con_firmantes');
      this.setState({ loading: false });
      return NotificationManager.success(
        notifications.notifBody18,
        notifications.notifTitle18,
        5000,
      );
    }

    const allContracts = contractsToDownloadExcelUtils(
      contractsSelected,
      fromDate,
      toDate,
    );
    if (!allContracts.length) {
      this.setState({ loading: false });
      return NotificationManager.warning(
        notifications.notifBody19,
        notifications.notifTitle19,
        5000,
      );
    }

    downloadExcel(allContracts, 'todos_mis_contratos');
    this.setState({ loading: false });
    return NotificationManager.success(
      notifications.notifBody20,
      notifications.notifTitle20,
      5000,
    );
  };

  onInputDateChange = (e) => {
    if (e.target.name === 'fromDate') {
      return this.setState({ fromDate: e.target.value });
    }

    this.setState({ toDate: e.target.value });
  };

  onClickShowModalToSelectDate = (downloadType) => {
    const { modalSelectDate } = this.state;
    if (downloadType === 'excel') {
      this.setState({ downloadType });
    }

    if (modalSelectDate) {
      this.setState({ downloadType: null });
    }
    this.setState({ modalSelectDate: !modalSelectDate });
  };

  downloadMassivePdfByDate = async (attribute) => {
    this.setState({ loading: true });
    const {
      signer,
      session: { user },
    } = this.props;
    const { fromDate, toDate } = this.state;
    const id = signer ? signer.rootUser : user.id;
    const legalRfc = signer ? signer.legalRfc : user.legalRfc;
    const { data } = await ContractActions.downloadPdfByDate({
      id,
      legalRfc,
      from: fromDate,
      to: toDate,
      signer,
      attribute,
    });
    if (data.error) {
      this.setState({ loading: false });
      // TODO: translate response
      return NotificationManager.error(data.error, 'Error', 10000);
    }
    this.setState({ loading: false });
    const nameZip = signer ? signer.legalRfc : user.legalRfc;
    return massiveDownloadFilesUtils(data, nameZip);
  };

  render() {
    const { session, myLabels, labels, history, signer, lang, translations } =
      this.props;
    const { user } = session;
    const { texts, buttons } = translations[lang].Contracts;
    // eslint-disable-next-line react/destructuring-assignment
    const contracts = this.state.contracts.length
      ? this.state.contracts
      : this.props.contracts;
    const {
      currentStatus,
      currentLabels,
      myStatus,
      titleOrder,
      createdAtOrder,
      legalNameOrder,
      expiresOnOrder,
      getcontract,
      // TODO: add when video ready
      showDeletePopUp,
      showCancelPopUp,
      // contractAuthorizer,
      showModal,
      contractsForMe,
      loading,
      responses,
      successSign,
      cannotSign,
      showAlertLocation,
      newMessageAlert,
      activeAllDownload,
      loadingDownload,
      filteredContracts,
      searchQuery,
      modalSelectDate,
      fromDate,
      toDate,
      downloadType,
      currentPage,
    } = this.state;

    return (
      <div className={`grid_list_contracts ${signer ? 'admin_view' : ''}`}>
        {/* Titulo de la pagina y nuevo */}
        {!signer ? (
          <div className="title_page_contracts">
            <h1>{texts.h1}</h1>
            <Link to="/nuevo">{texts.link1}</Link>
          </div>
        ) : null}
        {/* Filter contracts */}
        {getcontract ? (
          <div className="center_loading">
            <Loader />
            <p>{texts.p1}</p>
          </div>
        ) : contracts.length ? (
          <>
            <div className="filter_contracts">
              <FilterToolbarDisplay
                filterList={this.filterList}
                Checkbox={Checkbox}
                myLabels={myLabels}
                filterLabel={this.filterLabel}
                myStatus={myStatus}
                currentLabels={currentLabels}
                filterStatus={this.filterStatus}
                currentStatus={currentStatus}
                resetAllDownloads={this.resetAllDownloads}
                searchQuery={searchQuery}
              />
            </div>
            {/* Actions contracts */}
            <div className="action_contracts">
              <Pagination
                totalRecords={
                  currentStatus || currentLabels.length || searchQuery
                    ? filteredContracts.length
                    : this.props.contracts.length
                }
                pageLimit={10}
                pageNeighbours={1}
                onPageChanged={this.onPageChanged}
              />
              <ButtonsActionsFilesDisplay
                loading={loadingDownload}
                canDownloadPdf={this.canDownloadPdf}
                onClickDownloadFiles={this.onClickDownloadFiles}
                canSignAllContracts={this.canSignAllContracts}
                onCheckboxChange={this.onCheckboxChange}
                cannotSign={cannotSign}
                signerOrAdmin={signer}
                downloadExcel={this.onClickDownloadExcel}
                onClickShowModalToSelectDate={this.onClickShowModalToSelectDate}
              />
            </div>
            <div className="list_contracts page">
              <ContractListDisplay
                onSort={this.onSort}
                titleOrder={titleOrder}
                createdAtOrder={createdAtOrder}
                legalNameOrder={legalNameOrder}
                expiresOnOrder={expiresOnOrder}
                contracts={contracts}
                generateShortId={generateShortId}
                user={user}
                labelsForThisContract={this.labelsForThisContract}
                labels={labels}
                history={history}
                selectContractToSign={this.onClickCheckboxContractRow}
                onClickSelectAllCheckBox={this.onClickSelectAllCheckBox}
                downloadFile={this.onClickDownloadFileByFile}
                selectAllPdfToDownload={this.onClickSelectAllPdfToDownload}
                activeAllDownload={activeAllDownload}
                signerOrAdmin={signer}
                actualPage={currentPage}
              />
              {showCancelPopUp ? (
                <GenericConfirmModal
                  title={texts.genericModalTitle1}
                  message={texts.genericModalBody1}
                  okButton={buttons.genericModalConfirmButton1}
                  cancelButton={buttons.genericModalCancelButton1}
                  cancelAction={() => this.handleCancelPopUp()}
                  acceptAction={() => this.cancelContract()}
                />
              ) : null}
              {showDeletePopUp ? (
                <GenericConfirmModal
                  title={texts.genericModalTitle2}
                  message={texts.genericModalBody2}
                  okButton={buttons.genericModalConfirmButton2}
                  cancelButton={buttons.genericModalCancelButton1}
                  cancelAction={() => this.handleDeletePopUp()}
                  acceptAction={() => this.softDeleteContract()}
                />
              ) : null}

              {showModal ? (
                <GenericSignModal
                  handleConfirm={this.onSign}
                  handleCancel={this.hideModal}
                  contracts={contractsForMe}
                  type="sign"
                  loading={loading}
                  responses={responses}
                  successSign={successSign}
                />
              ) : null}

              {modalSelectDate ? (
                <SelectDateModal
                  handleCancel={this.onClickShowModalToSelectDate}
                  onInputDateChange={this.onInputDateChange}
                  downloadMassivePdfByDate={this.downloadMassivePdfByDate}
                  loading={loading}
                  fromDate={fromDate}
                  toDate={toDate}
                  signer={signer}
                  user={session.user}
                  downloadType={downloadType}
                  downloadExcel={this.onClickDownloadExcel}
                  hasFilter={
                    currentLabels.length ||
                    currentStatus !== '' ||
                    searchQuery !== ''
                  }
                />
              ) : null}

              {showAlertLocation ? (
                <GenericConfirmModal
                  title={
                    newMessageAlert
                      ? texts.genericModalTitle3
                      : texts.genericModalTitle4
                  }
                  message={
                    newMessageAlert
                      ? texts.genericModalBody3
                      : texts.genericModalBody4
                  }
                  okButton={buttons.genericModalConfirmButton3}
                  cancelButton={buttons.genericModalCancelButton3}
                  cancelAction={() =>
                    this.setState({ showAlertLocation: false })
                  }
                  acceptAction={() =>
                    this.setState({ showAlertLocation: false })
                  }
                />
              ) : null}
            </div>
          </>
        ) : (
          <div className="empty">
            <h2>{texts.h2}</h2>
            {!signer ? <Link to="/nuevo">{texts.link2}</Link> : null}
          </div>
        )}
      </div>
    );
  }
}

export default branch(
  {
    contracts: ['contracts'],
    session: ['session'],
    labels: ['labels'],
    myLabels: ['myLabels'],
    translations: ['translations'],
    lang: ['lang'],
  },
  Contracts,
);
