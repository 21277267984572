export const ApiRoutes = {
  signApi: '/methods/sign/:id', // sign
  getSignerApi: '/methods/get-signer',
  createContractSifeApi: '/methods/send-contract-sife',
  registerUserSifeApi: '/methods/register-sife',
  getUserApi: '/methods/get-user/:rfc',
  viewApi: '/methods/get/:id', // view
  downloadContractToViewAPI: '/methods/downloadContractToViewApi/:id',
  getPublicInfo: '/methods/public-info/:id',
};
