export const SelectDateModalTranslations = {
  es: {
    texts: {
      h3: 'Descargando documentos',
      p1: 'Esto puede tardar unos minutos',
      h3_2: 'Descarga tus documentos en excel',
      h3_3: 'Descarga tus documentos en PDF',
      small1: 'Selecciona un filtro de descarga y rango de fechas',
      small2: 'Fecha inicio',
      small3: 'Fecha fin',
      small4: 'Tipo de descarga',
      small5: '¿Quieres ver el total de tus documentos?',
      small6: (contracts) => `Hay ${contracts} pendientes de descarga`,
      small7: 'Tienes un filtro seleccionado',
      small8: 'tus documentos se descargarán con base a ese filtro',
      small9: 'Descarga por proceso 300 documentos',
      small10: ' Únicamente los firmados por todas las partes',
    },
    inputs: {
      selectOptionDefault: 'Descargar documentos por',
      selectOption1: 'Fecha de creación',
      selectOption2: 'Ultima fecha de firma',
      inputPlaceholder1: 'Fecha validación',
      inputPlaceholder2: 'Fecha validación',
      selectOption3: 'Información del documento',
      selectOption4: 'Información de firmantes',
    },
    buttons: {
      button1: 'Cerrar',
      button2: 'Descargar',
    },
    notifications: {
      notifTitle1: 'Error',
      notifBody1: '', // TODO: translate response
    },
  },
  en: {
    texts: {
      h3: 'Downloading documents',
      p1: 'This may take a few minutes',
      h3_2: 'Download your documents in excel',
      h3_3: 'Download your documents in PDF',
      small1: 'Select a download filter and date range',
      small2: 'Start date',
      small3: 'End date',
      small4: 'Download type',
      small5: 'Do you want to see the total of your documents?',
      small6: (contracts) => `There are ${contracts} pending download`,
      small7: 'You have a selected filter',
      small8: 'your documents will be downloaded based on that filter',
      small9: 'Download by process 300 documents',
    },
    inputs: {
      selectOptionDefault: 'Download documents by',
      selectOption1: 'Creation date',
      selectOption2: 'Last signature date',
      inputPlaceholder1: 'Validation date',
      inputPlaceholder2: 'Validation date',
      selectOption3: 'Document information',
      selectOption4: 'Signers information',
    },
    buttons: {
      button1: 'Close',
      button2: 'Download',
    },
    notifications: {
      notifTitle1: 'Error',
      notifBody1: '', // TODO: translate response
    },
  },
};
