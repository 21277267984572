export const AuthorizersListDisplayTranslations = {
  es: {
    texts: {
      spanTitle1: 'Autorizadores',
      p1: 'No hay autorizadores',
    },
    inputs: {},
    buttons: {},
    notifications: {},
  },
  en: {
    texts: {
      spanTitle1: 'Authorizers',
      p1: 'No authorizers',
    },
    inputs: {},
    buttons: {},
    notifications: {},
  },
};
