import React from 'react';
import Loader from '../Loader';
import { NotificationManager } from 'react-notifications';
import SuperUserActions from '../../actions/SuperUser';

class CouponModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      loading: false,
    };
  }

  handleChange = (e) => {
    const { value } = e.target;
    this.setState({ total: value });
  };

  handleUpdate = async () => {
    const { total } = this.state;
    const { user, updateGlobalState, coupon } = this.props;
    if (Number(total) === 0) {
      return NotificationManager.error(
        'El total de cupones a agregar debe ser mayor a 0',
        'Error',
        5000,
      );
    }
    this.setState({ loading: true });
    const response = await SuperUserActions.updateSpUserCoupon({
      couponId: coupon.id,
      createdBy: user.id,
      total,
    });

    const data = response?.data;
    if (!data?.success) {
      this.setState({ loading: false });
      return NotificationManager.error(
        data?.message || 'Ocurrió un error al actualizar cupones',
        'Error',
        5000,
      );
    }

    this.setState({ loading: false });
    updateGlobalState({ coupon: data?.updatedCoupon });
    return NotificationManager.success(data?.message, 'Éxito', 5000);
  };

  render() {
    const { hideModal, user, coupon } = this.props;
    const { loading } = this.state;
    return (
      <div className="modal-container">
        <div className="modal">
          <div
            className="header_modal"
            style={{ textAlign: 'center', color: '#2851e5' }}
          >
            Actualiza cupones de: {user.legalName} - {user.legalRfc}
          </div>
          <div className="body_modal">
            {loading ? (
              <div className="center_loading">
                <Loader />
                <p>Actualizando cupones...</p>
              </div>
            ) : (
              <>
                <p style={{ textAlign: 'center', fontSize: 14 }}>
                  El usuario tiene: {coupon?.usesLeft || 0} cupones
                </p>
                <input
                  onChange={this.handleChange}
                  name="legalRfc_update"
                  type="number"
                  className="input_nc"
                  required
                  placeholder="Cupones a agregar"
                  autoFocus
                  min={1}
                />
              </>
            )}
          </div>
          <div className="footer_modal">
            <button
              className="color-grey"
              type="button"
              onClick={() => hideModal()}
            >
              Cerrar
            </button>
            <button
              className="primary"
              type="button"
              onClick={this.handleUpdate}
              //   disabled={!this.disabledButton()}
            >
              Agregar cupones
            </button>
          </div>
        </div>
        <div className="background-screen" />
      </div>
    );
  }
}

export default CouponModal;
