export const GenericContractPreviewTranslations = {
  es: {
    texts: {
      h3: 'Firmar Dcoumento',
      p1: 'Para firmar el documento ingresa tu certificado, .key y tu contraseña ',
    },
    inputs: {},
    buttons: {
      button1: 'Activa ubicación para firmar',
      button2: 'Firmado',
      button3: 'Firmar',
      button4: 'Firmar Documento',
      button5: 'Firmar',
      button6: 'Ir a firmar',
      button7: 'Regresar',
    },
    notifications: {
      notifTitle1: 'Atención',
      notifBody1: 'Hubo un error al enviar contrato a SIFE',
    },
  },
  en: {
    texts: {
      h3: 'Sign Document',
      p1: 'To sign the document provide your certificate, .key and your password ',
    },
    inputs: {},
    buttons: {
      button1: 'Activate location to sign',
      button2: 'Signed',
      button3: 'Sign',
      button4: 'Sign Document',
      button5: 'Sign',
      button6: 'Go to sign',
      button7: 'Back',
    },
    notifications: {
      notifTitle1: 'Warning',
      notifBody1: 'There was an error sending the contract to SIFE',
    },
  },
};
