import { branch } from 'baobab-react/higher-order';
import React from 'react';

const AlertModalTranslations = {
  es: {
    buttons: {
      button1: 'Continuar',
    },
  },
  en: {
    buttons: {
      button1: 'Continue',
    },
  },
};

const AlertModal = ({ message, header, okButton, lang }) => {
  const { buttons } = AlertModalTranslations[lang];
  return (
    <div className="modal-container">
      <div className="modal">
        <div className="header_modal">
          <h1>{header}</h1>
        </div>
        <div className="body_modal">
          <p>{message}</p>
        </div>
        <div className="footer_modal">
          <button
            type="submit"
            style={{ background: '#6C6C6C' }}
            onClick={okButton}
          >
            {buttons.button1}
          </button>
        </div>
      </div>
      <div className="background-screen" />
    </div>
  );
};

export default branch(
  {
    lang: ['lang'],
  },
  AlertModal,
);
