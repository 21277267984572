import state from '../state';

const TranslationsActions = {
  switchLanguage: (language) => {
    localStorage.setItem('lang', language);
    state.select(['lang']).set(language);
    state.commit();
  },
  updateLanguageUser: (lang, user) => {
    const userUpdated = { user: { ...user } };
    userUpdated.user.lang = lang;
    localStorage.setItem('session', JSON.stringify(userUpdated));
    state.select(['session']).set(userUpdated);
    state.commit();
  },
};

export default TranslationsActions;
