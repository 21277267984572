export const ChipsTranslations = {
  es: {
    texts: {
      label1: 'Máximo de 10 etiquetas por documento.',
      small1: 'Da enter para crear tu nueva etiqueta',
    },
    inputs: {
      label1: 'Etiquetas opcional',
      inputPlaceholder1: 'Usar mis etiquetas',
      or: 'ó',
      inputPlaceholder2: 'Crear nuevas etiquetas',
    },
    buttons: {},
    notifications: {
      notifTitle1: 'Atención',
      notifBody1: (value) => `Ya existe ${value}`,
    },
  },
  en: {
    texts: {
      label1: 'Maximum of 10 tags per document.',
      small1: 'Enter to create a new label',
    },
    inputs: {
      label1: 'Tags optional',
      inputPlaceholder1: 'Use my tags',
      or: 'or',
      inputPlaceholder2: 'Add new tags',
    },
    buttons: {},
    notifications: {
      notifTitle1: 'Warning',
      notifBody1: (value) => `${value} already exists`,
    },
  },
};
