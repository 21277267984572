import React from 'react';

const GenericConfirmModal = ({
  title,
  message,
  okButton,
  cancelButton,
  cancelAction,
  acceptAction,
  type,
  textInfo,
}) => (
  <div className="modal-container">
    <div className="modal">
      <div className="header_modal">
        <h1>{title}</h1>
      </div>
      <div className="body_modal">
        <p>{message}</p>
        {textInfo ? <p>{textInfo}</p> : null}
      </div>
      <div className="footer_modal">
        <button
          style={{ color: 'black' }}
          type="button"
          className="secondary"
          onClick={cancelAction}
        >
          {cancelButton}
        </button>
        {type ? null : (
          <button
            style={{
              background: '#2851e5',
              color: 'white',
            }}
            type="button"
            onClick={acceptAction}
          >
            {okButton}
          </button>
        )}
      </div>
    </div>
    <div className="background-screen" />
  </div>
);

export default GenericConfirmModal;
