export const ContractPreviewTranslations = {
  es: {
    texts: {
      h3: 'Firmar Documentos',
      p1: (contractTitle) =>
        `Para firmar el documento "${contractTitle}" ingresa tu certificado, .key y tu contraseña`,
    },
    inputs: {},
    buttons: {
      button1: 'Firmar documento',
      button2: 'Firmar',
      button3: 'Ir a firmar',
      button4: 'Firmar',
      button5: 'Iniciar sesión',
      button6: 'Regresar',
    },
    notifications: {
      notifTitle1: 'Error',
      notifBody1: '', // TODO: translate response
      notifTitle2: 'Informativo',
      notifBody2: 'Firmando documento...\nEsto puede tardar unos minutos.',
      notifTitle3: 'Atención',
      notifBody3: '', // TODO: translate response
      notifTitle4: 'Éxito',
      notifBody4:
        'Documento firmado con éxito.\n Espere unos segundos y deslice para ver su firma.',
      notifTitle5: 'Atención',
      notifBody5: 'No se pudo firmar.',
      notifTitle6: 'Atención',
      notifBody6: '', // TODO: translate response
      notifTitle7: 'Informativo',
      notifBody7: 'Firmando documento...\nEsto puede tardar unos minutos.',
      notifTitle8: 'Atención',
      notifBody8: '', // TODO: translate response
      notifTitle9: 'Éxito',
      notifBody9:
        'Documento firmado con éxito.\n Espere unos segundos y deslice para ver su firma.',
      notifTitle10: 'Atención',
      notifBody10: 'No se pudo firmar.',
      notifTitle11: 'Éxito',
      notifBody11:
        'Documento firmado con éxito.\n Espere unos segundos y deslice para ver su firma.',
      notifTitle12: 'Éxito',
      notifBody12:
        'Documento firmado con éxito.\n Espere unos segundos y deslice para ver su firma.',
      notifTitle13: 'Advertencia',
      notifBody13: 'El rfc no es el mismo con el que iniciaste sesión',
      notifTitle14: 'Advertencia',
      notifBody14:
        'El email de inicio de sesión es diferente al registrado en contratos',
      notifTitle15: 'Advertencia',
      notifBody15: 'Hubo un error',
    },
  },
  en: {
    texts: {
      h3: 'Sign Documents',
      p1: (contractTitle) =>
        `To sign the document "${contractTitle}" enter your certificate, .key and your password`,
    },
    inputs: {},
    buttons: {
      button1: 'Sign document',
      button2: 'Sign',
      button3: 'Go to sign',
      button4: 'Sign',
      button5: 'Log in',
      button6: 'Back',
    },
    notifications: {
      notifTitle1: 'Error',
      notifBody1: '', // TODO: translate response
      notifTitle2: 'Info',
      notifBody2: 'Signing document...\nThis may take a few minutes.',
      notifTitle3: 'Attention',
      notifBody3: '', // TODO: translate response
      notifTitle4: 'Success',
      notifBody4:
        'Document signed successfully.\n Wait a few seconds and scroll to see your signature.',
      notifTitle5: 'Attention',
      notifBody5: 'Could not sign.',
      notifTitle6: 'Attention',
      notifBody6: '', // TODO: translate response
      notifTitle7: 'Info',
      notifBody7: 'Signing document...\nThis may take a few minutes.',
      notifTitle8: 'Attention',
      notifBody8: '', // TODO: translate response
      notifTitle9: 'Success',
      notifBody9:
        'Document signed successfully.\n Wait a few seconds and scroll to see your signature.',
      notifTitle10: 'Attention',
      notifBody10: 'Could not sign.',
      notifTitle11: 'Success',
      notifBody11:
        'Document signed successfully.\n Wait a few seconds and scroll to see your signature.',
      notifTitle12: 'Success',
      notifBody12:
        'Document signed successfully.\n Wait a few seconds and scroll to see your signature.',
      notifTitle13: 'Warning',
      notifBody13: 'The rfc is not the same as the one you logged in with',
      notifTitle14: 'Warning',
      notifBody14:
        'The login email is different from the one registered in contracts',
      notifTitle15: 'Warning',
      notifBody15: 'There was an error',
    },
  },
};
