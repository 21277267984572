import forge from 'node-forge';
import { translationsState } from '../translations/translations';

/**
 * Gets Buffer from file
 * @param file
 * @returns {Promise<any>}
 */
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

/**
 * Get Algorithm from Cert
 * @param algorithmOID
 * @returns {null|*}
 */
const getDigestAlgorithm = (algorithmOID) => {
  let algorithmHashName;
  switch (algorithmOID) {
    case '1.2.840.113549.1.1.5':
      algorithmHashName = 'sha1';
      break;
    case '1.2.840.113549.1.1.4':
      algorithmHashName = 'md5';
      break;
    case '1.2.840.10040.4.3':
      algorithmHashName = 'sha1';
      break;
    case '1.3.14.3.2.29':
      algorithmHashName = 'sha1';
      break;
    case '1.3.14.3.2.15':
      algorithmHashName = 'sha';
      break;
    case '1.3.14.3.2.3':
      algorithmHashName = 'md5';
      break;
    case '1.3.14.3.2.13':
      algorithmHashName = 'sha1';
      break;
    case '1.3.14.3.2.27':
      algorithmHashName = 'sha1';
      break;
    case '1.3.14.3.2.26':
      algorithmHashName = 'sha1';
      break;
    case '1.2.840.113549.2.5':
      algorithmHashName = 'md5';
      break;
    case '2.16.840.1.101.3.4.2.1':
      algorithmHashName = 'sha256';
      break;
    case '2.16.840.1.101.3.4.2.2':
      algorithmHashName = 'sha384';
      break;
    case '2.16.840.1.101.3.4.2.3':
      algorithmHashName = 'sha512';
      break;
    case '1.2.840.113549.1.1.11':
      algorithmHashName = 'sha256';
      break;
    case '1.2.840.113549.1.1.12':
      algorithmHashName = 'sha384';
      break;
    case '1.2.840.113549.1.1.13':
      algorithmHashName = 'sha512';
      break;
    case '1.2.840.10045.4.1':
      algorithmHashName = 'sha1';
      break;
    case '1.2.840.10045.4.3.2':
      algorithmHashName = 'sha256';
      break;
    case '1.2.840.10045.4.3.3':
      algorithmHashName = 'sha384';
      break;
    case '1.2.840.10045.4.3.4':
      algorithmHashName = 'sha512';
      break;
    default:
      return null;
  }
  return algorithmHashName;
};

/**
 * Get hash OID
 * @param method
 * @param data
 * @returns {*}
 */
const getHash = (method, data) =>
  new Promise((resolve, reject) => {
    const alg = method.toLowerCase();
    let md;
    if (alg === 'sha1') {
      md = forge.md.sha1.create();
    } else if (alg === 'sha256') {
      md = forge.md.sha256.create();
    } else if (alg === 'sha384') {
      md = forge.md.sha384.create();
    } else if (alg === 'sha512') {
      md = forge.md.sha512.create();
    } else if (alg === 'md5') md = forge.md.md5.create();
    if (md === undefined) {
      reject(new Error('No valid hash'));
    }
    md.update(data);
    resolve(md);
  });

const getInfoCertificate = (infoCer, type) => {
  const lang = localStorage.getItem('lang') || 'es';
  const { texts } = translationsState[lang].Utils.Signature;

  if (
    infoCer.includes('-----BEGIN CERTIFICATE-----') ||
    infoCer.includes('-----BEGIN ENCRYPTED PRIVATE KEY-----')
  ) {
    throw new Error(type === 'cer' ? texts.error1 : texts.error2);
  }
};

/**
 * Get the Sign Validity
 * @param credentials
 * @param hash
 * @param toSign
 * @returns {Promise<any>}
 */

const hashAndAll = async (credentials, hash = 'test', toSign = undefined) => {
  let cerForge;
  let keyForge;
  let privateKey;
  let signForge;
  const { asn1, util, pki } = forge;

  const lang = localStorage.getItem('lang') || 'es';
  const { texts } = translationsState[lang].Utils.Signature;

  try {
    const cerBase64 = await getBase64(credentials.cer);
    const cerBuffer = cerBase64.split('base64,')[1];
    const bufferDecoded = util.decode64(cerBuffer);
    getInfoCertificate(bufferDecoded, 'cer');
    cerForge = pki.certificateFromAsn1(asn1.fromDer(bufferDecoded));
    const keyBase64 = await getBase64(credentials.key);
    const keyBuffer = keyBase64.split('base64,')[1];
    const keyBufferDecoded = util.decode64(keyBuffer);
    getInfoCertificate(keyBufferDecoded, 'key');
    keyForge = asn1.fromDer(keyBufferDecoded);
    const legalRfcValue = cerForge.subject.attributes.find(
      (found) => found.type === '2.5.4.45',
    );
    let legalRfc = legalRfcValue.value;
    if (legalRfc.includes('/')) {
      [legalRfc] = legalRfc.split('/');
      [legalRfc] = legalRfc.split(' ');
    }
    if (legalRfc.includes('�')) {
      legalRfc = legalRfc.replace(/[?�]/g, 'Ñ');
    }

    if (toSign !== undefined && legalRfc !== toSign) {
      throw new Error(texts.error3);
    }
    try {
      privateKey = pki.privateKeyFromAsn1(
        pki.decryptPrivateKeyInfo(keyForge, credentials.passphrase),
      );
    } catch {
      throw new Error(texts.error4);
    }
    try {
      const algorithm = await getDigestAlgorithm(cerForge.signatureOid);
      const digest = await getHash(algorithm, hash);
      signForge = await privateKey.sign(digest);
      await cerForge.publicKey.verify(digest.digest().bytes(), signForge);
      const cuteSign = util.encode64(signForge);
      if (credentials.type === 'login_signers') {
        return { cuteSign, legalRfc, cerBuffer };
      }
      return cuteSign;
    } catch {
      throw new Error(texts.error5);
    }
  } catch (e) {
    /* handle error */
    console.log(e);
    throw e;
  }
};

export default hashAndAll;
