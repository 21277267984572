import { branch } from 'baobab-react/dist-modules/higher-order';
import React from 'react';

const VerificationWarning = ({ lang, translations }) => {
  const { texts } =
    translations[lang].ContractDetail.SignContractDisplay.VerificationWarning;
  return (
    <div className="verification_warning">
      <p>
        <i
          className="fas fa-exclamation-circle"
          style={{ color: '#2851e5' }}
        ></i>{' '}
        {texts.p1}
      </p>
      <div className="steps_to_verify">
        <p>{texts.p2}</p>
        <p>{texts.p3}</p>
        <p>{texts.p4}</p>
        <p>{texts.p5}</p>
        <p>{texts.p6}</p>
      </div>
    </div>
  );
};

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  VerificationWarning,
);
