import { branch } from 'baobab-react/higher-order';
import React from 'react';

const ConfigSigners = ({
  onChangeSearchSigner,
  setWrapperRef,
  lastSigners,
  searchSigner,
  onClickSelectSigner,
  showIdentityDocs,
  handleChangeCheckboxIdentityDocs,
  orderedSigners,
  handleChangeOrderSigners,
  translations,
  lang,
}) => {
  const { inputs } = translations[lang].NewContract.Signers.ConfigSigners;
  return (
    <div className="container_config_signers">
      <div className="container_search_signer">
        <div className="input_search_signer">
          <i
            className="fas fa-search iconoInput"
            style={{ color: '#6C6C6C' }}
          />
          <input
            id="mySigners"
            name="mySigners"
            placeholder={inputs.inputPlaceholder1}
            onChange={(e) => onChangeSearchSigner(e)}
            value={searchSigner}
            type="text"
            autoComplete="off"
            className="input_nc search_signer"
          />
        </div>
        <div className="dropdown">
          <div
            ref={setWrapperRef}
            className="dropdown-content"
            style={{
              display: lastSigners.length && searchSigner ? 'block' : 'none',
            }}
          >
            {lastSigners.map((signer, i) => (
              <div onClick={() => onClickSelectSigner(signer)} key={i}>
                <p style={{ marginBottom: 4, marginTop: 0 }}>
                  {signer.legalName}{' '}
                </p>
                <small>
                  {signer.legalRfc} / {signer.secondaryEmail || signer.email}{' '}
                </small>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="switches_signers">
        <div className="switch_signer" style={{ paddingRight: 10 }}>
          <label className="switch">
            <input
              type="checkbox"
              checked={showIdentityDocs}
              name="showIdentityDocs"
              onChange={() =>
                handleChangeCheckboxIdentityDocs(showIdentityDocs)
              }
            />
            <span className="slider round"></span>
          </label>
          <span style={{ paddingLeft: 10 }}>{inputs.switchLabel1}</span>
        </div>
        <div className="switch_signer">
          <label className="switch">
            <input
              type="checkbox"
              checked={orderedSigners}
              name="orderedSigners"
              onChange={() => handleChangeOrderSigners(orderedSigners)}
            />
            <span className="slider round"></span>
          </label>
          <span style={{ paddingLeft: 10 }}>{inputs.switchLabel2}</span>
        </div>
      </div>
    </div>
  );
};

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  ConfigSigners,
);
