import React from 'react';

const SignersOrAdminsDisplay = ({
  mySigners,
  forcePDF,
  editSignerOrAdmin,
  showRemoveModal,
  translation,
}) => {
  const { texts } = translation;
  return (
    <div className="my_signers_admins_list">
      {mySigners.length ? (
        <div className="table_list_my_signers_admin">
          {mySigners.map((signer) => (
            <div className="info_my_signer_admin" key={signer.legalRfc}>
              <div className="information_signer_admin">
                <p>
                  {signer.signed ? (
                    <i
                      className="fas fa-check-circle"
                      style={{ color: '#7ED321', paddingRight: 16 }}
                    />
                  ) : (
                    <i
                      className="far fa-clock"
                      style={{ color: '#e19614', paddingRight: 16 }}
                    />
                  )}
                  {signer.legalRfc}
                </p>
                <p style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {signer.legalName}
                </p>
                <p>{signer.secondaryEmail || signer.email}</p>
                <div className="actions_my_signers_admin">
                  <i
                    className="fas fa-file-pdf open_document"
                    onClick={() => forcePDF(signer, signer.table)}
                  />
                  <i
                    className="far fa-edit edit_my_signer"
                    onClick={() => editSignerOrAdmin(signer)}
                  />
                  <i
                    className="fas fa-minus-circle delete_my_signer"
                    onClick={() => showRemoveModal(signer)}
                  />
                </div>
              </div>
              <div className="permissions_signer_admin">
                <span>{texts.spanTitle}</span>
                <div className="single_permission">
                  {signer.canCreateContract ? (
                    <small>{texts.small1}</small>
                  ) : null}
                  {signer.canSee ? <small>{texts.small2}</small> : null}
                  {signer.canCreateAdmin ? <small>{texts.small3}</small> : null}
                  {signer.canCreateSigner ? (
                    <small>{texts.small4}</small>
                  ) : null}
                </div>
              </div>

              <hr></hr>
            </div>
          ))}
        </div>
      ) : (
        <p>{texts.p1}</p>
      )}
    </div>
  );
};

export default SignersOrAdminsDisplay;
