export const ConfirmContractModalTranslations = {
  es: {
    texts: {
      h2: 'Documento: ',
      small1: 'Firmantes',
      small2: 'No hay firmantes',
      ul1: (signersLength) => `Agregaste ${signersLength} firmantes en total`,
      small3: 'Autorizadores',
      small4: 'No hay autorizadores',
      ul2: (authorizersLength) =>
        `Agregaste ${authorizersLength} autorizadores en total`,
      small5: 'Copiados',
      small6: 'No hay copiados',
      ul3: (recipientsLength) =>
        `Agregaste ${recipientsLength} copiados en total`,
    },
    inputs: {},
    buttons: {
      button1: 'Regresar',
      button2: 'Enviar documento',
    },
  },
  en: {
    texts: {
      h2: 'Document: ',
      small1: 'Signers',
      small2: 'No signers',
      ul1: (signersLength) => `You added ${signersLength} signers in total`,
      small3: 'Authorizers',
      small4: 'No authorizers',
      ul2: (authorizersLength) =>
        `You added ${authorizersLength} authorizers in total`,
      small5: 'Copied recipients',
      small6: 'No copied recipients',
      ul3: (recipientsLength) =>
        `You added ${recipientsLength} copied recipients in total`,
    },
    inputs: {},
    buttons: {
      button1: 'Go back',
      button2: 'Send document',
    },
  },
};
