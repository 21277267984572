import React from 'react';
import moment from 'moment';
import { branch } from 'baobab-react/higher-order';
import { NotificationManager } from 'react-notifications';
import LabelActions from '../../actions/LabelActions';

class LabelsDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchLabel: '',
      myLabels: [],
      lastLabels: [],
      showInputs: false,
      showLabels: false,
    };
  }

  componentDidMount = () => {
    const { labels, myLabels } = this.state;
    const { admin, contract, user } = this.props;
    this.setState({ lastLabels: labels, myLabels });
    if (
      (admin?.rootUser || user.id) === (contract.rootUser || contract.createdBy)
    ) {
      this.setState({ showLabels: true });
    }
    document.addEventListener('mousedown', this.handleClickOutside);
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleClickOutside = (event) => {
    const { searchLabel } = this.state;
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      searchLabel
    ) {
      this.setState({ lastLabels: [], searchLabel: '' });
    }
  };

  inputChange = () => {};

  onChangeSearchLabel = (event) => {
    const { myLabels } = this.props;
    const value = event.target.value;
    const lastChar = value[value.length - 1];
    if (lastChar === '\\' || lastChar === '?') return;
    this.setState({ searchLabel: value });
    let updateLabels = myLabels.filter(
      (label) => label.content.toLowerCase().search(value.toLowerCase()) !== -1,
    );
    const limitedLabels = updateLabels.slice(-6);
    this.setState({ lastLabels: limitedLabels });
  };

  onClickSelectLabel = async (label) => {
    const { getNewLabels, labels, contract, lang, translations } = this.props;
    const { notifications } = translations[lang].ContractDetail.LabelsDisplay;
    const labelExist = labels.find(
      (searchLabel) => searchLabel.id === label.id,
    );
    if (labelExist) {
      return NotificationManager.warning(
        notifications.notifBody1,
        notifications.notifTitle1,
        6000,
      );
    }
    await LabelActions.assign({
      contract: contract.id,
      label: label.id,
    });
    this.setState({ searchLabel: '' });
    getNewLabels(label);
  };

  handleChipsKeyDown = async (e) => {
    const { myLabels, contract, getNewLabels, lang, translations } = this.props;
    const { notifications } = translations[lang].ContractDetail.LabelsDisplay;
    //TODO: VERIFY IF IS SIGNER OR ADMIN
    const { admin } = this.props;
    if (e.keyCode === 13) {
      let { value } = e.target;
      const chip = {};
      if (myLabels.find((element) => element.content === value)) {
        return NotificationManager.warning(
          notifications.notifBody2(value),
          notifications.notifTitle2,
          3000,
        );
      }
      chip.content = value;
      // eslint-disable-next-line no-bitwise
      chip.color = `#${(((1 << 24) * Math.random()) | 0).toString(16)}`;
      //TODO: VERIFY ROOTUSER
      chip.rootUser = admin ? admin.rootUser : null;
      const label = await LabelActions.create(chip);
      await LabelActions.assign({
        contract: contract.id,
        label: label.id,
      });
      getNewLabels(label, 'add');
    }
  };

  deleteLabel = async (label) => {
    const { contract, getNewLabels } = this.props;
    const response = await LabelActions.removeContractLabels(
      contract.id,
      label.id,
    );
    if (response.status === 200) {
      getNewLabels(label, 'delete');
    }
  };

  showInputsLabels = () => {
    const { showInputs } = this.state;
    this.setState({ showInputs: !showInputs });
  };

  render() {
    const { searchLabel, lastLabels, showInputs, showLabels } = this.state;
    const { contract, labels, type, lang, translations } = this.props;
    const { texts, inputs } = translations[lang].ContractDetail.LabelsDisplay;

    return (
      <>
        {type ? null : (
          <div className="expires_cd">
            <span>
              {texts.span1} <small>:</small>
            </span>
            <p>
              {contract.expiresOn
                ? moment(contract.expiresOn).format('DD MMM YYYY')
                : texts.p1}
            </p>
          </div>
        )}

        <div className="labels_cd">
          {showLabels ? (
            <span className="title_labels">
              {texts.spanTitle1}
              <i
                className="fas fa-plus-circle"
                onClick={() => this.showInputsLabels()}
              ></i>
            </span>
          ) : null}
          {showInputs ? (
            <div className="input_labels_cd">
              <div style={{ width: '100%' }}>
                <input
                  id="myLabels"
                  name="myLabels"
                  placeholder={inputs.inputPlaceholder1}
                  onChange={(e) => this.onChangeSearchLabel(e)}
                  value={searchLabel}
                  type="text"
                  autoComplete="off"
                  className="input_nc"
                />
                <div className="dropdown">
                  <div
                    ref={this.setWrapperRef}
                    className="dropdown-content"
                    style={{
                      display:
                        lastLabels?.length && searchLabel ? 'block' : 'none',
                    }}
                  >
                    {lastLabels?.map((label, i) => (
                      <div
                        onClick={() => this.onClickSelectLabel(label)}
                        key={i}
                      >
                        <p style={{ marginBottom: 4, marginTop: 0 }}>
                          {label.content}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <small style={{ marginLeft: 10, marginRight: 10 }}> Ó </small>
              <input
                id="addMyLabels"
                name="addMyLabels"
                placeholder={inputs.inputPlaceholder2}
                onKeyDown={(e) => this.handleChipsKeyDown(e)}
                type="text"
                autoComplete="off"
                className="input_nc"
              />
            </div>
          ) : null}
        </div>

        <div className={`list_labels ${!showInputs ? 'active_labels' : ''}`}>
          {showLabels && labels.length
            ? labels.map((label, i) => (
                <span
                  className="all_labels"
                  style={{ background: label.color }}
                  onClick={() => this.deleteLabel(label)}
                  key={`label-${i}`}
                >
                  {label.content} &#x2a2f;
                </span>
              ))
            : null}
        </div>
      </>
    );
  }
}

export default branch(
  {
    session: ['session'],
    myApiUsers: ['myApiUsers'],
    translations: ['translations'],
    lang: ['lang'],
  },
  LabelsDisplay,
);
