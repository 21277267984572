export const ChooseDocumentModalTranslations = {
  es: {
    texts: {
      h3: 'Solicitud de documentos',
      small1: 'Puedes seleccionar máximo 3 documentos',
      p1: 'Otro',
      small2: "(Presiona 'Enter' para agregar)",
      small3: 'Documentos: ',
    },
    inputs: {
      documentsList: [
        { id: 'INE-IFE', text: 'INE-IFE' },
        { id: 'CURP', text: 'CURP' },
        { id: 'Acta Constitutiva', text: 'Acta Constitutiva' },
        { id: 'Poder Notarial', text: 'Poder Notarial' },
      ],
      inputPlaceholder1: 'Agregar documento',
    },
    buttons: {
      button1: 'Continuar',
    },
  },
  en: {
    texts: {
      h3: 'Document request',
      small1: 'You can select a maximum of 3 documents',
      p1: 'Other',
      small2: "(Press 'Enter' to add)",
      small3: 'Documents: ',
    },
    inputs: {
      documentsList: [
        { id: 'INE-IFE', text: 'INE-IFE' },
        { id: 'CURP', text: 'CURP' },
        { id: 'Acta Constitutiva', text: 'Constitutive Act' },
        { id: 'Poder Notarial', text: 'Power of attorney' },
      ],
      inputPlaceholder1: 'Add document',
    },
    buttons: {
      button1: 'Continue',
    },
  },
};
