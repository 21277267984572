import { profileServices } from '../API/services/profileServices';
import state from '../state';

const ProfileActions = {
  /**
   * Returns all my Signers
   * @param userId
   * @returns {Promise}
   */
  async signers(userId) {
    const { data } = await profileServices.signersService({}, { userId });
    state.select(['mySigners']).set(data);
    return data;
  },

  /**
   * Returns all my Admins
   * @param userId
   * @returns {Promise}
   */
  async admins(userId) {
    const { data } = await profileServices.adminsService({}, { userId });
    state.select(['myAdmins']).set(data);
    return data;
  },

  async getLastSigners({ userId, typeUser, legalRfc, isAdmin }) {
    const { data } = await profileServices.getLastSignersService(
      { params: { typeUser, legalRfc, isAdmin } },
      { userId },
    );
    return data;
  },
  /**
   * Gets user Cards
   * @param userId
   * @returns {Promise}
   */
  async payments(userId) {
    const { data } = await profileServices.paymentsService({}, { userId });
    state.select(['myCards']).set(data);
    return data;
  },

  /**
   * Get templates for Api User
   * @param userId
   * @returns {Promise}
   */
  async templates(userId) {
    const { data } = await profileServices.templatesService({}, { userId });
    state.select(['myTemplates']).set(data);
    return data;
  },

  /**
   * Get coupons of User
   * @param userId
   * @returns {Promise}
   */
  async coupons(userId) {
    const { data } = await profileServices.couponsService({}, { userId });
    state.select(['myCoupons']).set(data);
    return data;
  },

  /**
   * Add Signers to my Profile
   * @param signer
   * @returns {Promise<*>}
   */
  async addSigner(signer) {
    const payload = {
      user: signer.user,
      canCreateContract: signer.canCreateContract,
      canSee: signer.canSee,
      canCreateSigner: signer.canCreateSigner,
      canCreateAdmin: signer.canCreateAdmin,
      canSign: signer.canSign,
      rootUser: signer.rootUser ? signer.rootUser : null,
      protectedUser: signer.protectedUser,
      protectedDocs: signer.protectedDocs,
    };
    const { data } = await profileServices.addSignerToProfileService({
      payload,
    });
    state.select(['mySigners']).set([{ ...data }]);
    return data;
  },

  /**
   * Sings a User Contract via Forge
   * @param signedUser
   * @param cer
   * @param cuteSign
   * @returns {Promise}
   */
  async signUser(signedUser, cer, cuteSign, locationUser) {
    const { id } = signedUser;
    const formData = new FormData();
    formData.append('cer', cer);
    formData.append('fileUrl', signedUser.fileUrl);
    formData.append('table', signedUser.table);
    formData.append('model', signedUser.model);
    formData.append('cuteSign', cuteSign);
    formData.append('locationUser', JSON.stringify(locationUser));
    const { data } = await profileServices.signUserService(
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
      { id },
    );
    state
      .select([
        `my${signedUser.model.charAt(0).toUpperCase}${signedUser.model.slice(
          1,
        )}s`,
      ])
      .set([{ ...data }]);
    return data;
  },

  /**
   * Add Admins to my profile
   * @param admin
   * @returns {Promise}
   */
  async addAdmin(admin) {
    const payload = {
      user: admin.user,
      canCreateContract: admin.canCreateContract,
      canSee: admin.canSee,
      canCreateSigner: admin.canCreateSigner,
      canCreateAdmin: admin.canCreateAdmin,
      canSign: admin.canSign,
      rootUser: admin.rootUser ? admin.rootUser : null,
      protectedUser: admin.protectedUser,
      protectedDocs: admin.protectedDocs,
    };
    const { data } = await profileServices.addAdminService(payload);
    state.select(['myAdmins']).set([{ ...data }]);
    return data;
  },

  /**
   * Adds a Secondary Email to a User
   * @param user
   * @param secondaryEmail
   * @returns {Promise}
   */
  async addEmail(user, secondaryEmail) {
    const payload = {
      user,
      secondaryEmail,
    };
    const { data } = await profileServices.addEmailService(payload);
    state.select(['secondaryEmail']).set(data.secondaryEmail);
    state.select(['session']).set({ user: data.user });
    return data.secondaryEmail;
  },

  /**
   * Adds a Card to a User
   * @param card
   * @returns {Promise}
   */
  async addPayment(card) {
    const { OpenPay } = window;
    OpenPay.setId('m6cjiqfjnwvupuyxgk4n');
    OpenPay.setApiKey('pk_2d0acf8dd93b452e9e327c9e72ddfe75');
    OpenPay.setSandboxMode(true);
    const deviceSessionId = OpenPay.deviceData.setup();
    let cardNumber = '';
    const cardChunks = card.number.split(' ');
    cardChunks.map((part) => {
      if (part.length) {
        cardNumber += part;
      }
      // part.length ? cardNumber += part : false;
      return cardNumber;
    });
    const openPayCard = await this.generateToken(card, cardNumber).then(
      (response) => response.id,
    );
    const payload = {
      card_number: cardNumber,
      holder_name: card.name,
      expiration_year: card.year,
      expiration_month: card.month,
      cvv2: card.cvv,
      brand: card.brand,
      openPayCard,
      deviceSessionId,
    };
    const { data } = await profileServices.addPaymentService(payload);
    return data;
  },

  /**
   * Adds to Queue an Non-User to Signer
   * @param signer
   * @returns {Promise<*>}
   */
  async signerQueue(signer) {
    const payload = {
      email: signer.email,
      legalName: signer.legalName,
      legalRfc: signer.legalRfc,
      canCreateAdmin: signer.canCreateAdmin,
      canCreateContract: signer.canCreateContract,
      canCreateSigner: signer.canCreateSigner,
      canSee: signer.canSee,
      canSign: signer.canSign,
      rootUser: signer.rootUser ? signer.rootUser : null,
      protectedUser: signer.protectedUser,
      protectedDocs: signer.protectedDocs,
    };
    const { data } = await profileServices.signerQueueService(payload);
    state.select(['mySigners']).set([{ ...data }]);
    return data;
  },

  /**
   * Adds to Queue an Non-User to Admin
   * @param admin
   * @returns {Promise<*>}
   */
  async adminQueue(admin) {
    const payload = {
      legalRfc: admin.legalRfc,
      legalName: admin.legalName,
      email: admin.email,
      canCreateContract: admin.canCreateContract,
      canSee: admin.canSee,
      canCreateSigner: admin.canCreateSigner,
      canCreateAdmin: admin.canCreateAdmin,
      canSign: admin.canSign,
      rootUser: admin.rootUser ? admin.rootUser : null,
      protectedUser: admin.protectedUser,
      protectedDocs: admin.protectedDocs,
    };

    const { data } = await profileServices.adminQueueService(payload);
    state.select(['myAdmins']).set([{ ...data }]);
    return data;
  },

  /**
   * Get a User from ID
   * @param id
   * @returns {Promise<*>}
   */
  async getUser(id) {
    const { data } = await profileServices.getUserProfileService({}, { id });
    return data;
  },

  /**
   * Remove payment (card) from User
   * @param card
   * @returns {Promise<*>}
   */
  async removeCard(card) {
    const payload = {
      ...card,
    };
    const { data } = await profileServices.removeCardService(payload);
    return data;
  },

  /**
   * Remove from my Signers
   * @param signer
   * @returns {Promise<*>}
   */
  async removeSigner(signer) {
    const payload = {
      legalName: signer.legalName,
      legalRfc: signer.legalRfc,
      id: signer.id,
      table: signer.table,
    };
    const { data } = await profileServices.removeSignerService(payload);
    return data;
  },

  /**
   * Remove from my Admins
   * @param admin
   * @returns {Promise<*>}
   */
  async removeAdmin(admin) {
    const payload = {
      legalName: admin.legalName,
      legalRfc: admin.legalRfc,
      id: admin.id,
      table: admin.table,
    };
    const { data } = await profileServices.removeAdminService(payload);
    return data;
  },

  /**
   * Force Delete of User when not signed
   * @param user
   * @returns {Promise}
   */
  async forceDelete(user) {
    const payload = {
      id: user.id,
      table: user.table,
    };
    const { data } = await profileServices.forceDeleteService(
      payload,
      {},
      { id: user.id },
    );
    return data;
  },

  /**
   * Creates a charge from OpenPay to a New Contract
   * @param params
   * @returns {Promise}
   */
  async chargeContract(params) {
    const payload = {
      title: params.title,
      cvv2: params.cvv2,
      deviceSessionId: localStorage.deviceSessionId,
    };
    const { data } = await profileServices.chargeContractService(payload);
    return data;
  },

  /**
   * Sends Template to BackEnd to Store it as HTML
   * @param template
   * @returns {Promise}
   */
  async addTemplate(template) {
    const payload = {
      htmlOriginal: template,
    };
    const response = await profileServices.addTemplateService(payload);
    return response;
  },

  /**
   * Delete template
   * @param id
   * @returns {Promise}
   */
  async deleteTemplate(id) {
    const response = await profileServices.deleteTemplateService(
      {},
      {},
      { id },
    );
    return response;
  },

  /**
   * Assign a Coupon to a User
   * @param code
   * @returns {Promise}
   */
  async addCoupon(code) {
    const { data } = await profileServices.addCouponService({}, {}, { code });
    return data;
  },

  /**
   * Use a Coupon on a contract
   * @param couponId
   * @returns {Promise}
   */
  async updateCoupon(couponId) {
    const { data } = await profileServices.updateCouponService(
      {},
      {},
      { couponId },
    );
    return data;
  },

  /**
   * Assign Coupon to Contract
   * @param couponId
   * @param contractId
   * @returns {Promise}
   */
  async couponContract(couponId, contractId) {
    const payload = {
      couponId,
      contractId,
    };
    const { data } = await profileServices.couponContractService(payload);
    return data;
  },

  /**
   * Notify Admins that User Requires MyAPI Privileges
   * @param userId
   * @returns {Promise}
   */
  async notifyAPIRequest(userId) {
    try {
      const { data, status } = await profileServices.notifyAPIRequestService(
        {},
        {},
        { userId },
      );
      if (status >= 400) {
        throw new Error(data);
      }
      return data;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  },

  /**
   * Generates Token for OpenPay client.js
   * @param card
   * @param cardNumber
   * @returns {Promise}
   */
  async generateToken(card, cardNumber) {
    const { OpenPay } = window;
    return new Promise(async (resolve, reject) => {
      OpenPay.token.create(
        {
          card_number: cardNumber,
          holder_name: card.name,
          expiration_year: card.year,
          expiration_month: card.month,
          cvv2: card.cvv,
        },
        (success) => {
          resolve(success.data);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },

  async userInfo(userId) {
    const { data } = await profileServices.userInfoService({}, { userId });
    state.select(['myInfo']).set(data);
    return data;
  },

  async updateUser(payload) {
    const { data } = await profileServices.updateUserService(
      payload,
      {},
      { userId: payload.userId },
    );
    return data;
  },

  async addProfileImage(payload) {
    const formData = new FormData();
    formData.append('file', payload);
    const { data } = await profileServices.addProfileImageService(formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    const session = state.get(['session']);
    const newSession = {
      user: {
        ...session.user,
        image: data.res.Location,
      },
    };
    state.select(['image']).set(data.res.Location);
    state.select(['session']).set(newSession);
    return data;
  },

  async updateSigners(infoSigers) {
    const response = await profileServices.updateSignersService(infoSigers);
    return response;
  },

  async updateNotificationUser(notificationData) {
    const { data } = await profileServices.updateNotificationUserService(
      notificationData,
    );
    return data;
  },
  async registerUsersToDownloadContracts(data) {
    const response =
      await profileServices.registerUsersToDownloadContractsService(data);
    return response;
  },
  async getMyUsers(id) {
    const response = await profileServices.getMyUsersService({}, { id });
    return response;
  },
  async createContractToAuthorizeDownload(data) {
    const response =
      await profileServices.createContractToAuthorizeDownloadService(data);
    return response;
  },

  async sendResponseToAuthorize(data) {
    const formData = new FormData();
    formData.append('cer', data.cer);
    formData.append('data', JSON.stringify(data));
    const response = await profileServices.sendResponseToAuthorizeService(
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response;
  },

  async updateUserData(condition, payload) {
    const { data } = await profileServices.updateUserDataService(
      payload,
      {},
      condition,
    );
    return data;
  },
};

export default ProfileActions;
