import React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { branch } from 'baobab-react/higher-order';
import { Redirect } from 'react-router-dom';
import Coupons from './Coupons';
import ApiUsers from './ApiUsers';
import Graphs from './Graphs';
import InfoSigners from './InfoSigners';
import SearchContracts from './SearchContracts';
import ContractsForMe from './ContractsForMe';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { session, location, history } = this.props;
    if (!session) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: {
              from: location.pathname,
            },
          }}
        />
      );
    }
    return (
      <div className="container_super_user">
        <h1>Gestión en Contratos</h1>
        <div className="page padded">
          <Tabs>
            <TabList>
              <Tab>Dashboard</Tab>
              <Tab>Firmantes</Tab>
              <Tab>Promos y API</Tab>
              <Tab>Buscadores</Tab>
              <Tab>Contratos</Tab>
            </TabList>
            <TabPanel>
              <Graphs location={location} />
            </TabPanel>
            <TabPanel>
              <InfoSigners location={location} />
            </TabPanel>
            <TabPanel>
              <Coupons location={location} />
              <ApiUsers location={location} history={history} />
            </TabPanel>
            <TabPanel>
              <SearchContracts location={location} />
            </TabPanel>
            <TabPanel>
              <ContractsForMe location={location} />
            </TabPanel>
            <TabPanel />
          </Tabs>
        </div>
      </div>
    );
  }
}

export default branch(
  {
    session: ['session'],
  },
  Dashboard,
);
