export const AuthorizeTokenModalTranslations = {
  es: {
    texts: {
      h3: 'Firmar documentos',
      p1: 'Denegado',
      p2: 'Firmado',
      p3: 'Para firmar el documento ingresa tu certificado, .key y tu contraseña',
    },
    inputs: {
      inputSelectOptDefault: 'Selecciona una opción',
      inputSelectOpt1: 'Aceptar',
      inputSelectOpt2: 'Rechazar',
      textareaPlaceholder1: 'Comentarios',
    },
    buttons: {
      button1: 'Firmar Documento',
      button2: 'Firmar',
      button3: 'Ir a firmar',
      button4: 'Enviar respuesta',
      button5: 'Regresar',
    },
    notifications: {},
  },
  en: {
    texts: {
      h3: 'Sign documents',
      p1: 'Denied',
      p2: 'Signed',
      p3: 'To sign the document enter your certificate, .key and your password',
    },
    inputs: {
      inputSelectOptDefault: 'Select an option',
      inputSelectOpt1: 'Accept',
      inputSelectOpt2: 'Reject',
      textareaPlaceholder1: 'Comments',
    },
    buttons: {
      button1: 'Sign Document',
      button2: 'Sign',
      button3: 'Go to sign',
      button4: 'Send response',
      button5: 'Back',
    },
    notifications: {},
  },
};
