const ListSignersDocumentsTranslations = {
  es: {
    texts: {
      spanTitle1: 'Documentos solicitados:',
    },
    inputs: {},
    buttons: {},
    notifications: {},
  },
  en: {
    texts: {
      spanTitle1: 'Requested documents:',
    },
    inputs: {},
    buttons: {},
    notifications: {},
  },
};
const ListVerificationDocsTranslations = {
  es: {
    texts: {
      spanTitle1: 'Documentos de verificación:',
      spanTitle2: 'Pendiente de verificación',
      span1: 'Reverso ID',
      span2: 'Selfie',
      span3: 'Frente ID',
    },
    inputs: {},
    buttons: {},
    notifications: {},
  },
  en: {
    texts: {
      spanTitle1: 'Verification documents:',
      spanTitle2: 'Pending verification',
      span1: 'ID back',
      span2: 'Selfie',
      span3: 'ID front',
    },
    inputs: {},
    buttons: {},
    notifications: {},
  },
};
export const SignersListDisplayTranslations = {
  es: {
    texts: {
      spanTitle1: 'Firmantes',
      tooltip1: 'Editar RFC del firmante',
      p1: 'No hay firmantes',
      genericModalTitle1: 'Cancelar',
      genericModalBody1: (signerChanged) =>
        `¿Estás seguro que quieres redireccionar el documento a ${signerChanged}?`,
    },
    inputs: {
      selectOptionDefault: 'Elegir firma',
      selectOption1: 'Indistinta',
      selectOption2: 'e.firma (SAT)',
      selectOption3: 'Firma electrónica criptográfica',
    },
    buttons: {
      genericModalConfirmButton1: 'Continuar',
      genericModalCancelButton1: 'Volver',
    },
    notifications: {
      notifTitle1: 'Atención',
      notifBody1:
        'No se permite que una persona firme a nombre de dos partes en el mismo documento',
      notifTitle2: 'Atención',
      notifBody2: 'No se encontró el usuario',
      notifTitle3: 'Atención',
      notifBody3:
        'Error al descargar el documento a visualizar contacta a soporte@sora.mx',
    },
    ListSignersDocuments: { ...ListSignersDocumentsTranslations.es },
    ListVerificationDocs: { ...ListVerificationDocsTranslations.es },
  },
  en: {
    texts: {
      spanTitle1: 'Signers',
      tooltip1: 'Edit signer RFC',
    },
    inputs: {
      selectOptionDefault: 'Choose signature',
      selectOption1: 'Any',
      selectOption2: 'e.firma (SAT)',
      selectOption3: 'Cryptographic electronic signature',
    },
    buttons: {},
    notifications: {
      notifTitle1: 'Attention',
      notifBody1:
        'It is not allowed for a person to sign on behalf of two parties in the same document',
      notifTitle2: 'Attention',
      notifBody2: 'User not found',
      notifTitle3: 'Attention',
      notifBody3:
        'Error downloading the document to view, please contact soporte@sora.mx',
    },
    ListSignersDocuments: { ...ListSignersDocumentsTranslations.en },
    ListVerificationDocs: { ...ListVerificationDocsTranslations.en },
  },
};
