import { AddendumsDisplayTranslations } from './AddendumsDisplay.translations';
import { AuthorizersListDisplayTranslations } from './AuthorizersListDisplay.translations';
import { ContractPreviewTranslations } from './ContractPreview.translations';
import { ContractPreviewAuthorizerTranslations } from './ContractPreviewAuthorizer.translations';
import { CopiedListDisplayTranslations } from './CopiedListDisplay.translations';
import { LabelsDisplayTranslations } from './LabelsDisplay.translations';
import { SignContractDisplayTranslations } from './SignContractDisplay.translations';
import { SignersListDisplayTranslations } from './SignersListDisplay.translations';
import { StatusContractDisplayTranslations } from './StatusContractDisplay.translations';

export const ContractDetailTranslations = {
  es: {
    texts: {
      p1: 'Cargando Documentos',
      link1: 'Regresar a Mis documentos',
      genericModalTitle1: 'Ubicación bloqueada',
      genericModalBody1:
        'Porfavor busca en la configuración de tu navegador como restablecer los permisos de la ubiación, de otra manera no podrás firmar tus documentos.',
      genericModalTitle2: 'Permiso de usar tu ubicación',
      genericModalBody2:
        'Para poder usar tu ubicación necesitamos que des click en permitir. Esto es necesario ya que de otra manera el navegador bloqueará nuestro acceso a tu ubicación y no podrás firmar',
    },
    inputs: {},
    buttons: {
      button1: 'Activa tu ubicación para poder firmar',
      genericModalConfirmButton1: 'Cancelar',
      genericModalCancelButton1: 'Cerrar',
    },
    notifications: {
      notifTitle1: 'Atención',
      notifBody1: 'La geolocalización no está soportada por tu navegador',
      notifTitle2: 'Éxito',
      notifBody2: 'Firmantes actualizados con exito',
    },
    StatusContractDisplay: { ...StatusContractDisplayTranslations.es },
    LabelsDisplay: { ...LabelsDisplayTranslations.es },
    AuthorizersListDisplay: { ...AuthorizersListDisplayTranslations.es },
    SignersListDisplay: { ...SignersListDisplayTranslations.es },
    CopiedListDisplay: { ...CopiedListDisplayTranslations.es },
    SignContractDisplay: { ...SignContractDisplayTranslations.es },
    AddendumsDisplay: { ...AddendumsDisplayTranslations.es },
    ContractPreviewAuthorizer: { ...ContractPreviewAuthorizerTranslations.es },
    ContractPreview: { ...ContractPreviewTranslations.es },
  },
  en: {
    texts: {
      p1: 'Loading Documents',
      link1: 'Back to My documents',
      genericModalTitle1: 'Location blocked',
      genericModalBody1:
        'Please search in your browser settings how to reset the location permissions, otherwise you will not be able to sign your documents.',
      genericModalTitle2: 'Permission to use your location',
      genericModalBody2:
        'To be able to use your location we need you to click on allow. This is necessary because otherwise the browser will block our access to your location and you will not be able to sign',
    },
    inputs: {},
    buttons: {
      button1: 'Activate your location to be able to sign',
      genericModalConfirmButton1: 'Cancel',
      genericModalCancelButton1: 'Close',
    },
    notifications: {
      notifTitle1: 'Attention',
      notifBody1: 'Geolocation is not supported by your browser',
      notifTitle2: 'Success',
      notifBody2: 'Signers updated successfully',
    },
    StatusContractDisplay: { ...StatusContractDisplayTranslations.en },
    LabelsDisplay: { ...LabelsDisplayTranslations.en },
    AuthorizersListDisplay: { ...AuthorizersListDisplayTranslations.en },
    SignersListDisplay: { ...SignersListDisplayTranslations.en },
    CopiedListDisplay: { ...CopiedListDisplayTranslations.en },
    SignContractDisplay: { ...SignContractDisplayTranslations.en },
    AddendumsDisplay: { ...AddendumsDisplayTranslations.en },
    ContractPreviewAuthorizer: { ...ContractPreviewAuthorizerTranslations.en },
    ContractPreview: { ...ContractPreviewTranslations.en },
    // UpdateSignerModal: { ...UpdateSignerModalTranslations.en },
  },
};
