import { NotificationManager } from 'react-notifications';
import ContractActions from '../actions/Contract';
import LabelActions from '../actions/LabelActions';
import { translationsState } from '../translations/translations';

export const renderStatus = (
  turn,
  signer,
  contract,
  type,
  previousAddendum,
) => {
  const lang = localStorage.getItem('lang') || 'es';
  const { texts } = translationsState[lang].ContractAndPublicDetailHelpers;

  const { status } = contract;

  if (type === 'addendum') {
    if (previousAddendum && !previousAddendum?.allPartiesSigned) {
      return <span className="status pending">{texts.span12}</span>;
    }
  }

  if (!contract.is_valid && contract.hasOwnProperty('is_valid')) {
    return (
      <span className="status" style={{ color: '#D0021B' }}>
        <i className="fas fa-ban" style={{ marginRight: 10 }} />
        {texts.span1}
      </span>
    );
  }

  //Firmado por todas las partes
  if (contract.allPartiesSigned && status === 'ready') {
    return (
      <span className="status">
        <i className="fas fa-check-circle" style={{ color: '#7ED321' }} />{' '}
        {texts.span2}
      </span>
    );
  }

  //Listo para mi firma...
  if (
    (contract.lastSignedTurn + 1 === signer.turn && !signer.signed) ||
    (contract.orderedSigners && !signer.signed)
  ) {
    return <span className="status ready">{texts.span3}</span>;
  }

  //Firmado por mi
  if (
    signer.signed &&
    !signer.hasOwnProperty('canSee') &&
    !signer?.hasOwnProperty('authorizedAt')
  ) {
    return <span className="status ready">{texts.span4}</span>;
  }

  if (
    signer.signed &&
    signer.hasOwnProperty('authorizedAt') &&
    contract.lastAuthorizer !== contract.authorizers
  ) {
    return <span className="status ready">{texts.span11}</span>;
  }

  return <span className="status pending">{texts.span10}</span>;
};

export const renderStatusAuthorizer = (authorizer, type) => {
  const lang = localStorage.getItem('lang') || 'es';
  const { texts } = translationsState[lang].ContractAndPublicDetailHelpers;
  if (type === 'list') {
    if (authorizer.signed === 0 && authorizer.noSigned === 0) {
      return <i className="far fa-flag" style={{ color: '#ffb432' }}></i>;
    }

    if (authorizer.signed === 1 && authorizer.noSigned === 0) {
      return <i className="far fa-flag" style={{ color: '#7ED321' }} />;
    }

    if (authorizer.noSigned === 1 && authorizer.signed === 0) {
      return <i className="far fa-flag" style={{ color: '#f95c5e' }} />;
    }

    if (Object.keys(authorizer).length === 9) {
      return <i className="far fa-flag" style={{ color: '#ffb432' }}></i>;
    }
  }

  if (type === 'global') {
    let authorized = [];
    let noAuthorized = [];

    // eslint-disable-next-line
    authorizer.map((auth) => {
      if (auth.signed === 1 && auth.noSigned === 0) {
        authorized.push(auth);
      }

      if (auth.signed === 0 && auth.noSigned === 1) {
        noAuthorized.push(auth);
      }
    });

    if (authorized.length === authorizer.length) {
      return (
        <span className="status" style={{ color: '#7ED321' }}>
          <i className="far fa-flag" style={{ color: '#7ED321' }}></i>{' '}
          {texts.span6}
        </span>
      );
    }

    if (noAuthorized.length > 0) {
      return (
        <span className="status" style={{ color: '#f95c5e' }}>
          <i className="far fa-flag" style={{ color: '#f95c5e' }}></i>{' '}
          {texts.span7}
        </span>
      );
    } else {
      return (
        <span className="status" style={{ color: '#ffb432' }}>
          <i className="far fa-flag" style={{ color: '#ffb432' }}></i>{' '}
          {texts.span8}
        </span>
      );
    }
  }
};

export const renderStatusPublic = (contract) => {
  const lang = localStorage.getItem('lang') || 'es';
  const { texts } = translationsState[lang].ContractAndPublicDetailHelpers;
  if (contract.allPartiesSigned) {
    return (
      <span className="status">
        <i className="fas fa-check-circle" style={{ color: '#7ED321' }} />{' '}
        {texts.span9}
      </span>
    );
  } else {
    return <span className="status pending mt-15">{texts.span10}</span>;
  }
};

export const softDeleteContract = async (contractId, user) => {
  const lang = localStorage.getItem('lang') || 'es';
  const { notifications } =
    translationsState[lang].ContractAndPublicDetailHelpers;
  await LabelActions.removeContractLabels(contractId);
  await ContractActions.softDeleteContract(contractId, user)
    .then(() => {
      NotificationManager.success(
        notifications.notifBody1,
        notifications.notifTitle1,
        10000,
      );
      window.location.href = `${window.location.origin}/contratos`;
    })
    .catch((error) => {
      console.log(error);
      NotificationManager.error(
        notifications.notifBody2,
        notifications.notifTitle2,
        10000,
      );
    });
};

export const cancelContract = (contractId) => {
  const lang = localStorage.getItem('lang') || 'es';
  const { notifications } =
    translationsState[lang].ContractAndPublicDetailHelpers;
  ContractActions.cancelContract(contractId)
    .then(() => {
      NotificationManager.success(
        notifications.notifBody3,
        notifications.notifTitle3,
        10000,
      );
      window.location.href = `${window.location.origin}/contratos`;
    })
    .catch(() => {
      NotificationManager.error(
        notifications.notifBody4,
        notifications.notifTitle4,
        10000,
      );
    });
};

export const checkCancelOrDelete = (contract, userId, action) => {
  if (action === 'cancel') {
    return (
      !contract.is_valid ||
      (contract.createdBy !== userId && contract.rootUser !== userId)
    );
  }
  if (action === 'delete') {
    return (
      contract.is_valid ||
      (contract.createdBy !== userId && contract.rootUser !== userId)
    );
  }
};
