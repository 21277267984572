import moment from 'moment-timezone';
import {
  createContractSifeApiService,
  getSignerService,
  getUserApiService,
  registerUserSifeService,
  signApiService,
  viewApiService,
  getPublicInfoService,
} from '../API/services/apiServices';

const APIActions = {
  /**
   * @param id
   * @param token
   * @returns {Promise}
   */
  async view(id, token) {
    const response = await viewApiService({ token }, {}, { id });
    return response.data;
  },

  async getUser(rfc, token) {
    const response = await getUserApiService({ token }, {}, { rfc });
    return response.data;
  },

  async registerUserSife(data) {
    const response = await registerUserSifeService(data);
    return response;
  },

  async createContractSifeAPI(contract, user, tokennew) {
    const email = user.email;
    const rfc = user.legalRfc;
    const rfcUser = true;
    const userType = user.typeUser;
    const docsArray = [
      {
        docObjToHash: {
          docInfo: {
            fechaInicio: moment(contract.createdAt).format('YYYY-MM-DD'),
            fechaFinal: moment(contract.validUntil).format('YYYY-MM-DD'),
            monto: '5000',
          },
        },
        docHash: contract.lastHash || contract.hash,
        docId: contract.unique_id,
        docName: contract.title,
        userEmail: user.email,
        userRFC: user.legalRfc,
        hashAlgorithm: 'SHA256',
      },
    ];
    const response = await createContractSifeApiService({
      docsArray,
      userType,
      rfcUser,
      email,
      rfc,
    });
    return response;
  },

  async getSigner(userId, contractId, token) {
    const response = await getSignerService({ userId, contractId, token });
    return response;
  },

  /**
   * @param token
   * @param cer
   * @param cuteSign
   * @param legalRfc
   * @param id
   * @param secondaryEmail
   * @returns {Promise}
   */
  async sign(token, cer, cuteSign, legalRfc, id, secondaryEmail) {
    const formData = new FormData();
    formData.append('token', token);
    formData.append('cer', cer);
    formData.append('legalRfc', legalRfc);
    formData.append('cuteSign', cuteSign);
    formData.append('secondaryEmail', secondaryEmail);

    const response = await signApiService(
      formData, // data
      {
        // rqConfig
        headers: { 'Content-Type': 'multipart/form-data' },
      },
      { id },
    );
    return response.data;
  },

  async getPublicInfo(id) {
    const response = await getPublicInfoService({}, { id });
    return response;
  },
};

export default APIActions;
