import { AddSignersDisplayTranslations } from './AddSignersDisplay.translations';
import { ChipsDisplayMassiveTranslations } from './ChipsDisplayMassive.translations';
import { ErrorsUploadFileTranslations } from './ErrorsUploadFile.translations';
import { SelectDateDisplayTranslations } from './SelectDateDisplay.translations';
import { UploadFilesDisplayTranslations } from './UploadFilesDisplay.translations';

export const NewContractMassiveTranslations = {
  es: {
    texts: {
      h1: 'Múltiples documentos',
      p1: (aproximateTime) => `Tiempo aproximado ${aproximateTime}`,
      p2: 'Subiendo PDFS... Esto puede tardar unos minutos, no salgas de la pagina.',
      genericModalTitle1: 'Cancelar',
      genericModalBody1: (filesLength) =>
        `Estás a punto de subir ${filesLength} documentos. ¿Desea continuar?`,
      genericModalTitle2: 'Pendientes por subir',
      genericModalBody2: (filesLength) =>
        `Quedaban ${filesLength} documentos pendientes por subir. ¿Desea subirlos ahora?`,
    },
    inputs: {
      inputLabel1: 'Nombre:',
      inputLabelSpan1: '(Se agrega el RFC del pdf al titulo)',
    },
    buttons: {
      button1: 'Subir Documentos',
      genericModalConfirmButton1: 'Continuar',
      genericModalCancelButton1: 'Volver',
      genericModalConfirmButton2: 'Continuar',
      genericModalCancelButton2: 'Volver',
    },
    notifications: {
      notifTitle1: 'Atención',
      notifBody1: 'No puedes firmar por dos partes',
      notifTitle2: 'Atención',
      notifBody2: (typeOfPerson, indexPlusOne) =>
        `El RFC del ${typeOfPerson} ${indexPlusOne} no es válido`, // indexPlusOne = i + 1
      notifTitle3: 'Atención',
      notifBody3: (typeOfPerson, indexPlusOne) =>
        `El nombre del ${typeOfPerson} ${indexPlusOne} no es válido`, // indexPlusOne = i + 1
      notifTitle4: 'Atención',
      notifBody4: (typeOfPerson, indexPlusOne) =>
        `El correo electrónico del ${typeOfPerson} ${indexPlusOne} no es válido`, // indexPlusOne = i + 1
      notifTitle5: 'Error', // TODO: translate response
      notifBody5: '', // TODO: translate response
      notifTitle6: 'Éxito', // TODO: translate response
      notifBody6: '', // TODO: translate response
      notifTitle7: 'Atención',
      notifBody7: (value) => `Ya existe '${value}`,
      notifTitle8: 'Atención',
      notifBody8: 'No puedes firmar por dos partes',
      notifTitle9: 'Atención',
      notifBody9: 'Solo puedes subir de 2 documentos en adelante',
    },
    AddSignersDisplay: { ...AddSignersDisplayTranslations.es },
    ChipsDisplayMassive: { ...ChipsDisplayMassiveTranslations.es },
    ErrorsUploadFile: { ...ErrorsUploadFileTranslations.es },
    SelectDateDisplay: { ...SelectDateDisplayTranslations.es },
    UploadFilesDisplay: { ...UploadFilesDisplayTranslations.es },
  },
  en: {
    texts: {
      h1: 'Multiple documents',
      p1: (aproximateTime) => `Approximate time ${aproximateTime}`,
      p2: 'Uploading PDFS... This can take a few minutes, do not leave the page.',
      genericModalTitle1: 'Cancel',
      genericModalBody1: (filesLength) =>
        `You are about to upload ${filesLength} documents. Do you want to continue?`,
      genericModalTitle2: 'Pending to upload',
      genericModalBody2: (filesLength) =>
        `${filesLength} documents were pending to upload. Do you want to upload them now?`,
    },
    inputs: {
      inputLabel1: 'Name:',
      inputLabelSpan1: '(The RFC of the pdf is added to the title)',
    },
    buttons: {
      button1: 'Upload Documents',
      genericModalConfirmButton1: 'Continue',
      genericModalCancelButton1: 'Back',
      genericModalConfirmButton2: 'Continue',
      genericModalCancelButton2: 'Back',
    },
    notifications: {
      notifTitle1: 'Attention',
      notifBody1: 'You can not sign for two parties',
      notifTitle2: 'Attention',
      notifBody2: (typeOfPerson, indexPlusOne) =>
        `The RFC of the ${typeOfPerson} ${indexPlusOne} is not valid`, // indexPlusOne = i + 1
      notifTitle3: 'Attention',
      notifBody3: (typeOfPerson, indexPlusOne) =>
        `The name of the ${typeOfPerson} ${indexPlusOne} is not valid`, // indexPlusOne = i + 1
      notifTitle4: 'Attention',
      notifBody4: (typeOfPerson, indexPlusOne) =>
        `The email of the ${typeOfPerson} ${indexPlusOne} is not valid`, // indexPlusOne = i + 1
      notifTitle5: 'Error', // TODO: translate response
      notifBody5: '', // TODO: translate response
      notifTitle6: 'Success', // TODO: translate response
      notifBody6: '', // TODO: translate response
      notifTitle7: 'Attention',
      notifBody7: (value) => `'${value}' already exists`,
      notifTitle8: 'Attention',
      notifBody8: 'You can not sign for two parties',
      notifTitle9: 'Attention',
      notifBody9: 'You can only upload 2 documents or more',
    },
    AddSignersDisplay: { ...AddSignersDisplayTranslations.en },
    ChipsDisplayMassive: { ...ChipsDisplayMassiveTranslations.en },
    ErrorsUploadFile: { ...ErrorsUploadFileTranslations.en },
    SelectDateDisplay: { ...SelectDateDisplayTranslations.en },
    UploadFilesDisplay: { ...UploadFilesDisplayTranslations.en },
  },
};
