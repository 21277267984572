export const urlBuilder = (url, urlVar) => {
  if (url.includes(':')) {
    const entries = Object.entries(urlVar);
    let newUrl;
    for (const [key, value] of entries) {
      newUrl = url.replace(`:${key}`, value);
    }
    return newUrl;
  }
  return urlVar ? `${url}/${urlVar}` : url;
};
