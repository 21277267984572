export const HomeTranslations = {
  es: {
    texts: {
      h1: (name) => `Hola, ${name}. ¿Qué deseas hacer?`,
      p: 'Mi perfil',
      link1: 'Subir nuevo documento',
      link2: 'Subir múltiples documentos',
      link3: 'Ver mis documentos',
      p2: 'Perfil de apoderado',
      p3: 'Perfil de administrador',
    },
    inputs: {},
    buttons: {},
  },
  en: {
    texts: {
      h1: (name) => `Hello, ${name}. What do you want to do?`,
      p: 'My profile',
      link1: 'Upload new document',
      link2: 'Upload multiple documents',
      link3: 'See my documents',
      p2: 'Legal representative profile',
      p3: 'Administrator profile',
    },
    inputs: {},
    buttons: {},
  },
};
