import { branch } from 'baobab-react/higher-order';

const FilterToolbarDisplay = ({
  filterList,
  Checkbox,
  myLabels,
  filterLabel,
  myStatus,
  currentLabels,
  filterStatus,
  currentStatus,
  resetAllDownloads,
  searchQuery,
  lang,
  translations,
}) => {
  const { texts, inputs } = translations[lang].Contracts.FilterToolbar;
  return (
    <>
      <div className="filter search_contract">
        <input
          type="text"
          placeholder={inputs.inputPlaceholder1}
          className="search"
          value={searchQuery}
          onChange={filterList}
        />
      </div>

      <div className="filter filter_label">
        <label className="label-title">
          <i className="fas fa-tags" />
          {texts.labelTitle1}
        </label>
        <ul>
          {myLabels.length ? (
            myLabels.map((label) => (
              <li key={label.id}>
                <Checkbox
                  color={label.color}
                  size="3"
                  tickSize="1"
                  borderThickness="2"
                  id={label.id}
                  checked={currentLabels.indexOf(label.content) !== -1}
                  onChange={filterLabel.bind(this, label.content)}
                />
                <label className="label-text" htmlFor={label.id}>
                  {label.content}
                </label>
              </li>
            ))
          ) : (
            <li>
              <label className="label-text">{texts.labelText1}</label>
            </li>
          )}
        </ul>
      </div>

      <div className="filter filter_status">
        <label className="label-title">
          <i className="fas fa-check-double" />
          {texts.labelTitle2}
        </label>
        <ul>
          {myStatus.map((stat) => {
            const text = texts[stat.translationId];
            return (
              <li key={stat.key}>
                <Checkbox
                  color="#6C6C6C"
                  size="3"
                  tickSize="1"
                  borderThickness="2"
                  id={stat.key}
                  checked={currentStatus.indexOf(stat.key) !== -1}
                  onChange={filterStatus.bind(this, stat.key)}
                  className="mtm-5"
                />
                <label className="label-text" htmlFor={stat.key}>
                  <i className={stat.icon} />
                  &nbsp; {text}
                </label>
              </li>
            );
          })}
        </ul>
      </div>

      <div className="filter reset_contracts tooltip top">
        <label className="tiptext">{texts.labelTooltip1}</label>
        <label
          className="label-title"
          style={{ cursor: 'pointer' }}
          onClick={() => resetAllDownloads()}
        >
          <i className="fas fa-check-double mr-5" />
          {texts.labelTitle3}
        </label>
      </div>
    </>
  );
};

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  FilterToolbarDisplay,
);
