import React from 'react';
import { Redirect } from 'react-router-dom';
import { branch } from 'baobab-react/higher-order';
import { NotificationManager } from 'react-notifications';
import SuperUserActions from '../../actions/SuperUser';

class NewApiUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cer: null,
    };
  }

  promptFile = (event) => {
    event.target.blur();
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    const { name } = event.target;
    input.setAttribute('accept', '.cer');
    input.onchange = (e) => {
      const file = e.target.files[0];
      this.setState({ [name]: file });
      this.setState({ cer: file });
    };
    input.click();
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { cer } = this.state;
    const response = await SuperUserActions.createApiUser(cer);
    if (response.error) {
      return NotificationManager.error(response.error, 'Atención', 10000);
    }

    NotificationManager.success(
      'Usuario de API registrado con éxito',
      'Éxito',
      10000,
    );
  };

  canContinue() {
    return this.state;
  }

  render() {
    const { session, location } = this.props;
    const { cer } = this.state;
    if (!session) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: {
              from: location.pathname,
            },
          }}
        />
      );
    }
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <h1>Registrar un nuevo ApiUser</h1>
              <h3>
                Se creará un nuevo usuario usando la informacion del certificado
                y se habilitará como usuario de la API
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3 col-sm-offset-4">
              <form onSubmit={this.handleSubmit}>
                <input
                  onClick={this.promptFile}
                  onChange={() => {}}
                  name="cer"
                  type="text"
                  value={cer ? cer.name : ''}
                  placeholder="Certificado (.cer)"
                />
                <button
                  disabled={!this.canContinue()}
                  type="submit"
                  className="primary block"
                >
                  Continuar
                </button>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default branch({ session: ['session'] }, NewApiUser);
