import React from 'react';
import { branch } from 'baobab-react/higher-order';
import SuperUserActions from '../../../actions/SuperUser';
import { NotificationManager } from 'react-notifications';
import Loader from '../../../components/Loader';
import LabelsSuperUser from './LabelsSuperUser';
import ContractActions from '../../../actions/Contract';
import GenericConfirmModal from '../../../components/Modals/GenericConfirmModal';
import { ListInfoContract } from './ListInfoContract';
import { ListParticipantsContracts } from './ListParticipantsContracts';
import { ListCoincidencesContract } from './ListCoincidencesContract';
import AddRecipientSuperUser from './AddRecipientSuperUser';
import UpdateSignerSuperAdmin from './UpdateSignerSuperAdmin';
import ChangeSignerSuperAdmin from './ChangeSignerSuperAdmin';

const smallStyles = {
  fontSize: '13px',
  color: '#2851e5',
};

class SearchContractAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contract: null,
      participants: [],
      contractId: null,
      signType: '',
      requestVerification: false,
      allContracts: [],
      loading: false,
      modalLabels: false,
      modalToDeleteContract: false,
      modalToDeleteRecipient: false,
      recipientToDelete: null,
      modalToAddRecipient: false,
      canUpdateSigners: true,
      modalToUpdateSigner: false,
      signerToUpdate: null,
      modalToCancelContract: false,
      modalToChangeSigner: false,
    };
  }

  componentDidMount = () => {
    this.setState({ contractId: null, contract: null, participants: [] });
  };

  onInputChange = (e) => {
    const { value } = e.target;
    this.setState({ contractId: value });
  };

  searchContractsById = async (e, id) => {
    const { contractId } = this.state;
    this.setState({ contract: null, participants: [], loading: true });
    if (!contractId && !id) {
      return this.showAlert(false, 'El id del contrato no puede ir vacio');
    }
    this.setState({ loading: true });

    const response = await SuperUserActions.searchContracts(id || contractId);
    const { data } = response;
    if (!data.success) {
      this.setState({ loading: false, allContracts: [] });
      return this.showAlert(data.success, data.message);
    }

    this.setState({
      contract: data.contract[0],
      ...(!id && { allContracts: data?.allContracts ?? [] }),
      participants: [
        ...(data?.allAuthorizers ?? []),
        ...(data?.allSigners ?? []),
        ...(data?.recipients ?? []),
      ],
      loading: false,
      canUpdateSigners: data?.canUpdateSigners,
    });
  };

  onInputContractChange = (event, signerId) => {
    const { participants } = this.state;
    const { value, name, checked } = event.target;
    const allParticipants = [...participants];
    allParticipants[signerId][name] = name === 'signType' ? value : checked;
    this.setState({ participants: allParticipants });
  };

  showAlert = (status, message) => {
    if (status) {
      NotificationManager.success(message, 'Éxito', 5000);
      return { isError: true };
    }
    NotificationManager.warning(message, 'Error', 5000);
    return { isError: false };
  };

  showStatus = (contract) => {
    if (contract.deletedAt) {
      return 'Eliminado';
    }

    if (!Boolean(contract.is_valid)) {
      return 'Cancelado';
    }

    if (contract.status === 'authPending') {
      return 'Pendiente de autorizar';
    }

    if (contract.status === 'ready') {
      return 'Firmado por todas las partes';
    }

    if (contract.status === 'readyByMe') {
      return 'Parcialmente firmado';
    }

    if (!contract.status) {
      return 'Sin firmas';
    }
  };

  onClickDeleteContract = async () => {
    const { contract } = this.state;
    const {
      session: { user },
    } = this.props;
    if (!contract) {
      return NotificationManager.warning(
        'No has seleccionado ningún contrato',
        'Error',
        5000,
      );
    }

    try {
      const response = await ContractActions.softDeleteContract(
        contract.id,
        user,
      );
      if (response !== 1) {
        return NotificationManager.error(
          'Error al eliminar el documento',
          'Error',
          5000,
        );
      }
      this.setState({ modalToDeleteContract: false });
      NotificationManager.success(
        'Documento eliminado correctamente',
        'Éxito',
        5000,
      );
      this.setState({ contract: null });
      return;
    } catch (error) {
      console.log(error);
      return NotificationManager.error(
        'Ocurrió un error al eliminar el contrato',
        'Éxito',
        5000,
      );
    }
  };

  onClickDeleteRecipient = async () => {
    const { contract, recipientToDelete } = this.state;
    try {
      if (!contract || !recipientToDelete) {
        return NotificationManager.warning(
          'Falta el id del contrato o del copiado para poder eliminarlo',
          'Error',
          5000,
        );
      }

      if (
        recipientToDelete.hasOwnProperty('turn') ||
        recipientToDelete.hasOwnProperty('signedAt')
      ) {
        return NotificationManager.warning(
          'No es posible eliminar este usuario. Parece que no es copiado',
          'Error',
          5000,
        );
      }
      const response = await SuperUserActions.deleteRecipientSuperAdmin({
        contractId: contract.id,
        recipientId: recipientToDelete.id,
      });
      if (!response?.data?.success) {
        return NotificationManager.error(
          response?.data?.message || 'Ocurrió un error al eliminar el copiado',
          'Error',
          5000,
        );
      }

      NotificationManager.success(
        'Copiado eliminado correctamente',
        'Éxito',
        5000,
      );
      this.setState({ modalToDeleteRecipient: false, recipientToDelete: null });
      this.searchContractsById();
    } catch (error) {
      console.log(error);
      return NotificationManager.error(
        'Ocurrió un error al eliminar el copiado',
        'Error',
        5000,
      );
    }
  };

  cancelDocument = async () => {
    const { contract } = this.state;
    try {
      if (!contract || !contract.id) {
        return NotificationManager.error(
          'No existe un documento para cancelar',
          'Error',
          5000,
        );
      }
      const response = await ContractActions.cancelContract(contract.id);
      if (!Boolean(response)) {
        return NotificationManager.error(
          'Ocurrió un error al cancelar el documento',
          'Error',
          5000,
        );
      }
      NotificationManager.success(
        'Documento cancelado correctamente',
        'Éxito',
        5000,
      );
      this.setState({ modalToCancelContract: false });
      this.searchContractsById();
    } catch (error) {
      return NotificationManager.error(
        'Ocurrió un error al cancelar el documento',
        'Error',
        5000,
      );
    }
  };

  setExternalData = (data) => {
    this.setState(data);
  };

  render() {
    const {
      contract,
      participants,
      allContracts,
      contractId,
      loading,
      modalLabels,
      modalToDeleteContract,
      modalToDeleteRecipient,
      recipientToDelete,
      modalToAddRecipient,
      canUpdateSigners,
      modalToUpdateSigner,
      signerToUpdate,
      modalToCancelContract,
      modalToChangeSigner,
    } = this.state;

    if (loading) {
      return (
        <>
          <div className="input_to_search_sp flex">
            <input
              name="contractId"
              placeholder="Buscar contrato por titulo o ID"
              onChange={(e) => this.onInputChange(e)}
              type="text"
              // autoComplete="off"
              className="input_nc"
            />
            <i
              className="fas fa-search iconoInput"
              style={{ color: '#6C6C6C', cursor: 'pointer' }}
              onClick={this.searchContractsById}
            />
          </div>
          <div className="center_loading">
            <Loader />
            <p>Buscando documentos...</p>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="input_to_search_sp flex">
          <input
            name="contractId"
            placeholder="Buscar contrato por titulo o ID"
            onChange={(e) => this.onInputChange(e)}
            type="text"
            // autoComplete="off"
            className="input_nc"
          />
          <i
            className="fas fa-search iconoInput"
            style={{ color: '#6C6C6C', cursor: 'pointer' }}
            onClick={this.searchContractsById}
          />
        </div>

        {allContracts?.length ? (
          <ListCoincidencesContract
            contractId={contractId}
            allContracts={allContracts}
            searchContractsById={this.searchContractsById}
          />
        ) : null}
        {contract ? (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 7,
                marginTop: 10,
              }}
            >
              <p style={{ margin: 0 }}>Nota:</p>
              <small style={smallStyles}>
                1. Si el contrato tiene autorizadores y no se ha autorizado en
                su totalidad no es posible eliminar copiados.
              </small>
              <small style={smallStyles}>
                2. Si el contrato tiene autorizadores y no se ha autorizado en
                su totalidad, no es posible editar información de los firmantes
              </small>
              <small style={smallStyles}>
                3. El email que aparece debajo del nombre de los firmantes
                corresponde al que el creador del documento uso al momento de
                enviar el documento. Su finalidad es SOLO PARA NOTIFICACIONES
              </small>
              <small style={smallStyles}>
                4. UUID: {contract?.unique_id || ''}
              </small>
            </div>
            <ListInfoContract
              contract={contract}
              showStatus={this.showStatus}
              openModalLables={() => this.setState({ modalLabels: true })}
              openModalToDeleteContract={() =>
                this.setState({ modalToDeleteContract: true })
              }
              openModalToCancelContract={() =>
                this.setState({ modalToCancelContract: true })
              }
            />

            <ListParticipantsContracts
              participants={participants}
              onInputContractChange={this.onInputContractChange}
              setExternalData={this.setExternalData}
              canUpdateSigners={canUpdateSigners}
            />

            {modalLabels ? (
              <LabelsSuperUser
                contract={contract}
                handleCancel={() => this.setState({ modalLabels: false })}
              />
            ) : null}

            {modalToDeleteContract ? (
              <GenericConfirmModal
                title="Eliminación de documento"
                message={`¿Deseas eliminar el contrato: ${contract.title}?`}
                okButton="Si, eliminar"
                cancelButton="Cancelar"
                cancelAction={() =>
                  this.setState({ modalToDeleteContract: false })
                }
                acceptAction={() => this.onClickDeleteContract()}
              />
            ) : null}

            {modalToDeleteRecipient ? (
              <GenericConfirmModal
                title="Eliminación copiado"
                message={`¿Deseas eliminar el copiado: ${recipientToDelete?.legalRfc}?`}
                okButton="Si, eliminar"
                cancelButton="Cancelar"
                cancelAction={() =>
                  this.setState({ modalToDeleteRecipient: false })
                }
                acceptAction={() => this.onClickDeleteRecipient()}
              />
            ) : null}

            {modalToCancelContract ? (
              <GenericConfirmModal
                title="Cancelación de documento"
                message={`¿Deseas cancelar el documento ${contract.title} con ID: ${contract.id}?`}
                okButton="Si, cancelar"
                cancelButton="Cerrar"
                cancelAction={() =>
                  this.setState({ modalToCancelContract: false })
                }
                acceptAction={() => this.cancelDocument()}
              />
            ) : null}

            {modalToAddRecipient ? (
              <AddRecipientSuperUser
                contract={contract}
                handleCancel={() =>
                  this.setState({ modalToAddRecipient: false })
                }
                searchContractsById={this.searchContractsById}
              />
            ) : null}

            {modalToUpdateSigner && signerToUpdate ? (
              <UpdateSignerSuperAdmin
                contract={contract}
                signerToUpdate={signerToUpdate}
                searchContractsById={this.searchContractsById}
                handleCancel={() =>
                  this.setState({
                    modalToUpdateSigner: false,
                    signerToUpdate: null,
                  })
                }
                setExternalData={this.setExternalData}
              />
            ) : null}
            {modalToChangeSigner && signerToUpdate ? (
              <ChangeSignerSuperAdmin
                contract={contract}
                searchContractsById={this.searchContractsById}
                signerToUpdate={signerToUpdate}
                handleCancel={() =>
                  this.setState({
                    modalToChangeSigner: false,
                    signerToUpdate: null,
                  })
                }
                setExternalData={this.setExternalData}
              />
            ) : null}
          </>
        ) : null}
      </>
    );
  }
}

export default branch(
  {
    session: ['session'],
  },
  SearchContractAdmin,
);
