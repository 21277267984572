export const ContractPreviewAuthorizerTranslations = {
  es: {
    texts: {},
    inputs: {
      selectOptionDefault: '-- Seleccionar --',
      selectOption1: 'Autorizar',
      selectOption2: 'No autorizar',
      textareaPlaceholder1: 'Comentarios',
    },
    buttons: {
      button1: 'No autorizado',
      button2: 'Autorizado',
      button3: 'Enviar respuesta',
      button4: 'Regresar',
    },
    notifications: {
      notifTitle1: 'Éxito',
      notifBody1: 'Actualización de documento exitosa.',
      notifTitle2: 'Atención',
      notifBody2: 'Hubo un error al actualizar el documento.',
    },
  },
  en: {
    texts: {},
    inputs: {
      selectOptionDefault: '-- Select --',
      selectOption1: 'Authorize',
      selectOption2: 'Do not authorize',
      textareaPlaceholder1: 'Comments',
    },
    buttons: {
      button1: 'Not authorized',
      button2: 'Authorized',
      button3: 'Send response',
      button4: 'Back',
    },
    notifications: {
      notifTitle1: 'Success',
      notifBody1: 'Document update successful.',
      notifTitle2: 'Attention',
      notifBody2: 'There was an error updating the document.',
    },
  },
};
