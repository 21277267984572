const CameraTranslations = {
  es: {
    texts: {
      p1: 'Verificando documentos',
    },
    inputs: {},
    buttons: {},
    notifications: {},
  },
  en: {
    texts: {
      p1: 'Verifying documents',
    },
    inputs: {},
    buttons: {},
    notifications: {},
  },
};
export const CameraModalTranslations = {
  es: {
    texts: {
      h2: 'Selecciona tipo de identificación',
      small1:
        'Seleccionar el tipo de identificación a utilizar. Deben de ser identificaciones oficiales.',
      small2: 'Tomar foto',
      small3: '¡Datos de verificación completos!',
      small4: 'CURP validada ante RENAPO',
      small5: 'Credencial validada ante INE',
      p1: 'Verificando todos tus documentos',
    },
    inputs: {},
    buttons: {
      button1: 'Continuar',
      button2: 'Atras',
      button3: 'Verificar',
      button4: 'Continuar',
      button5: 'Cerrar modal',
    },
    notifications: {
      notifTitle1: 'Atención',
      notifBody1: 'No pudimos comparar las fotos, vuelve a intentarlo.',
      notifTitle2: 'Atención',
      notifBody2: 'Verificacion exitosa, puedes realizar tu firma',
      notifTitle3: 'Atención',
      notifBody3: 'Cámara no soportada en este dispositivo',
      notifTitle4: 'Atención',
      notifBody4:
        'No pudimos validar tu foto, vuelve a intentar y asegurate de tener buena iluminación o asegurate que sea el ID que seleccionaste',
    },
    Camera: { ...CameraTranslations.es },
  },
  en: {
    texts: {
      h2: 'Select type of identification',
      small1:
        'Select the type of identification to use. It must be an official identifications.',
      small2: 'Take photo',
      small3: '¡Verification data completed!',
      small4: 'CURP validated before RENAPO',
      small5: 'ID validated before INE',
      p1: 'Verifying all your documents',
    },
    inputs: {},
    buttons: {
      button1: 'Continue',
      button2: 'Back',
      button3: 'Verify',
      button4: 'Continue',
      button5: 'Close modal',
    },
    notifications: {
      notifTitle1: 'Attention',
      notifBody1: 'We could not compare the photos, try again.',
      notifTitle2: 'Attention',
      notifBody2: 'Successful verification, you can make your signature',
      notifTitle3: 'Attention',
      notifBody3: 'Camera not supported on this device',
      notifTitle4: 'Attention',
      notifBody4:
        'We could not validate your photo, try again and make sure you have good lighting or make sure the ID is the type you selected',
    },
    Camera: { ...CameraTranslations.en },
  },
};
