import React from 'react';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
// Actions
import Signature from '../../utils/signature';
import SessionActions from '../../actions/Session';
import Loader from '../../components/Loader';
import Nav from '../../components/Landing/Nav';
import { branch } from 'baobab-react/higher-order';

class ActivationLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cer: null,
      message: null,
      key: null,
      passphrase: '',
      loading: false,
    };
  }

  /**
   * Handle input changes
   * @param  {Object} event Input change event
   */
  onInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  /**
   * Create a fake file input to allow the user to input a file
   * @param  {Object} event Input click event
   */
  promptFile = (event) => {
    event.target.blur();
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    const { name } = event.target;

    if (name === 'cer') {
      input.setAttribute('accept', '.cer');
    } else {
      input.setAttribute('accept', '.key');
    }
    input.onchange = (e) => {
      const file = e.target.files[0];
      this.setState({ [name]: file });
    };
    input.click();
  };

  /**
   * Makes a Session Request for Existing User
   * @param event
   */
  handleSubmit = (event) => {
    event.preventDefault();
    const { cer, key, passphrase } = this.state;
    const { translations, lang } = this.props;
    const { notifications } = translations[lang].ActivationLogin;
    this.setState({
      loading: true,
    });
    Signature({
      cer,
      key,
      passphrase,
    })
      .then(() => {
        SessionActions.login(cer)
          .then((response) => {
            this.setState({ loading: false });
            if (response.error) {
              // TODO: translate response
              NotificationManager.warning(response.error, 'Advertencia', 10000);
              this.setState({ message: response.error });
            }
            if (response.session && response.token) {
              localStorage.setItem('sessionType', 'efirma');
              window.location = localStorage.getItem('redirect')
                ? localStorage.getItem('redirect')
                : '/';
            }
          })
          .catch(() => {
            this.setState({ loading: false });
            NotificationManager.warning(
              notifications.notifBody2,
              notifications.notifTitle2,
              10000,
            );
          });
      })
      .catch((error) => {
        this.setState({ loading: false });
        // TODO: translate response
        NotificationManager.warning(error.message, 'Advertencia', 10000);
      });
  };

  /**
   * Validates Submit Button
   * @returns {*}
   */
  canContinue = () => {
    const { cer, key, passphrase } = this.state;
    return cer && key && passphrase.length;
  };

  render() {
    const { cer, key, passphrase, loading, message } = this.state;
    const { translations, lang } = this.props;
    const { texts, inputs, buttons } = translations[lang].ActivationLogin;
    return (
      <>
        <NotificationContainer />
        <Nav />
        <div className="container_landing">
          <div className="login_wrapper">
            <div className="login_form redesign">
              <div className="inputs_login_form">
                <h2>{texts.h2}</h2>
                {/*TODO: translate response  */}
                {message ? <p className="error_message"> {message}</p> : null}
                <input
                  className="input_nc"
                  onClick={this.promptFile}
                  onChange={() => {}}
                  name="cer"
                  type="text"
                  value={cer ? cer.name : ''}
                  placeholder={inputs.inputPlaceholder1}
                />
                <input
                  className="input_nc"
                  onClick={this.promptFile}
                  onChange={() => {}}
                  name="key"
                  type="text"
                  value={key ? key.name : ''}
                  placeholder={inputs.inputPlaceholder2}
                />
                <input
                  className="input_nc"
                  onChange={this.onInputChange}
                  name="passphrase"
                  type="password"
                  value={passphrase}
                  placeholder={inputs.inputPlaceholder3}
                />
                <div className="start_session">
                  {loading ? (
                    <div className="center_loading">
                      <Loader />
                    </div>
                  ) : (
                    <button
                      className="primary"
                      hidden={loading}
                      disabled={!this.canContinue()}
                      type="button"
                      onClick={this.handleSubmit}
                    >
                      {buttons.button1}
                    </button>
                  )}
                </div>
                <small style={{ fontWeight: 300 }}>Powered by Sora</small>
              </div>
            </div>
            <div className="info_login_form redesign">
              <div className="container_info_login">
                <h1 className="animated pulse infinite">{texts.h1}</h1>
                <h3>{texts.h3}</h3>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  ActivationLogin,
);
