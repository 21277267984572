import state from '../state';

export const setLocalStorage = (resLogin, sessionType) => {
  localStorage.setItem('token', resLogin.data.token);
  localStorage.setItem('session', JSON.stringify(resLogin.data.session));
  localStorage.setItem('sessionType', sessionType);
  state.select(['session']).set(resLogin.data.session.user);
  state.commit();
  setTimeout(() => {
    window.location = localStorage.getItem('redirect')
      ? localStorage.getItem('redirect')
      : '/';
  }, 1000);
};
