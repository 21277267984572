import React from 'react';
import { Redirect } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
// Actions
import Nav from '../../components/Landing/Nav';
import { RegisterForm } from './RegisterForm';
import { branch } from 'baobab-react/higher-order';

class Registration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      demo: false,
    };
  }

  /**
   * Handles Login Redirection
   */
  handleLogin = () => {
    this.setState({ login: true });
  };

  /**
   * Handles Login Redirection
   */
  handleDemo = () => {
    this.setState({ demo: true });
  };
  //Comment test

  render() {
    const { handleLogin, location, translations, lang } = this.props;
    const { loading, message, demo, successMessage } = this.state;

    const translation = translations[lang].Registration;
    const { texts, buttons } = translation;

    return (
      <>
        {demo ? (
          <Redirect
            to={{
              pathname: location.state ? location.state.from : '/demo',
              state: {
                from: location.pathname,
              },
            }}
          />
        ) : null}
        <NotificationContainer />
        <Nav lang={this.props.lang} />
        <div className="container_landing">
          <div className="login_wrapper register_landing">
            <div className="login_form">
              <RegisterForm
                message={message}
                loading={loading}
                handleLogin={this.handleLogin || handleLogin}
                successMessage={successMessage}
                translation={translation}
              />
            </div>
            <div className="info_login_form">
              <div className="container_info_login">
                <h1>{texts.h1}</h1>
                <div className="message_register">
                  <p>{texts.p2} &nbsp;</p>
                  <i className="fas fa-chevron-right fa-4x" />
                </div>
                <p style={{ margin: 0 }}>{texts.p3}</p>
                <button
                  className="register_button"
                  type="button"
                  onClick={this.handleDemo}
                >
                  <b>{buttons.testDemoUser}</b>
                </button>

                {/* <p>{texts.or} &nbsp;</p>
                <button>
                  <b>
                    <a
                      href="https://www.gob.mx/tramites/ficha/obtencion-de-e-firma/SAT137"
                      rel="noopener noreferrer"
                      target="_blank"
                      style={{
                        color: 'black',
                      }}
                    >
                      {buttons.applyESignature}
                    </a>
                  </b>
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  Registration,
);
