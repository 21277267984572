import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { branch } from 'baobab-react/higher-order';
import { NotificationManager } from 'react-notifications';
import SuperUserActions from '../../actions/SuperUser';

class Coupons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      myUsersCouponsUsed: [],
    };
  }

  async componentDidMount() {
    await SuperUserActions.getCoupons();
    const usedcoupons = await SuperUserActions.getUsedCoupons();
    if (usedcoupons.status !== 200) {
      NotificationManager.error(
        'Error al obtener cupones usados',
        'Atención',
        10000,
      );
    } else {
      this.setState({ myUsersCouponsUsed: usedcoupons.data });
    }
  }

  canDisableCoupons = (e) => e.contracts !== 0;

  async disableCoupon(e) {
    const response = await SuperUserActions.disableCoupon(e.id);
    if (response.status === 404) {
      NotificationManager.error('No se pudo deshabilitar', 'Atención', 10000);
    } else {
      NotificationManager.success('Cupon deshabilitado', 'Éxito', 10000);
    }
  }

  render() {
    const { session, location, myCoupons } = this.props;
    const { myUsersCouponsUsed } = this.state;
    if (!session) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: {
              from: location.pathname,
            },
          }}
        />
      );
    }
    return (
      <div className="container_coupons">
        <p>Cupones para documentos</p>
        <div className="table_coupons border_api">
          {myCoupons.length
            ? myCoupons.map((coupon) => (
                <>
                  <input
                    disabled
                    type="text"
                    name="code"
                    defaultValue={`Código: ${coupon.code}`}
                    className="input_nc"
                    maxLength="16"
                    placeholder="coupon Number"
                  />
                  <input
                    disabled
                    type="text"
                    name="contracts"
                    defaultValue={`documentos: ${coupon.contracts}`}
                    className="input_nc"
                    style={{ textTransform: 'capitalize' }}
                    placeholder="Titular de tarjeta"
                  />
                  <button
                    type="button"
                    className="fas fa-minus-circle"
                    style={{
                      color: '#D0021B',
                      cursor: 'pointer',
                    }}
                    onClick={() => this.disableCoupon(coupon)}
                    disabled={!this.canDisableCoupons(coupon)}
                  />
                </>
              ))
            : null}
        </div>
        <p>Cupones usados</p>
        <div className="coupons_used border_api">
          {myUsersCouponsUsed.length
            ? myUsersCouponsUsed.map((usedCoupon) => (
                <>
                  <input
                    disabled
                    type="text"
                    name="code"
                    defaultValue={`Código: ${usedCoupon.Codigo}`}
                    className="input_nc"
                    maxLength="16"
                    placeholder="coupon Number"
                  />
                  <input
                    disabled
                    type="text"
                    name="code"
                    defaultValue={`RFC: ${usedCoupon.RFC}`}
                    className="input_nc"
                    maxLength="16"
                    placeholder="coupon Number"
                  />
                  <input
                    disabled
                    type="text"
                    name="code"
                    defaultValue={`documentos gratis: ${usedCoupon.ContratosGratis}`}
                    className="input_nc"
                    maxLength="16"
                    placeholder="coupon Number"
                  />
                  <input
                    disabled
                    type="text"
                    name="contracts"
                    defaultValue={`documentos disponibles: ${usedCoupon.Disponibles}`}
                    className="input_nc"
                    style={{ textTransform: 'capitalize' }}
                    placeholder="Titular de tarjeta"
                  />
                  <i
                    className="fas fa-minus-circle"
                    style={{ color: '#D0021B' }}
                  />
                </>
              ))
            : null}
        </div>

        <div style={{ marginTop: 40 }}>
          <Link
            to={{
              pathname: '/nuevo/cupon',
              state: {
                from: location.pathname,
              },
            }}
          >
            <span className="add mt-5 mb-10">
              {' '}
              Nuevo Cupón <i className="fas fa-plus-circle" />
            </span>
          </Link>
        </div>
      </div>
    );
  }
}

export default branch(
  {
    session: ['session'],
    myCoupons: ['myCoupons'],
  },
  Coupons,
);
