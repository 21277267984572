import React from 'react';
import { branch } from 'baobab-react/higher-order';
import { Redirect } from 'react-router-dom';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
// Actions
import SessionActions from '../../actions/Session';
import Loader from '../../components/Loader';
import Nav from '../../components/Landing/Nav';
import { emailRegex } from '../../utils/constants';

class Demo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      login: false,
      loading: false,
      email: '',
    };
  }

  /**
   * Handle input changes
   * @param  {Object} event Input change event
   */
  onInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  /**
   * Makes a Session Request for Existing User
   * @param event
   */
  handleSubmit = (event) => {
    event.preventDefault();
    const { email } = this.state;
    const { lang } = this.props;
    this.setState({ loading: true });
    SessionActions.requestDemo(email, lang)
      .then((response) => {
        if (response.error) {
          NotificationManager.warning(response.error, 'Advertencia', 10000);
        } else {
          NotificationManager.success(response.message, 'Advertencia', 10000);
        }
        this.setState({ loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false });
        NotificationManager.warning(error.message, 'Advertencia', 10000);
      });
  };

  /**
   * Validates trough a binary expression email
   * @returns {*}
   */
  canContinue = () => {
    const { email } = this.state;
    return email.length && email.match(emailRegex);
  };

  /**
   * Redirects to Login
   */
  handleLogin = () => {
    this.setState({ login: true });
  };

  render() {
    const { login, email, loading, register } = this.state;
    const { location, handleLogin, lang, translations } = this.props;

    const translation = translations[lang].Demo;
    const { texts, inputs, buttons } = translation;
    return (
      <>
        {login ? (
          <Redirect
            to={{
              pathname: '/login',
              state: {
                from: location.pathname,
              },
            }}
          />
        ) : null}
        {register ? (
          <Redirect
            to={{
              pathname: '/register',
              state: {
                from: location.pathname,
              },
            }}
          />
        ) : null}
        <NotificationContainer />
        <Nav />
        <div className="container_landing">
          <div className="login_wrapper register_landing">
            <div className="login_form redesign">
              <div className="inputs_login_form">
                <p className="demo_text">
                  {texts.p1}
                  {/* <b className="button_send">{buttons.button1}</b>. */}
                </p>
                <input
                  className="input_nc"
                  onChange={this.onInputChange}
                  name="email"
                  value={email}
                  type="email"
                  placeholder={inputs.inputPlaceholder1}
                />
                <div className="start_session">
                  {loading ? (
                    <div className="center_loading">
                      <Loader />
                    </div>
                  ) : (
                    <button
                      className="primary"
                      type="button"
                      disabled={!this.canContinue()}
                      onClick={this.handleSubmit}
                    >
                      {buttons.button1}
                    </button>
                  )}
                </div>
                <p className="demo_text">{texts.p2}</p>
              </div>
            </div>
            <div className="info_login_form redesign">
              <div className="container_info_login">
                <h1>{texts.h1}</h1>
                <div className="message_register">
                  <h2>{texts.h2}</h2>
                  <i className="fas fa-chevron-right fa-4x" />
                </div>
                <p>{texts.p3}</p>
                <button type="button" onClick={handleLogin || this.handleLogin}>
                  {buttons.button2}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  Demo,
);
// export default Demo;
