import axios from 'axios';
import { contratosConfig } from './configs/contratosConfig';
import { intercpetorHandler } from './intercpetors';

const origin = window.location.origin;
let cstmEnv = 'local';
export let sifeAuthOptions = {
  login: '/ext-login?reqEmailVerify=1',
  signup: '/ext-signup?reqEmailVerify=1&reqFields=email,rfc',
  signUpData: '/ext-signup?reqEmailVerify=1&reqFields=email,rfc',
  lang: () => `&lang=${localStorage.getItem('lang') || 'es'}`,
};
export let sifeAuthUrl = 'http://localhost:3000';
export let sifeSignUrl = 'http://localhost:3000/home/summary';
export let sifeSingleSignUrl = 'http://localhost:3000/home/single-sign/';

if (origin.includes('staging')) {
  cstmEnv = 'staging';
  sifeAuthUrl = 'https://dev.sife.app.sora.mx';
  sifeSignUrl = 'https://dev.sife.app.sora.mx';
  sifeSingleSignUrl = 'https://dev.sife.app.sora.mx/home/single-sign/';
} else if (origin.includes('dev.contratos.sora')) {
  cstmEnv = 'dev';
  sifeAuthUrl = 'https://dev.sife.app.sora.mx';
  sifeSignUrl = 'https://dev.sife.app.sora.mx';
  sifeSingleSignUrl = 'https://dev.sife.app.sora.mx/home/single-sign/';
} else if (origin.includes('contratos.sora')) {
  cstmEnv = 'prod';
  sifeAuthUrl = 'https://sife.app.sora.mx';
  sifeSignUrl = 'https://sife.app.sora.mx';
  sifeSingleSignUrl = 'https://sife.app.sora.mx/home/single-sign/';
}
export const trustedOrigins = [sifeAuthUrl];
const getApiClient = ({ protocol, hostname, port, api }) => {
  let axiosConfig;
  if (cstmEnv === 'local') {
    axiosConfig = {
      baseURL: `${protocol}://${hostname}:${port}/${api}`,
      responseType: 'json',
      validateStatus: () => true,
    };
  } else {
    axiosConfig = {
      baseURL: `${protocol}://${hostname}/${api}`,
      responseType: 'json',
      validateStatus: () => true,
    };
  }

  console.log('Pointed API: ', axiosConfig);
  return axios.create(axiosConfig);
};

export const contratosInstance = getApiClient(contratosConfig(cstmEnv));

intercpetorHandler(cstmEnv, contratosInstance);
