export const ContractAuthorizationTranslations = {
  es: {
    texts: {
      p1: 'Rechazado',
      p2: 'Aceptado',
      p3: 'Pendiente',
      small1: 'Usuarios registrados',
      small2: 'Agregar nuevos',
      small3: 'Pendientes de firma',
      th1: 'RFC',
      th2: 'Nombre',
      th3: 'Correo',
      th4: 'Estatus',
      td1: 'No hay usuarios registrados',
      p4: 'Procesando Documento',
      th5: 'RFC solicitante',
      th6: 'Nombre solicitante',
      th7: 'Correo solicitante',
      th8: 'Mis acciones',
      p5: 'No hay solicitudes pendientes',
      p6: 'Procesando Documento',
      genericModalTitle1: 'Ubicación bloqueada',
      genericModalBody1:
        'Porfavor busca en la configuración de tu navegador como restablecer los permisos de la ubiación, de otra manera no podrás firmar tus documentos.',
      genericModalTitle2: 'Permiso de usar tu ubicación',
      genericModalBody2:
        'Para poder usar tu ubicación necesitamos que des click en permitir. Esto es necesario ya que de otra manera el navegador bloqueará nuestro acceso a tu ubicación y no podrás firmar',
    },
    inputs: {
      inputPlaceholder1: 'Ingresa origen de tus peticiones',
      inputPlaceholder2: 'RFC',
    },
    buttons: {
      spanButton1: 'Agregar +',
      spanButton2: 'Finalizar',
      genericModalConfirmButton1: 'Cancelar',
      genericModalCancelButton1: 'Cerrar',
    },
    notifications: {
      notifTitle1: 'Atención',
      notifBody1: 'Hubo un error al obtener usuarios',
      notifTitle2: 'Atención',
      notifBody2: (repeatedUsers) => `${repeatedUsers} \n rfcs ya registrados`,
      notifTitle3: 'Exito',
      notifBody3: 'Proceso completado exitosamente',
      notifTitle4: 'Atención',
      notifBody4: 'Error al descargar el documento a visualizar',
      notifTitle5: 'Atención',
      notifBody5: 'Hubo un error al enviar tu respuesta',
      notifTitle6: 'Atención',
      notifBody6: 'Has denegado que alguien pueda descargar tus documentos',
      notifTitle7: 'Informativo',
      notifBody7: 'Firmando documento...\nEsto puede tardar unos minutos.',
      notifTitle8: 'Error',
      notifBody8: 'No pudimos leer el certificado.',
      notifTitle9: 'Error',
      notifBody9:
        'El RFC con el que intentas firmar es diferente al del inicio de sessión.',
      notifTitle10: 'Atención',
      notifBody10:
        'No pudimos obtener tu ubicación, asegurate de otorgarnos los permisos correspondientes',
      notifTitle11: 'Exito',
      notifBody11: 'Documento firmado con éxito',
      notifTitle12: 'Error',
      notifBody12: 'Hubo un error al firmar el documento',
    },
  },
  en: {
    texts: {
      p1: 'Rejected',
      p2: 'Accepted',
      p3: 'Pending',
      small1: 'Registered users',
      small2: 'Add new',
      small3: 'Pending signature',
      th1: 'RFC',
      th2: 'Name',
      th3: 'Email',
      th4: 'Status',
      td1: 'There are no registered users',
      p4: 'Processing Document',
      th5: 'Requester RFC',
      th6: 'Requester Name',
      th7: 'Requester Email',
      th8: 'My actions',
      p5: 'There are no pending requests',
      p6: 'Processing Document',
    },
    inputs: {
      inputPlaceholder1: 'Enter origin of your requests',
      inputPlaceholder2: 'RFC',
    },
    buttons: {
      spanButton1: 'Add +',
      spanButton2: 'Finish',
    },
    notifications: {
      notifTitle1: 'Warning',
      notifBody1: 'There was an error getting users',
      notifTitle2: 'Warning',
      notifBody2: (repeatedUsers) =>
        `${repeatedUsers} \n rfcs already registered`,
      notifTitle3: 'Success',
      notifBody3: 'Process completed successfully',
      notifTitle4: 'Warning',
      notifBody4: 'Error downloading document to view',
      notifTitle5: 'Warning',
      notifBody5: 'There was an error sending your response',
      notifTitle6: 'Warning',
      notifBody6: 'You have denied that someone can download your documents',
      notifTitle7: 'Info',
      notifBody7: 'Signing document...\nThis may take a few minutes.',
      notifTitle8: 'Error',
      notifBody8: 'We could not read the certificate.',
      notifTitle9: 'Error',
      notifBody9:
        'The RFC with which you try to sign is different from the one you started the session with.',
      notifTitle10: 'Warning',
      notifBody10:
        'We could not get your location, make sure you grant us the corresponding permissions',
      notifTitle11: 'Success',
      notifBody11: 'Document signed successfully',
      notifTitle12: 'Error',
      notifBody12: 'There was an error signing the document',
    },
  },
};
