import { branch } from 'baobab-react/higher-order';
import React from 'react';

const SignersListNewContract = ({
  dropdownSigners,
  index,
  signerInputChange,
  signer,
  dropdown,
  onBlurGenerateSoraIdToUsers,
  onKeyUpSearchByEmail,
  showInputRfc,
  findUsersSife,
  onKeyUpFindUser,
  showVerificationButtons,
  signerCheckboxChange,
  removeSigner,
  orderedSigners,
  translations,
  lang,
}) => {
  const { texts, inputs } =
    translations[lang].NewContract.Signers.SignersListNewContract;
  return (
    <>
      <div className="container_inputs_signers">
        <div
          className={`inputs_signers ${
            dropdownSigners ? 'dropdown_active' : ''
          }`}
        >
          {!dropdownSigners ? (
            <>
              <div className="legalRfc_signer_nc">
                {orderedSigners ? <small>{index + 1}.</small> : null}
                <input
                  type="text"
                  name="legalRfc"
                  value={signer.legalRfc.toUpperCase()}
                  className="input_nc"
                  style={{
                    textTransform: 'uppercase',
                    opacity:
                      dropdownSigners && signer.signType === '4' ? 0.5 : 1,
                  }}
                  required
                  minLength="12"
                  maxLength="13"
                  onChange={(e) => signerInputChange(index, e)}
                  disabled={dropdownSigners && signer.signType === '4'}
                  placeholder="RFC"
                  onBlur={(e) => findUsersSife(e, index)}
                  onKeyUp={(e) => onKeyUpFindUser(e, index)}
                />
              </div>
              <input
                type="text"
                name="legalName"
                value={signer.legalName}
                className="input_nc legalName_signer_nc"
                style={{
                  textTransform: 'capitalize',
                }}
                required
                maxLength="100"
                minLength="2"
                onChange={(e) => signerInputChange(index, e)}
                placeholder="Nombre"
              />
              <input
                type="email"
                name="email"
                autoComplete=""
                value={signer.secondaryEmail || signer.email}
                className="input_nc email_signer_nc"
                onChange={(e) => signerInputChange(index, e)}
                required
                placeholder="email"
                onBlur={(e) =>
                  onBlurGenerateSoraIdToUsers(e, index, signer.signType)
                }
                onKeyUp={(e) => onKeyUpSearchByEmail(e, index, signer.signType)}
              />
            </>
          ) : (
            <>
              <div className="select_signature_active">
                {orderedSigners ? <small>{index + 1}.</small> : null}
                <select
                  name="signType"
                  onChange={(e) => signerInputChange(index, e)}
                  autoComplete="nope"
                  value={signer.signType || ''}
                  className="input_nc"
                >
                  <option value="" disabled>
                    {inputs.selectOption1}
                  </option>
                  <option value={1}>{inputs.selectOption2}</option>
                  <option value={2}>{inputs.selectOption3}</option>
                  <option value={3}>{inputs.selectOption4}</option>
                  <option value={4}>{inputs.selectOption5}</option>
                </select>
                <i className="fas fa-angle-down	" style={{ color: 'black' }} />
              </div>
              <input
                type="text"
                name="legalName"
                value={signer.legalName}
                className="input_nc legalName_signer_nc"
                style={{
                  textTransform: 'capitalize',
                }}
                required
                maxLength="100"
                minLength="2"
                onChange={(e) => signerInputChange(index, e)}
                placeholder={inputs.inputPlaceholder2}
              />
              <input
                type="email"
                name="email"
                autoComplete=""
                value={signer.secondaryEmail || signer.email}
                className="input_nc email_signer_nc"
                onChange={(e) => signerInputChange(index, e)}
                required
                placeholder={inputs.inputPlaceholder3}
                onBlur={(e) =>
                  onBlurGenerateSoraIdToUsers(e, index, signer.signType)
                }
                onKeyUp={(e) => onKeyUpSearchByEmail(e, index, signer.signType)}
              />
              <div className="tooltip top legalRfc_signer_nc">
                {dropdownSigners &&
                  signer.signType === '4' &&
                  signer.legalRfc.includes('@') && (
                    <span className="tiptext" style={{ padding: 10 }}>
                      {texts.tooltip1}
                    </span>
                  )}

                {!showInputRfc(signer) ? (
                  <input
                    type="text"
                    name="legalRfc"
                    value={signer.legalRfc.toUpperCase()}
                    className="input_nc"
                    style={{
                      textTransform: 'uppercase',
                      opacity:
                        dropdownSigners && signer.signType === '4' ? 0.5 : 1,
                    }}
                    required
                    minLength="12"
                    maxLength="13"
                    onChange={(e) => signerInputChange(index, e)}
                    disabled={dropdownSigners && signer.signType === '4'}
                    placeholder={inputs.inputPlaceholder1}
                    onBlur={(e) => findUsersSife(e, index)}
                    onKeyUp={(e) => onKeyUpFindUser(e, index)}
                  />
                ) : null}
              </div>
            </>
          )}
        </div>
        <div className="action_delete_signer_nc">
          {orderedSigners ? <small>{index + 1}.</small> : <small></small>}
          <i
            className={`fas fa-minus-circle `}
            style={{
              color: '#f95c5e',
              cursor: 'pointer',
            }}
            onClick={() => removeSigner(signer)}
          />
        </div>
      </div>
    </>
  );
};

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  SignersListNewContract,
);
