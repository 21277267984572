import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { branch } from 'baobab-react/higher-order';
import { NotificationManager } from 'react-notifications';
import SuperUserActions from '../../actions/SuperUser';
import generateShortId from '../../utils/makeId';
import Loader from '../../components/Loader';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      newCoupons: [],
      button: false,
    };
  }

  handleForm = (event) => {
    event.preventDefault();
  };

  // canAddCoupons = () => {
  //   const { newCoupons } = this.state;
  //   return newCoupons.every(this.isValid) && newCoupons.length > 0;
  // };

  couponInputChange = (couponId, event) => {
    const { name, value } = event.target;
    const { newCoupons } = this.state;
    const array = [...newCoupons];
    array[couponId][name] = value;
  };

  // isValid(value) {
  //   return value.code.length && value.contracts > 0;
  // }

  onBlurInputs = (e) => {
    if (e.code.length > 0 && parseInt(e.contracts) > 0) {
      this.setState({ button: true });
    } else {
      this.setState({ button: false });
    }
  };

  addCoupon() {
    const { newCoupons } = this.state;
    const newCoupon = {
      code: '',
      contracts: 1,
    };
    newCoupons.push(newCoupon);
    // eslint-disable-next-line
    newCoupons.map((c) => {
      if (c.code === '') {
        this.setState({ button: false });
      }
    });
    this.setState({ newCoupons });
    this.forceUpdate();
  }

  removeCoupon(e) {
    const { newCoupons } = this.state;
    const array = [...newCoupons];
    this.setState({ newCoupons: [] });
    const index = array.indexOf(e);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ newCoupons: array });
      // eslint-disable-next-line
      newCoupons.map((c) => {
        if (c.code !== '') {
          this.setState({ button: true });
        }
      });
    }
    this.forceUpdate();
  }

  handleAddCoupons() {
    const { history, location, session } = this.props;
    const { newCoupons } = this.state;
    this.setState({ loading: true });

    newCoupons.map(async (coupon) => {
      const response = await SuperUserActions.createCoupon(coupon);
      if (response.status === 200) {
        NotificationManager.success(
          `Cupón ${response.data.code} creado`,
          'Éxito',
          10000,
        );
        history.push({
          pathname: `/superadmin/${session.user.legalRfc}`,
          state: {
            from: location.pathname,
          },
        });
      } else {
        NotificationManager.error(
          `No se pudo crear ${coupon.code}`,
          'Atención',
          10000,
        );
      }
      this.setState({ loading: false });
    });

    // newCoupons.map(coupon => SuperUserActions.createCoupon(coupon)
    //   .then((newCoupon) => {
    //     NotificationManager.success(`Cupón ${newCoupon.code} creado`, 'Éxito', 10000);
    //     history.push({
    //       pathname: `/superadmin/${session.user.legalRfc}`,
    //       state: {
    //         from: location.pathname
    //       }
    //     });
    //   })
    //   .catch(() => {
    //     NotificationManager.error(`No se pudo crear ${coupon.code}`, 'Atención', 10000);
    //   }));
    // this.setState({ loading: false });
  }

  render() {
    // Require session to access
    const { session, location } = this.props;
    const { newCoupons, loading, button } = this.state;
    if (!session) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: {
              from: location.pathname,
            },
          }}
        />
      );
    }
    return (
      <>
        <h1>Nuevos Cupones</h1>
        <div className="page padded">
          <div className="row">
            <div className="col-sm-12">
              <form onSubmit={this.handleForm.bind(this)}>
                <label htmlFor="Firmantes">
                  Cupones para contratos
                  <table>
                    <tbody>
                      {newCoupons.length
                        ? newCoupons.map((coupon, index) => (
                            <>
                              <tr key={generateShortId()}>
                                <td className="pb-0">
                                  <input
                                    type="text"
                                    name="code"
                                    defaultValue={coupon.code}
                                    onChange={this.couponInputChange.bind(
                                      this,
                                      index,
                                    )}
                                    onBlur={this.onBlurInputs.bind(
                                      this,
                                      coupon,
                                    )}
                                    className="signerInput"
                                    maxLength="25"
                                    placeholder="Código de cupón"
                                  />
                                </td>
                                <td className="pb-0">
                                  <input
                                    type="number"
                                    name="contracts"
                                    defaultValue={coupon.contracts}
                                    onChange={this.couponInputChange.bind(
                                      this,
                                      index,
                                    )}
                                    onBlur={this.onBlurInputs.bind(
                                      this,
                                      coupon,
                                    )}
                                    className="signerInput"
                                    min="1"
                                    max="5"
                                    placeholder="Cantidad de contratos por usuario"
                                  />
                                </td>
                                <td className="actions pb-0">
                                  <i
                                    className={`fas fa-minus-circle ${
                                      loading ? 'hidden' : ''
                                    }`}
                                    style={{
                                      color: '#D0021B',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => this.removeCoupon(coupon)}
                                  />
                                </td>
                              </tr>
                            </>
                          ))
                        : null}
                    </tbody>
                  </table>
                  <div className="row">
                    <div className="col-sm-2 float-left align-left">
                      <span
                        className="add mt-5 mbm-10"
                        onClick={this.addCoupon.bind(this)}
                      >
                        Agregar +
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-2">
                      {loading ? (
                        <div className="row center-xs mt-15">
                          <Loader />
                        </div>
                      ) : (
                        false
                      )}
                    </div>
                    <div className="col-sm-2">
                      <Link
                        to={{
                          pathname: location.state ? location.state.from : '/',
                          state: {
                            from: location.pathname,
                          },
                        }}
                      >
                        <button type="button" className="color-grey">
                          Regresar
                        </button>
                      </Link>
                    </div>
                    <div className="col-sm-2 col-sm-offset-6">
                      <button
                        type="submit"
                        className="primary"
                        onClick={this.handleAddCoupons.bind(this)}
                        // disabled={!this.canAddCoupons()}
                        style={{
                          pointerEvents: `${button ? '' : 'none'}`,
                          opacity: `${button ? '1' : '0.6'}`,
                        }}
                      >
                        Guardar
                      </button>
                    </div>
                  </div>
                </label>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default branch({ session: ['session'] }, Dashboard);
