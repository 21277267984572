import { branch } from 'baobab-react/higher-order';
import React from 'react';

const CopiedListDisplay = ({ recipients, translations, lang }) => {
  const { texts } = translations[lang].ContractDetail.CopiedListDisplay;
  return (
    <div className="recipients_cd">
      <span>{texts.spanTitle}</span>
      {recipients.length ? (
        <div className="table_list_recipient_cd">
          {recipients.map((recipient, i) => (
            <>
              <p className="status_turn_copied">{i + 1} </p>
              <p className="legalRfc_copied">{recipient.legalRfc}</p>
              <p className="legalName_recipient">
                {recipient.legalRfc.length < 13
                  ? recipient.legalName.toUpperCase()
                  : recipient.legalName}{' '}
              </p>
            </>
          ))}
        </div>
      ) : (
        <p className="no_results">{texts.p1}</p>
      )}
    </div>
  );
};

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  CopiedListDisplay,
);
