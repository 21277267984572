import React from 'react';
import moment from 'moment';

export const ListInfoContract = ({
  contract,
  showStatus,
  openModalLables,
  openModalToDeleteContract,
  openModalToCancelContract,
}) => {
  return (
    <div className="table_info_contract_sp" style={{ marginTop: 30 }}>
      <div className="header_info_contract">
        <small className="bold">Titulo</small>
        <small className="bold">Fecha Creación</small>
        <small className="bold">Estatus</small>
        <small className="bold">Creador</small>
        <small className="bold">Más...</small>
      </div>
      <div className="body_info_contract">
        <small className="normal_font">{contract.title}</small>
        <small className="normal_font">
          {moment(contract.createdAt).format('DD MMM YYYY h:mm a')}
        </small>
        <small className="normal_font">{showStatus(contract)}</small>
        {contract?.userRoot ? (
          <small className="normal_font" style={{ fontSize: 13 }}>
            {`${contract?.userCreator?.legalName} (${contract?.userCreator?.legalRfc}) a nombre de: `}
            <br></br>
            <small className="signer_data normal" style={{ fontSize: 13 }}>
              {contract?.userRoot?.legalName &&
                `${contract?.userRoot?.legalName} (${
                  contract?.userRoot?.legalRfc || ''
                })`}
            </small>
          </small>
        ) : (
          <small className="normal_font">
            {`${contract?.userCreator?.legalName} (${contract?.userCreator?.legalRfc})`}
          </small>
        )}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 10,
          }}
        >
          <i
            style={{ cursor: 'pointer', fontSize: 20 }}
            className="far fa-clipboard"
            onClick={() => openModalLables()}
          />
          {Boolean(contract.is_valid) && (
            <i
              className="fas fa-ban"
              style={{ cursor: 'pointer', fontSize: 20 }}
              onClick={() => openModalToCancelContract()}
            />
          )}
          <i
            className="far fa-trash-alt"
            style={{ cursor: 'pointer', fontSize: 20 }}
            onClick={() => openModalToDeleteContract()}
          />
        </div>
      </div>
    </div>
  );
};
