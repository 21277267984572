export const LoginTranslations = {
  es: {
    texts: {
      h4: 'Iniciar sesión con',
      small1: '¿Aún no tienes una cuenta?',
      a: 'Regístrate',
      h1: '¿Estás listo para dejar de firmar documentos a mano?',
      h3: 'Empieza a cargar y firmarlos digitalmente',
      label1: 'Más Rápido',
      label2: 'Más Seguro',
      label3: 'Más Barato',
    },
    inputs: {
      cer: 'Certificado (.cer)',
      key: 'Llave privada (.key)',
      passphrase: 'Clave privada (contraseña)',
    },
    buttons: {
      login: 'Ingresar',
      testDemoUser: 'Pruebala con Usuario DEMO',
      loginForm: 'Ingresar con',
    },
  },
  en: {
    texts: {
      h4: 'Login with',
      small1: "Don't have an account?",
      a: 'Signup',
      h1: 'Are you ready to stop signing documents by hand?',
      h3: 'Start uploading and digitally signing them',
      label1: 'Faster',
      label2: 'Safer',
      label3: 'Cheaper',
    },
    inputs: {
      cer: 'Certificate (.cer)',
      key: 'Private key (.key)',
      passphrase: 'Private code (password)',
    },
    buttons: {
      login: 'Login',
      testDemoUser: 'Try it out with a demo user',
      loginForm: 'Login with',
    },
  },
};
