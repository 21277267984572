import { LanguageSwitcherConstants } from './LanguageSwitcher.constants';

export const TxtFlag = ({ lang, flagGap = '8px', onClickHandler }) => {
  const { flag, text } = LanguageSwitcherConstants[lang];
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: flagGap,
        cursor: 'pointer',
      }}
      onClick={onClickHandler}
    >
      <div>{text}</div>
      <img alt="es" src={`/${flag}.svg`} style={{ height: '18px' }} />
    </div>
  );
};
