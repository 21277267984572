import { endpointHandler } from '../createEndpoint';
const {
  loginSifeEP,
  registerEP,
  registerSifeEP,
  activateEP,
  requestDemoEP,
  loginEP,
  sendApiFormEP,
} = endpointHandler();

// POST
export const registerService = (data, reqConfig, urlVar) => {
  return registerEP.post(data, reqConfig, urlVar);
};
export const registerSifeService = (data, reqConfig, urlVar) => {
  return registerSifeEP.post(data, reqConfig, urlVar);
};
export const loginService = (data, reqConfig, urlVar) => {
  return loginEP.post(data, reqConfig, urlVar);
};
export const loginSifeService = (data, reqConfig, urlVar) => {
  return loginSifeEP.post(data, reqConfig, urlVar);
};
export const activateService = (data, reqConfig, urlVar) => {
  return activateEP.post(data, reqConfig, urlVar);
};
export const requestDemoService = (data, reqConfig, urlVar) => {
  return requestDemoEP.post(data, reqConfig, urlVar);
};
export const sendApiFormService = (data, reqConfig, urlVar) => {
  return sendApiFormEP.post(data, reqConfig, urlVar);
};
