import React from 'react';
import moment from 'moment';
import { branch } from 'baobab-react/higher-order';
import AddendumActions from '../../actions/AddendumActions';
import { NotificationManager } from 'react-notifications';
import AddendumForm from '../../components/AddendumForm';

class AddendumsDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddendumForm: false,
      addendums: [],
    };
  }

  showAddendumForm = () => {
    const { addendums } = this.state;
    this.setState({ showAddendumForm: true });
    this.setState({ addendums });
  };

  hideAddendumForm = () => {
    this.setState({ showAddendumForm: false });
  };

  onAddendum = async (modalState) => {
    const { description, file } = modalState;
    const {
      signers,
      contract,
      addendums,
      updateStateAddendum,
      lang,
      translations,
    } = this.props;
    const { notifications } =
      translations[lang].ContractDetail.AddendumsDisplay;
    updateStateAddendum({ loading: true });
    this.hideAddendumForm();
    NotificationManager.info(
      notifications.notifBody1,
      notifications.notifTitle1,
      3000,
    );

    try {
      const addendum = await AddendumActions.createAddendum({
        contract: contract.id,
        description,
        file,
        participants: signers.length,
        orderedSigners: contract.orderedSigners,
      });
      const signerAddendum = await AddendumActions.getThisSignersAddendum(
        addendum.id,
      );
      const myTurnAddendum =
        addendum?.lastSignedTurn + 1 === signerAddendum?.turn &&
        !signerAddendum?.signed;
      updateStateAddendum({
        addendums: [...addendums, addendum],
        loading: false,
        canAddAddendum: false,
        isMyTurnToSignAddendum: myTurnAddendum,
        signerAddendum,
        addendum,
      });
    } catch (error) {
      console.log(error);
      updateStateAddendum({ loading: false });
      return NotificationManager.error(
        notifications.notifBody2,
        notifications.notifTitle2,
        3000,
      );
    }
  };

  render() {
    const {
      addendums,
      // isMyTurnToSignAddendum,
      showModalAddendum,
      canAddAddendum,
      contract,
      lang,
      translations,
    } = this.props;
    const { texts, buttons } =
      translations[lang].ContractDetail.AddendumsDisplay;
    const { showAddendumForm } = this.state;

    const canCreateAddendum =
      (contract?.allPartiesSigned && !addendums?.length) ||
      (contract?.allPartiesSigned &&
        addendums?.every((add) => add?.allPartiesSigned));

    return (
      <div className="sign_addendum_cd">
        {addendums?.map((addendum, index) => {
          const previousAddendum = addendums[index - 1];
          return (
            <>
              <span>
                {texts.spanTitle1} {moment(addendum.createdAt).format('LL')}
              </span>
              <p>
                <b style={{ fontWeight: 500 }}>{texts.p1} </b>
                {addendum.description}
              </p>

              <div className="center_addendum_sign">
                <button
                  style={{
                    background: addendum?.isMyTurn ? '#2851e5' : '#6C6C6C',
                  }}
                  type="button"
                  onClick={() => showModalAddendum(addendum, previousAddendum)}
                >
                  {addendum?.isMyTurn ? buttons.button1 : buttons.button2}
                </button>
              </div>
            </>
          );
        })}
        {canCreateAddendum ? (
          <span className="add_addendum_cd" onClick={this.showAddendumForm}>
            {buttons.button3}
          </span>
        ) : null}
        {showAddendumForm ? (
          <AddendumForm
            handleConfirm={this.onAddendum}
            handleCancel={this.hideAddendumForm}
            contractTitle={contract.title}
          />
        ) : null}
      </div>
    );
  }
}

export default branch(
  {
    session: ['session'],
    translations: ['translations'],
    lang: ['lang'],
  },
  AddendumsDisplay,
);
