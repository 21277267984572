const UploadIdentityDocsTranslations = {
  es: {
    texts: {
      p1: 'Subiendo documentos',
      p2: 'Sube los siguientes documentos para poder firmar',
    },
    inputs: {},
    buttons: {
      button1: 'Subir Documentos',
      button2: 'Ver Documento',
    },
    notifications: {
      notifTitle1: 'Error',
      notifBody1: 'El peso del documento debe ser menor a 10MB',
      notifTitle2: '', //TODO: translate response
      notifBody2: '', //TODO: translate response
      notifTitle3: 'Éxito',
      notifBody3: 'Documentos cargados con éxito',
    },
  },
  en: {
    texts: {
      p1: 'Uploading documents',
      p2: 'Upload the following documents to sign',
    },
    inputs: {},
    buttons: {
      button1: 'Upload Documents',
      button2: 'View Document',
    },
    notifications: {
      notifTitle1: 'Error',
      notifBody1: 'The document weight must be less than 10MB',
      notifTitle2: '', //TODO: translate response
      notifBody2: '', //TODO: translate response
      notifTitle3: 'Success',
      notifBody3: 'Documents uploaded successfully',
    },
  },
};
const VerificationWarningTranslations = {
  es: {
    texts: {
      p1: 'Verifica tu identidad para firmar este contrato',
      p2: 'Para comprobar tu identidad necesitas',
      p3: '- Tener un dispositivo con cámara',
      p4: '- Contar con tu identificación oficial',
      p5: '- Estar en un ambiente iluminado para capturar tu selfie',
      p6: '¿Tienes dudas? Contacta al creador de este contrato',
    },
    inputs: {},
    buttons: {},
    notifications: {},
  },
  en: {
    texts: {
      p1: 'Verify your identity to sign this contract',
      p2: 'To verify your identity you need to',
      p3: '- Have a device with camera',
      p4: '- Have your official identification',
      p5: '- Be in a well-lit environment to capture your selfie',
      p6: 'Do you have doubts? Contact the creator of this contract',
    },
    inputs: {},
    buttons: {},
    notifications: {},
  },
};
const ActionButtonsTranslations = {
  es: {
    texts: {
      genericModalTitle1: 'Cancelar documento',
      genericModalBody1:
        'Puedes cancelar este documento ya que no ha sido firmado por todas las partes. ¿Estás seguro que deseas cancelar este documento?',
      genericModalTitle2: 'Eliminar documento',
      genericModalBody2:
        'Este documento se eliminará de manera permanente. ¿Estás seguro que deseas eliminarlo?',
    },
    inputs: {},
    buttons: {
      button1: 'Cancelar documento',
      button2: 'Eliminar documento',
      genericModalConfirmButton1: 'Cancelar',
      genericModalCancelButton1: 'Volver',
      genericModalConfirmButton2: 'Eliminar',
      genericModalCancelButton2: 'Volver',
    },
    notifications: {},
  },
  en: {
    texts: {
      genericModalTitle1: 'Cancel document',
      genericModalBody1:
        'You can cancel this document because it has not been signed by all parties. Are you sure you want to cancel this document?',
      genericModalTitle2: 'Delete document',
      genericModalBody2:
        'This document will be permanently deleted. Are you sure you want to delete it?',
    },
    inputs: {},
    buttons: {
      button1: 'Cancel document',
      button2: 'Delete document',
      genericModalConfirmButton1: 'Cancel',
      genericModalCancelButton1: 'Back',
      genericModalConfirmButton2: 'Delete',
      genericModalCancelButton2: 'Back',
    },
    notifications: {},
  },
};

export const SignContractDisplayTranslations = {
  es: {
    texts: {},
    inputs: {},
    buttons: {
      button1: 'Actualizar firmante',
      button2: 'Ver y autorizar documento',
      button3: 'Ver documento',
      button4: 'Iniciar verificación',
      button5: 'Ver y firmar documento',
      button6: 'Ver documento',
      button7: 'Ver y firmar documento',
      button8: 'Ver documento',
    },
    notifications: {},
    VerificationWarning: { ...VerificationWarningTranslations.es },
    UploadIdentityDocs: { ...UploadIdentityDocsTranslations.es },
    ActionButtons: { ...ActionButtonsTranslations.es },
  },
  en: {
    texts: {},
    inputs: {},
    buttons: {
      button1: 'Update signer',
      button2: 'View and authorize document',
      button3: 'View document',
      button4: 'Start verification',
      button5: 'View and sign document',
      button6: 'View document',
      button7: 'View and sign document',
      button8: 'View document',
    },
    notifications: {},
    VerificationWarning: { ...VerificationWarningTranslations.en },
    UploadIdentityDocs: { ...UploadIdentityDocsTranslations.en },
    ActionButtons: { ...ActionButtonsTranslations.en },
  },
};
