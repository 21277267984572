import { NotificationManager } from 'react-notifications';
import ContractActions from '../actions/Contract';
import { emailRegex, namePdfRegex, rfcRegex } from './constants';

export const convertSeconds = (totalMB) => {
  // time in seconds
  const totalTime = 110;
  // test files in MB
  const testUpload = 50;
  // get time all files
  const seconds = (totalMB * totalTime) / testUpload;

  const segundos = Math.round(seconds % 0x3c).toString();
  const minutos = (Math.floor(seconds / 0x3c) % 0x3c).toString();
  return `${minutos} minutos y ${segundos} segundos`;
};

export const verifyUploadContractsUtils = (acceptedFiles) => {
  const docError = [];
  const rfcError = [];
  const emailError = [];
  const bytes = 262144000;
  const limitedFiles = [];
  const pendingFiles = [];
  let total = 0;

  acceptedFiles.forEach((file) => {
    if (!file.name.match(namePdfRegex)) return docError.push(file.name);

    const name = file.name.substring(0, file.name.lastIndexOf('.'));
    const split = name.split(';');
    const rfc = split[0];
    const email = split[1];
    if (!rfc.match(rfcRegex)) rfcError.push(file.name);
    if (!email.match(emailRegex)) emailError.push(file.name);
    total += file.size;
    if (total < bytes) {
      limitedFiles.push(file);
    } else {
      pendingFiles.push(file);
    }
  });

  return { docError, rfcError, emailError, limitedFiles, pendingFiles, total };
};

export const limitFilesInMBUtils = (total) => {
  const bytes = 262144000;
  const totalMB = Math.trunc((total * 250) / bytes);
  //TODO: QUEDA PENDIENTE LA ALERTA
  if (totalMB >= 250) {
    NotificationManager.warning(
      'Intentaste subir más de 250 MB, quedaron pendientes tus ultimos documentos, al finalizar la primer carga podrás subir los restantes',
      'Atención',
      10000,
    );
  }
  return { totalMB };
};

export const usersMostUsedNewContract = (value, participants) => {
  /* const lastChar = value[value.length - 1];
  if (lastChar === '\\' || lastChar === '?') return; */
  let participantsUpdated = participants.filter(
    (participant) =>
      participant.legalName?.toLowerCase().search(value.toLowerCase()) !== -1 ||
      participant.legalRfc?.toLowerCase().search(value.toLowerCase()) !== -1 ||
      participant.secondaryEmail?.toLowerCase().search(value.toLowerCase()) !==
        -1,
  );
  const allParticipant = participantsUpdated.slice(-6);
  return allParticipant;
};

export const getRfcByEmail = async (info) => {
  const { allUsers, participantId, participant, value, typeOfParticipant } =
    info;
  const response = await ContractActions.generateSoraId({
    secondaryEmail: value,
    participant,
  });
  const data = response?.data;
  allUsers[participantId].legalRfc = data?.success ? data?.rfc : '';
  allUsers[participantId].disableLegalRfc = true;
  if (!data?.success && data?.type === 'MULTIPLE_USERS') {
    allUsers[participantId].disclaimerMultipleUsers = true;
    if (typeOfParticipant === 'recipient') {
      allUsers[participantId].disableLegalRfc = false;
    }
    return allUsers;
  }
  return allUsers;
};
