export const ActivationTranslations = {
  es: {
    texts: {
      ol1_li1_u1: 'Verifica',
      ol1_li1_1: 'que el RFC a activar sea de la cuenta que se va activar.',
      ol1_li2_1: 'Activa la ',
      ol1_li2_u1: 'casilla ',
      ol1_li2_2: 'con el RFC',
      ol1_li3_1: 'Pulsa el ',
      ol1_li3_u1: 'botón ',
      ol1_li3_2: 'de Activar.',
      p1: 'Redireccionamiento ...',
      h1: 'Activa tu cuanta',
      h2: 'Sigue estos sencillos pasos para activar tu cuenta',
      text1: 'Ingresa el',
      text2: 'código',
      text3: 'de verificación que se envió por correo',
      small1: 'Si no recibes el código de activación da',
      small2: 'click aquí',
    },
    inputs: {
      checkboxLabel1: (legalRfc) => `Activar RFC: ${legalRfc}`,
    },
    buttons: {
      button1: 'Activar',
    },
    notifications: {
      notifTitle1: 'Activación',
      notifBody1: 'Cuenta activada',
      notifTitle2: 'Advertencia',
      notifBody2: '', // TODO: translate response
      notifTitle3: 'Advertencia',
      notifBody3: 'Credenciales inválidas',
    },
  },
  en: {
    texts: {
      ol1_li1_u1: 'Verify',
      ol1_li1_1:
        'that the RFC to activate is from the same account that is going to be activated.',
      ol1_li2_1: 'Activate the ',
      ol1_li2_u1: 'box ',
      ol1_li2_2: 'with the RFC',
      ol1_li3_1: 'Press the ',
      ol1_li3_u1: 'button ',
      ol1_li3_2: 'Activate.',
      p1: 'Redirecting ...',
      h1: 'Activate your account',
      h2: 'Follow these simple steps to activate your account',
      text1: 'Enter the',
      text2: 'verification',
      text3: 'code that was sent by email',
      small1: "If you didn't receive the activation code,",
      small2: 'click here',
    },
    inputs: {
      checkboxLabel1: (legalRfc) => `Activate RFC: ${legalRfc}`,
    },
    buttons: {
      button1: 'Activate',
    },
    notifications: {
      notifTitle1: 'Activation',
      notifBody1: 'Account activated',
      notifTitle2: 'Warning',
      notifBody2: '', // TODO: translate response
      notifTitle3: 'Warning',
      notifBody3: 'Invalid credentials',
    },
  },
};
