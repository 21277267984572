import React from 'react';
import Autocomplete from 'react-autocomplete';
import { NotificationManager } from 'react-notifications';
import generateShortId from '../../utils/makeId';
import { branch } from 'baobab-react/higher-order';

class ChipsDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chips: [],
      chipsInput: '',
      autocompleteInput: '',
      error: false,
    };
  }

  onInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleChipsChange = (e) => {
    this.setState({ chipsInput: e.target.value });
  };

  handleChipsKeyDown = (e) => {
    const { chips } = this.state;
    const { myLabels, updateStateNewContract, translations, lang } = this.props;
    const { notifications } = translations[lang].NewContract.Chips;
    if (e.keyCode === 13) {
      const { value } = e.target;
      const chip = {};
      this.setState({ error: false });
      if (
        !myLabels.find((element) => element.content === value) &&
        !chips.find((element) => element.content === value)
      ) {
        chip.content = value;
        // eslint-disable-next-line no-bitwise
        chip.color = `#${(((1 << 24) * Math.random()) | 0).toString(16)}`;
        this.setState({
          chips: [...chips, chip],
          chipsInput: '',
        });
        updateStateNewContract({ chips: [...chips, chip] });
      } else {
        this.setState({ error: true });
        NotificationManager.warning(
          notifications.notifBody1(value),
          notifications.notifTitle1,
          3000,
        );
      }
    }
  };

  addChip = (chip) => {
    const { chips } = this.state;
    const { updateStateNewContract } = this.props;
    const newChip = {
      content: chip.content,
      color: chip.color,
      id: chip.id,
    };
    this.setState({ chips: [...chips, newChip] });
    updateStateNewContract({ chips: [...chips, newChip] });
  };

  removeChip = (e) => {
    const { chips } = this.state;
    const { updateStateNewContract } = this.props;
    const array = [...chips];
    const filteredArray = array.filter((chip) => chip !== e);
    this.setState({ chips: filteredArray });
    updateStateNewContract({ chips: filteredArray });
  };

  render() {
    const { chips, error, chipsInput, autocompleteInput } = this.state;
    const { myLabels, translations, lang } = this.props;
    const { inputs, texts } = translations[lang].NewContract.Chips;

    return (
      <>
        <div className="labels_nc">
          {chips.length <= 9 ? (
            <label htmlFor="chipsInput" className="label_nc">
              {inputs.label1}
              <div className="labels_flex">
                <Autocomplete
                  inputProps={{
                    className: `input_nc`,
                    placeholder: inputs.inputPlaceholder1,
                    name: 'autocompleteInput',
                  }}
                  wrapperStyle={{
                    marginTop: 4,
                    // height: 60,
                    width: '100%',
                  }}
                  shouldItemRender={(item, value) =>
                    item.content.toLowerCase().indexOf(value.toLowerCase()) > -1
                  }
                  getItemValue={(item) => item.content}
                  items={myLabels}
                  renderItem={(item, highlighted) => (
                    <div
                      key={generateShortId()}
                      style={{
                        padding: 5,
                        backgroundColor: highlighted ? '#F5F6F9' : '#FFFFFF',
                        color: highlighted ? '#2851E5' : '#000',
                      }}
                    >
                      {item.content}
                    </div>
                  )}
                  onChange={(e) => this.onInputChange(e)}
                  onSelect={(value, item) =>
                    chips.find((element) => element.content === value)
                      ? false
                      : this.addChip({
                          content: item.content,
                          color: item.color,
                          id: item.id,
                        })
                  }
                  value={autocompleteInput}
                  menuStyle={{
                    borderRadius: '3px',
                    boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                    background: 'rgba(255, 255, 255, 0.9)',
                    padding: '2px 0',
                    fontSize: '90%',
                    position: 'fixed',
                    overflow: 'scroll',
                    maxWidth: '10%',
                    maxHeight: '15%',
                    zIndex: '2',
                  }}
                />
                <span style={{ paddingLeft: 2, paddingRight: 2 }}>
                  {inputs.or}
                </span>
                <input
                  placeholder={inputs.inputPlaceholder2}
                  name="chipsInput"
                  style={{
                    color: error ? 'red' : 'black',
                    marginTop: 10,
                  }}
                  value={chipsInput}
                  onChange={this.onInputChange}
                  onKeyDown={this.handleChipsKeyDown}
                  className="input_nc"
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingRight: 5,
                }}
              >
                <small>{texts.small1}</small>
              </div>
            </label>
          ) : (
            <label>{texts.label1}</label>
          )}
        </div>
        <div className="list_labels_nc">
          {chips.length
            ? chips.map((chip, index) => (
                <span
                  key={index}
                  className="all_labels"
                  style={{ background: chip.color }}
                  onClick={() => this.removeChip(chip)}
                >
                  {chip.content} &#x2a2f;
                </span>
              ))
            : null}
        </div>
      </>
    );
  }
}

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  ChipsDisplay,
);
