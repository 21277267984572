import React from 'react';
import ViewPdf from '../../containers/_common/ViewPdf';
import { branch } from 'baobab-react/higher-order';

class IdentityDocumentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirming: false,
    };
  }

  render() {
    const {
      contractUrl,
      mimeTypeDoc,
      onClickCloseIdentityDocumentModal,
      lang,
      translations,
    } = this.props;
    const { buttons } = translations[lang].Modals.IdentityDocumentModal;
    return (
      <>
        {mimeTypeDoc.includes('image/') ? (
          <div className="show_image_identity">
            <div className="resize_image">
              <img
                src={contractUrl}
                alt="Identificación de usuario"
                className=""
              />
            </div>
            <div
              className="background-screen"
              onClick={onClickCloseIdentityDocumentModal}
            />
          </div>
        ) : (
          <div className="modal-container-sign">
            <div className="modal-sign">
              <ViewPdf contractUrl={contractUrl} />

              {/* <iframe title="preview" src={contractUrl} frameBorder="0" /> */}
              <div className="footer_modal">
                <button
                  className="color-grey"
                  type="button"
                  onClick={onClickCloseIdentityDocumentModal}
                >
                  {buttons.button1}
                </button>
              </div>
            </div>
            <div className="background-screen" />
          </div>
        )}
      </>
    );
  }
}

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  IdentityDocumentModal,
);
