import React from 'react';
import { branch } from 'baobab-react/higher-order';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import IdleTimer from 'react-idle-timer';
import jwt from 'jsonwebtoken';
// Containers
import NewContract from './NewContract';
import NewContractMassive from './NewContractMassive';

import Contracts from './Contracts';
import ContractDetail from './ContractDetail';
import Profile from './Profile/Profile';
import Signer from './Signer/Signer';
import PublicContractDetail from './PublicContractDetail';
import Admin from './Admin/Admin';
import SuperUser from './SuperUser/Dashboard';
import NewCoupon from './SuperUser/NewCoupon';
import NewApiUser from './SuperUser/NewApiUser';
import NewTemplate from './Templates/NewTemplate';
// Components
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Home from '../components/Home';
import AlertModal from '../components/Modals/AlertModal';
// import HelpVideos from '../components/HelpVideos';
// Actions
import SessionActions from '../actions/Session';
import state from '../state';
import UpdateUserModal from '../components/Modals/UpdateUserModal';
import PrivacyPolicy from '../components/Modals/PrivacyPolicy';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inactivityModal: false,
      showListSigners: true,
      showListAdmins: true,
      showSidebar: false,
    };
    this.idleTimer = null;
    this.onAction = this.onAction.bind(this);
    this.onActive = this.onActive.bind(this);
    this.onIdlePreventive = this.onIdlePreventive.bind(this);
    this.onIdle = this.onIdle.bind(this);
    this.handleInactivityModal = this.handleInactivityModal.bind(this);
  }

  componentDidMount() {
    const { session, location } = this.props;
    window.addEventListener('resize', () => {
      state.select('width').set({ width: window.innerWidth });
    });
    this.setState({ showSidebar: window.innerWidth > 992 });

    this.validateStorageSession(location);
    if (!session) {
      this.redirectToLanding(location);
    }
  }

  onAction(e) {
    //
  }

  onActive(e) {
    //
  }

  onIdlePreventive(e) {
    const { notifications } = this.props;
    NotificationManager.error(
      notifications.notifBody1,
      notifications.notifTitle1,
      60000,
    );
  }

  onIdle(e) {
    this.showInactivityModal();
  }

  validateStorageSession = (location) => {
    const { invalidSessionModal } = this.state;
    if (localStorage.token === undefined) {
      return this.redirectToLanding(location);
    }
    if (
      !invalidSessionModal &&
      jwt.decode(localStorage.token)?.exp < Date.now() / 1000
    ) {
      this.setState({ invalidSessionModal: true });
    }
  };

  redirectToLanding = (location) => (
    <Redirect
      to={{
        pathname: '/login',
        state: { from: location.pathname },
      }}
    />
  );

  showInactivityModal() {
    this.setState({ inactivityModal: true });
  }

  handleInactivityModal() {
    this.setState({ inactivityModal: false });
    SessionActions.logout();
  }

  onClickShowListSignersOrAdmins = (type) => {
    const { showListSigners, showListAdmins, showSidebar } = this.state;
    if (type === 'sidebar') {
      this.setState({ activeStyle: true });
      return this.setState({
        showSidebar: !showSidebar,
      });
    }
    if (type === 'signer') {
      this.setState({ activeStyle: true });
      return this.setState({ showListSigners: !showListSigners });
    }

    this.setState({ activeStyle: true });
    this.setState({ showListAdmins: !showListAdmins });
  };

  render() {
    // Require session to access
    const {
      inactivityModal,
      invalidSessionModal,
      showListSigners,
      showListAdmins,
      showSidebar,
    } = this.state;
    const { session, location, history, width, translations, lang } =
      this.props;
    if (!session) return this.redirectToLanding(location);
    this.validateStorageSession(location);
    // const width = window.innerWidth;
    const { user } = session;
    const { texts } = translations[lang].Dashboard;

    return (
      <>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdlePreventive}
          onAction={this.onAction}
          debounce={250}
          timeout={5000 * 60 * 14}
        />
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={5000 * 30 * 15}
        />

        <div
          id="new_container"
          className={`new_container ${showSidebar ? 'active' : ''}`}
        >
          <>
            <Sidebar
              user={user}
              location={location}
              onClickShowList={this.onClickShowListSignersOrAdmins}
              showListSigners={showListSigners}
              showListAdmins={showListAdmins}
              width={width}
              showSidebar={showSidebar}
            />
            <div
              className={
                width?.width < 992 && showSidebar ? 'background-screen' : null
              }
              onClick={() => this.onClickShowListSignersOrAdmins('sidebar')}
            />
          </>
          <Header
            user={session.user}
            onClickShowList={this.onClickShowListSignersOrAdmins}
            width={width}
          />

          <main className="new_main">
            <NotificationContainer />
            {!user.privacyPolicyAccepted && <PrivacyPolicy />}
            {user.legalRfc?.includes('@') &&
              !user?.lastLegalRfc &&
              user.privacyPolicyAccepted && (
                <UpdateUserModal session={session} />
              )}
            <Switch>
              {user.adminUser ? (
                <Route path="/nuevo/cupon" component={NewCoupon} />
              ) : (
                false
              )}
              {user.adminUser ? (
                <Route path="/nuevo/apiUser" component={NewApiUser} />
              ) : (
                false
              )}
              <Route path="/nueva/plantilla" component={NewTemplate} />
              <Route path="/nuevo" component={NewContract} />
              <Route path="/contratos/:contractId" component={ContractDetail} />
              <Route path="/contratos" component={Contracts} />
              <Route path="/perfil" component={Profile} />
              <Route path="/carga-masiva" component={NewContractMassive} />
              {/* <Route path="/ayuda" component={HelpVideos} /> */}
              <Route
                path="/public/contracts/:contractId"
                component={PublicContractDetail}
              />
              {user.adminUser ? (
                <Route path="/superadmin/:legalRfc" component={SuperUser} />
              ) : (
                false
              )}
              {user.representing.map((represented) => (
                <Route
                  path="/contracts/on/:legalRfc"
                  key={represented.legalRfc}
                  component={Signer}
                />
              ))}
              {user.permissions.length
                ? user.permissions.map((permission) =>
                    permission.rootUser !== user.id ? (
                      <Route
                        path="/admin/of/:legalRfc"
                        key={`admin-${permission.legalRfc}`}
                        component={Admin}
                      />
                    ) : (
                      false
                    ),
                  )
                : false}
              <Route
                path="/"
                component={() => (
                  <Home user={user} location={location} history={history} />
                )}
              />
            </Switch>
            {inactivityModal ? (
              <AlertModal
                message={texts.alertModalBody1}
                header={texts.alertModalTitle1}
                okButton={this.handleInactivityModal}
              />
            ) : null}
            {invalidSessionModal ? (
              <AlertModal
                message={texts.alertModalBody2}
                header={texts.alertModalTitle2}
                okButton={() => SessionActions.logout()}
              />
            ) : null}
          </main>
        </div>
      </>
    );
  }
}

export default branch(
  {
    session: ['session'],
    width: ['width'],
    translations: ['translations'],
    lang: ['lang'],
  },
  Dashboard,
);
