import React from 'react';
// import { ListSignerDocument } from './ListSignerDocument';
import { ListVerificationDoc } from './ListVerificationDoc';
import { branch } from 'baobab-react/higher-order';

class ListVerificationDocs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mainUser: null,
      showContractToCreator: false,
      isSignerRegistered: false,
      myDocs: [],
    };
  }

  componentDidMount() {
    const { contract, user, admin, localSigner } = this.props;

    //Muestra al creador del contrato el listado completo de documentos ya sea desde cuenta personal
    // o desde admin/apoderado
    const idMainUser = admin ? admin.rootUser : user.id;
    if ((contract.rootUser || contract.createdBy) === idMainUser) {
      this.setState({ showContractToCreator: true });
    }

    if (localSigner.requestVerification && localSigner.userId === user.id) {
      this.setState({ isSignerRegistered: true });
    }
  }

  changeDocName = (docName) => {
    const { translations, lang } = this.props;
    const { texts } =
      translations[lang].ContractDetail.SignersListDisplay.ListVerificationDocs;
    if (docName === 'back') {
      return texts.span1;
    }

    if (docName === 'selfieImage') {
      return texts.span2;
    }

    return texts.span3;
  };

  render() {
    let { showContractToCreator, isSignerRegistered } = this.state;
    const {
      localSigner,
      verificationDocs,
      onClickDownloadIdentityDocument,
      user,
      translations,
      lang,
    } = this.props;
    const { texts } =
      translations[lang].ContractDetail.SignersListDisplay.ListVerificationDocs;
    return showContractToCreator ? (
      <ListVerificationDoc
        verificationDocs={verificationDocs}
        localSigner={localSigner}
        onClickDownloadIdentityDocument={onClickDownloadIdentityDocument}
        changeDocName={this.changeDocName}
        texts={texts}
      />
    ) : isSignerRegistered ? (
      <ListVerificationDoc
        verificationDocs={verificationDocs.filter(
          (doc) => doc.userId === user.id,
        )}
        localSigner={localSigner}
        onClickDownloadIdentityDocument={onClickDownloadIdentityDocument}
        changeDocName={this.changeDocName}
        texts={texts}
      />
    ) : null;
  }
}

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  ListVerificationDocs,
);
