import { ContractAuthorizationTranslations } from './ContractAuthorization.translations';
import { MyAPITranslations } from './MyAPI.translations';
import { MyInfoTranslations } from './MyInfo.translations';
import { MySignerOrMyAdminsTranslations } from './MySignerOrMyAdmins.translations';
import { NotificationsTranslations } from './Notifications.translations';

export const ProfileTranslations = {
  es: {
    texts: {
      h1: 'Mi Perfil',
      tab1: 'Información',
      tab2: 'Apoderados',
      tab3: 'Administradores',
      tab4: 'Plantillas',
      tab5: 'API',
      tab6: 'Solicitar documentos',
      tab7: 'Notificaciones',
      alertModalTitle1: 'Atención',
      alertModalBody1:
        "Para acceder a todas las funciones de soracontratos como: 'Cobros y Facturación' , 'Apoderados' , 'Administradores' , 'API' deberás iniciar sesión con tu propia e-firma.",
    },
    inputs: {},
    buttons: {},
    notifications: {},
    MyInfo: { ...MyInfoTranslations.es },
    MySignerOrMyAdmins: { ...MySignerOrMyAdminsTranslations.es },
    MyAPI: { ...MyAPITranslations.es }, // FIXME: This is not working and maybe irrelevant now
    MyTemplates: {}, // FIXME: This is not working and maybe irrelevant now
    ContractAuthorization: { ...ContractAuthorizationTranslations.es },
    Notifications: { ...NotificationsTranslations.es },
  },
  en: {
    texts: {
      h1: 'My profile',
      tab1: 'Info',
      tab2: 'Representatives',
      tab3: 'Administrators',
      tab4: 'Templates',
      tab5: 'API',
      tab6: 'Request documents',
      tab7: 'Notifications',
      alertModalTitle1: 'Warning',
      alertModalBody1:
        'To access all features from SoraContratos you must login with your own e.Firma (SAT)',
    },
    inputs: {},
    buttons: {},
    notifications: {},
    MyInfo: { ...MyInfoTranslations.en },
    MySignerOrMyAdmins: { ...MySignerOrMyAdminsTranslations.en },
    MyAPI: { ...MyAPITranslations.en }, // FIXME: This is not working and maybe irrelevant now
    MyTemplates: {}, // FIXME: This is not working and maybe irrelevant now
    ContractAuthorization: { ...ContractAuthorizationTranslations.en },
    Notifications: { ...NotificationsTranslations.en },
  },
};
