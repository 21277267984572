// Dependencies
import React from 'react';
import { Link } from 'react-router-dom';
import { branch } from 'baobab-react/higher-order';
import { NotificationManager } from 'react-notifications';

// Components
import ContractPreview from '../components/ContractPreview';
import GenericConfirmModal from '../components/Modals/GenericConfirmModal';
import StatusContractDisplay from './ContractDetails/StatusContractDisplay';
import LabelsDisplay from './ContractDetails/LabelsDisplay';
import AuthorizersListDisplay from './ContractDetails/AuthorizersListDisplay';
import SignersListDisplay from './ContractDetails/SignersListDisplay';
import CopiedListDisplay from './ContractDetails/CopiedListDisplay';
import AddendumsDisplay from './ContractDetails/AddendumsDisplay';
import SignContractDisplay from './ContractDetails/SignContractDisplay';
import CameraModal from '../components/Modals/CameraModal';
import LabelActions from '../actions/LabelActions';
import Loader from '../components/Loader';

//ACTIONS
import ContractActions from '../actions/Contract';
import AddendumActions from '../actions/AddendumActions';
import ProfileActions from '../actions/Profile';
import ContractPreviewAuthorizer from '../components/ContractPreviewAuthorizer';

//HELPERS
import {
  renderStatus,
  renderStatusAuthorizer,
} from '../helpers/ContractAndPublicDetailHelpers';
//UTILS
import { getLocationUtils } from '../utils/locationUtils';
import { downloadFileToView } from '../utils/downloadFilesUtils';
import IframeToSign from './ContractDetails/IframeToSign';
import UpdateSignerModal from '../components/Modals/UpdateSignerModal';
import GenericContractPreview from '../components/GenericContractPreview';

class ContractDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contract: null,
      addendum: null,
      authorizers: [],
      signers: [],
      recipients: [],
      labels: [],
      myLabels: [],
      addendums: [],
      signer: null,
      contractUrl: null,
      signersDocuments: [],
      myDocuments: [],
      mimeTypeDoc: '',
      showCameraModal: false,
      verificationDocs: [],
      myVerificationDocs: [],
      dropdown: [
        'Indistinta',
        'e.firma (SAT)',
        'Firma electrónica criptográfica',
      ],
      signersDropdown: false,
      cannotSign: false,
      locationUser: null,
      showAlertLocation: false,
      newMessageAlert: false,

      showModalContract: false,
      showModalAuthorizer: false,
      showModalAddendum: false,
      showModalContractSigned: false,

      totalLoading: 0,
      isMyTurnToSign: false,
      signTypeSigner: null,
      textAreaComments: false,
      canAddAddendum: false,
      isMyTurnToSignAddendum: false,
      signerAddendum: null,
      loadInfo: false,
      verifySife: '',
      showIframeToSign: false,
      showIframeToSignAddendum: false,
      showDialogToEditRfc: false,
      signerToUpdate: null,
      previousAddendum: null,
    };
  }

  async componentDidMount() {
    localStorage.removeItem('redirect');
    const { match, session, translations, lang } = this.props;
    const { notifications } = translations[lang].ContractDetail;
    try {
      this.setState({ loadInfo: true });
      const { user } = session;
      const contract = await ContractActions.view(match.params.contractId);
      this.redirectContractDeteled({ contract });
      const participants = await ContractActions.getThisSigners(contract.id);
      const signers = participants.allSignersAuth.length
        ? participants.allSignersAuth
        : participants.allSigners;
      this.redirectIfUserDoesnExist({
        allAuthorizers: participants.allAuthorizers,
        signers,
        recipients: participants.recipients,
      });
      const authorizersContract = participants?.allAuthorizers?.length
        ? participants.allAuthorizers
        : [];
      const signer =
        signers.find((signer) => signer.userId === user.id) ||
        authorizersContract?.find((auth) => auth.userId === user.id);
      const labels = await ContractActions.getThisLabels(contract.id);
      const myLabels = await LabelActions.myLabels(user.id);
      const mySigners = await ProfileActions.signers(user.id);
      if (signer) {
        if (signer.updatedBy) {
          const user = await ProfileActions.getUser(signer.updatedBy);
          signer.representing = user.legalName;
          signer.representedRfc = user.legalRfc;
        }
        const myTurn =
          contract.lastSignedTurn + 1 === signer.turn && !signer.signed
            ? true
            : false;
        const myTurnOrderedSigner = contract.orderedSigners && !signer.signed;

        this.setState({
          isMyTurnToSign: contract.orderedSigners
            ? myTurnOrderedSigner
            : myTurn,
          verifySife: this.getTypeSignUser(signer.signType),
        });
      }

      const addendums = await AddendumActions.getThisAddendums(contract.id);
      const addendum = addendums.find((addendum) => !addendum.allPartiesSigned);

      this.setState({ signerAddendum: signer, addendums: addendums ?? [] });
      if (addendum) {
        const signerAddendum = await AddendumActions.getThisSignersAddendum(
          addendum.id,
        );

        const mapAddendums = addendums?.map((add) => {
          if (!signerAddendum) {
            return {
              ...add,
            };
          }

          const myTurnSign = signerAddendum?.turn;
          const addendumTurn = add?.lastSignedTurn + 1;

          const isTheSameId = add?.id === addendum?.id;

          const isMyTurn =
            myTurnSign === addendumTurn &&
            !signerAddendum?.signed &&
            isTheSameId;

          const isMyTurnWithOutOrder =
            add.orderedSigners && !signerAddendum?.signed && isTheSameId;

          return {
            ...add,
            isMyTurn: addendum.orderedSigners
              ? isMyTurnWithOutOrder
              : Boolean(isMyTurn),
          };
        });

        // const myTurnAddendum =
        //   addendum.lastSignedTurn + 1 === signerAddendum.turn &&
        //   !signerAddendum.signed;
        // const myTurnAddendumOrderedSigner =
        //   addendum.orderedSigners && !signerAddendum.signed;

        this.setState({
          addendum,
          // signerAddendum,
          // isMyTurnToSignAddendum: addendum.orderedSigners
          //   ? myTurnAddendumOrderedSigner
          //   : myTurnAddendum,
          addendums: mapAddendums,
        });
      }

      this.setState({
        contract,
        authorizers: participants.allAuthorizers,
        signers: signers.sort((a, b) => {
          return a.turn - b.turn;
        }),
        recipients: participants.recipients,
        signer,
        labels,
        myLabels,
        mySigners,
        signersDocuments: participants.documents,
        myDocuments: participants.documents.filter(
          (doc) => doc.userId === user.id && !doc.uploaded,
        ),
        verificationDocs: participants.verificationDocs,
        value: user.legalName,
        canAddAddendum:
          contract.allPartiesSigned && addendum && addendum?.allPartiesSigned,
      });

      this.setState({ loadInfo: false });
      /* const locationUser = getLocationUtils(
        this.onSuccessLocation,
        this.onErrorLocation,
        this.verifyIfUserHasLocation,
      );

      if (locationUser) {
        this.setState({ cannotSign: true });
        return NotificationManager.warning(
          notifications.notifBody1,
          notifications.notifTitle1,
          10000,
        );
      } */
    } catch (error) {
      console.log(error);
      this.setState({ loadInfo: false });
    }
  }

  redirectContractDeteled = ({ contract }) => {
    if (contract?.deletedAt) {
      const { history, location } = this.props;
      history.push({
        pathname: '/contratos',
        state: {
          from: location.pathname,
        },
      });
    }
  };

  redirectIfUserDoesnExist = (data) => {
    const { session, history, location } = this.props;
    const { allAuthorizers, signers, recipients } = data;
    const userRfc = session.user.legalRfc;
    const userId = session.user.id;
    const allUsers = [...allAuthorizers, ...signers, ...recipients];

    const userExist = allUsers.some((user) => user.legalRfc === userRfc);
    const updateBySignerExist = signers.some(
      (signer) => signer.updatedBy === userId,
    );
    if (!userExist && !updateBySignerExist) {
      history.push({
        pathname: '/contratos',
        state: {
          from: location.pathname,
        },
      });
    }
  };

  showModalContract = async () => {
    const { contract } = this.state;
    await this.viewDocument(contract, 'contract', 'showModalContract');
  };

  showModalAuthorizer = async () => {
    const { contract } = this.state;
    await this.viewDocument(contract, 'contract', 'showModalAuthorizer');
  };

  openAddendum = async (addendum) => {
    await new Promise((resolve) => setTimeout(resolve, 200));
    await this.viewDocument(addendum, 'contract_addendum', 'showModalAddendum');
  };

  showModalAddendum = async (addendum, previousAddendum) => {
    const signerAddendum = await AddendumActions.getThisSignersAddendum(
      addendum.id,
    );

    if (!signerAddendum) {
      NotificationManager.error(
        'Error obtener firmante del addendum',
        'Error',
        5000,
      );
      return;
    }

    this.setState({
      signerAddendum: signerAddendum,
      addendum,
      previousAddendum,
    });

    if (addendum?.allPartiesSigned || !addendum?.isMyTurn) {
      this.setState({ isMyTurnToSignAddendum: false });
      this.openAddendum(addendum);
      return;
    }

    if (signerAddendum?.signed) {
      this.setState({ isMyTurnToSignAddendum: false });
      this.openAddendum(addendum);
      return;
    }

    this.setState({ isMyTurnToSignAddendum: true });
    this.openAddendum(addendum);
    return;
  };

  showModalContractSigned = async () => {
    const { contract } = this.state;
    await this.viewDocument(contract, 'contract', 'showModalContractSigned');
  };

  hideModals = () => {
    this.setState({
      showModalContract: false,
      showModalAuthorizer: false,
      showModalAddendum: false,
      showModalContractSigned: false,
    });
  };

  viewDocument = async (doc, docType, keyState) => {
    this.setState({ loading: true });
    const response = await downloadFileToView(doc, docType);
    if (!response.success) {
      return this.setState({ loading: false });
    }

    return this.setState({ [keyState]: true, loading: false });
  };

  onChangeSignTypeInSigner = (e, index) => {
    const { name, value } = e.target;
    const { signers } = this.state;
    const finalSigners = [...signers];
    finalSigners[index][name] = value;
    this.setState({ signers: finalSigners });
    this.setState({ signersDropdown: true });
  };

  onClickUpdateSigner = async () => {
    const { translations, lang } = this.props;
    const { notifications } = translations[lang].ContractDetail;
    const { signers, contract } = this.state;
    const updatedSignersDropdown = signers.map((signer) => ({
      id: signer.userId ? signer.userId : signer.id,
      legalRfc: signer.legalRfc,
      signType: signer.signType,
    }));
    const response = await ContractActions.updateSigner(
      updatedSignersDropdown,
      contract,
    );
    if (response.status === 200) {
      this.setState({ signersDropdown: false });
      return NotificationManager.success(
        notifications.notifBody2,
        notifications.notifTitle2,
        3000,
      );
    }
  };

  openModalToUpdateRfc = (signer) => {
    this.setState({ showDialogToEditRfc: true, signerToUpdate: signer });
  };

  closeModal = () => {
    this.setState({ showDialogToEditRfc: false, signerToUpdate: null });
  };

  onSuccessLocation = (position) => {
    const {
      coords: { latitude, longitude },
    } = position;
    this.setState({
      locationUser: {
        latitude,
        longitude,
      },
    });
    this.setState({ showAlertLocation: false, cannotSign: false });
  };

  onErrorLocation = (error) => {
    this.setState({
      cannotSign: true,
      showAlertLocation: false,
      newMessageAlert: true,
    });
  };

  verifyIfUserHasLocation = (result) => {
    if (result.state === 'prompt') {
      this.setState({ showAlertLocation: true, cannotSign: true });
    }
  };

  onClickOpenCameraModal = () => {
    this.setState({ showCameraModal: true });
  };

  closeCameraModal = async (responseVerification) => {
    const { signer } = this.state;
    signer.verified = responseVerification.data
      ? responseVerification.data.success
      : false;
    if (signer.verified) {
      const { match } = this.props;
      const participants = await ContractActions.getThisSigners(
        match.params.contractId,
      );
      this.setState({ verificationDocs: participants.verificationDocs });
    }

    this.setState({ showCameraModal: false });
  };

  getNewLabels = async (label, type) => {
    const { labels } = this.state;

    if (type === 'delete') {
      const filteredLabels = labels.filter(
        (labelFilter) => labelFilter.id !== label.id,
      );
      return this.setState({ labels: filteredLabels });
    }

    this.setState({ labels: [...labels, label] });
    if (type === 'add') {
      const myLabels = await LabelActions.myLabels();
      this.setState({ myLabels });
    }
  };

  updateStateIdentityDocuments = (data) => {
    this.setState(data);
  };

  updateAddendums = async ({ addendums = [] }) => {
    if (!addendums?.length) return;
    const addendum = addendums.find((addendum) => {
      return !addendum.allPartiesSigned;
    });

    if (!addendum) return;

    if (addendum) {
      const signerAddendum = await AddendumActions.getThisSignersAddendum(
        addendum.id,
      );

      const mapAddendums = addendums?.map((add) => {
        if (!signerAddendum) {
          return {
            ...add,
          };
        }

        const myTurnSign = signerAddendum?.turn;
        const addendumTurn = add?.lastSignedTurn + 1;

        const isTheSameId = add?.id === addendum?.id;

        const isMyTurn =
          myTurnSign === addendumTurn && !signerAddendum?.signed && isTheSameId;

        const isMyTurnWithOutOrder =
          add.orderedSigners && !signerAddendum?.signed && isTheSameId;

        return {
          ...add,
          isMyTurn: addendum.orderedSigners
            ? isMyTurnWithOutOrder
            : Boolean(isMyTurn),
        };
      });

      this.setState({ addendums: mapAddendums });
    }
  };

  updateStateSignContract = async (data) => {
    const { addendum } = this.state;
    if (data?.modal === 'addendum') {
      const getAddendum = data.addendums.find((add) => add.id === addendum.id);
      this.setState({ addendum: getAddendum });
      this.showModalAddendum(getAddendum);
      this.updateAddendums({ addendums: data?.addendums ?? [] });
    }

    if (data?.modal === 'contract') {
      this.showModalContract();
    }

    this.setState(data);
  };

  updateStateAddendum = (data) => {
    this.setState(data);
  };

  getTypeSignUser = (signerSignType) => {
    const sessionType = localStorage.getItem('sessionType');
    // const { signer } = this.state;
    console.log('SESSION USER', sessionType);
    if (
      (signerSignType === '1' && sessionType === 'efirma') ||
      (signerSignType === '2' && sessionType === 'efirma') ||
      (signerSignType === '2' && sessionType === 'sife')
    ) {
      return 'efirma_sign';
    }

    if (
      (signerSignType === '1' && sessionType === 'sife') ||
      (signerSignType === '3' && sessionType === 'sife')
    ) {
      return 'sife_sign';
    }

    if (signerSignType === '3' && sessionType === 'efirma') {
      return 'sife_session';
    }
  };

  render() {
    const {
      authorizers,
      contract,
      labels,
      signers,
      recipients,
      loading,
      value,
      signer,
      addendums,
      addendum,
      showModalAuthorizer,
      signerAddendum,
      signersDropdown,
      cannotSign,
      showAlertLocation,
      newMessageAlert,
      signersDocuments,
      myDocuments,
      showCameraModal,
      verificationDocs,
      myLabels,
      isMyTurnToSign,
      showModalContract,
      canAddAddendum,
      showModalAddendum,
      isMyTurnToSignAddendum,
      loadInfo,
      locationUser,
      verifySife,
      showIframeToSign,
      showIframeToSignAddendum,
      showDialogToEditRfc,
      signerToUpdate,
      showModalContractSigned,
      previousAddendum,
    } = this.state;

    const { session, mySigners, location, totalLoading, translations, lang } =
      this.props;
    const { user } = session;
    const { texts, buttons } = translations[lang].ContractDetail;

    if (loadInfo) {
      return (
        <div className="center_loading">
          <Loader />
          <p>{texts.p1}</p>
        </div>
      );
    }
    if (!contract) {
      return null;
    }
    return (
      <div className="contract_detail">
        <Link
          to={{
            pathname: '/contratos',
            state: {
              from: location.pathname,
            },
          }}
          className="back"
        >
          &lt; {texts.link1}
        </Link>
        <div className="titlebar">
          <h1>
            {contract.title.length <= 45
              ? contract.title
              : `${contract.title.substring(0, 45)}...`}
          </h1>
          {contract.imageUrl && <img src={contract.imageUrl} alt="Logo Sora" />}
        </div>

        <div className="grid_contract_details page">
          <StatusContractDisplay
            allAuthorized={authorizers.every((auth) => auth.signed)}
            signer={signer}
            contract={contract}
            authorizers={authorizers}
            renderStatus={renderStatus}
            renderStatusAuthorizer={renderStatusAuthorizer}
          />

          <LabelsDisplay
            contract={contract}
            labels={labels}
            user={user}
            myLabels={myLabels}
            getNewLabels={this.getNewLabels}
          />

          <AuthorizersListDisplay
            authorizers={authorizers}
            renderStatusAuthorizer={renderStatusAuthorizer}
          />

          <SignersListDisplay
            signers={signers}
            contract={contract}
            allAuthorized={authorizers.every((auth) => auth.signed)}
            mySigners={mySigners}
            user={user}
            onChangeSignTypeInSigner={this.onChangeSignTypeInSigner}
            value={value}
            signersDocuments={signersDocuments}
            verificationDocs={verificationDocs}
            signer={signer}
            updateStateIdentityDocuments={this.updateStateIdentityDocuments}
            onClickUpdateSigner={this.onClickUpdateSigner}
            openModalToUpdateRfc={this.openModalToUpdateRfc}
          />

          <CopiedListDisplay recipients={recipients} />

          {cannotSign ? (
            <button type="button" className="cancel location_cd">
              {buttons.button1}
            </button>
          ) : (
            <>
              <SignContractDisplay
                contract={contract}
                loading={loading}
                totalLoading={totalLoading}
                authorizers={authorizers}
                authorizer={authorizers.find((auth) => user.id === auth.userId)}
                signer={signer}
                isMyTurnToSign={isMyTurnToSign}
                myDocuments={myDocuments}
                showModalContract={this.showModalContract}
                showModalContractSigned={this.showModalContractSigned}
                updateStateIdentityDocuments={this.updateStateIdentityDocuments}
                onClickOpenCameraModal={this.onClickOpenCameraModal}
                showModalAuthorizer={this.showModalAuthorizer}
                verifySife={verifySife}
                signersDropdown={signersDropdown}
                onClickUpdateSigner={this.onClickUpdateSigner}
              />

              <AddendumsDisplay
                addendums={addendums}
                isMyTurnToSignAddendum={isMyTurnToSignAddendum}
                showModalAddendum={this.showModalAddendum}
                canAddAddendum={canAddAddendum}
                signers={signers}
                contract={contract}
                updateStateAddendum={this.updateStateAddendum}
                addendum={addendum}
              />
            </>
          )}

          {showModalAuthorizer ? (
            <ContractPreviewAuthorizer
              handleCancel={this.hideModals}
              contract={contract}
              authorizer={authorizers.find((auth) => user.id === auth.userId)}
              updateStateSignContract={this.updateStateSignContract}
            />
          ) : null}

          {showModalContract ? (
            <ContractPreview
              contract={contract}
              signer={signer}
              windowOpenSession={this.windowOpenSession}
              updateStateSignContract={this.updateStateSignContract}
              type="contract"
              locationUser={locationUser}
              isMyTurnToSign={isMyTurnToSign}
            />
          ) : null}

          {showModalAddendum ? (
            <ContractPreview
              contract={addendum}
              signer={signerAddendum}
              windowOpenSession={this.windowOpenSession}
              updateStateSignContract={this.updateStateSignContract}
              originalContract={contract}
              type="addendum"
              locationUser={locationUser}
              isMyTurnToSign={isMyTurnToSignAddendum}
              signerConfig={signer}
              previousAddendum={previousAddendum}
            />
          ) : null}

          {showModalContractSigned ? (
            <GenericContractPreview
              handleCancel={this.hideModals}
              type="demo"
              contract={contract}
              contractUrl={this?.props?.contractUrl}
            />
          ) : null}

          {showIframeToSign ? (
            <IframeToSign
              contract={contract}
              signer={signer}
              updateStateSignContract={this.updateStateSignContract}
              type="contract"
            />
          ) : null}

          {showIframeToSignAddendum ? (
            <IframeToSign
              contract={addendum}
              signer={signerAddendum}
              updateStateSignContract={this.updateStateSignContract}
              originalContract={contract}
              type="addendum"
            />
          ) : null}

          {showAlertLocation ? (
            <GenericConfirmModal
              title={
                newMessageAlert
                  ? texts.genericModalTitle1
                  : texts.genericModalTitle2
              }
              message={
                newMessageAlert
                  ? texts.genericModalBody1
                  : texts.genericModalBody2
              }
              okButton={buttons.genericModalConfirmButton1}
              cancelButton={buttons.genericModalCancelButton1}
              cancelAction={() => this.setState({ showAlertLocation: false })}
              acceptAction={() => this.setState({ showAlertLocation: false })}
            />
          ) : null}

          {showCameraModal ? (
            <CameraModal
              closeCameraModal={this.closeCameraModal}
              contract={contract}
            />
          ) : null}

          {showDialogToEditRfc ? (
            <UpdateSignerModal
              contract={contract}
              signerToUpdate={signerToUpdate}
              closeModal={this.closeModal}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default branch(
  {
    contracts: ['contracts'],
    session: ['session'],
    mySigners: ['mySigners'],
    totalLoading: ['totalLoading'],
    contractUrl: ['contractUrl'],
    translations: ['translations'],
    lang: ['lang'],
  },
  ContractDetail,
);
