export const LabelsDisplayTranslations = {
  es: {
    texts: {
      span1: 'Vigencia',
      p1: 'No especificado',
      spanTitle1: 'Etiquetas',
    },
    inputs: {
      inputPlacholder1: 'Buscar etiquetas',
      inputPlacholder2: 'Crear nuevas etiquetas',
    },
    buttons: {},
    notifications: {
      notifTitle1: 'Atención',
      notifBody1: 'El documento ya cuenta con esa etiqueta',
      notifTitle2: 'Atención',
      notifBody2: (value) => `Ya existe '${value}'`,
    },
  },
  en: {
    texts: {
      span1: 'Expires',
      p1: 'Not specified',
      spanTitle1: 'Labels',
    },
    inputs: {
      inputPlaceholder1: 'Search labels',
      inputPlaceholder2: 'Create new labels',
    },
    buttons: {},
    notifications: {},
  },
};
