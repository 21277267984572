import React from 'react';
import Loader from '../../components/Loader';
import { branch } from 'baobab-react/higher-order';

export const ButtonsActionsFilesDisplay = ({
  loading,
  canDownloadPdf,
  onClickDownloadFiles,
  canSignAllContracts,
  onCheckboxChange,
  cannotSign,
  signerOrAdmin,
  downloadExcel,
  onClickShowModalToSelectDate,
  lang,
  translations,
}) => {
  const { texts, buttons } = translations[lang].Contracts.ButtonsActionsFiles;
  return loading ? (
    <div className="loader_download_contracts">
      <Loader />
      <p>{texts.p1}</p>
    </div>
  ) : (
    <div className="button_actions">
      {signerOrAdmin ? (
        <>
          {canDownloadPdf().length ? (
            <div
              className="download_files_contracts"
              onClick={() => onClickDownloadFiles(canDownloadPdf())}
            >
              <i className="fas fa-file-download" />
              {texts.div1}
            </div>
          ) : null}

          <i
            className="far fa-file-excel"
            onClick={() => onClickShowModalToSelectDate('excel')}
          />

          <i
            className="far fa-file-archive"
            onClick={() => onClickShowModalToSelectDate()}
          />
        </>
      ) : null}
      {!signerOrAdmin ? (
        cannotSign ? (
          <button type="button" className="cancel">
            {buttons.button1}
          </button>
        ) : (
          <>
            {canDownloadPdf().length ? (
              <div
                className="download_files_contracts"
                onClick={() => onClickDownloadFiles(canDownloadPdf())}
              >
                <i className="fas fa-file-download" />
                {texts.div2}
              </div>
            ) : null}

            <i
              className="far fa-file-excel"
              onClick={() => onClickShowModalToSelectDate('excel')}
            />

            <i
              className="far fa-file-archive"
              onClick={() => onClickShowModalToSelectDate()}
            />
            <button
              type="button"
              id="myCheck"
              className="primary"
              disabled={!canSignAllContracts()}
              onClick={onCheckboxChange}
            >
              {buttons.button2}
            </button>
          </>
        )
      ) : null}
    </div>
  );
};

export default branch(
  {
    lang: ['lang'],
    translations: ['translations'],
  },
  ButtonsActionsFilesDisplay,
);
