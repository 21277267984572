import React from 'react';

export const ListSignerDocument = ({
  signersDocuments,
  localSigner,
  onClickDownloadIdentityDocument,
  texts,
}) => {
  return (
    <div className="verifications_user_docs">
      <div className="title_verification">
        <span style={{ color: localSigner.allDocsUploaded ? '#7ed321' : '' }}>
          {texts.spanTitle1}
        </span>
      </div>
      <div className="user_documents">
        {signersDocuments.map((doc, i) =>
          localSigner.userId === doc.userId ? (
            <span
              key={i}
              className="doc"
              style={{
                pointerEvents: doc.uploaded ? '' : 'none',
                opacity: doc.uploaded ? '1' : '0.5',
                color: doc.uploaded ? '#7ed321' : '',
                border: doc.uploaded ? '1px solid #7ed321' : '',
              }}
              onClick={() =>
                onClickDownloadIdentityDocument(doc, 'identityDocuments')
              }
            >
              {doc.document}
            </span>
          ) : null,
        )}
      </div>
    </div>
  );
};
