import { branch } from 'baobab-react/higher-order';
import React from 'react';

const ChooseSignatureType = ({
  handleChangeTypeSign,
  showMessageSigner,
  signTypeSigner,
  pageType,
  translations,
  lang,
}) => {
  const { texts, inputs } =
    translations[lang].NewContract.Signers.ChooseSignatureType;

  return (
    <>
      <span style={{ color: '#6c6c6c' }}>{texts.spanTitle} </span>
      <div className="tooltip-1 right">
        <i className="fas fa-exclamation-circle"></i>
        <div className="tiptext">
          <p>
            {texts.p1} <span>{texts.span1}</span>
          </p>
          <p>
            {texts.p2} <span>{texts.span2}</span>
          </p>
          <p>
            {texts.p3} <span>{texts.span3}</span>
          </p>
          <p>
            {texts.p4} <span>{texts.span4}</span>
          </p>
          {!pageType && (
            <p>
              {texts.p5} <span>{texts.span5}</span>
            </p>
          )}
        </div>
      </div>
      <div className="choose_signature_warning">
        <div className="dropdown_choose_signature">
          <select
            name="signType"
            onChange={handleChangeTypeSign}
            defaultValue=""
            className="input_nc"
          >
            <option value="" disabled>
              {inputs.selectOption1}
            </option>
            <option value="1">{inputs.selectOption2}</option>
            <option value="2">{inputs.selectOption3}</option>
            <option value="3">{inputs.selectOption4}</option>
            {!pageType && <option value="4">{inputs.selectOption5}</option>}
          </select>
          <i className="fas fa-angle-down	" style={{ color: 'black' }} />
        </div>

        {showMessageSigner && signTypeSigner !== '3' ? (
          <div className="warning">
            {texts.divWarning}
            <i className="fas fa-exclamation-triangle" />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  ChooseSignatureType,
);
