import React from 'react';
import moment from 'moment';

import {
  validateIcon,
  redirectTo,
  inputCheckbox,
} from '../helpers/ContractRowHelpers.js';
import { statusInContractUtils } from '../utils/contractsContainerUtils.js';
import { branch } from 'baobab-react/higher-order.js';

const ContractRow = ({
  contract,
  labels,
  history,
  user,
  selectContractToSign,
  downloadFile,
  signerOrAdmin,
  actualPage,
  lang,
  translations,
}) => {
  const { texts } = translations[lang].Contracts.ContractRow;
  return (
    <>
      <tr
        style={{
          fontWeight: contract.status === 'forMe' && !signerOrAdmin ? 700 : 100,
          cursor: 'pointer',
        }}
      >
        {!signerOrAdmin ? (
          <td
            className="center_table_data"
            title={statusInContractUtils(contract.status)}
          >
            {inputCheckbox(contract, user, selectContractToSign)}
            {validateIcon(contract)}
          </td>
        ) : (
          <td className="center_table_data">
            {validateIcon(contract)}
            {}
          </td>
        )}

        <td
          title={contract.title}
          onClick={() =>
            redirectTo(
              contract.id,
              contract.status,
              history,
              user,
              contract,
              signerOrAdmin,
              actualPage,
            )
          }
        >
          {contract.title.length <= 35
            ? contract.title
            : `${contract.title.substring(0, 30)}...`}
          <span
            className={
              labels.length >= 5
                ? 'float-right mbm-10'
                : 'float-right align-center'
            }
          >
            {labels.map((label) =>
              label.contract === contract.id ? (
                <span
                  key={label.id}
                  className="label"
                  style={{ background: `${label.color}` }}
                />
              ) : null,
            )}
          </span>
        </td>
        <td>
          {contract.createdAt
            ? moment(contract.createdAt).format('D MMM YYYY')
            : false}
        </td>
        <td title={contract?.legalName ? contract.legalName : ''}>
          {!contract?.legalName
            ? 'No definido'
            : contract?.legalName.length <= 35
            ? contract?.legalName
            : `${contract?.legalName?.substring(0, 30)}...`}
        </td>
        <td>
          {contract.expiresOn === 'Error'
            ? ''
            : contract.expiresOn === null ||
              contract.expiresOn === '0000-00-00 00:00:00'
            ? texts.td1
            : moment(contract.expiresOn).format('DD MMM YYYY')}
        </td>
        {!signerOrAdmin ? (
          <td>{contract.addendums > 0 ? ` + ${contract.addendums}` : ''}</td>
        ) : null}
        <td className="center_table_data">
          {contract.status === 'ready' &&
          contract.hasOwnProperty('downloadedPdf') ? (
            <i
              className="fas fa-file-download"
              style={{
                marginRight: 7,
                color: contract.isDownload ? '#2851e5' : null,
              }}
              onClick={() => downloadFile(contract)}
            ></i>
          ) : null}
          {/* {!signerOrAdmin ? (
          <i
            className="fas fa-ellipsis-v"
            style={{ float: 'right', color: '#2851e5' }}
            onContextMenu={(event) => onContextMenu(event, contract, user)}
          ></i>
        ) : null} */}
        </td>
      </tr>
    </>
  );
};

export default branch(
  {
    lang: ['lang'],
    translations: ['translations'],
  },
  ContractRow,
);
