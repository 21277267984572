export const CopiedListDisplayTranslations = {
  es: {
    texts: {
      spanTitle: 'Con copia',
      p1: 'No hay copiados',
    },
    inputs: {},
    buttons: {},
    notifications: {},
  },
  en: {
    texts: {
      spanTitle: 'With copy',
      p1: 'No copied recipients',
    },
    inputs: {},
    buttons: {},
    notifications: {},
  },
};
