export const StatusContractDisplayTranslations = {
  es: {
    texts: {
      spanTitle1: 'Estatus',
      span1: 'Esperando a otros firmantes',
      span2: 'Descripción',
    },
    inputs: {},
    buttons: {},
    notifications: {},
  },
  en: {
    texts: {
      spanTitle1: 'Status',
      span1: 'Waiting for other signers',
      span2: 'Description',
    },
    inputs: {},
    buttons: {},
    notifications: {},
  },
};
