import React from 'react';
import { Link } from 'react-router-dom';
// Actions

const Header = ({ user, onClickShowList, width }) => (
  <header className="header">
    <div
      className="container_logo_sidebar"
      onClick={() => onClickShowList('sidebar')}
    >
      <button id="button-menu" className="button-menu">
        <i className="fas fa-bars"></i>
      </button>
    </div>

    <div className="container_user_name">
      <Link
        to={{
          pathname: '/perfil',
          state: {
            from: '/',
          },
        }}
      >
        <i className="fas fa-user" />
        <span>{user.legalName}</span>
      </Link>
    </div>

    {/* <div
      style={{
        padding: '15px',
        margin: '15px',
        textDecoration: 'none',
        fontWeight: '300',
        cursor: 'pointer',
      }}
    >
      <span onClick={() => SessionActions.logout()} className="logout">
        Cerrar Sesión
      </span>
    </div> */}
  </header>
);

export default Header;
