import React, { useEffect, useState } from 'react';
import TranslationsActions from '../../actions/TranslationsActions';
import { TxtFlag } from './TxtFlag';
import { branch } from 'baobab-react/higher-order';
import ProfileActions from '../../actions/Profile';

const LanguageSwitcher = ({ lang, flagGap, user }) => {
  const [switchState, setSwitchState] = useState(true);

  useEffect(() => {
    async function updateData() {
      // You can await here
      const response = await ProfileActions.updateUserData(
        { userId: user.id },
        { lang },
      );
      if (response?.success) {
        console.log('Updating language');
        TranslationsActions.updateLanguageUser(lang, user);
      }
    }

    const updatedLang = localStorage.getItem('lang') || lang;
    if (user && user.lang !== updatedLang) {
      updateData();
    }
  }, [lang, user]);

  const switchLanguage = async (state) => {
    let lang;
    if (state) {
      lang = 'es';
    } else {
      lang = 'en';
    }
    TranslationsActions.switchLanguage(lang);
    setSwitchState(state);
  };
  return (
    <div
      style={{
        display: 'flex',
        gap: '16px',
      }}
    >
      <TxtFlag
        lang={lang}
        flagGap={flagGap}
        onClickHandler={() => switchLanguage(!switchState)}
      />
      <label className="switch">
        <input
          type="checkbox"
          checked={lang === 'es' ? true : false}
          name="active"
          onChange={() => switchLanguage(!switchState)}
        />
        <span className="slider round"></span>
      </label>
    </div>
  );
};

export default branch({ lang: ['lang'] }, LanguageSwitcher);
