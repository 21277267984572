import { branch } from 'baobab-react/higher-order';
import React from 'react';

const AuthorizersListDisplay = ({
  authorizers,
  renderStatusAuthorizer,
  translations,
  lang,
}) => {
  const { texts } = translations[lang].ContractDetail.AuthorizersListDisplay;
  return (
    <div className="authorizers_cd">
      <span>{texts.spanTitle1}</span>
      {authorizers.length ? (
        <div className="table_list_authorizers_cd">
          {authorizers.map((localSigner, i) => (
            <React.Fragment key={i}>
              <p className="status_turn_auth">
                {i + 1}
                {renderStatusAuthorizer(localSigner, 'list')}
              </p>
              <p className="legalRfc_auth">{localSigner.legalRfc}</p>
              <p className="legalName_auth">
                {localSigner.legalRfc.length < 13
                  ? localSigner.legalName.toUpperCase()
                  : localSigner.legalName}
              </p>
              <p className="comments">{localSigner.comment}</p>
            </React.Fragment>
          ))}
        </div>
      ) : (
        <p className="no_results">{texts.p1}</p>
      )}
    </div>
  );
};

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  AuthorizersListDisplay,
);
