import React from 'react';
import { branch } from 'baobab-react/higher-order';
import ContractActions from '../actions/Contract';
import { NotificationManager } from 'react-notifications';
import ViewPdf from '../containers/_common/ViewPdf';

class ContractPreviewAuthorizer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirming: false,
      selectOption: '',
      textAreaComments: false,
      comments: '',
    };
  }

  // DETECTA EL CAMBIO DEL ESTATUS EN EL MODAL
  handleChangeStatus = (event) => {
    this.setState({ selectOption: `${event.target.value}` });
    if (event.target.value === 'unauthorize') {
      this.setState({ textAreaComments: true });
    } else {
      this.setState({ textAreaComments: false });
    }
  };

  handleChangeComments = (event) => {
    this.setState({ comments: `${event.target.value}` });
  };

  authorizeContract = async (contract) => {
    const { selectOption, comments } = this.state;
    const { session, updateStateSignContract, translations, lang } = this.props;
    const { notifications } =
      translations[lang].ContractDetail.ContractPreviewAuthorizer;
    try {
      // ACTUALIZA EL STATUS DEL CONTRATO EN LA BD --- SIGNED
      if (selectOption === 'authorize') {
        const payload = {
          contractId: contract.id,
          lastAuthorizer: contract.lastAuthorizer + 1,
          signer: session.user,
          authorize: true,
          contract,
        };
        await ContractActions.updateAuthorizerContract(payload);
        this.setState({ isAuthorized: true });
      }
      // ACTUALIZA EL STATUS DEL CONTRATO EN LA BD --- NOSIGNED
      if (selectOption === 'unauthorize') {
        const payload = {
          contractId: contract.id,
          lastAuthorizer: contract.lastAuthorizer,
          signer: session.user,
          unauthorize: true,
          contract,
          comments,
        };
        await ContractActions.updateAuthorizerContract(payload);
        this.setState({ isNotAuthorized: true });
        this.setState({ textAreaComments: false });
      }

      const participants = await ContractActions.getThisSigners(contract.id);
      updateStateSignContract({ authorizers: participants.allAuthorizers });
      return NotificationManager.success(
        notifications.notifBody1,
        notifications.notifTitle1,
        3000,
      );
    } catch (error) {
      console.log(error);
      return NotificationManager.error(
        notifications.notifBody2,
        notifications.notifTitle2,
        3000,
      );
    }
  };

  displayInfoToAuthorize = (authorizer) => {
    const { selectOption } = this.state;
    const { contract, lang, translations } = this.props;
    const { inputs, buttons } =
      translations[lang].ContractDetail.ContractPreviewAuthorizer;
    if (authorizer.noSigned) {
      return (
        <button
          style={{
            background: '#fff',
            color: '#f95c5e',
            cursor: 'default',
            opacity: 1,
            fontWeight: 'bold',
            letterSpacing: 2,
          }}
          disabled
          type="button"
        >
          {buttons.button1}
        </button>
      );
    }

    if (authorizer.signed) {
      return (
        <button
          style={{
            background: '#fff',
            color: '#15f0b0',
            cursor: 'default',
            opacity: 1,
            fontWeight: 'bold',
            letterSpacing: 2,
          }}
          disabled
          type="button"
        >
          {buttons.button2}
        </button>
      );
    }

    return (
      <div className="footer_authorizer">
        <select
          className="dropdown-authorazer"
          name="authorize-select"
          onChange={this.handleChangeStatus}
          style={{ marginRight: 10 }}
        >
          <option value="">{inputs.selectOptionDefault}</option>
          <option value="authorize" id="authorize">
            {inputs.selectOption1}
          </option>
          <option value="unauthorize" id="unauthorize">
            {inputs.selectOption2}
          </option>
        </select>
        <button
          className="primary"
          type="button"
          onClick={() => this.authorizeContract(contract)}
          style={{
            pointerEvents: `${selectOption !== '' ? '' : 'none'}`,
            opacity: `${selectOption !== '' ? 1 : '0.6'}`,
          }}
        >
          {buttons.button3}
        </button>
      </div>
    );
  };

  render() {
    const { textAreaComments } = this.state;
    const {
      contract,
      handleCancel,
      contractUrl,
      authorizer,
      lang,
      translations,
    } = this.props;
    const { inputs, buttons } =
      translations[lang].ContractDetail.ContractPreviewAuthorizer;

    return contract ? (
      <>
        <div class="modal-demo">
          <div class="modal-content-demo">
            <div class="modal-header-demo" style={{ height: '15px' }}></div>
            <div class="modal-body-demo">
              <ViewPdf contractUrl={contractUrl} />
              {textAreaComments && (
                <div className="body_modal" style={{ textAlign: 'center' }}>
                  <textarea
                    className="text_area_comments"
                    id="description"
                    name="description"
                    placeholder={inputs.textareaPlaceholder1}
                    onChange={this.handleChangeComments}
                    rows={3}
                  />
                </div>
              )}
            </div>
            <div className="modal-footer-demo">
              <button
                className="color-grey"
                type="button"
                onClick={handleCancel}
              >
                {buttons.button4}
              </button>
              {this.displayInfoToAuthorize(authorizer)}
            </div>
          </div>
        </div>
        <div class="modal-overlay"></div>

        {/* 
        
        <div className="modal-container-sign">
        <div className="modal-sign contract_preview_authorizer">
          <ViewPdf contractUrl={contractUrl} />
          {textAreaComments && (
            <div className="body_modal" style={{ textAlign: 'center' }}>
              <textarea
                className="text_area_comments"
                id="description"
                name="description"
                placeholder={inputs.textareaPlaceholder1}
                onChange={this.handleChangeComments}
                rows={3}
              />
            </div>
          )}
          <div className="footer_modal">
            <button className="color-grey" type="button" onClick={handleCancel}>
              {buttons.button4}
            </button>
            {this.displayInfoToAuthorize(authorizer)}
          </div>
        </div>
        <div className="background-screen" />
      </div>
        */}
      </>
    ) : null;
  }
}

export default branch(
  {
    session: ['session'],
    contractUrl: ['contractUrl'],
    translations: ['translations'],
    lang: ['lang'],
  },
  ContractPreviewAuthorizer,
);
