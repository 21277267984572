import React from 'react';
import { ListSignerDocument } from './ListSignerDocument';
import { branch } from 'baobab-react/higher-order';

class ListSignersDocuments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showContractToCreator: false,
      isSignerRegistered: false,
      viewOnlyMyDocs: false,
      queueDocuments: false,
    };
  }

  componentDidMount() {
    const { contract, user, admin, localSigner } = this.props;

    //Muestra al creador del contrato el listado completo de documentos ya sea desde cuenta personal
    // o desde admin/apoderado
    const idMainUser = admin ? admin.rootUser : user.id;
    if ((contract.rootUser || contract.createdBy) === idMainUser) {
      this.setState({ showContractToCreator: true });
    }

    //Verifica si el usuario esta registrado para mostrarle los documentos
    if (localSigner.requestDocument && localSigner.hasOwnProperty('userId')) {
      this.setState({ isSignerRegistered: true });
    }

    //Mostrando unicamente los docs del firmante
    if (localSigner.userId === idMainUser && localSigner.requestDocument) {
      this.setState({ viewOnlyMyDocs: true });
    }

    //Mostrar queuedocs si es que el usuario tiene asignados
    if (localSigner.requestDocument && !localSigner.hasOwnProperty('userId')) {
      this.setState({ queueDocuments: true });
    }
  }

  render() {
    const {
      localSigner,
      signersDocuments,
      onClickDownloadIdentityDocument,
      translations,
      lang,
    } = this.props;
    const {
      showContractToCreator,
      isSignerRegistered,
      viewOnlyMyDocs,
      queueDocuments,
    } = this.state;

    const { texts } =
      translations[lang].ContractDetail.SignersListDisplay.ListSignersDocuments;

    return showContractToCreator && isSignerRegistered ? (
      <ListSignerDocument
        signersDocuments={signersDocuments}
        localSigner={localSigner}
        onClickDownloadIdentityDocument={onClickDownloadIdentityDocument}
        texts={texts}
      />
    ) : viewOnlyMyDocs ? (
      <ListSignerDocument
        signersDocuments={signersDocuments}
        localSigner={localSigner}
        onClickDownloadIdentityDocument={onClickDownloadIdentityDocument}
        texts={texts}
      />
    ) : queueDocuments && showContractToCreator ? (
      <div className="verifications_user_docs">
        <div className="title_verification">
          <span style={{ color: localSigner.allDocsUploaded ? '#7ed321' : '' }}>
            {texts.spanTitle1}
          </span>
        </div>
        <div className="user_documents">
          {JSON.parse(localSigner.requestDocument).map((documentQueue, i) => (
            <span
              key={i}
              className="doc"
              style={{
                pointerEvents: 'none',
                opacity: '0.5',
              }}
            >
              {documentQueue}
            </span>
          ))}
        </div>
      </div>
    ) : null;
  }
}

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  ListSignersDocuments,
);
