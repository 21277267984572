const AddendumFormTranslations = {
  es: {
    texts: {
      h2: (contractTitle) => `Nuevo adendum a ${contractTitle}`,
    },
    inputs: {
      textareaLabel1: 'Descripción',
      dropzoneLabel1: 'Documento',
    },
    buttons: {
      button1: 'Selecciona o arrastra un archivo',
      button2: 'Regresar',
      button3: 'Crear adendum',
    },
    notifications: {},
  },
  en: {
    texts: {
      h2: (contractTitle) => `New addendum for ${contractTitle}`,
    },
    inputs: {
      textareaLabel1: 'Description',
      dropzoneLabel1: 'Document',
    },
    buttons: {
      button1: 'Select or drag a file',
      button2: 'Back',
      button3: 'Create addendum',
    },
    notifications: {},
  },
};
export const AddendumsDisplayTranslations = {
  es: {
    texts: {
      spanTitle1: 'Adendum',
      p1: 'Descripción:',
    },
    inputs: {},
    buttons: {
      button1: 'Ver y firmar adendum',
      button2: 'Ver adendum',
      button3: 'Agregar adendum +',
    },
    notifications: {
      notifTitle1: 'Informativo',
      notifBody1: 'Creando adendum...\nEspere unos minutos.',
      notifTitle2: 'Atención',
      notifBody2: 'Error al crear el adendum\ncontacte a Sora. soporte@sora.mx',
    },
    AddendumForm: { ...AddendumFormTranslations.es },
  },
  en: {
    texts: {
      spanTitle1: 'Addendum',
      p1: 'Description:',
    },
    inputs: {},
    buttons: {
      button1: 'View and sign addendum',
      button2: 'View addendum',
      button3: 'Add addendum +',
    },
    notifications: {
      notifTitle1: 'Informative',
      notifBody1: 'Creating addendum...\nWait a few minutes.',
      notifTitle2: 'Attention',
      notifBody2: 'Error creating addendum\ncontact Sora. soporte@sora.mx',
    },
    AddendumForm: { ...AddendumFormTranslations.en },
  },
};
