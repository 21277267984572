export const navStyle = {
  backgroundColor: '#fff',
  WebkitBackdropFilter: 'saturate(180%) blur(20px)',
  backdropFilter: 'saturate(180%) blur(20px)',
  borderBottom: '1px solid #dee2e6',
  position: 'sticky',
  top: '0',
  zIndex: '1020',
  boxShadow: '0 .125rem .25rem rgba(0, 0, 0, .075)',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '8px',
};
