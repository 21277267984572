import React from 'react';
import Loader from '../Loader';

class GenericSignModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirming: false,
    };
  }

  showConfirmation = () => {
    this.setState({ confirming: true });
  };

  /**
   * Shows the File Input and Accepts Key & Cer
   * @param event
   */
  promptFile = (event) => {
    event.target.blur();
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    const { name } = event.target;
    if (name === 'cer') {
      input.setAttribute('accept', '.cer');
    } else {
      input.setAttribute('accept', '.key');
    }
    input.onchange = (e) => {
      const file = e.target.files[0];
      this.setState({ [name]: file });
    };
    input.click();
  };

  /**
   * Handle input changes
   * @param  {Object} event Input change event
   */
  onInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  /**
   * Can Sign the Contract if all inputs
   * @returns {*}
   */
  canSign = () => {
    const { key, cer, passphrase } = this.state;
    return passphrase && key && cer;
  };

  render() {
    const {
      handleCancel,
      handleConfirm,
      contracts,
      loading,
      responses,
      successSign,
    } = this.props;
    const { cer, key, passphrase } = this.state;
    return (
      <div className="modal-container">
        <div className="modal generic_sign_modal">
          {contracts.length > 0 ? (
            <>
              {loading ? (
                <>
                  <div className="info_sign_documents_modal">
                    <h3>Firmando documentos</h3>
                    <small>{`${contracts.length} documentos seleccionados`}</small>
                  </div>
                  <div className="center_loading">
                    <Loader />
                    <p>{`${responses.length} documentos firmados`}</p>
                  </div>
                </>
              ) : successSign ? (
                <div className="info_sign_documents_modal">
                  <i
                    className="fas fa-check-circle fa-2x"
                    style={{ color: '#7ED321', marginBottom: 20 }}
                  />{' '}
                  <br></br>
                  <small>{`${responses.length} Documentos Firmados con éxito`}</small>
                  <div className="footer_modal">
                    <button
                      className="color-grey"
                      type="button"
                      onClick={handleCancel}
                      disabled={loading}
                    >
                      Regresar
                    </button>
                    <button
                      className="primary"
                      type="button"
                      disabled={!this.canSign() || loading || successSign}
                      onClick={() => handleConfirm(this)}
                    >
                      Firmar
                    </button>
                  </div>
                </div>
              ) : (
                <div className="sign_contract_modal">
                  <div className="header_modal">
                    <h3>Firmar documentos</h3>
                    <small
                      style={{ fontSize: 14 }}
                    >{`${contracts.length} documentos seleccionados`}</small>
                  </div>

                  <div className="body_modal">
                    <input
                      onClick={this.promptFile}
                      onChange={() => {}}
                      name="cer"
                      type="text"
                      required
                      value={cer ? cer.name : ''}
                      placeholder="Certificado (.cer)"
                      className="input_nc"
                    />
                    <input
                      onClick={this.promptFile}
                      onChange={() => {}}
                      name="key"
                      type="text"
                      required
                      value={key ? key.name : ''}
                      placeholder="Llave (.key)"
                      className="input_nc"
                    />
                    <input
                      onChange={this.onInputChange}
                      name="passphrase"
                      type="password"
                      required
                      value={passphrase || ''}
                      placeholder="Clave Privada"
                      className="input_nc"
                    />
                    <div className="warning_save_key">
                      <small>
                        *No guardamos ninguna clave de la firma en Sora
                      </small>
                    </div>
                  </div>

                  <div className="footer_modal">
                    <button
                      className="color-grey"
                      type="button"
                      onClick={handleCancel}
                      disabled={loading}
                    >
                      Regresar
                    </button>
                    <button
                      className="primary"
                      type="button"
                      disabled={!this.canSign() || loading || successSign}
                      onClick={() => handleConfirm(this)}
                    >
                      Firmar
                    </button>
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="header_modal">
                <h3>No hay contratos para firmar</h3>
              </div>
              <div className="footer_modal">
                <button
                  className="color-grey"
                  type="button"
                  onClick={handleCancel}
                  disabled={loading}
                >
                  Regresar
                </button>
                <button
                  className="primary"
                  type="button"
                  disabled={!this.canSign() || loading || successSign}
                  onClick={() => handleConfirm(this)}
                >
                  Firmar
                </button>
              </div>
            </>
          )}
        </div>
        <div className="background-screen" />
      </div>
    );
  }
}

export default GenericSignModal;
