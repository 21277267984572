import state from '../state';
import Signature from '../utils/signature';

export const signersOrAdminsLoginUtils = async (credentials, props) => {
  const { cer, key, passphrase } = credentials.state;
  const { session } = props;
  const { user } = session;
  try {
    const { legalRfc } = await Signature({
      cer,
      key,
      passphrase,
      type: 'login_signers',
    });
    if (legalRfc !== user.legalRfc) {
      return {
        error: true,
        message:
          'El rfc de tus credenciales no es el mismo con el que iniciaste sesión',
      };
    }

    const sessionModified = {
      ...session.user,
      protectedUser: false,
      typeUser: null,
    };
    const newSession = {
      user: sessionModified,
    };
    localStorage.setItem('session', JSON.stringify(newSession));
    state.select(['session']).set({ user: newSession.user });
    return { error: false, message: '' };
  } catch (error) {
    console.log(error);
    return { error: true, message: error.message };
  }
};
