import React from 'react';
import moment from 'moment';
import Loader from '../../components/Loader';

export const TableReportsDisplay = ({ loading, reportSelected, signers }) => {
  return (
    <div className="table_reports_container">
      {loading ? (
        <div className="center_loading">
          <Loader />
        </div>
      ) : reportSelected === '3' ? (
        <div className="table_sife">
          <div className="header_table_sife header_table_reports">
            {signers.length ? (
              <>
                <small className="bold">RFC</small>
                <small className="bold">Nombre</small>
                <small className="bold">Email</small>
                <small className="bold">Fecha en que fue agregado</small>
                <small className="bold">Nombre Quien lo invito</small>
                <small className="bold">RFC Quien lo invito</small>
                <small className="bold">Registrado</small>
              </>
            ) : null}
          </div>
          <div className="body_table_sife spacing_tables">
            {signers.length ? (
              signers.map((signer, index) => (
                <>
                  <small className="normal_font">{signer.legalRfc}</small>
                  <small className="normal_font">{signer.legalName}</small>
                  <small className="normal_font">
                    {signer.secondaryEmail || signer.email}
                  </small>
                  <small className="normal_font">
                    {moment(signer.signerCreatedAt).format('DD MMM YYYY')}
                  </small>
                  <small className="normal_font">{signer.legalNameMain}</small>
                  <small className="normal_font">{signer.legalRfcMain}</small>
                  <small className="normal_font">
                    {signer.userId ? (
                      <i
                        className="fas fa-check-circle"
                        style={{ color: '#7ED321' }}
                      />
                    ) : (
                      <i className="fas fa-ban" style={{ color: '#D0021B' }} />
                    )}
                  </small>
                </>
              ))
            ) : (
              <p>No hay firmantes</p>
            )}
          </div>
        </div>
      ) : reportSelected === '2' ? (
        <div className="table_contracts_sp">
          <div className="hader_table_contracts_sp header_table_reports">
            {signers.length ? (
              <>
                <small className="bold">RFC</small>
                <small className="bold">Nombre</small>
                <small className="bold">Email</small>
                <small className="bold">Total Contratos Creados</small>
              </>
            ) : null}
          </div>
          <div className="body_table_contracts_sp spacing_tables">
            {signers.length ? (
              signers.map((signer, index) => (
                <>
                  <small className="normal_font">{signer.legalRfc}</small>
                  <small className="normal_font">{signer.legalName}</small>
                  <small className="normal_font">{signer.secondaryEmail}</small>
                  <small className="normal_font">
                    {signer.Total_Contratos_Creados}
                  </small>
                </>
              ))
            ) : (
              <p>No hay información</p>
            )}
          </div>
        </div>
      ) : reportSelected === '1' ? (
        <div className="table_invited_sp">
          <div className="header_table_invited_sp header_table_reports">
            {signers.length ? (
              <>
                <small className="bold">RFC</small>
                <small className="bold">Nombre</small>
                <small className="bold">Email</small>
                <small className="bold">Total Invitados</small>
              </>
            ) : null}
          </div>
          <div className="body_table_invited_sp spacing_tables">
            {signers.length ? (
              signers.map((signer, index) => (
                <>
                  <small className="normal_font">
                    {signer.originalLegalRfc}
                  </small>
                  <small className="normal_font">{signer.legalName}</small>
                  <small className="normal_font">{signer.email}</small>
                  <small className="normal_font">
                    {signer.Total_Invitados}
                  </small>
                </>
              ))
            ) : (
              <p>No hay información</p>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};
