import { contractsServices } from '../API/services/contractServices';
import state from '../state';
import { NotificationManager } from 'react-notifications';

export const downloadExcel = (data, type) => {
  const csvRows = [];
  const headers = Object.keys(data[0]);
  csvRows.push(headers.join(','));

  for (const row of data) {
    const values = headers.map((header) => {
      const escaped = ('' + row[header]).replace(/"/g, '\\"');
      return `"${escaped}"`;
    });
    csvRows.push(values.join(','));
  }

  const csvRowsNew = csvRows.join('\n');
  getFileInExcelUtils(csvRowsNew, type);
};

const getFileInExcelUtils = (data, type) => {
  const blob = new Blob([data], {
    type: 'text/csv',
  });
  const file = window.URL.createObjectURL(blob);
  const domElement = document.createElement('A');
  domElement.setAttribute('href', file);
  domElement.setAttribute('download', `${type}.csv`);
  document.body.appendChild(domElement);
  domElement.click();
};

export const downloadFileToView = async (doc, docType) => {
  const response = await contractsServices.downloadContractToViewService(
    {
      responseType: 'blob',
      onDownloadProgress: (progressEvent) => {
        const percentage =
          Math.max((progressEvent.loaded * 100) / progressEvent.total) || 0;
        state.select('totalLoading').set(parseInt(percentage.toFixed(0)));
        if (percentage === 100) {
          state.select('totalLoading').set(0);
        }
      },
    },
    { id: `${doc.id}-${docType}` },
  );

  if (response.status >= 500 || response.status === 404) {
    NotificationManager.error(
      'Error al descargar el documento a visualizar contacta a soporte@sora.mx',
      'Atención',
      10000,
    );
    return { success: false };
  }
  const objUrl = URL.createObjectURL(response.data);
  state.select('contractUrl').set(objUrl);
  return { success: true, headers: response.headers };
};
