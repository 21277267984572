export const SignerTranslations = {
  es: {
    texts: {
      h1: (signerLegalName) => `Apoderado - ${signerLegalName}`,
      p1: 'Verificando cuenta ...',
      tab1: 'Documentos',
      tab2: 'Nuevo Documento',
      tab3: 'Usuarios',
      tab4: 'Documentos Masivos',
      genricModalTitle1: 'Información',
      genericModalBody1: (signerLegalName) =>
        `${signerLegalName} te ha concedido permisos para acceder a su información. Por favor verifica tu cuenta con tu e.firma (SAT)`,
      alertModalTitle1: 'Atención',
      alertModalBody1:
        'Por seguridad cerramos tu sesión después de 15 minutos de inactividad.',
    },
    inputs: {},
    buttons: {
      genericModalConfirmButton1: 'Regresar',
      genericModalCancelButton1: 'Aceptar',
    },
    notifications: {},
  },
  en: {
    texts: {
      h1: (signerLegalName) => `Signer - ${signerLegalName}`,
      p1: 'Verifying account ...',
      tab1: 'Contracts',
      tab2: 'New Contract',
      tab3: 'Signers',
      tab4: 'Massive Contracts',
      genricModalTitle1: 'Information',
      genericModalBody1: (signerLegalName) =>
        `${signerLegalName} has granted you access to their information. Please verify your account with your e.firma (SAT)`,
      alertModalTitle1: 'Attention',
      alertModalBody1:
        'For your security we close this session after 15 minutes of inactivity.',
    },
    inputs: {},
    buttons: {
      genericModalConfirmButton1: 'Back',
      genericModalCancelButton1: 'Accept',
    },
    notifications: {},
  },
};
