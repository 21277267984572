export const SelectDateDisplayTranslations = {
  es: {
    texts: {
      label1: 'Vigencia del documento',
    },
    inputs: {
      checkboxDiv1: 'Seleccionar fecha',
      checkboxDiv2: 'Indefinida',
    },
    buttons: {},
    notifications: {},
  },
  en: {
    texts: {
      label1: 'Expires',
    },
    inputs: {
      checkboxDiv1: 'Select date',
      checkboxDiv2: 'Indefinite',
    },
    buttons: {},
    notifications: {},
  },
};
