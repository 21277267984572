export const LanguageSwitcherConstants = {
  es: {
    flag: 'mx',
    text: 'es',
  },
  en: {
    flag: 'us',
    text: 'en',
  },
};
