export const ContractInputsTranslations = {
  es: {
    texts: {},
    inputs: {
      inputLabel1: 'Nombre',
      textarea1: 'Descripción',
      inputLabel2: 'Vigencia',
      inputSpanInactive: 'Indefinida',
      inputSpanActive: 'Seleccionar fecha',
    },
    buttons: {},
  },
  en: {
    texts: {},
    inputs: {
      inputLabel1: 'Title',
      textarea1: 'Description',
      inputLabel2: 'Expires',
      inputSpanInactive: 'Indefinite',
      inputSpanActive: 'Select date',
    },
    buttons: {},
  },
};
