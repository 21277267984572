import { branch } from 'baobab-react/higher-order';
import React from 'react';

class ChooseDocumentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirming: false,
      contracts: 0,
    };
  }

  showConfirmation = () => {
    this.setState({ confirming: true });
  };

  render() {
    const {
      handleCancel,
      loading,
      documentsList,
      signerCheckboxChange,
      signerDocument,
      onChangeSetDocumentsAllSigners,
      modalDocumentType,
      allDocuments,
      onInputDocumentChange,
      onClickRemoveDocumentSigner,
      translations,
      lang,
    } = this.props;

    const { texts, inputs, buttons } =
      translations[lang].Modals.ChooseDocumentModal;

    return (
      <div className="modal-container">
        <div className="modal choose_identity_docs_nc">
          <div className="header_modal header_identity_modal">
            <h3>{texts.h3}</h3>
            <small>{texts.small1}</small>
          </div>
          <div className="body_modal">
            <div className="list_checkbox_docs">
              {documentsList.map((doc, i) =>
                modalDocumentType === 'allSigners' ? (
                  <div
                    key={`switchs_identity_docs ${i}`}
                    className="switchs_identity_docs"
                  >
                    <span className="doc_name_identiy">{doc}</span>
                    <div className="switch_div_identity">
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={Array.from(allDocuments).includes(doc)}
                          onChange={(e) =>
                            onChangeSetDocumentsAllSigners(e, doc)
                          }
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                ) : (
                  <div
                    className="switchs_identity_docs"
                    key={`switchs_identity_docs ${i}`}
                  >
                    <span className="doc_name_identiy">{doc}</span>
                    <div className="switch_div_identity">
                      <label className="switch">
                        <input
                          type="checkbox"
                          name="requestDocument"
                          value=""
                          checked={Array.from(
                            signerDocument.requestDocument,
                          ).includes(doc)}
                          onChange={(e) =>
                            signerCheckboxChange(signerDocument.id, e, doc)
                          }
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                ),
              )}
            </div>
            <div className="input_identity_doc_modal">
              <div className="message_input_doc_modal">
                <p>{texts.p1} </p>
                <small style={{ color: '#6C6C6C', marginLeft: 5 }}>
                  {texts.small2}
                </small>
              </div>
              <input
                id="inputDocument"
                name="inputDocument"
                placeholder={inputs.inputPlaceholder1}
                onChange={(e) => onInputDocumentChange(e)}
                onKeyDown={(e) => onInputDocumentChange(e)}
                type="text"
                autoComplete="off"
                className="input_nc"
              />
            </div>
            <div className="add_document_nc">
              <small className="title_signers">{texts.small3}</small>
              <div className="user_documents">
                {modalDocumentType === 'allSigners'
                  ? allDocuments.map((document, i) =>
                      !documentsList.includes(document) ? (
                        <span
                          key={i}
                          onClick={() =>
                            onClickRemoveDocumentSigner(document, null)
                          }
                        >
                          {document} &#x2a2f;
                        </span>
                      ) : null,
                    )
                  : Array.from(signerDocument.requestDocument).map(
                      (document, i) =>
                        !documentsList.includes(document) ? (
                          <span
                            key={i}
                            onClick={() =>
                              onClickRemoveDocumentSigner(
                                document,
                                signerDocument.id,
                              )
                            }
                          >
                            {document} &#x2a2f;
                          </span>
                        ) : null,
                    )}
              </div>
            </div>
          </div>
          <div className="footer_modal">
            <button
              className="primary"
              type="button"
              onClick={handleCancel}
              disabled={loading}
            >
              {buttons.button1}
            </button>
          </div>
        </div>
        <div className="background-screen" />
      </div>
    );
  }
}

export default branch(
  {
    lang: ['lang'],
    translations: ['translations'],
  },
  ChooseDocumentModal,
);

// export default ChooseDocumentModal;
