export const UploadButtonTranslations = {
  es: {
    texts: {},
    inputs: {},
    buttons: {
      button1: 'Subir Documento',
    },
    notifications: {
      notifTitle1: 'Atención',
      notifBody1: 'Debe seleccionar un tipo de firma',
      notifTitle2: 'Atención',
      notifBody2: (key, indexPlusOne) =>
        `El RFC del ${key} ${indexPlusOne} no es válido debe ser entre 12 y 13 caracteres`,
      notifTitle3: 'Atención',
      notifBody3: (key, indexPlusOne) =>
        `El nombre del ${key} ${indexPlusOne} no es válido`,
      notifTitle4: 'Atención',
      notifBody4: (key, indexPlusOne) =>
        `El email del ${key} ${indexPlusOne} no es válido`,
      notifTitle5: 'Atención',
      notifBody5: (key, indexPlusOne) =>
        `Debe seleccionar un tipo de firma para el ${key} ${indexPlusOne}`,
      notifTitle6: 'Información',
      notifBody6: (typeUser) =>
        `No estás dentro de los ${typeUser}, te copiaremos el documento`,
    },
  },
  en: {
    texts: {},
    inputs: {},
    buttons: {
      button1: 'Upload Document',
    },
    notifications: {
      notifTitle1: 'Warning',
      notifBody1: 'You must select a signature type',
      notifTitle2: 'Warning',
      notifBody2: (typeUser, indexPlusOne) => {
        return `The RFC of the ${userTypesTranslations[typeUser]} ${indexPlusOne} is not valid, it must be between 12 and 13 characters`;
      },
      notifTitle3: 'Warning',
      notifBody3: (typeUser, indexPlusOne) => {
        return `The name of the ${userTypesTranslations[typeUser]} ${indexPlusOne} is not valid`;
      },
      notifTitle4: 'Warning',
      notifBody4: (typeUser, indexPlusOne) => {
        return `The email of the ${userTypesTranslations[typeUser]} ${indexPlusOne} is not valid`;
      },
      notifTitle5: 'Warning',
      notifBody5: (typeUser, indexPlusOne) => {
        return `You must select a signature type for the ${userTypesTranslations[typeUser]} ${indexPlusOne}`;
      },
      notifTitle6: 'Information',
      notifBody6: (typeUser) => {
        return `You are not within the ${userTypesTranslations[typeUser]}, we will copy the document for you`;
      },
    },
  },
};

const userTypesTranslations = {
  autorizador: 'authroizer',
  firmante: 'signer',
  copiado: 'copied recipient',
  autorizadores: 'authroizers',
  firmantes: 'signers',
  copiados: 'copied recipients',
};
