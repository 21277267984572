export const DemoTranslations = {
  es: {
    texts: {
      p1: '1. Ingresa tu correo electrónico para recibir las instrucciones para accesar con un usuario demo y haz click en el botón',
      p2: '2. ¡Revisa tu correo! En unos momentos recibirás un mensaje de soracontratos con los pasos a seguir.',
      h1: '¿Quieres conocer más?',
      h2: 'Sigue estos sencillos pasos para probar la platforma con un usuario demo',
      p3: '¿Ya eres usuario?',
    },
    inputs: {
      inputPlaceholder1: 'Correo electrónico',
    },
    buttons: {
      button1: 'Enviar',
      button2: 'Inicia Sesión',
    },
  },
  en: {
    texts: {
      p1: '1. Enter your email to receive instructions on how to access with a demo user',
      p2: '2. Check your email! You will receive a message from soracontratos with the steps to follow.',
      h1: 'Want to know more?',
      h2: 'Follow these simple steps to try the platform with a demo user',
      p3: 'Are you already a user?',
    },
    inputs: {
      inputPlaceholder1: 'Email',
    },
    buttons: {
      button1: 'Send',
      button2: 'Login',
    },
  },
};
