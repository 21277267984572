import { branch } from 'baobab-react/higher-order';
import React from 'react';
import Dropzone from 'react-dropzone';

class AddendumForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      file: null,
      description: '',
    };
    this.handleForm = this.handleForm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  canSubmit = () => {
    const { file, description } = this.state;
    return file && description.length;
  };

  handleForm = (event) => {
    event.preventDefault();
  };

  onInputChange = (event) => {
    const { name, files, value } = event.target;
    let change;
    if (event.target.type === 'file') {
      change = { [name]: files[0] };
    } else {
      change = { [name]: value };
    }
    this.setState(change);
  };

  handleSubmit() {
    const { handleConfirm } = this.props;
    handleConfirm(this.state);
  }

  handleCancel() {
    const { handleCancel } = this.props;
    handleCancel();
  }

  render() {
    const { loading, file, description, fileInput } = this.state;
    const { contractTitle, lang, translations } = this.props;
    const { texts, inputs, buttons } =
      translations[lang].ContractDetail.AddendumsDisplay.AddendumForm;
    return (
      <div className="modal-container">
        <div className="modal">
          <div className="header_modal">
            <h2 style={{ fontWeight: 500 }}>{texts.h2(contractTitle)}</h2>
          </div>
          <div className="body_modal">
            <div className="page container_add_addendum">
              <form onSubmit={this.handleForm} className="create_addendum_form">
                <label
                  htmlFor="description"
                  className="label_description_addendum"
                >
                  {inputs.textareaLabel1}
                  <textarea
                    id="description"
                    name="description"
                    style={{ marginTop: 10, minHeight: 140 }}
                    onChange={this.onInputChange}
                    value={description}
                    disabled={loading}
                    rows={5}
                  />
                </label>
                <label className="label_dropzone_addendum">
                  {inputs.dropzoneLabel1}
                  <Dropzone
                    onDrop={(acceptedFiles) =>
                      this.setState({ file: acceptedFiles[0] })
                    }
                    accept="application/pdf"
                    multiple={false}
                    ref={fileInput}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <>
                        <button
                          type="button"
                          className="file-drop-zone"
                          style={{
                            background: file ? 'rgba(40, 81, 229, .05)' : null,
                          }}
                          onClick={this.showFileDialog}
                          {...getRootProps()}
                        >
                          {file ? (
                            <i
                              className="fas fa-check-circle fa-2x"
                              style={{ color: '#7ED321' }}
                            />
                          ) : (
                            <i
                              className="fas fa-cloud-upload-alt fa-2x"
                              style={{ color: '#6C6C6C' }}
                            />
                          )}
                          <input
                            name="file"
                            {...getInputProps()}
                            onChange={this.onInputChange}
                            disabled={loading}
                            type="file"
                            accept=".pdf"
                          />
                          {file ? (
                            <>
                              <span>{file.name}</span>
                            </>
                          ) : (
                            <span>{buttons.button1}</span>
                          )}
                        </button>
                      </>
                    )}
                  </Dropzone>
                </label>
              </form>
            </div>
          </div>
          <div className="footer_modal">
            <button
              className="color-grey"
              type="button"
              onClick={this.handleCancel}
            >
              {buttons.button2}
            </button>
            <button
              type="submit"
              className="primary"
              disabled={!this.canSubmit()}
              onClick={this.handleSubmit}
            >
              {buttons.button3}
            </button>
          </div>
        </div>
        <div className="background-screen" />
      </div>
    );
  }
}

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  AddendumForm,
);
