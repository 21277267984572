import React from 'react';
import { branch } from 'baobab-react/higher-order';

class LablesSuperUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {};

  render() {
    const { contract, handleCancel } = this.props;

    return (
      <>
        <div className="modal-container mtm-20">
          <div
            className="modal"
            style={{
              width: 900,
              margin: '22vh auto 0px auto',
            }}
          >
            <h2>Etiquetas del contrato</h2>
            <div className="row" style={{ paddingBottom: 20, paddingLeft: 10 }}>
              {contract?.labels?.length ? (
                <div className="list_labels">
                  {contract.labels.map((label, i) => (
                    <span
                      className="all_labels"
                      style={{ background: label.color }}
                      key={`label-${i}`}
                    >
                      {label.content}
                    </span>
                  ))}
                </div>
              ) : (
                <small>No hay etiquetas</small>
              )}
            </div>

            <div
              className="col-sm-12"
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <button
                type="button"
                className="secondary"
                onClick={handleCancel}
                style={{ color: 'black' }}
              >
                Regresar
              </button>
            </div>
          </div>
          <div className="background-screen" />
        </div>
      </>
    );
  }
}

export default branch(
  {
    session: ['session'],
  },
  LablesSuperUser,
);
