import React from 'react';
import { branch } from 'baobab-react/higher-order';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class CerFields extends React.Component {
  /**
   * Builds the component
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {};
  }

  promptFile = (event) => {
    event.target.blur();
    const { getDataFromInputs } = this.props;
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    const { name } = event.target;
    if (name === 'cer') {
      input.setAttribute('accept', '.cer');
    } else {
      input.setAttribute('accept', '.key');
    }
    input.onchange = (e) => {
      const file = e.target.files[0];
      this.setState({ [name]: file }, () => {
        getDataFromInputs(this.state);
      });
    };
    input.click();
  };

  onInputChange = (event) => {
    const { getDataFromInputs } = this.props;
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      getDataFromInputs(this.state);
    });
  };

  render() {
    const { cer, key, passphrase } = this.state;
    const { translations, lang } = this.props;
    const { texts, inputs } = translations[lang].CommonComponents.CerFields;
    return (
      <div className="inputs_to_sign" style={{ width: '100%' }}>
        <div>
          <input
            onClick={this.promptFile}
            onChange={() => {}}
            name="cer"
            type="text"
            className="input_nc"
            required
            value={cer ? cer.name : ''}
            placeholder={inputs.inputPlaceholder1}
          />
        </div>
        <div>
          <input
            onClick={this.promptFile}
            onChange={() => {}}
            name="key"
            type="text"
            required
            className="input_nc"
            value={key ? key.name : ''}
            placeholder={inputs.inputPlaceholder2}
          />
          <small>{texts.small1}</small>
        </div>
        <div>
          <input
            onChange={this.onInputChange}
            name="passphrase"
            type="password"
            className="input_nc"
            required
            value={passphrase || ''}
            placeholder={inputs.inputPlaceholder3}
          />
        </div>
      </div>
    );
  }
}

export default branch(
  {
    width: ['width'],
    translations: ['translations'],
    lang: ['lang'],
  },
  CerFields,
);
