import React from 'react';
import { branch } from 'baobab-react/higher-order';

class SignerModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  addStylesToInput = () => {
    const { signer, showErrors } = this.props;

    const style = { textTransform: 'uppercase' };

    if (showErrors.legalRfc && signer.signerType === 'rfc') {
      style.border = '1px solid red';
      return style;
    }

    if (signer.signerType === 'other') {
      style.opacity = '0.5';
      style.border = '2px solid #e5e8ef';
      return style;
    }

    return style;
  };

  render() {
    const {
      signerOrAdminInputChange,
      signer,
      handleAddSignersOrAdmins,
      editSignerOrAdminButton,
      editSignerOrAdmin,
      showSignerOrAdminModal,
      onBlurCheckValidity,
      showErrors,
      canAddSignerOrAdmin,
      myLabels,
      filteredLabels,
      removeChip,
      handleChipsKeyDown,
      type,
      onKeyUpGenerateSoraId,
      translations,
      lang,
    } = this.props;
    const { texts, inputs, buttons } = translations[lang].Modals.SignerModal;
    return (
      <div className="modal-container">
        <div className="modal">
          <div className="header_modal">
            <h2>{type === 'signer' ? texts.h2_1 : texts.h2_2}</h2>
            <span className="title_modal_my_signer">{texts.spanTitle1}</span>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: 10,
                }}
              >
                <input
                  style={{ marginRight: 5, height: 40 }}
                  type="radio"
                  id="rfc"
                  name="signerType"
                  value="Si"
                  checked={signer.signerType === 'rfc'}
                  onChange={(e) => signerOrAdminInputChange(e)}
                />
                <label htmlFor="rfc">{inputs.inputLabel1}</label>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: 10,
                }}
              >
                <input
                  style={{ marginRight: 5 }}
                  type="radio"
                  id="other"
                  name="signerType"
                  value="No"
                  checked={signer.signerType === 'other'}
                  onChange={(e) => signerOrAdminInputChange(e)}
                />
                <label htmlFor="other">{inputs.inputLabel2}</label>
              </div>
            </div>
          </div>

          <div className="body_modal" style={{ fontSize: 14 }}>
            <span className="title_modal_my_signer">{texts.spanTitle2}</span>
            <div className="inputs_add_my_signers">
              <div className="input_my_signer">
                <input
                  type="email"
                  name="email"
                  defaultValue={signer.email}
                  onChange={(e) => signerOrAdminInputChange(e)}
                  required
                  placeholder={inputs.inputPlaceholder1}
                  autoComplete="nope"
                  disabled={editSignerOrAdminButton}
                  onBlur={(e) => onBlurCheckValidity(e)}
                  style={{ border: showErrors.email ? '1px solid red' : null }}
                  className="input_nc"
                  onKeyUp={(e) => onKeyUpGenerateSoraId(e)}
                />
                {showErrors.email ? <small>{inputs.inputSmall1}</small> : null}
              </div>
              <div className="input_my_signer">
                <input
                  type="text"
                  name="legalName"
                  defaultValue={signer.legalName}
                  style={{
                    textTransform: 'capitalize',
                    border: showErrors.legalName ? '1px solid red' : null,
                  }}
                  required
                  minLength="10"
                  onChange={(e) => signerOrAdminInputChange(e)}
                  placeholder={inputs.inputPlaceholder2}
                  disabled={editSignerOrAdminButton}
                  onBlur={(e) => onBlurCheckValidity(e)}
                  className="input_nc"
                />
                {showErrors.legalName ? (
                  <small>{inputs.inputSmall2}</small>
                ) : null}
              </div>
              <div className="tooltip top input_my_signer">
                {signer?.signerType === 'other' &&
                  signer.legalRfc.includes('@') && (
                    <span className="tiptext" style={{ padding: 10 }}>
                      {texts.tooltip1}
                    </span>
                  )}

                <input
                  type="text"
                  name="legalRfc"
                  defaultValue={signer.legalRfc.toUpperCase()}
                  style={{ ...this.addStylesToInput() }}
                  required
                  maxLength="13"
                  minLength="12"
                  onChange={(e) => signerOrAdminInputChange(e)}
                  placeholder={
                    signer.signerType === 'rfc'
                      ? inputs.inputPlaceholder3
                      : inputs.inputPlaceholder4
                  }
                  disabled={
                    signer.signerType === 'rfc' && !editSignerOrAdminButton
                      ? false
                      : true
                  }
                  onBlur={(e) =>
                    signer.signerType === 'rfc' ? onBlurCheckValidity(e) : null
                  }
                  className="input_nc"
                />
                {showErrors.legalRfc && signer.signerType === 'rfc' ? (
                  <small>{inputs.inputSmall3}</small>
                ) : null}
              </div>
              <div className="input_my_signer"></div>
            </div>
            <div className="permission_e_firma">
              <span className="title_modal_my_signer">{texts.spanTitle3}</span>
              <div className="permission_signer_modal">
                <div className="description_permission">
                  <p>{texts.p1}</p>
                  <small>{texts.small1}</small>
                </div>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={signer.protectedUser}
                    name="protectedUser"
                    onChange={(e) =>
                      signerOrAdminInputChange(e, {
                        type: 'permission',
                        active: !signer.protectedUser,
                      })
                    }
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>

            <div className="all_permissions_signers">
              <span className="title_modal_my_signer">{texts.spanTitle4}</span>
              <div className="first_group_permission">
                <div className="permission_signer_modal">
                  <div className="description_permission">
                    <p>{texts.p2}</p>
                    <small>{texts.small2}</small>
                  </div>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={signer.canCreateContract}
                      name="canCreateContract"
                      onChange={(e) =>
                        signerOrAdminInputChange(e, {
                          type: 'permission',
                          active: !signer.canCreateContract,
                        })
                      }
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="permission_signer_modal">
                  <div className="description_permission">
                    <p>{texts.p3}</p>
                    <small>{texts.small3}</small>
                  </div>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={signer.canSee}
                      name="canSee"
                      onChange={(e) =>
                        signerOrAdminInputChange(e, {
                          type: 'permission',
                          active: !signer.canSee,
                        })
                      }
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>

              <div className="second_group_permission">
                <div className="permission_signer_modal">
                  <div className="description_permission">
                    <p>{texts.p4}</p>
                    <small>{texts.small4}</small>
                  </div>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={signer.canCreateAdmin}
                      name="canCreateAdmin"
                      onChange={(e) =>
                        signerOrAdminInputChange(e, {
                          type: 'permission',
                          active: !signer.canCreateAdmin,
                        })
                      }
                    />
                    <span className="slider round"></span>
                  </label>
                </div>

                <div className="permission_signer_modal">
                  <div className="description_permission">
                    <p>{texts.p5}</p>
                    <small>{texts.small5}</small>
                  </div>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={signer.canCreateSigner}
                      name="canCreateSigner"
                      onChange={(e) =>
                        signerOrAdminInputChange(e, {
                          type: 'permission',
                          active: !signer.canCreateSigner,
                        })
                      }
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>

              <div className="third_group_permission">
                <div className="label_info">
                  <span className="title_modal_my_signer">
                    {texts.spanTitle5}
                  </span>
                  <br />
                  <small>{texts.small6}</small>
                </div>
                <div className="container_input_labels_my_signer">
                  {/* INPUTS TO SEARCH O CREATE LABELS */}
                  <div className="label_contract">
                    <input
                      // className="input_label_signer_admin"
                      id="chipsInput"
                      placeholder={inputs.inputPlaceholder5}
                      name="chipsInput"
                      onChange={(e) => signerOrAdminInputChange(e)}
                      onKeyDown={(e) => handleChipsKeyDown(e)}
                      className="input_nc"
                    />
                    <span style={{ paddingRight: 5, paddingLeft: 5 }}>ó</span>
                    <div className="content-select-1">
                      <select
                        name="protectedDocs"
                        className="select_labels"
                        onChange={(e) => signerOrAdminInputChange(e)}
                      >
                        <option style={{ fontWeight: 'bold' }}>
                          {inputs.inputSelectDefault1}
                        </option>
                        {myLabels.map((label) => (
                          <option key={label.id} value={label.id}>
                            {label.content}
                          </option>
                        ))}
                      </select>
                      <i
                        className="fas fa-chevron-down"
                        style={{ right: 11, top: 'calc(50% - 3px)' }}
                      ></i>
                    </div>
                  </div>

                  {/* LIST LABELS */}
                  <div className="labels_selected_my_signer">
                    {filteredLabels.length
                      ? filteredLabels.map((chip, i) => (
                          <span
                            key={i}
                            style={{ background: chip.color }}
                            onClick={() => removeChip(chip)}
                          >
                            {chip.content} &#x2a2f;
                          </span>
                        ))
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*FIN---- ESTE ES PARA LOS PERMISOS DEL FIRMANTE */}
          <div className="footer_modal">
            <button
              type="button"
              className="secondary"
              onClick={showSignerOrAdminModal}
              style={{ color: 'black' }}
            >
              {buttons.button1}
            </button>
            {!editSignerOrAdminButton ? (
              <button
                type="submit"
                className="primary"
                onClick={handleAddSignersOrAdmins}
                disabled={!canAddSignerOrAdmin()}
              >
                {buttons.button2}
              </button>
            ) : (
              <button
                type="submit"
                className="primary"
                onClick={editSignerOrAdmin}
              >
                {buttons.button3}
              </button>
            )}
          </div>
        </div>
        <div className="background-screen" />
      </div>
    );
  }
}

export default branch(
  {
    session: ['session'],
    translations: ['translations'],
    lang: ['lang'],
  },
  SignerModal,
);
