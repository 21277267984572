import React, { useState } from 'react';
import EfirmaRegisterForm from '../Register/EfirmaRegisterForm';
import SifeRegisterForm from '../Register/SifeRegisterForm';

export const RegisterForm = () => {
  const [form, setForm] = useState('sife');

  return (
    <>
      <div className="inputs_login_form">
        {form === 'sife' ? (
          <SifeRegisterForm setForm={setForm} />
        ) : (
          <EfirmaRegisterForm setForm={setForm} />
        )}
      </div>
    </>
  );
};
