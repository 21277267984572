export const IdentityDocumentModalTranslations = {
  es: {
    texts: {},
    inputs: {},
    buttons: {
      button1: 'Regresar',
    },
    notifications: {},
  },
  en: {
    texts: {},
    inputs: {},
    buttons: {
      button1: 'Back',
    },
    notifications: {},
  },
};
