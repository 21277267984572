// import Axios from 'axios';
import React, { useState } from 'react';
import SifeForm from '../Login/SifeForm';
import EfirmaForm from '../Login/EfirmaForm.js';

export const LoginForm = () => {
  const [form, setForm] = useState('sife');
  return (
    <>
      {form === 'sife' ? (
        <SifeForm setForm={setForm} />
      ) : (
        <EfirmaForm setForm={setForm} />
      )}
    </>
  );
};
