import { contratosInstance } from '../instance';
import { ProfileRoutes } from '../routes';

export const ProfileEPs = (createEndpoint) => {
  return {
    signersEP: createEndpoint(contratosInstance, ProfileRoutes.signers),
    adminsEP: createEndpoint(contratosInstance, ProfileRoutes.admins),
    paymentsEP: createEndpoint(contratosInstance, ProfileRoutes.payments),
    templatesEP: createEndpoint(contratosInstance, ProfileRoutes.templates),
    couponsEP: createEndpoint(contratosInstance, ProfileRoutes.coupons),
    signUserEP: createEndpoint(contratosInstance, ProfileRoutes.signUser),
    addAdminEP: createEndpoint(contratosInstance, ProfileRoutes.addAdmin),
    addEmailEP: createEndpoint(contratosInstance, ProfileRoutes.addEmail),
    addPaymentEP: createEndpoint(contratosInstance, ProfileRoutes.addPayment),
    signerQueueEP: createEndpoint(contratosInstance, ProfileRoutes.signerQueue),
    adminQueueEP: createEndpoint(contratosInstance, ProfileRoutes.adminQueue),
    removeCardEP: createEndpoint(contratosInstance, ProfileRoutes.removeCard),
    removeAdminEP: createEndpoint(contratosInstance, ProfileRoutes.removeAdmin),
    forceDeleteEP: createEndpoint(contratosInstance, ProfileRoutes.forceDelete),
    addTemplateEP: createEndpoint(contratosInstance, ProfileRoutes.addTemplate),
    addCouponEP: createEndpoint(contratosInstance, ProfileRoutes.addCoupon),
    userInfoEP: createEndpoint(contratosInstance, ProfileRoutes.userInfo),
    getLastSignersEP: createEndpoint(
      contratosInstance,
      ProfileRoutes.getLastSigners,
    ),
    addSignerToProfileEP: createEndpoint(
      contratosInstance,
      ProfileRoutes.addSignerToProfile,
    ),
    getUserProfileEP: createEndpoint(
      contratosInstance,
      ProfileRoutes.getUserProfile,
    ),
    removeSignerEP: createEndpoint(
      contratosInstance,
      ProfileRoutes.removeSigner,
    ),
    chargeContractEP: createEndpoint(
      contratosInstance,
      ProfileRoutes.chargeContract,
    ),
    deleteTemplateEP: createEndpoint(
      contratosInstance,
      ProfileRoutes.deleteTemplate,
    ),
    updateCouponEP: createEndpoint(
      contratosInstance,
      ProfileRoutes.updateCoupon,
    ),
    couponContractEP: createEndpoint(
      contratosInstance,
      ProfileRoutes.couponContract,
    ),
    notifyAPIRequestEP: createEndpoint(
      contratosInstance,
      ProfileRoutes.notifyAPIRequest,
    ),
    updateUserEP: createEndpoint(contratosInstance, ProfileRoutes.updateUser),
    addProfileImageEP: createEndpoint(
      contratosInstance,
      ProfileRoutes.addProfileImage,
    ),
    updateSignerEp: createEndpoint(
      contratosInstance,
      ProfileRoutes.updateSigner,
    ),
    updateNotificationsEP: createEndpoint(
      contratosInstance,
      ProfileRoutes.updateNotifications,
    ),
    downloadContractToViewProfileEP: createEndpoint(
      contratosInstance,
      ProfileRoutes.downloadContractToViewProfile,
    ),
    registerUsersToDownloadContractsEP: createEndpoint(
      contratosInstance,
      ProfileRoutes.registerUsersToDownloadContracts,
    ),
    getMyUsersEP: createEndpoint(contratosInstance, ProfileRoutes.getMyUsers),
    createContractToAuthorizeDownloadEP: createEndpoint(
      contratosInstance,
      ProfileRoutes.createContractToAuthorizeDownload,
    ),
    sendResponseToAuthorizeEP: createEndpoint(
      contratosInstance,
      ProfileRoutes.sendResponseToAuthorize,
    ),
    updateUserDataEP: createEndpoint(
      contratosInstance,
      ProfileRoutes.updateUserData,
    ),
  };
};
