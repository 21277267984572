import { branch } from 'baobab-react/higher-order';

const ChipsDisplayMassive = ({
  chips,
  generateShortId,
  removeChip,
  Autocomplete,
  loading,
  myLabels,
  error,
  chipsInput,
  onInputChange,
  handleChipsKeyDown,
  addChip,
  autocompleteInput,
  translations,
  lang,
}) => {
  const { texts, inputs } =
    translations[lang].NewContractMassive.ChipsDisplayMassive;
  return (
    <>
      <div className="labels_massive">
        {chips.length <= 9 ? (
          <label className="label_nc labels_nc_massive" htmlFor="chipsInput">
            {' '}
            {inputs.inputLabel1} <i> {inputs.inputLabelItalic1} </i>
            <div className="labels_flex_massive">
              <Autocomplete
                inputProps={{
                  id: 'chips-input',
                  className: `input_nc autocomplete_labels`,
                  placeholder: inputs.inputPlaceholder1,
                  name: 'autocompleteInput',
                }}
                shouldItemRender={(item, value) =>
                  item.content.toLowerCase().indexOf(value.toLowerCase()) > -1
                }
                getItemValue={(item) => item.content}
                items={myLabels}
                renderItem={(item, highlighted) => (
                  <div
                    key={generateShortId()}
                    style={{
                      padding: 5,
                      backgroundColor: highlighted ? '#F5F6F9' : '#FFFFFF',
                      color: highlighted ? '#2851E5' : '#000',
                    }}
                  >
                    {item.content}
                  </div>
                )}
                onChange={(e) => onInputChange(e)}
                onSelect={(value, item) =>
                  chips.find((element) => element.content === value)
                    ? false
                    : addChip({
                        content: item.content,
                        color: item.color,
                        id: item.id,
                      })
                }
                value={autocompleteInput}
                menuStyle={{
                  borderRadius: '3px',
                  boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                  background: 'rgba(255, 255, 255, 0.9)',
                  padding: '2px 0',
                  fontSize: '90%',
                  position: 'fixed',
                  overflow: 'scroll',
                  maxHeight: '10%',
                  zIndex: '2',
                  maxWidth: '10%',
                }}
              />
              <span className="mt-5" style={{ padding: 10 }}>
                {texts.span1}
              </span>
              <input
                className="input_nc"
                id="chipsInput"
                placeholder={inputs.inputPlaceholder2}
                name="chipsInput"
                value={chipsInput}
                disabled={loading}
                onChange={onInputChange}
                onKeyDown={handleChipsKeyDown}
              />
            </div>
          </label>
        ) : (
          <label>{texts.label1}</label>
        )}
      </div>
      <div className="list_labels_massive">
        {chips.length
          ? chips.map((chip, index) => (
              <span
                key={index}
                className="all_labels"
                style={{ background: chip.color }}
                onClick={() => removeChip(chip)}
              >
                {chip.content} &#x2a2f;
              </span>
            ))
          : false}
      </div>
    </>
  );
};

export default branch(
  { translations: ['translations'], lang: ['lang'] },
  ChipsDisplayMassive,
);
