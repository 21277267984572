export const PublicContractDetailTranslations = {
  es: {
    texts: {
      notifTitle1: 'Éxito',
      notifBody1: 'Firmantes actualizados con exito',
      link1: 'Regresar a Mis documentos',
      link2: 'Regresar',
    },
    inputs: {},
    buttons: {
      button1: 'Actualizar Firmante',
      button2: 'Ver documento',
    },
    notifications: {},
  },
  en: {
    texts: {
      notifTitle1: 'Success',
      notifBody1: 'Signers updated successfully',
      link1: 'Back to My documents',
      link2: 'Back',
    },
    inputs: {},
    buttons: {
      button1: 'Update Signer',
      button2: 'View document',
    },
    notifications: {},
  },
};
