import React from 'react';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import { branch } from 'baobab-react/higher-order';
import { NotificationManager } from 'react-notifications';
// Actions
import SuperUserActions from '../../actions/SuperUser';
import { downloadExcel } from '../../utils/downloadFilesUtils';
import { TableReportsDisplay } from './TableReportsDisplay';

class InfoSigners extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signers: [],
      dateStart: '',
      dateEnd: '',
      allSigners: [],
      loading: false,
      reportValue: '',
      reportSelected: '',
    };
  }

  onInputChange = (event) => {
    const { name, files, value } = event.target;
    let change;
    if (event.target.type === 'file') {
      change = { [name]: files[0] };
    } else if (name === 'ttl') {
      change = { [name]: parseInt(value, 10) };
    } else {
      change = { [name]: value };
    }
    this.setState(change);
  };

  onClickSearchSigners = async () => {
    const { dateStart, dateEnd, reportValue } = this.state;
    this.setState({ loading: true });
    if (dateStart > dateEnd) {
      this.setState({ loading: false });
      return NotificationManager.warning(
        'La fecha de inicio no puede ser mayor a la final',
        'Atención',
        5000,
      );
    }

    try {
      const { data } = await SuperUserActions.getSinersByDate(
        `${dateStart}_${dateEnd}`,
        reportValue,
      );
      if (!data.info.length) {
        this.setState({ loading: false });
        return NotificationManager.warning(
          'No se encontraron resultados para tu busqueda',
          'Atención',
          5000,
        );
      }
      this.setState({
        allSigners: data.info,
        reportSelected: data.reportSelected,
      });
      let hash = {};
      let signers = data.info.filter((signer) =>
        hash[signer.legalRfc] ? false : (hash[signer.legalRfc] = true),
      );
      this.setState({
        signers: data.reportSelected !== '3' ? data.info : signers,
        loading: false,
      });
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
      return NotificationManager.error(
        'Hubo un error al obtener información',
        'Atención',
        5000,
      );
    }
  };

  reportInputChange = (e) => {
    const { value } = e.target;
    this.setState({ reportValue: value });
  };

  downloadExcelState = () => {
    const { signers, reportSelected } = this.state;
    if (reportSelected === '3') {
      const allSigners = signers.map((signer) => {
        return {
          RFC: signer.legalRfc,
          Nombre: signer.legalName,
          Email: signer.secondaryEmail || signer.email,
          Fecha_Creacion: moment(signer.createdAt).format('DD MMM YYYY'),
          Nombre_Quien_Invito: signer.legalNameMain,
          RFC_Quien_Invito: signer.legalRfcMain,
          Registrado: signer.userId ? 'SI' : 'NO',
        };
      });
      return downloadExcel(allSigners, 'reporte de firmantes');
    }
    const name =
      reportSelected === '1'
        ? 'reporte_usaurios_SIFE'
        : 'reporte_contratos_por_usuario';
    downloadExcel(signers, name);
  };

  render() {
    const { session, location } = this.props;
    const {
      dateStart,
      dateEnd,
      signers,
      allSigners,
      loading,
      reportValue,
      reportSelected,
    } = this.state;
    if (!session) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: {
              from: location.pathname,
            },
          }}
        />
      );
    }
    return (
      <div className="container_info_signers">
        <div className="inputs_to_search_sp">
          <div className="form_control">
            <p>Tipo de reporte</p>
            <select
              name="signType"
              onChange={(e) => this.reportInputChange(e)}
              autoComplete="nope"
              value={reportValue}
              className="input_nc"
            >
              <option value="">-- Elegir Reporte --</option>
              <option value="1">Reporte SIFE</option>
              <option value="2">Reporte contratos</option>
              <option value="3">Reporte firmantes</option>
            </select>
          </div>
          <div className="form_control">
            <p>Fecha de inicio</p>
            <input
              className="input_nc"
              id="dateStart"
              name="dateStart"
              onChange={this.onInputChange}
              value={dateStart}
              type="date"
            />
          </div>

          <div className="form_control">
            <p>Fecha de fin</p>
            <input
              className="input_nc"
              id="dateEnd"
              name="dateEnd"
              onChange={this.onInputChange}
              value={dateEnd}
              type="date"
            />
          </div>
        </div>
        <button className="primary" onClick={this.onClickSearchSigners}>
          Buscar
        </button>
        <div className="display_data">
          <p className="align-center">
            {' '}
            {allSigners.length} Firmantes totales{' '}
          </p>

          <TableReportsDisplay
            loading={loading}
            signers={signers}
            reportSelected={reportSelected}
          />

          {signers.length ? (
            <button
              type="submit"
              className="primary"
              onClick={() => this.downloadExcelState()}
            >
              Descargar Excel
            </button>
          ) : null}
        </div>
      </div>
    );
  }
}

export default branch(
  {
    session: ['session'],
    myApiUsers: ['myApiUsers'],
  },
  InfoSigners,
);
