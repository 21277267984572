export const CerFieldsTranslations = {
  es: {
    texts: {
      small1: '*No guardamos ninguna clave de la firma electrónica en Sora',
    },
    inputs: {
      inputPlaceholder1: 'Certificado (.cer)',
      inputPlaceholder2: 'Llave (.key)',
      inputPlaceholder3: 'Clave Privada',
    },
    buttons: {},
    notifications: {},
  },
  en: {
    texts: {
      small1: '*We do not store any electronic signature key in Sora',
    },
    inputs: {
      inputPlaceholder1: 'Certificate (.cer)',
      inputPlaceholder2: 'Key (.key)',
      inputPlaceholder3: 'Private code (password)',
    },
    buttons: {},
    notifications: {},
  },
};
