import React from 'react';
import { branch } from 'baobab-react/higher-order';
import { NotificationManager } from 'react-notifications';
import { emailRegex } from '../../../utils/constants';
import SuperUserActions from '../../../actions/SuperUser';
import Loader from '../../../components/Loader';

class AddRecipientSuperUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recipient: {
        legalName: '',
        legalRfc: '',
        email: '',
      },
      loading: false,
    };
  }

  componentDidMount = () => {};

  handleChange = (event) => {
    const { name, value } = event.target;
    const { recipient } = this.state;
    if (name === 'legalRfc') {
      this.setState({
        recipient: {
          ...recipient,
          [name]: value.replace(/ /g, '').toUpperCase(),
        },
      });
      return;
    }

    if (name === 'email') {
      this.setState({
        recipient: {
          ...recipient,
          [name]: value.replace(/ /g, '').toLowerCase(),
        },
      });
      return;
    }

    return this.setState({ recipient: { ...recipient, [name]: value } });
  };

  handleSubmit = async () => {
    const { recipient } = this.state;
    const { contract, searchContractsById, handleCancel } = this.props;
    if (!contract) {
      return NotificationManager.error('El contrato no existe', 'Error', 5000);
    }
    if (!recipient.legalName || !recipient.legalRfc || !recipient.email) {
      return NotificationManager.error(
        'Debes completar todos los campos.',
        'Error',
        5000,
      );
    }
    if (recipient.legalRfc < 12 || recipient.legalRfc > 13) {
      return NotificationManager.error('El rfc no es válido', 'Error', 5000);
    }

    if (!emailRegex.test(recipient.email)) {
      return NotificationManager.error('El email no es válido', 'Error', 5000);
    }
    this.setState({ loading: true });

    try {
      const response = await SuperUserActions.addCopiedUserSuperAdmin({
        contractId: contract.id,
        ...recipient,
      });
      if (!response?.data?.success) {
        this.setState({ loading: false });
        return NotificationManager.error(
          response?.data?.message || 'Ocurrió un error al agregar copiado',
          'Error',
          5000,
        );
      }

      NotificationManager.success(
        response?.data?.message || 'Copiado agregado correctamente',
        'Éxito',
        5000,
      );
      searchContractsById();
      this.setState({ loading: false });
      handleCancel();
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
      return NotificationManager.error(
        'Ocurrió un error al agregar copiado',
        'Error',
        5000,
      );
    }
  };

  render() {
    const { handleCancel } = this.props;
    const { recipient, loading } = this.state;

    return (
      <>
        <div className="modal-container mtm-20">
          <div className="modal" style={{ width: 600 }}>
            <h2>Agregar copiado</h2>
            <div className="row" style={{ paddingBottom: 20, paddingLeft: 10 }}>
              {loading ? (
                <div className="center_loading" style={{ width: '100%' }}>
                  <Loader />
                  <p>Agregando usuario...</p>
                </div>
              ) : (
                <>
                  <input
                    type="text"
                    name="legalRfc"
                    className="input_nc"
                    required
                    maxLength="13"
                    minLength="12"
                    onChange={this.handleChange}
                    placeholder="Ingresa RFC"
                    value={recipient.legalRfc}
                  />
                  <input
                    type="text"
                    name="email"
                    className="input_nc"
                    required
                    onChange={this.handleChange}
                    placeholder="Ingresa correo"
                    value={recipient.email}
                  />
                  <input
                    type="text"
                    name="legalName"
                    className="input_nc"
                    required
                    onChange={this.handleChange}
                    placeholder="Ingresa nombre completo"
                    value={recipient.legalName}
                  />
                </>
              )}
            </div>

            <div
              className="col-sm-12"
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <button
                type="button"
                className="secondary"
                onClick={handleCancel}
                style={{ color: 'black' }}
              >
                Regresar
              </button>
              <button
                type="button"
                className="primary"
                onClick={this.handleSubmit}
                disabled={loading}

                // style={{ color: 'black' }}
              >
                Agregar
              </button>
            </div>
          </div>
          <div className="background-screen" />
        </div>
      </>
    );
  }
}

export default branch(
  {
    session: ['session'],
  },
  AddRecipientSuperUser,
);
