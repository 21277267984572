import React from 'react';
import { branch } from 'baobab-react/higher-order';
import { Redirect } from 'react-router-dom';
import SearchContractAdmin from './ContractsSu/SearchContractAdmin';
import SearchContractsUser from './SearchContractsUser';
import SearchSignersAdmins from './SearchSignersAdmins';
class SearchContracts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTypeForm: 'contracts',
    };
  }

  onClickSelectForm = (form) => {
    this.setState({ showTypeForm: form });
  };

  render() {
    const { session, location } = this.props;
    const { showTypeForm } = this.state;
    if (!session) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: {
              from: location.pathname,
            },
          }}
        />
      );
    }

    return (
      <div className="container_search_sp">
        <p className="align_center">Selecciona tu criterio de busqueda</p>
        <div className="menu_container">
          <div
            className={`sp_admin_search ${
              showTypeForm === 'contracts' ? 'active_menu_sp' : ''
            }`}
            onClick={() => this.onClickSelectForm('contracts')}
          >
            <small>Contratos</small>
          </div>
          <div
            className={`sp_admin_search ${
              showTypeForm === 'users' ? 'active_menu_sp' : ''
            }`}
            onClick={() => this.onClickSelectForm('users')}
          >
            <small>Usuarios</small>
          </div>
          <div
            className={`sp_admin_search ${
              showTypeForm === 'signers' ? 'active_menu_sp' : ''
            }`}
            onClick={() => this.onClickSelectForm('signers')}
          >
            <small>Apoderados</small>
          </div>
          <div
            className={`sp_admin_search ${
              showTypeForm === 'admins' ? 'active_menu_sp' : ''
            }`}
            onClick={() => this.onClickSelectForm('admins')}
          >
            <small>Admins</small>
          </div>
        </div>
        <div>
          {showTypeForm === 'contracts' ? (
            <SearchContractAdmin />
          ) : showTypeForm === 'users' ? (
            <SearchContractsUser />
          ) : showTypeForm === 'signers' ? (
            <SearchSignersAdmins userType="signers" />
          ) : (
            <SearchSignersAdmins userType="admins" />
          )}
        </div>
      </div>
    );
  }
}

export default branch(
  {
    session: ['session'],
  },
  SearchContracts,
);
