import { contratosInstance } from '../instance';
import { LabelRoutes } from '../routes';

export const LabelsEPs = (createEndpoint) => {
  return {
    createLabelEP: createEndpoint(contratosInstance, LabelRoutes.createLabel),
    assignEP: createEndpoint(contratosInstance, LabelRoutes.assign),
    listLabelEP: createEndpoint(contratosInstance, LabelRoutes.listLabel),
    listRootEP: createEndpoint(contratosInstance, LabelRoutes.listRoot),
    myLabelsEP: createEndpoint(contratosInstance, LabelRoutes.myLabels),
    rootLabelsEP: createEndpoint(contratosInstance, LabelRoutes.rootLabels),
    removeContractLabelsEP: createEndpoint(
      contratosInstance,
      LabelRoutes.removeContractLabels,
    ),
  };
};
