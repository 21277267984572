import { branch } from 'baobab-react/higher-order';
import { NotificationManager } from 'react-notifications';
import React from 'react';
import Autocomplete from 'react-autocomplete';
import Checkbox from 'react-simple-checkbox';
import Dropzone from 'react-dropzone';
// actions
import ContractActions from '../actions/Contract';
import LabelActions from '../actions/LabelActions';
// Utils
import generateShortId from '../utils/makeId';
import Loader from '../components/Loader';

import GenericConfirmModal from '../components/Modals/GenericConfirmModal';
import UploadFilesDisplay from './NewContractMassive/UploadFilesDisplay';
import SelectDateDisplay from './NewContractMassive/SelectDateDisplay';
import AddSignerDisplay from './NewContractMassive/AddSignersDisplay';
import ErrorsUploadFile from './NewContractMassive/ErrorsUploadFile';
import { emailRegex } from '../utils/constants';
import {
  convertSeconds,
  limitFilesInMBUtils,
  verifyUploadContractsUtils,
} from '../utils/newContractMassiveUtils';
import ChipsDisplayMassive from './NewContractMassive/ChipsDisplayMassive';

class NewContractMassive extends React.Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
    this.state = {
      title: '',
      description: '',
      ttl: 40,
      signers: [],
      chips: [],
      chipsInput: '',
      loading: false,
      error: false,
      myLabels: [],
      date: '',
      checkDate: false,
      showTableSigners: true,
      showTableErrors: true,
      orderedSigners: true,
      allUploadFiles: [],
      errors: [],
      showConfirmModal: false,
      success: false,
      responses: [],
      responsesSigners: [],
      startUpload: false,
      aproximmateTime: null,
      totalMB: 0,
      pendingFiles: [],
      showModalPendingFiles: false,
      labelsMassive: [],
      signatureType: '2',
    };
  }

  async componentDidMount() {
    const { signers } = this.state;
    const {
      session: { user },
      signerOrAdmin,
    } = this.props;
    const { coupon } = user;
    const id = signerOrAdmin ? signerOrAdmin.rootUser : user.id;
    this.setState({ coupon });
    LabelActions.myLabels(id).then((myLabels) => this.setState({ myLabels }));
    const defaultSigner = {
      legalName: signerOrAdmin ? signerOrAdmin.legalName : user.legalName,
      legalRfc: signerOrAdmin ? signerOrAdmin.legalRfc : user.legalRfc,
      email: signerOrAdmin
        ? signerOrAdmin.secondaryEmail || signerOrAdmin.email
        : user.secondaryEmail || user.email,
      sendEmail: false,
    };

    this.setState({
      signers: [defaultSigner],
    });
    this.checkValidity(signers, 'FIRMANTE');
  }

  onInputChange = (event) => {
    const { name, files, value } = event.target;
    let change;
    if (event.target.type === 'file') {
      change = { [name]: files[0] };
    } else if (name === 'ttl') {
      change = { [name]: parseInt(value, 10) };
    } else {
      change = { [name]: value };
    }
    this.setState(change);
  };

  handleChangeTypeSign = (e) => {
    const { value } = e.target;
    this.setState({ signatureType: value });
  };

  handleChangeOrderSigners = () => {
    const { orderedSigners } = this.state;
    this.setState({ orderedSigners: !orderedSigners });
  };

  signerInputChange = (signerId, event) => {
    const { name, value } = event.target;
    const { signers } = this.state;
    const { session, translations, lang } = this.props;
    const { notifications } = translations[lang].NewContractMassive;
    const array = [...signers];
    array[signerId][name] = value;
    if (name === 'legalRfc') {
      const myDuplicates = signers.filter(
        (signer) => signer.legalRfc.search(session.user.legalRfc) !== -1,
      );
      if (myDuplicates.length > 1) {
        NotificationManager.warning(
          notifications.notifBody1,
          notifications.notifTitle1,
          3000,
        );
      }
    }
  };

  canUpload = () => {
    const { title, ttl, allUploadFiles, success } = this.state;

    return title.length && ttl > 0 && success && allUploadFiles.length > 0;
  };

  hideConfirmModal = () => {
    this.setState({ showConfirmModal: false });
  };

  handleForm = (event) => {
    event.preventDefault();
  };

  checkValidity = (group, typeOfPerson) => {
    const { session, translations, lang } = this.props;
    const { notifications } = translations[lang].NewContractMassive;
    // eslint-disable-next-line array-callback-return
    let localFlag = true;
    // ESTE IF VALIDA SI EL USUARIO ES SIFE O NO A TRAVES DE EMAIL Y RFC
    if (session.user.typeUser === 'sife') {
      localFlag = true;
    } else {
      group.forEach((person, i) => {
        if (person.legalRfc.length !== 13 && person.legalRfc.length !== 12) {
          NotificationManager.warning(
            notifications.notifBody2(typeOfPerson, i + 1),
            notifications.notifTitle2,
            5000,
          );
          localFlag = false;
        }
        if (person.legalName.length < 2) {
          NotificationManager.warning(
            notifications.notifBody3(typeOfPerson, i + 1),
            notifications.notifTitle3,
            5000,
          );
          localFlag = false;
        }
        if (person.email.match(emailRegex) === null) {
          NotificationManager.warning(
            notifications.notifBody4(typeOfPerson, i + 1),
            notifications.notifTitle4,
            5000,
          );
          localFlag = false;
        }
      });
    }

    return localFlag;
  };

  handleSubmit = async () => {
    this.setState({ showConfirmModal: false, showModalPendingFiles: false });
    this.setState({ loading: true });
    this.setState({ startUpload: true });
    const { signerOrAdmin, session } = this.props;
    const rootUser = signerOrAdmin ? signerOrAdmin.rootUser : null;
    const userAction = signerOrAdmin || session.user;
    const { allUploadFiles } = this.state;
    const {
      title,
      description,
      orderedSigners,
      labelsMassive,
      date,
      signers,
      totalMB,
      pendingFiles,
      signatureType,
    } = this.state;
    const aproximmateTime = convertSeconds(totalMB);
    this.setState({ aproximmateTime });
    const userFound = signers?.find(
      (signer) => signer?.legalRfc === userAction?.legalRfc,
    );

    const data = {
      title,
      description,
      orderedSigners,
      date,
      signatureType,
      rootUser,
      signers,
      userFound: userFound || 'NO_USER',
      userAction,
      labels: labelsMassive?.length
        ? labelsMassive.map((label) => label.id)
        : [],
    };
    const formData = new FormData();
    formData.append('contractInfo', JSON.stringify(data));
    for (let i = 0; i < allUploadFiles.length; i++) {
      formData.append('files', allUploadFiles[i]);
    }
    const responseUpload = await ContractActions.uploadContractsMassive(
      formData,
    );
    this.setState({ loading: false, aproximmateTime: null });
    if (!responseUpload?.data?.success) {
      // TODO: translate response
      return NotificationManager.error(
        responseUpload.data.message,
        'Error',
        5000,
      );
    }
    // TODO: translate response
    NotificationManager.success(responseUpload.data.message, 'Éxito', 5000);
    if (pendingFiles.length) {
      this.setState({ showModalPendingFiles: true });
      return this.handleSetFiles(pendingFiles);
    }
  };

  handleChipsChange = (e) => {
    this.setState({ chipsInput: e.target.value });
  };

  handleChipsKeyDown = async (e) => {
    const { myLabels, chips, labelsMassive } = this.state;
    const { signerOrAdmin, translations, lang } = this.props;
    const { notifications } = translations[lang].NewContractMassive;

    if (e.keyCode === 13) {
      const { value } = e.target;
      const chip = {};
      this.setState({ error: false });
      if (
        !myLabels.find((element) => element.content === value) &&
        !chips.find((element) => element.content === value)
      ) {
        chip.content = value;
        // eslint-disable-next-line no-bitwise
        chip.color = `#${(((1 << 24) * Math.random()) | 0).toString(16)}`;
        chip.rootUser = signerOrAdmin ? signerOrAdmin.rootUser : null;
        const label = await LabelActions.create(chip);
        this.setState({ labelsMassive: [...labelsMassive, label] });
        this.setState({
          chips: [...chips, chip],
          chipsInput: '',
        });
      } else {
        this.setState({ error: true });
        NotificationManager.warning(
          notifications.notifBody7(value),
          notifications.notifTitle7,
          3000,
        );
      }
    }
  };

  addChip = (chip) => {
    const { chips } = this.state;
    const newChip = {
      content: chip.content,
      color: chip.color,
      id: chip.id,
    };
    this.setState({ chips: [...chips, newChip] });
    this.setState({ labelsMassive: [...chips, newChip] });
  };

  addSigner = () => {
    const { signers } = this.state;
    const newSigner = {
      legalRfc: '',
      legalName: '',
      email: '',
      sendEmail: false,
    };
    this.setState({ signers: [...signers, newSigner] });
  };

  addMe = () => {
    const { signers } = this.state;
    const {
      session: { user },
      signerOrAdmin,
      lang,
      translations,
    } = this.props;
    const { notifications } = translations[lang].NewContractMassive;
    if (!signers.find((found) => found.legalRfc === user.legalRfc)) {
      const newSigner = {
        legalName: signerOrAdmin ? signerOrAdmin.legalName : user.legalName,
        legalRfc: signerOrAdmin ? signerOrAdmin.legalRfc : user.legalRfc,
        email: signerOrAdmin
          ? signerOrAdmin.secondaryEmail || signerOrAdmin.email
          : user.secondaryEmail || user.email,
        sendEmail: false,
      };
      this.setState({ signers: [...signers, newSigner] });
    } else {
      NotificationManager.warning(
        notifications.notifBody8,
        notifications.notifTitle8,
        3000,
      );
    }
  };

  removeSigner = (e) => {
    const { signers } = this.state;
    const array = [...signers];
    const filteredArray = array.filter((signer) => signer !== e);
    this.setState({ signers: filteredArray });
  };

  removeChip = (e) => {
    const { chips } = this.state;
    const array = [...chips];
    const filteredArray = array.filter((chip) => chip !== e);
    this.setState({ chips: filteredArray, labelsMassive: filteredArray });
  };

  handleCheckDate = () => {
    const { checkDate } = this.state;
    this.setState({ checkDate: !checkDate });
    if (checkDate) {
      this.setState({ date: '0000-00-00 00:00:00' });
    }
  };

  addLastSigners = (signer) => {
    const { signers } = this.state;
    this.setState({ signers: [...signers, signer] });
  };

  handleTableSigners = () => {
    const { showTableSigners } = this.state;
    this.setState({ showTableSigners: !showTableSigners });
  };

  handleTableErrors = () => {
    const { showTableErrors } = this.state;
    this.setState({ showTableErrors: !showTableErrors });
  };

  handleSetFiles = async (acceptedFiles) => {
    const { translations, lang } = this.props;
    const { notifications } = translations[lang].NewContractMassive;
    if (acceptedFiles?.length === 1) {
      return NotificationManager.warning(
        notifications.notifBody9,
        notifications.notifTitle9,
        5000,
      );
    }
    this.setState({ errors: [] });
    this.setState({ success: true });
    const {
      docError,
      rfcError,
      emailError,
      pendingFiles,
      limitedFiles,
      total,
    } = verifyUploadContractsUtils(acceptedFiles);

    if (docError.length > 0 || emailError.length > 0 || rfcError.length > 0) {
      this.setState({ errors: [...docError, ...emailError, ...rfcError] });
      return;
    }

    this.setState({ pendingFiles });
    const { totalMB } = limitFilesInMBUtils(total);
    this.setState({ totalMB });
    this.setState({ allUploadFiles: limitedFiles.map((file) => file) });
  };

  render() {
    const {
      title,
      signers,
      chips,
      myLabels,
      error,
      chipsInput,
      loading,
      date,
      checkDate,
      showTableSigners,
      allUploadFiles,
      errors,
      showTableErrors,
      showConfirmModal,
      startUpload,
      aproximmateTime,
      showModalPendingFiles,
      orderedSigners,
    } = this.state;

    const { session, signerOrAdmin, translations, lang } = this.props;
    const { texts, inputs, buttons } = translations[lang].NewContractMassive;
    return (
      <div
        className={`new_contract_massive_view ${
          signerOrAdmin ? 'admin_view' : ''
        }`}
      >
        {!signerOrAdmin ? (
          <div className="title_massive">
            <h1>{texts.h1}</h1>
          </div>
        ) : null}
        <div className="contract_info_massive page padded shadow_new_contract">
          <UploadFilesDisplay
            Dropzone={Dropzone}
            handleSetFiles={this.handleSetFiles}
            allUploadFiles={allUploadFiles}
          />
          <label htmlFor="title" className="label_nc title_contract_massive">
            {inputs.inputLabel1}
            <span style={{ fontSize: 12 }}> {inputs.inputLabelSpan1}</span>
            <input
              id="title"
              name="title"
              onChange={this.onInputChange}
              value={title}
              disabled={loading}
              type="text"
              className="input_nc"
            />
          </label>
          <ChipsDisplayMassive
            chips={chips}
            generateShortId={generateShortId}
            removeChip={this.removeChip}
            Autocomplete={Autocomplete}
            loading={loading}
            myLabels={myLabels}
            error={error}
            chipsInput={chipsInput}
            onInputChange={this.onInputChange}
            handleChipsKeyDown={this.handleChipsKeyDown}
            addChip={this.addChip}
          />
          <SelectDateDisplay
            Checkbox={Checkbox}
            checkDate={checkDate}
            handleCheckDate={this.handleCheckDate}
            onInputChange={this.onInputChange}
            loading={loading}
            date={date}
          />
        </div>

        <div className="signers_massive page padded shadow_new_contract">
          <AddSignerDisplay
            handleTableSigners={this.handleTableSigners}
            showTableSigners={showTableSigners}
            signers={signers}
            generateShortId={generateShortId}
            signerInputChange={this.signerInputChange}
            removeSigner={this.removeSigner}
            loading={loading}
            addSigner={this.addSigner}
            addMe={this.addMe}
            session={session}
            handleChangeTypeSign={this.handleChangeTypeSign}
            handleChangeOrderSigners={this.handleChangeOrderSigners}
            orderedSigners={orderedSigners}
          />
        </div>
        <div className="errors_massive page padded shadow_new_contract">
          <ErrorsUploadFile
            errors={errors}
            showTableErrors={showTableErrors}
            handleTableErrors={this.handleTableErrors}
            generateShortId={generateShortId}
            loading={loading}
          />
        </div>

        <div className="info_to_upload_massive ">
          <div className="time_to_upload">
            {aproximmateTime ? <p>{texts.p1(aproximmateTime)}</p> : null}
            {startUpload ? <p>{texts.p2}</p> : null}
          </div>
          {loading ? (
            <div className="center_loading">
              <Loader />
            </div>
          ) : (
            <button
              type="button"
              className="primary"
              onClick={() => this.setState({ showConfirmModal: true })}
              disabled={!this.canUpload()}
            >
              {buttons.button1}
            </button>
          )}
        </div>

        {showConfirmModal ? (
          <GenericConfirmModal
            title={texts.genericModalTitle1}
            message={texts.genericModalBody1(allUploadFiles.length)}
            okButton={buttons.genericModalConfirmButton1}
            cancelButton={buttons.genericModalCancelButton1}
            cancelAction={() => this.hideConfirmModal()}
            acceptAction={() => this.handleSubmit()}
          />
        ) : null}

        {showModalPendingFiles ? (
          <GenericConfirmModal
            title={texts.genericModalTitle2}
            message={texts.genericModalBody2(allUploadFiles.length)}
            okButton={buttons.genericModalConfirmButton2}
            cancelButton={buttons.genericModalCancelButton2}
            cancelAction={() => this.hideConfirmModal()}
            acceptAction={() => this.handleSubmit()}
          />
        ) : null}
      </div>
    );
  }
}

export default branch(
  {
    session: ['session'],
    myCoupons: ['myCoupons'],
    translations: ['translations'],
    lang: ['lang'],
  },
  NewContractMassive,
);
