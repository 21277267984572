import {
  activateService,
  loginService,
  loginSifeService,
  registerService,
  registerSifeService,
  requestDemoService,
  sendApiFormService,
} from '../API/services/sessionServices';
import state from '../state';

const SessionActions = {
  /**
   * Sets Session
   * @param session
   * @param token
   */
  genericSessionResponse(session, token) {
    localStorage.session = JSON.stringify(session);
    localStorage.token = token;
    state.select(['session']).set(session);
    state.commit();
  },
  /**
   * Destroys Session
   */
  genericSessionDestroy() {
    delete localStorage.session;
    delete localStorage.token;
    localStorage.removeItem('token_sife');
    localStorage.removeItem('user_sife');
    localStorage.removeItem('location_url');
    localStorage.removeItem('currentPage');
    window.location = '/login';
  },
  /**
   * Handle a user signup
   * @return {Promise}
   * @param cer
   * @param email
   */
  async register(cer, email, lang, privacyPolicyAccepted) {
    const formData = new FormData();
    formData.append('cer', cer);
    formData.append('email', email);
    formData.append('lang', lang);
    formData.append('privacyPolicyAccepted', privacyPolicyAccepted);
    const { data } = await registerService(formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return data;
    // return sessionAPI
    //   .post('/register', formData, {
    //     headers: { 'Content-Type': 'multipart/form-data' },
    //   })
    //   .then((res) => res.data);
  },

  //register sife - contratos
  async registerSife(e) {
    const user = {
      email: e.data.email,
      legalRfc: e.data.rfc,
      legalName:
        `${e.data.name} ${e.data.lastNameP} ${e.data.lastNameM}`.trim(),
      token: e.data.token_sife,
    };
    const payload = { user };
    const { data } = await registerSifeService(payload, {
      headers: { Authorization: e.data.token_sife },
    });
    return data;
    // return sessionAPI.post('/register-sife', { user }).then((res) => {
    //   return res.data;
    // });
  },

  /**
   * This activates the user via RFC
   * @param legalRfc
   * @returns {Promise}
   */
  async activate(legalRfc) {
    const payload = { legalRfc };
    const { data } = await activateService(payload);
    return data;
    // return sessionAPI.post('/activate', payload).then((res) => res.data);
  },

  /**
   * This request a demo and sends email
   * @param email
   * @returns {Promise}
   */
  async requestDemo(email, lang) {
    const payload = { email, lang };
    const { data } = await requestDemoService(payload);
    return data;
    // return sessionAPI.post('/demo', payload).then((res) => res.data);
  },

  /**
   * Handle a user login
   * @return {Promise}
   * @param cer
   */
  async login(cer) {
    // IMPORTANT: Key and passphrase must never leave the browser
    const formData = new FormData();
    formData.append('cer', cer);
    const { data } = await loginService(formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    const { session, token } = data;
    if (session && token) {
      this.genericSessionResponse(session, token);
    }
    return data;
    // return sessionAPI
    //   .post('/login', formData, {
    //     headers: { 'Content-Type': 'multipart/form-data' },
    //   })
    //   .then((res) => {
    //     const { session, token } = res.data;
    //     if (session && token) {
    //       this.genericSessionResponse(session, token);
    //     }
    //     return res.data;
    //   });
  },

  /**
   * LOGIN SIFE-CONTRATOS
   */
  async loginSife(e) {
    const payload = {
      email: e.data.email,
      legalRfc: e.data.rfc,
    };
    const res = await loginSifeService(payload, {
      headers: { Authorization: e.data.token_sife },
    });
    const { session, token } = res.data;
    if (session && token) {
      this.genericSessionResponse(session, token);
    }
    return res;

    // return sessionAPI.post('/login-sife', { user }).then((res) => {
    //   console.log('login sife');
    //   // console.log(res.data)
    //   const { session, token } = res.data;
    //   if (session && token) {
    //     this.genericSessionResponse(session, token);
    //   }
    //   return res.data;
    // });
  },

  /**
   * Handle logging out
   * @return null
   */
  logout() {
    this.genericSessionDestroy();
  },

  async sendApiForm(payload) {
    const { data } = await sendApiFormService(payload);
    return data;
    // return sessionAPI.post('/send-form', payload).then((res) => res.data);
  },
};

export default SessionActions;
