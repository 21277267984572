import React from 'react';
import { branch } from 'baobab-react/higher-order';
import { NotificationManager } from 'react-notifications';
import { convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import ProfileActions from '../../actions/Profile';
import Loader from '../../components/Loader';

class NewTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      editorState: EditorState.createEmpty(),
    };
  }

  componentDidMount() {}

  // draftToHtml(convertToRaw(editorState.getCurrentContent()))

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  canUpload = () => {
    const { editorState } = this.state;
    return (
      draftToHtml(convertToRaw(editorState.getCurrentContent())).length > 1
    );
  };

  async handleSubmit(event) {
    event.preventDefault();
    const { editorState } = this.state;
    const { history, location, translations, lang } = this.props;
    const { notifications } = translations[lang].NewTemplate;
    this.setState({ loading: true });
    // Request to api
    const response = await ProfileActions.addTemplate(
      draftToHtml(convertToRaw(editorState.getCurrentContent())),
    );

    //Validate response
    if (response.status !== 200) {
      NotificationManager.error(
        notifications.notifBody1,
        notifications.notifTitle1,
        5000,
      );
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
      NotificationManager.info(
        notifications.notifBody2,
        notifications.notifTitle2,
        5000,
      );
      history.push({
        pathname: '/perfil',
        state: {
          from: location.pathname,
        },
      });
    }
  }

  render() {
    const { editorState, loading } = this.state;
    const { translations, lang } = this.props;
    const { texts, buttons } = translations[lang].NewTemplate;
    return (
      <div className="new_template_container">
        <h1>{texts.h1}</h1>
        <div className="page padded">
          <Editor
            editorState={editorState}
            toolbarClassName=""
            wrapperClassName=""
            editorClassName="html"
            onEditorStateChange={this.onEditorStateChange}
            localization={{
              locale: 'es',
            }}
          />
          {loading ? (
            <div className="center_loading">
              <Loader />
            </div>
          ) : (
            <div style={{ textAlign: 'right' }}>
              <button
                type="button"
                className="primary"
                onClick={this.handleSubmit.bind(this)}
                disabled={!this.canUpload()}
              >
                {buttons.button1}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default branch(
  {
    session: ['session'],
    translations: ['translations'],
    lang: ['lang'],
  },
  NewTemplate,
);
