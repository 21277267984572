import React, { Fragment } from 'react';
import moment from 'moment';
import { branch } from 'baobab-react/higher-order';
import SuperUserActions from '../../actions/SuperUser';
import { NotificationManager } from 'react-notifications';
import UserModal from './UserModal';
import { statusInContractUtils } from '../../utils/contractsContainerUtils';
import Loader from '../../components/Loader';
import CouponModal from '../../components/Modals/CouponModal';
import ProfileActions from '../../actions/Profile';
import { validateIconSuperAdmin } from '../../helpers/superUser.helpers';
import GenericConfirmModal from '../../components/Modals/GenericConfirmModal';

class SearchContractsUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      legalRfc: '',
      contracts: [],
      allContracts: [],
      user: null,
      showModalUser: false,
      userUpdated: null,
      loading: false,
      addCouponModal: false,
      coupon: null,
      deletingUser: false,
      deleteUserModal: false,
    };
  }

  componentDidMount = () => {
    this.setState({
      legalRfc: '',
      contracts: [],
      allContracts: [],
      user: null,
    });
  };

  onInputChange = (e) => {
    const { value } = e.target;
    this.setState({ legalRfc: value });
  };

  searchUserContractsByRfc = async () => {
    const { legalRfc } = this.state;
    this.setState({
      // legalRfc: '',
      contracts: [],
      allContracts: [],
      user: null,
      coupon: null,
    });
    if (!legalRfc) {
      return this.showAlert(false, 'El rfc no puede ir vacio');
    }
    this.setState({ loading: true });
    const response = await SuperUserActions.searchContractsUser(legalRfc);
    const { data } = response;
    if (!data.success) {
      if (data?.user) {
        const [coupon] = await ProfileActions.coupons(data?.user?.id);
        this.setState({ coupon });
      } else {
        this.setState({ coupon: null });
      }
      this.setState({ user: data.user, loading: false });
      return this.showAlert(data.success, data.message);
    }
    let newCoupon = null;
    if (data?.user) {
      [newCoupon] = await ProfileActions.coupons(data?.user?.id);
    }
    this.setState({
      contracts: data.contracts,
      allContracts: data.contracts,
      user: data.user,
      loading: false,
      coupon: data?.coupon || newCoupon,
    });
  };

  onClickDeleteUser = async (legalRfc) => {
    try {
      if (!legalRfc) {
        return NotificationManager.error(
          'El rfc del usuario es requerido',
          'Error',
          5000,
        );
      }
      this.setState({ loading: true, deletingUser: true });
      const { data } = await SuperUserActions.deleteUser(legalRfc);
      if (!data?.status) {
        return NotificationManager.error(
          data?.message || 'Ocurrió un error al eliminar el usuario',
          'Error',
          5000,
        );
      }

      this.setState({ showModalUser: false });
      this.setState({ user: null });
      return NotificationManager.success(
        'Usuario eliminado correctamente',
        'Éxito',
        5000,
      );
    } catch (error) {
      this.setState({ showModalUser: false });
      return NotificationManager.error(
        'Ocurrió un error al eliminar el usuario',
        'Error',
        5000,
      );
    } finally {
      this.setState({
        loading: false,
        deletingUser: false,
        deleteUserModal: false,
      });
    }
  };

  onClickShowModalUser = () => {
    const { showModalUser } = this.state;
    this.setState({ showModalUser: !showModalUser });
  };

  filterList = (e) => {
    const { allContracts } = this.state;
    const { value } = e.target;
    const lastChar = value[value.length - 1];
    if (lastChar === '\\') return;
    const updatedList = allContracts.filter(
      (contract) =>
        contract.title.toLowerCase().search(value?.toLowerCase() || '') !==
          -1 ||
        contract.id
          .toString()
          .toLowerCase()
          .search(value?.toLowerCase() || '') !== -1,
    );
    this.setState({ contracts: updatedList });
  };

  showAlert = (status, message) => {
    if (status) {
      NotificationManager.success(message, 'Éxito', 5000);
      return { isError: true };
    }
    NotificationManager.warning(message, 'Atención', 5000);
    return { isError: false };
  };

  onClickEditUser = async () => {
    const { userUpdated } = this.state;
    const info = {
      user: userUpdated,
      table: null,
      signType: null,
    };
    if (!userUpdated) {
      return NotificationManager.warning(
        'No has modificado ningún dato del usuario',
        'Advertencia',
        5000,
      );
    }
    const { data } = await SuperUserActions.updateUserSuperAdmin(info);
    const { isError } = this.showAlert(data.status, data.message);
    if (!isError) {
      this.setState({ showModalUser: false });
      this.setState({ user: userUpdated });
      this.searchUserContractsByRfc();
      return;
    }

    this.setState({ showModalUser: false, user: userUpdated });
    this.searchUserContractsByRfc();
  };

  onInputUserChangeUser = (event) => {
    const { name, value } = event.target;
    const { user } = this.state;
    const userUpdated = {
      ...user,
      [name]: value,
    };
    if (name === 'validatedAt') {
      userUpdated.validatedAt = value;
    }
    this.setState({ userUpdated });
  };

  updateGlobalState = (state) => {
    this.setState(state);
  };

  render() {
    // const { session, location } = this.props;
    const {
      contracts,
      allContracts,
      user,
      showModalUser,
      userUpdated,
      loading,
      addCouponModal,
      coupon,
      deletingUser,
      deleteUserModal,
    } = this.state;

    if (loading) {
      return (
        <>
          <div className="input_to_search_sp flex">
            <input
              name="legalRfc"
              placeholder="Buscar usuario por RFC"
              onChange={(e) => this.onInputChange(e)}
              type="text"
              className="input_nc"
            />
            <i
              className="fas fa-search iconoInput"
              style={{ color: '#6C6C6C', cursor: 'pointer' }}
              onClick={this.searchUserContractsByRfc}
            />
          </div>
          <div className="center_loading">
            <Loader />
            <p>
              {' '}
              {deletingUser
                ? 'Eliminando usuario...'
                : 'Buscando información...'}{' '}
            </p>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="input_to_search_sp flex">
          <input
            name="legalRfc"
            placeholder="Buscar usuario por RFC"
            onChange={(e) => this.onInputChange(e)}
            type="text"
            className="input_nc"
          />
          <i
            className="fas fa-search iconoInput"
            style={{ color: '#6C6C6C', cursor: 'pointer' }}
            onClick={this.searchUserContractsByRfc}
          />
        </div>
        {user ? (
          <div className="table_user_register" style={{ marginBottom: 30 }}>
            <div className="header_user_register">
              <small className="bold">Nombre</small>
              <small className="bold">RFC</small>
              <small className="bold">Tipo de registro</small>
              <small className="bold">Hora Validación</small>
              <small className="bold">Último Login</small>
              <small className="bold">Acciones</small>
            </div>
            <div className="body_user_register">
              <small className="normal_font">{user.legalName}</small>
              <small className="normal_font">{user.legalRfc}</small>
              <small className="normal_font">
                {user.typeUser ? 'SIFE' : 'e.firma(SAT)'}
              </small>
              <small className="normal_font">
                {`${moment(user.validatedAt).format('DD MMM YYYY h:mm a')}`}
              </small>
              <small className="normal_font">
                {`${moment(user.lastLoginAt).format('DD MMM YYYY h:mm a')}`}
              </small>
              <small
                className="normal_font"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {/* {allContracts?.length ? null : (
                  <i
                    className="far fa-trash-alt"
                    style={{ cursor: 'pointer', marginRight: 10 }}
                    // onClick={() => this.onClickDeleteUser(user.legalRfc)}
                    onClick={() => this.setState({ deleteUserModal: true })}
                  ></i>
                )} */}
                <i
                  className="far fa-edit"
                  style={{ marginRight: 10, cursor: 'pointer' }}
                  onClick={() => this.onClickShowModalUser()}
                ></i>
                <i
                  style={{ cursor: 'pointer' }}
                  className="far fa-clipboard"
                  onClick={() => this.setState({ addCouponModal: true })}
                ></i>
              </small>
            </div>
          </div>
        ) : null}

        {allContracts?.length ? (
          <>
            <div className="toolbar">
              <div className="input-container" style={{ width: '30%' }}>
                <input
                  type="text"
                  placeholder="Buscar contrato..."
                  className="input_nc"
                  onChange={this.filterList}
                />
              </div>
            </div>
            <div className="page scrollable">
              <div className="table_contract_sp">
                <div
                  className="header_table_contract"
                  style={{
                    gridTemplateColumns: 'repeat(5, 1fr)',
                    padding: 10,
                  }}
                >
                  <small>ID</small>
                  <small className="bold">Estatus</small>
                  <small
                    style={{
                      cursor: 'pointer',
                    }}
                    className="bold"
                  >
                    Nombre
                  </small>
                  <small className="bold">Fecha Emisión</small>
                  <small className="bold">Emisor</small>
                </div>
                <div
                  className="body_table_contract"
                  style={{
                    gridTemplateColumns: 'repeat(5, 1fr)',
                    padding: 10,
                  }}
                >
                  {contracts?.map((contract) => (
                    <Fragment key={contract.id}>
                      <small
                        className={`${
                          contract.status === 'forMe'
                            ? 'contract_for_me'
                            : 'other_status'
                        }`}
                      >
                        {contract.id}
                      </small>
                      <small
                        className={`${
                          contract.status === 'forMe'
                            ? 'contract_for_me'
                            : 'other_status'
                        }`}
                        title={
                          contract.hasOwnProperty('authorizedAt') &&
                          !contract.signed
                            ? statusInContractUtils('authPending')
                            : statusInContractUtils(contract.status)
                        }
                      >
                        {validateIconSuperAdmin(contract)}
                      </small>
                      <small
                        className={`${
                          contract.status === 'forMe'
                            ? 'contract_for_me'
                            : 'other_status'
                        }`}
                      >
                        {contract.title}
                      </small>
                      <small
                        className={`${
                          contract.status === 'forMe'
                            ? 'contract_for_me'
                            : 'other_status'
                        }`}
                      >
                        {moment(contract.createdAt).format('D MMM YYYY')}
                      </small>
                      <small
                        className={`${
                          contract.status === 'forMe'
                            ? 'contract_for_me'
                            : 'other_status'
                        }`}
                      >
                        {contract.legalName}
                      </small>
                    </Fragment>
                  ))}
                </div>
              </div>
            </div>
          </>
        ) : (
          user && (
            <div style={{ width: '100%', textAlign: 'center' }}>
              <h4>El usuario no cuenta con ningún documento</h4>
            </div>
          )
        )}
        {showModalUser ? (
          <UserModal
            user={userUpdated || user}
            hideModal={this.onClickShowModalUser}
            editUser={this.onClickEditUser}
            onChangeInPutUser={this.onInputUserChangeUser}
          />
        ) : null}

        {addCouponModal ? (
          <CouponModal
            user={userUpdated || user}
            hideModal={() => this.setState({ addCouponModal: false })}
            coupon={coupon}
            updateGlobalState={this.updateGlobalState}
          />
        ) : null}

        {/* {deleteUserModal && user ? (
          <GenericConfirmModal
            title={'Eliminación de usuario'}
            message={`¿Estás seguro de eliminar al usuario ${user?.legalName} - ${user?.legalRfc}?`}
            okButton={'Eliminar'}
            cancelButton={'Cancelar'}
            cancelAction={() => this.setState({ deleteUserModal: false })}
            acceptAction={() => this.onClickDeleteUser(user.legalRfc)}
          />
        ) : null} */}
      </>
    );
  }
}

export default branch(
  {
    session: ['session'],
  },
  SearchContractsUser,
);
