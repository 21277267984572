import React from 'react';
import ContractActions from '../../actions/Contract';
import { NotificationManager } from 'react-notifications';
import Loader from '../Loader';
import { branch } from 'baobab-react/higher-order';

class UpdateSignerModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rfcToUpdate: '',
      loading: false,
    };
  }

  handleChange = (e) => {
    const { value } = e.target;
    const newValue = value.replace(/\s/g, '');
    this.setState({ rfcToUpdate: newValue });
  };

  updateUser = async () => {
    const { rfcToUpdate } = this.state;
    const { contract, signerToUpdate, lang, translations } = this.props;
    const { notifications } = translations[lang].Modals.UpdateSignerModal;

    if (!rfcToUpdate || rfcToUpdate?.length < 12) {
      return NotificationManager.error(
        notifications.notifBody1,
        notifications.notifTitle1,
        3000,
      );
    }

    if (!signerToUpdate) {
      return NotificationManager.error(
        notifications.notifBody2,
        notifications.notifTitle2,
        3000,
      );
    }
    this.setState({ loading: true });

    const response = await ContractActions.updateSigner(
      [],
      contract,
      signerToUpdate,
      rfcToUpdate,
    );

    if (!response?.success) {
      this.setState({ loading: false });
      return NotificationManager.error(
        response?.message || notifications.notifBody3,
        notifications.notifTitle3,
        3000,
      );
    }

    NotificationManager.success(
      response?.message || notifications.notifBody4,
      notifications.notifTitle4,
      3000,
    );
    this.setState({ loading: true });
    window.location.reload();
  };

  render() {
    const { rfcToUpdate, loading } = this.state;
    const { closeModal, lang, translations } = this.props;
    const { texts, inputs, buttons } =
      translations[lang].Modals.UpdateSignerModal;
    return (
      <div className="modal-container">
        <div className="modal">
          <div className="header_modal">
            <h1 style={{ color: '#2851e5' }}>{texts.h1}</h1>
          </div>
          <div className="body_modal">
            {loading ? (
              <div className="center_loading">
                <Loader />
                <p>{texts.p1}</p>
              </div>
            ) : (
              <>
                <p>{texts.p2}</p>
                <input
                  value={rfcToUpdate}
                  onChange={this.handleChange}
                  name="legalRfc_update"
                  type="text"
                  className="input_nc"
                  required
                  placeholder={inputs.inputPlaceholder1}
                  autoFocus
                  maxLength={13}
                  style={{ ...(rfcToUpdate && { textTransform: 'uppercase' }) }}
                />
              </>
            )}
          </div>
          <div className="footer_modal">
            <button
              className="color-grey"
              type="button"
              onClick={() => closeModal()}
              disabled={loading}
            >
              {buttons.button1}
            </button>
            <button
              className="primary"
              type="button"
              //   disabled={!this.disabledButton()}
              onClick={() => this.updateUser()}
            >
              {buttons.button2}
            </button>
          </div>
        </div>
        <div className="background-screen" />
      </div>
    );
  }
}

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  UpdateSignerModal,
);
