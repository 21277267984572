import { contratosInstance } from '../instance';
import { ApiRoutes } from '../routes';

export const ApiEPs = (createEndpoint) => {
  return {
    viewApiEP: createEndpoint(contratosInstance, ApiRoutes.viewApi),
    getUserApiEP: createEndpoint(contratosInstance, ApiRoutes.getUserApi),
    getSignerApiEP: createEndpoint(contratosInstance, ApiRoutes.getSignerApi),
    signApiEP: createEndpoint(contratosInstance, ApiRoutes.signApi),
    registerUserSifeApiEP: createEndpoint(
      contratosInstance,
      ApiRoutes.registerUserSifeApi,
    ),
    createContractSifeApiEP: createEndpoint(
      contratosInstance,
      ApiRoutes.createContractSifeApi,
    ),
    downloadContractToViewAPIEP: createEndpoint(
      contratosInstance,
      ApiRoutes.downloadContractToViewAPI,
    ),
    getPublicInfoEP: createEndpoint(contratosInstance, ApiRoutes.getPublicInfo),
  };
};
