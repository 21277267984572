export const DashboardTranslations = {
  es: {
    texts: {
      alertModalTitle1: 'Sesión vencida',
      alertModalBody1:
        'Por seguridad cerramos tu sesión después de 15 minutos de inactividad.',
      alertModalTitle2: 'Sesión vencida',
      alertModalBody2: 'Ups! Tu sesión ha expirado.',
    },
    notifications: {
      notifTitle1: 'Atención',
      notifBody1:
        'Por seguridad cerramos tu sesión después de 15 minutos de inactividad.',
    },
  },
  en: {
    texts: {
      alertModalTitle1: 'Session expired',
      alertModalBody1:
        'For your security we close this session after 15 minutes of inactivity.',
      alertModalTitle2: 'Session expired',
      alertModalBody2: 'Ups! Your session has expired.',
    },
    notifications: {
      notifTitle1: 'Attention',
      notifBody1:
        'For your security we close this session after 15 minutes of inactivity.',
    },
  },
};
