export const SidebarTranslations = {
  es: {
    texts: {
      link1: 'Nuevo documento',
      link2: 'Mis documentos',
      link3: 'Configuración',
      link4: 'Lista Apoderados',
      link5: 'Lista Admins',
      link6: 'Cerrar sesión',
    },
    inputs: {},
    buttons: {},
  },
  en: {
    texts: {
      link1: 'New document',
      link2: 'My documents',
      link3: 'Settings',
      link4: 'Legal Rep. list',
      link5: 'Admins list',
      link6: 'Logout',
    },
    inputs: {},
    buttons: {},
  },
};
