import { AuthorizeTokenModalTranslations } from './AuthorizeTokenModal.translations';
import { CameraModalTranslations } from './CamerModal.translations';
import { ChooseDocumentModalTranslations } from './ChooseDocumentModal.translations';
import { ConfirmContractModalTranslations } from './ConfirmContractModal.translations';
import { CredentialsModalTranslations } from './CredentialsModal.translations';
import { GenericContractPreviewTranslations } from './GenericContractPreview.translations';
import { IdentityDocumentModalTranslations } from './IdentityDocumentModal.translations';
import { IframeToSignTranslations } from './IframeToSign.translations';
import { PrivacyPolicyModalTranslations } from './PrivacyPolicyModal.translations';
import { SelectDateModalTranslations } from './SelectDateModal.translations';
import { SignerModalTranslations } from './SignerModal.translations';
import { UpdateSignersModalTranslations } from './UpdateSignersModal.translations';

export const ModalsTranslations = {
  es: {
    texts: {},
    inputs: {},
    buttons: {},
    AuthorizeTokenModal: { ...AuthorizeTokenModalTranslations.es },
    CameraModal: { ...CameraModalTranslations.es },
    ChooseDocumentModal: { ...ChooseDocumentModalTranslations.es },
    ConfirmContractModal: { ...ConfirmContractModalTranslations.es },
    CredentialsModal: { ...CredentialsModalTranslations.es },
    GenericContractPreview: { ...GenericContractPreviewTranslations.es },
    IdentityDocumentModal: { ...IdentityDocumentModalTranslations.es },
    IframeToSign: { ...IframeToSignTranslations.es },
    SelectDateModal: { ...SelectDateModalTranslations.es },
    SignerModal: { ...SignerModalTranslations.es },
    UpdateSignerModal: { ...UpdateSignersModalTranslations.es },
    PrivacyPolicyModal: { ...PrivacyPolicyModalTranslations.es },
  },
  en: {
    texts: {},
    inputs: {},
    buttons: {},
    AuthorizeTokenModal: { ...AuthorizeTokenModalTranslations.en },
    CameraModal: { ...CameraModalTranslations.en },
    ChooseDocumentModal: { ...ChooseDocumentModalTranslations.en },
    ConfirmContractModal: { ...ConfirmContractModalTranslations.en },
    CredentialsModal: { ...CredentialsModalTranslations.en },
    GenericContractPreview: { ...GenericContractPreviewTranslations.en },
    IdentityDocumentModal: { ...IdentityDocumentModalTranslations.en },
    IframeToSign: { ...IframeToSignTranslations.en },
    SelectDateModal: { ...SelectDateModalTranslations.en },
    SignerModal: { ...SignerModalTranslations.en },
    UpdateSignerModal: { ...UpdateSignersModalTranslations.en },
    PrivacyPolicyModal: { ...PrivacyPolicyModalTranslations.en },
  },
};
