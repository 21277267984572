import React from 'react';
import moment from 'moment';

class UserModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirming: false,
    };
  }

  render() {
    const { onChangeInPutUser, user, editUser, hideModal } = this.props;
    return (
      <div className="modal-container mtm-20">
        <div
          className="modal"
          style={{
            width: 900,
            margin: '22vh auto 0px auto',
          }}
        >
          <h2 style={{ marginLeft: 18 }}>Editar usuario</h2>

          <div className="row" style={{ paddingBottom: 20 }}>
            <div className="col-sm-4">
              <small>RFC</small>
              <input
                type="text"
                name="legalRfc"
                className="input_nc"
                defaultValue={user.legalRfc.toUpperCase()}
                required
                maxLength="13"
                minLength="12"
                onChange={(e) => onChangeInPutUser(e)}
                placeholder="RFC"
                disabled
              />
            </div>
            <div className="col-sm-4">
              <small>Nombre</small>
              <input
                type="text"
                name="legalName"
                className="input_nc"
                defaultValue={user.legalName}
                required
                minLength="10"
                onChange={(e) => onChangeInPutUser(e)}
                placeholder="Nombre Completo"
                disabled
              />
            </div>
            <div className="col-sm-4">
              <small>Email</small>
              <input
                type="email"
                name="secondaryEmail"
                className="input_nc"
                defaultValue={user.secondaryEmail}
                onChange={(e) => onChangeInPutUser(e)}
                required
                placeholder="email"
                autoComplete="nope"
              />
            </div>
            <div className="col-sm-5">
              <small>Fecha de Validación</small>
              <input
                type="date"
                name="validatedAt"
                className="input_nc"
                value={moment(user.validatedAt).format('YYYY-MM-DD')}
                onChange={(e) => onChangeInPutUser(e)}
                required
                placeholder="Fecha validación"
                autoComplete="nope"
                disabled
              />
              {!user?.validatedAt && (
                <>
                  <small style={{ fontSize: 12 }}>Link verificación</small>
                  <br></br>
                  <small style={{ fontSize: 12, color: '#2851e5' }}>{`${
                    window.location.origin
                  }/activate/${user.legalRfc || user.id}`}</small>
                </>
              )}
            </div>
            <div className="col-sm-5">
              <small>Tipo de registro</small>
              <input
                type="text"
                name="typeUser"
                className="input_nc"
                defaultValue={user.typeUser}
                onChange={(e) => onChangeInPutUser(e)}
                required
                placeholder="Tipo registro"
                autoComplete="nope"
              />
            </div>
          </div>

          <div
            className="col-sm-12"
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <button
              type="button"
              className="secondary"
              onClick={hideModal}
              style={{ color: 'black' }}
            >
              Regresar
            </button>
            <button
              type="submit"
              className="primary"
              onClick={() => editUser()}
            >
              Editar
            </button>
          </div>
        </div>
        <div className="background-screen" />
      </div>
    );
  }
}

export default UserModal;
