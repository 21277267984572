import React from 'react';
import { branch } from 'baobab-react/higher-order';
import SuperUserActions from '../../actions/SuperUser';
import { NotificationManager } from 'react-notifications';

class SearchSignersAdmins extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      legalRfc: '',
      user: null,
      allUsers: [],
      representing: [],
    };
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.userType !== this.props.userType) {
      this.setState({
        user: null,
        allUsers: [],
        representing: [],
      });
    }
  };

  onInputChange = (e) => {
    const { value } = e.target;
    this.setState({ legalRfc: value });
  };

  searchAdminOrSigner = async () => {
    const { legalRfc } = this.state;
    if (!legalRfc) {
      return NotificationManager.warning(
        'El RFC no puede ir vació',
        'Atención',
        5000,
      );
    }
    const { userType } = this.props;
    const response = await SuperUserActions.getSignersOrAdmins(
      legalRfc,
      userType,
    );
    const {
      data: { success, allUsers, representing, user },
    } = response;
    if (!success) {
      return NotificationManager.warning(
        response.data.message,
        'Atención',
        5000,
      );
    }
    this.setState({ allUsers, representing, user });
  };

  getStatusContract = (user) => {
    if (user.signed) {
      return <i className="fas fa-check-circle" style={{ color: '#7ED321' }} />;
    }

    return <i className="far fa-clock" style={{ color: '#e19614' }} />;
  };

  getStatusRegister = (user) => {
    if (user.hasOwnProperty('user')) {
      return <i className="fas fa-check" style={{ color: '#7ED321' }} />;
    }
    return <i className="fas fa-ban" style={{ color: '#D0021B' }} />;
  };

  render() {
    const { userType } = this.props;
    const { allUsers, representing, user } = this.state;

    return (
      <>
        <div className="input_to_search_sp flex">
          <input
            name="legalRfc"
            placeholder={`Buscar ${
              userType === 'signers' ? 'firmantes' : 'administradores'
            } por RFC`}
            onChange={(e) => this.onInputChange(e)}
            type="text"
            className="input_nc"
          />
          <i
            className="fas fa-search iconoInput"
            style={{ color: '#6C6C6C', cursor: 'pointer' }}
            onClick={this.searchAdminOrSigner}
          />
        </div>
        {user ? (
          <>
            <div className="table_my_signers_sp">
              <h3 className="align-center">
                {userType === 'signers' ? 'Apoderados' : 'Administradores'} de{' '}
                {user.legalName} - {user.legalRfc}
              </h3>
              {allUsers?.length ? (
                <>
                  <div className="header_my_signers_sp">
                    <small className="bold">Firmado</small>
                    <small className="bold">Registrado</small>
                    <small className="bold">Nombre</small>
                    <small className="bold">RFC</small>
                    <small className="bold">Registrado por</small>
                  </div>
                  <div className="body_my_signers_sp">
                    {allUsers?.map((user) => (
                      <>
                        <small className="normal_font">
                          {this.getStatusContract(user)}
                        </small>
                        <small className="normal_font">
                          {this.getStatusRegister(user)}
                        </small>
                        <small className="normal_font">{user.legalName}</small>
                        <small className="normal_font">{user.legalRfc}</small>
                        <small className="normal_font">{`${user.creatorLegalName}-${user.creatorLegalRfc}`}</small>
                      </>
                    ))}
                  </div>
                </>
              ) : (
                <h3
                  className="align-center"
                  style={{ color: '#6c6c6c', fontSize: 14 }}
                >
                  Sin resultados para mostrar
                </h3>
              )}
            </div>

            {/* DE QUIEN SOY APODERADO */}
            <div className="table_im_representing">
              <h3 className="align-center">
                {user.legalName} - {user.legalRfc} es{' '}
                {userType === 'signers' ? 'apoderado' : 'administrador'} de de:{' '}
              </h3>
              {representing?.length ? (
                <>
                  <div className="header_im_representing">
                    <small className="bold">Nombre</small>
                    <small className="bold">RFC</small>
                    <small className="bold">Email</small>
                  </div>
                  <div className="body_im_representing">
                    {representing?.map((user) => (
                      <>
                        <small className="normal_font">{user.legalName}</small>
                        <small className="normal_font">{user.legalRfc}</small>
                        <small className="normal_font">{user.email}</small>
                      </>
                    ))}
                  </div>
                </>
              ) : (
                <h3 style={{ color: '#6c6c6c', fontSize: 14 }}>
                  Sin resultados para mostrar
                </h3>
              )}
            </div>
          </>
        ) : null}
      </>
    );
  }
}

export default branch(
  {
    session: ['session'],
  },
  SearchSignersAdmins,
);
