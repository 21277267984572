import { branch } from 'baobab-react/higher-order';
import React from 'react';

const UploadFilesDisplay = ({
  Dropzone,
  handleSetFiles,
  allUploadFiles,
  translations,
  lang,
}) => {
  const { texts } = translations[lang].NewContractMassive.UploadFilesDisplay;
  return (
    <>
      <div className="dropzone_massive">
        <Dropzone onDrop={handleSetFiles}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps({ className: 'file-drop-zone massive' })}>
              <input {...getInputProps()} type="file" accept=".pdf" />
              {allUploadFiles.length > 0 ? (
                <i
                  className="fas fa-check-circle fa-2x"
                  style={{ color: '#7ED321' }}
                />
              ) : (
                <i
                  className="fas fa-cloud-upload-alt fa-2x"
                  style={{ color: '#6C6C6C' }}
                />
              )}
              {!allUploadFiles.length ? (
                <span>{texts.span1}</span>
              ) : (
                <span>{texts.span2(allUploadFiles.length)}</span>
              )}
            </div>
          )}
        </Dropzone>
      </div>
      <div className="info_files">
        <div className="info-massive-contract">
          <i className="fas fa-exclamation-circle"></i>
          <ul>
            <li>{texts.li1}</li>
            <li>{texts.li2}</li>
            <li>{texts.li3}</li>
            <li>{texts.li3}</li>
            <li>{texts.li4}</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  UploadFilesDisplay,
);
