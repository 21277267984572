import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import axios from 'axios';
import Application from './containers/Application';
import state from './state';

import './less/index.less';
moment.locale('es');

if (localStorage.session) {
  const session = JSON.parse(localStorage.session);
  state.select(['session']).set(session);
}

if (localStorage.token) {
  axios.defaults.headers.common['X-Jwt-Token'] = localStorage.token;
}

if (!localStorage.videos) {
  const videos = {
    contracts: false,
  };
  localStorage.videos = JSON.stringify(videos);
}

if (
  !(
    navigator.userAgent.includes('Chrome') ||
    navigator.userAgent.includes('Firefox') ||
    navigator.userAgent.includes('Safari')
  )
) {
  // eslint-disable-next-line no-alert
  window.alert(
    'Es posible su navegador no sea compatible con la interfaz de soracontratos actual.\n' +
      ' \nAsegúrese de descargar y probar en la versión más reciente de cualquiera de estas opciones:\n' +
      ' \nGoogle Chrome\nMozilla Firefox',
  );
}
const root = document.getElementById('root');
ReactDOM.render(<Application />, root);
