import { endpointHandler } from '../createEndpoint';

const {
  viewApiEP,
  getUserApiEP,
  registerUserSifeApiEP,
  createContractSifeApiEP,
  getSignerApiEP,
  signApiEP,
  downloadContractToViewAPIEP,
  getPublicInfoEP,
} = endpointHandler();

export const viewApiService = (data, reqConfig, urlVar) => {
  return viewApiEP.post(data, reqConfig, urlVar);
};
export const getUserApiService = (data, reqConfig, urlVar) => {
  return getUserApiEP.post(data, reqConfig, urlVar);
};
export const registerUserSifeService = (data, reqConfig, urlVar) => {
  return registerUserSifeApiEP.post(data, reqConfig, urlVar);
};
export const createContractSifeApiService = (data, reqConfig, urlVar) => {
  return createContractSifeApiEP.post(data, reqConfig, urlVar);
};
export const getSignerService = (data, reqConfig, urlVar) => {
  return getSignerApiEP.post(data, reqConfig, urlVar);
};
export const signApiService = (data, reqConfig, urlVar) => {
  return signApiEP.post(data, reqConfig, urlVar);
};
export const downloadContractToViewAPIService = (reqConfig, urlVar) => {
  return downloadContractToViewAPIEP.get(reqConfig, urlVar);
};

export const getPublicInfoService = (reqConfig, urlVar) => {
  return getPublicInfoEP.get(reqConfig, urlVar);
};
