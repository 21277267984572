export const UploadFilesDisplayTranslations = {
  es: {
    texts: {
      span1: 'Selecciona o arrastra los documentos que deseas firmar',
      span2: (docsLength) => `${docsLength} documentos seleccionados`,
      li1: 'Tus documentos deben de estar en formato PDF',
      li2: 'Para un tiempo de subida optimo, los documentos deben pesar entre 400 y 500 KB',
      li3: 'La estructura del nombre del pdf debe ser la siguiente: RFC;correo@sora.mx (Ej. AAAA123456F40;usuario@sora.mx)',
      li4: 'Si se enviarán multiples documentos para un mismo firmante la estructura del nombre debe ser RFC;correo@sora.mx;ID (Ej. AAAA123456F40;usuario@correo;123',
    },
    inputs: {},
    buttons: {},
    notifications: {},
  },
  en: {
    texts: {
      span1: 'Select or drag the documents you wish to sign',
      span2: (docsLength) => `${docsLength} documents selected`,
      li1: 'Your documents must be in PDF format',
      li2: 'For an optimal upload time, the documents must weigh between 400 and 500 KB',
      li3: 'The structure of the pdf name must be: RFC;email@sora.mx (Ej. AAAA123456F40;user@sora.mx)',
      li4: 'If multiple documents will be sent for the same signer, the name structure should be RFC;email@sora.mx;ID (e.g., AAAA123456F40;user@email;123).',
    },
    inputs: {},
    buttons: {},
    notifications: {},
  },
};
