const VideoActions = {
  /**
   * Dynamic assign of videos storage
   * @param key
   * @returns {Promise<boolean|*>}
   */
  async showVideo(key) {
    const videos =
      localStorage.videos !== undefined ? JSON.parse(localStorage.videos) : {};
    const session =
      localStorage.session !== undefined
        ? JSON.parse(localStorage.session)
        : {};
    if (session.user.legalRfc === 'BAJF541014RB3') return true;
    if (!videos[key]) {
      videos[key] = !videos[key];
      localStorage.videos = JSON.stringify(videos);
    } else {
      return !videos[key];
    }
    return videos[key];
  },
};

export default VideoActions;
