import { branch } from 'baobab-react/higher-order';
import { NotificationManager } from 'react-notifications';
import React from 'react';
// actions
import ContractActions from '../actions/Contract';
import LabelActions from '../actions/LabelActions';
import ProfileActions from '../actions/Profile';
// components
import ConfirmContractModal from '../components/Modals/ConfirmContractModal';
import Loader from '../components/Loader';
import GenericConfirmModal from '../components/Modals/GenericConfirmModal';
import ContractInputsDisplay from './NewContract/ContractInputsDisplay';
import ChipsDisplay from './NewContract/ChipsDisplay';
import DropzoneDisplay from './NewContract/DropzoneDisplay';
import AuthorizersDisplay from './NewContract/AuthorizersDisplay';
import SignersDisplay from './NewContract/SignersDisplay';
import RecipientsDisplay from './NewContract/RecipientsDisplay';
import UploadButtonDisplay from './NewContract/UploadButtonDisplay';

class NewContract extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      description: '',
      ttl: 40,
      file: null,
      signers: [],
      recipients: [],
      loading: false,
      myLabels: [],
      coupon: null,
      date: null,
      checkAuthorizers: false,
      authorizers: [],
      orderedSigners: true,
      signTypeSigner: '',
      contractUser: '',
      adminOrSigner: false,
      chips: [],
      showConfirmModal: false,
      activeButtonUploadPdf: false,
      showModal: false,
    };
  }

  async componentDidMount() {
    const { session, signer, admin } = this.props;
    const { user } = session;
    const contractUser = signer || admin || user;
    try {
      const id = contractUser.rootUser || contractUser.id;
      const [coupon] = await ProfileActions.coupons(id);
      const myLabels = await LabelActions.myLabels(id);

      this.setState({
        myLabels,
        coupon: coupon.usesLeft ? coupon : 0,
        contractUser,
      });
      if (signer || admin) this.setState({ signerOrAdmin: true });
    } catch (error) {
      console.error(error);
    }
  }

  hideModal = () => {
    this.setState({ showConfirmModal: false });
  };

  createAndUploadContract = async () => {
    const { lang, translations } = this.props;
    const translation = translations[lang].NewContract;
    const { notifications } = translation;
    this.setState({ loading: true, showConfirmModal: false, showModal: false });
    const {
      title,
      description,
      ttl,
      chips,
      signers,
      recipients,
      checkAuthorizers,
      authorizers,
      file,
      coupon,
      date,
      orderedSigners,
      signTypeSigner,
      contractUser,
      signerOrAdmin,
    } = this.state;
    let rootUser = signerOrAdmin ? contractUser.rootUser : null;
    if (file.size > 15728640) {
      NotificationManager.error(
        notifications.notifBody1(file.name),
        notifications.notifTitle1,
        10000,
      );
      this.setState({ loading: false });
      return;
    }
    try {
      const contract = await ContractActions.create(
        {
          title,
          description,
          ttl,
          file,
          participants: signers.length,
          expiresOn: date,
          authorizers: checkAuthorizers ? authorizers.length : null,
          status: checkAuthorizers ? 'authPending' : null,
          orderedSigners: !orderedSigners,
          rootUser,
          labels: chips.length ? chips : [],
          isMassive: false,
        },
        checkAuthorizers,
      );
      await ProfileActions.updateCoupon(coupon.userCouponId || coupon.id);
      await ProfileActions.couponContract(
        coupon.coupon || coupon.id,
        contract.id,
      );
      for (let i = 0; i < chips.length; i++) {
        const chip = chips[i];
        if (!chip.id) {
          if (signerOrAdmin) chip.rootUser = contractUser.rootUser;
          const label = await LabelActions.create(chip);
          LabelActions.assign({
            contract: contract.id,
            label: label.id,
            rootUser,
          });
          continue;
        }
        LabelActions.assign({
          contract: contract.id,
          label: chip.id,
          rootUser,
        });
      }

      //ADD AUTHORIZERS AND SEND EMAILS
      if (checkAuthorizers) {
        for (let i = 0; i < authorizers.length; i++) {
          const auhtorizer = authorizers[i];
          ContractActions.searchByRfc(auhtorizer).then((user) => {
            if (user.id) {
              ContractActions.addAuthorizer({
                contractId: contract.id,
                userId: user.id,
                rootUser,
              });
            } else {
              ContractActions.addToQueueAuthorizer(
                contract.id,
                auhtorizer,
                rootUser,
              );
            }
          });
          ContractActions.sendEmailAuthorizer(
            auhtorizer,
            contract,
            authorizers,
          );
        }
      }

      for (let index = 0; index < signers.length; index++) {
        const signer = signers[index];
        const user = await ContractActions.searchByRfc(signer);
        const typeOfSignature = signTypeSigner
          ? signTypeSigner
          : signer.signType === '4'
          ? (signer.signType = '3')
          : signer.signType;
        const newSigner = {
          turn: !orderedSigners ? 1 : index + 1,
          signType: typeOfSignature,
          rootUser,
          requestDocument: signer.requestDocument.size
            ? JSON.stringify(Array.from(signer.requestDocument))
            : null,
          requestVerification: signer.requestVerification,
          verifyCreateSign: signer.showButtons ? signer.verifyCreateSign : true,
          acceptedDisclaimer: signer.acceptedDisclaimer,
        };
        if (user.id) {
          const signerQuery = checkAuthorizers
            ? ContractActions.addSignerWithAuthorizer
            : ContractActions.addSigner;
          signerQuery({
            contractId: contract.id,
            userId: user.id,
            ...newSigner,
            emailSend: signer.email,
          });
        } else {
          const queueQuery = checkAuthorizers
            ? ContractActions.addToQueueSignerWithAuth
            : ContractActions.addToQueue;
          queueQuery({
            contract: contract.id,
            email: signer.email,
            legalName: signer.legalName,
            legalRfc: signer.legalRfc,
            ...newSigner,
          });
        }
        if (signerOrAdmin) signer.rootUser = contractUser.rootUser;
        if (!checkAuthorizers) {
          ContractActions.sendEmailSigner(
            signer,
            index + 1,
            contract,
            signTypeSigner ? signTypeSigner : signer.signType,
          );
        }
      }

      for (let i = 0; i < recipients.length; i++) {
        const recipient = recipients[i];
        recipient.rootUser = rootUser;
        ContractActions.addRecipient(recipient, contract.id);
        ContractActions.sendEmailRecipient(recipient, contract);
      }

      this.setState({ loading: false });
      this.redirectToContractDetail(contract);
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
      return NotificationManager.error(
        notifications.notifBody2,
        notifications.notifTitle2,
        4000,
      );
    }
  };

  updateStateNewContract = (data) => {
    this.setState(data);
  };

  redirectToContractDetail = (contract) => {
    const { history } = this.props;
    const { contractUser, recipients, signerOrAdmin } = this.state;
    const path =
      contractUser.rootUser ||
      recipients.find(
        (recipient) => recipient.legalRfc === contractUser.legalRfc,
      )
        ? `/public/contracts/${contract.id}`
        : `/contratos/${contract.id}`;

    return history.push({
      pathname: path,
      state: {
        from: window.location.pathname,
        admin: signerOrAdmin ? contractUser : null,
      },
    });
  };

  handleCancelPopUp = () => {
    this.setState({ showModal: false });
  };

  render() {
    const {
      title,
      description,
      file,
      signers,
      recipients,
      myLabels,
      loading,
      showModal,
      showConfirmModal,
      coupon,
      checkAuthorizers,
      authorizers,
      signerOrAdmin,
      signTypeSigner,
      activeButtonUploadPdf,
      contractUser,
    } = this.state;

    const { lang, translations } = this.props;
    const translation = translations[lang].NewContract;
    const { texts, buttons } = translation;

    return (
      <div className={`new_contract_view ${signerOrAdmin ? 'admin_view' : ''}`}>
        <div className="title_nc">
          {!signerOrAdmin ? <h1>{texts.h1}</h1> : null}
        </div>
        <div className="complete_info_contract page padded shadow_new_contract">
          <ContractInputsDisplay
            updateStateNewContract={this.updateStateNewContract}
          />
          <DropzoneDisplay
            updateStateNewContract={this.updateStateNewContract}
          />
          <ChipsDisplay
            myLabels={myLabels}
            updateStateNewContract={this.updateStateNewContract}
          />
        </div>

        <div className="authorizers_nc page padded shadow_new_contract">
          <AuthorizersDisplay
            updateStateNewContract={this.updateStateNewContract}
            signer={this.props.signer}
            admin={this.props.admin}
          />
        </div>

        <div className="signers_nc page padded shadow_new_contract">
          <SignersDisplay
            updateStateNewContract={this.updateStateNewContract}
            signer={this.props.signer}
            admin={this.props.admin}
          />
        </div>

        <div className="recipients_nc page padded shadow_new_contract">
          <RecipientsDisplay
            updateStateNewContract={this.updateStateNewContract}
            recipients={recipients}
            signer={this.props.signer}
            admin={this.props.admin}
          />
        </div>

        <div className="send_contract_nc">
          {loading ? (
            <div className="center_loading">
              <Loader />
            </div>
          ) : (
            <UploadButtonDisplay
              authorizers={authorizers}
              signers={signers}
              recipients={recipients}
              checkAuthorizers={checkAuthorizers}
              signTypeSigner={signTypeSigner}
              title={title}
              file={file}
              activeButtonUploadPdf={activeButtonUploadPdf}
              updateStateNewContract={this.updateStateNewContract}
              coupon={coupon}
              addRecipientIsNotParticipant={this.addRecipientIsNotParticipant}
              contractUser={contractUser}
            />
          )}
        </div>

        {/* <div className="row center-xs mt-1">
          <Loader />
        </div> */}

        {showModal ? (
          <GenericConfirmModal
            title={texts.genericModalTitle1}
            message={`${
              !coupon
                ? texts.genericModalBody1
                : coupon.usesLeft < 10
                ? texts.genericModalBody2(coupon?.usesLeft)
                : null
            }`}
            okButton={buttons.genericModalConfirmButton1}
            cancelButton={buttons.genericModalCancelButton1}
            cancelAction={() => this.handleCancelPopUp()}
            acceptAction={() =>
              !coupon
                ? this.handleCancelPopUp()
                : this.createAndUploadContract()
            }
          />
        ) : null}

        {showConfirmModal ? (
          <ConfirmContractModal
            contract={{
              title,
              description,
              file,
            }}
            signers={signers}
            recipients={recipients}
            authorizers={checkAuthorizers ? authorizers : null}
            handleConfirmContract={() => this.createAndUploadContract()}
            handleCancel={this.hideModal}
          />
        ) : null}
      </div>
    );
  }
}

export default branch(
  {
    session: ['session'],
    myCoupons: ['myCoupons'],
    translations: ['translations'],
    lang: ['lang'],
  },
  NewContract,
);
