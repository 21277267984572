export const UpdateSignersModalTranslations = {
  es: {
    texts: {
      h1: 'Actualiza el rfc de tu firmante',
      p1: 'Actualizando firmante',
      p2: 'Ingresa el rfc al que quieres redireccionar el contrato',
    },
    inputs: {
      inputPlaceholder1: 'Ingresa el rfc aquí...',
    },
    buttons: {
      button1: 'Cerrar',
      button2: 'Actualizar',
    },
    notifications: {
      notifTitle1: 'Atención',
      notifBody1: 'El rfc no es correcto',
      notifTitle2: 'Error',
      notifBody2: 'No pudimos obtener la información del firmante',
      notifTitle3: 'Error',
      notifBody3: 'Hubo un error al actualizar', // TODO: translate response
      notifTitle4: 'Éxito',
      notifBody4: 'Firmante actualizado con éxito',
    },
  },
  en: {
    texts: {
      h1: 'Update signer rfc',
      p1: 'Updating signer',
      p2: 'Enter the rfc you want to redirect the contract to',
    },
    inputs: {
      inputPlaceholder1: 'Enter rfc here...',
    },
    buttons: {
      button1: 'Close',
      button2: 'Update',
    },
    notifications: {
      notifTitle1: 'Attention',
      notifBody1: 'Rfc is not correct',
      notifTitle2: 'Error',
      notifBody2: 'We could not get signer information',
      notifTitle3: 'Error',
      notifBody3: 'There was an error updating', // TODO: translate response
      notifTitle4: 'Success',
      notifBody4: 'Signer updated successfully',
    },
  },
};
