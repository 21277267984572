import { branch } from 'baobab-react/higher-order';
import React from 'react';

const ErrorsUploadFile = ({
  errors,
  showTableErrors,
  handleTableErrors,
  generateShortId,
  translations,
  lang,
}) => {
  const { texts } = translations[lang].NewContractMassive.ErrorsUploadFile;
  return (
    <div className="container_errors">
      <div className="dropdown_tables">
        <span style={{ color: 'black' }}>{texts.span1(errors.length)}</span>
        {showTableErrors ? (
          <i className="fas fa-chevron-up" onClick={handleTableErrors}></i>
        ) : (
          <i className="fas fa-chevron-down" onClick={handleTableErrors}></i>
        )}
      </div>
      {showTableErrors ? (
        errors.length ? (
          errors.map((error, index) => (
            <div className="error" key={`error-${index}`}>
              <p className="doc_error" key={index}>
                {/*TODO: Translate response  */}
                {error}
              </p>
              <span className="message_error">{texts.span2}</span>
            </div>
          ))
        ) : (
          <p>{texts.p1}</p>
        )
      ) : null}
    </div>
  );
};

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  ErrorsUploadFile,
);
