export const NewTemplateTranslations = {
  es: {
    texts: {
      h1: 'Nueva Plantilla de Documentos',
    },
    inputs: {},
    buttons: {
      button1: 'Subir Plantilla',
    },
    notifications: {
      notifTitle1: 'Atención',
      notifBody1: 'No se pudo crear la plantilla',
      notifTitle2: 'Informativo',
      notifBody2: 'Agregado éxitosamente',
    },
  },
  en: {
    texts: {
      h1: 'New Document Template',
    },
    inputs: {},
    buttons: {
      button1: 'Upload Template',
    },
    notifications: {
      notifTitle1: 'Attention',
      notifBody1: 'Could not create template',
      notifTitle2: 'Info',
      notifBody2: 'Added successfully',
    },
  },
};
