import React, { Fragment } from 'react';
import Loader from '../../components/Loader';
import { NotificationManager } from 'react-notifications';
// Actions
import Signature from '../../utils/signature';
import SessionActions from '../../actions/Session';
import { branch } from 'baobab-react/higher-order';

class EfirmaForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cer: null,
      message: null,
      key: null,
      passphrase: '',
      demo: false,
      loading: false,
      register: false,
      showmodalsife: false,
    };
  }

  /**
   * Handle input changes
   * @param  {Object} event Input change event
   */
  onInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  /**
   * Create a fake file input to allow the user to input a file
   * @param  {Object} event Input click event
   */
  promptFile = (event) => {
    event.target.blur();
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    const { name } = event.target;

    if (name === 'cer') {
      input.setAttribute('accept', '.cer');
    } else {
      input.setAttribute('accept', '.key');
    }
    input.onchange = (e) => {
      const file = e.target.files[0];
      this.setState({ [name]: file });
    };
    input.click();
  };

  /**
   * Makes a Session Request for Existing User
   * @param event
   */
  handleSubmit = (event) => {
    event.preventDefault();
    const { cer, key, passphrase } = this.state;
    this.setState({ loading: true });
    Signature({
      cer,
      key,
      passphrase,
    })
      .then(() => {
        SessionActions.login(cer)
          .then((response) => {
            this.setState({ loading: false });
            if (response.error === 'Usuario no registrado') {
              NotificationManager.warning(
                `${response.error}. Se te redireccionará a la página de registro en 5 segundos`,
                'Advertencia',
                10000,
              );
              this.setState({ message: response.error });
              setTimeout(() => {
                window.location = '/register';
              }, 5000);
              return;
            }

            if (response.error) {
              NotificationManager.warning(response.error, 'Advertencia', 10000);
              this.setState({ message: response.error });
            }

            if (response.session && response.token) {
              localStorage.setItem('sessionType', 'efirma');
              window.location = localStorage.getItem('redirect')
                ? localStorage.getItem('redirect')
                : '/';
            }
          })
          .catch(() => {
            this.setState({ loading: false });
            NotificationManager.warning(
              'Credenciales inválidas',
              'Advertencia',
              10000,
            );
          });
      })
      .catch((error) => {
        this.setState({ loading: false });
        NotificationManager.warning(error.message, 'Advertencia', 10000);
      });
  };

  /**
   * Validates Submit Button
   * @returns {*}
   */
  canContinue = () => {
    const { cer, key, passphrase } = this.state;
    return cer && key && passphrase.length;
  };

  /**
   * Redirects to Demo
   */
  handleRegister = () => {
    this.setState({ register: true });
  };

  render() {
    const { translations, lang, setForm } = this.props;
    const { cer, key, passphrase, loading, message } = this.state;

    const translation = translations[lang].Login;
    const { texts, buttons, inputs } = translation;

    return (
      <Fragment>
        <div style={{ margin: '0 auto', marginBottom: 20 }}>
          <img
            src="https://sora-contratos.s3.us-east-2.amazonaws.com/images/soracontratos+nuevo.png"
            alt="logo"
            style={{ width: 250 }}
          />
        </div>
        <div className="sife_info">
          <h4>{texts.h4} e.firma(SAT)</h4>
        </div>
        <div className="no_account_sife">
          <small>{texts.small1}</small>{' '}
          <a href="/register" className="register_link">
            &nbsp;{texts.a}
          </a>{' '}
        </div>
        <div className="inputs_login_form">
          {message ? <p className="error_message"> {message}</p> : null}
          <input
            className="input_nc"
            onClick={this.promptFile}
            onChange={() => {}}
            name="cer"
            type="text"
            value={cer ? cer.name : ''}
            placeholder={inputs.cer}
          />
          <input
            className="input_nc"
            onClick={this.promptFile}
            onChange={() => {}}
            name="key"
            type="text"
            value={key ? key.name : ''}
            placeholder={inputs.key}
          />
          <input
            className="input_nc"
            onChange={this.onInputChange}
            name="passphrase"
            type="password"
            value={passphrase}
            placeholder={inputs.passphrase}
          />
        </div>
        <div className="start_session">
          {loading ? (
            <div className="center_loading">
              <Loader />
            </div>
          ) : (
            <button
              className="primary_button_landing session"
              hidden={loading}
              disabled={!this.canContinue()}
              type="button"
              onClick={this.handleSubmit}
            >
              {buttons.login}
            </button>
          )}
        </div>
        <div className="divider">
          <span className="divider-text">o</span>
        </div>
        <div className="footer_login">
          <button
            className="secondary_button_landing"
            type="button"
            onClick={() => setForm('sife')}
          >
            {buttons.loginForm} SIFE
          </button>
        </div>
      </Fragment>
    );
  }
}

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  EfirmaForm,
);
