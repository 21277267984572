import { CerFieldsTranslations } from './CerFields.translations';

export const CommonComponentsTranslations = {
  es: {
    texts: {},
    inputs: {},
    buttons: {},
    notifications: {},
    CerFields: { ...CerFieldsTranslations.es },
  },
  en: {
    texts: {},
    inputs: {},
    buttons: {},
    notifications: {},
    CerFields: { ...CerFieldsTranslations.en },
  },
};
