import { branch } from 'baobab-react/higher-order';
import React, { Fragment } from 'react';
import { NotificationManager } from 'react-notifications';
import SessionActions from '../../actions/Session';
import {
  sifeAuthOptions,
  sifeAuthUrl,
  trustedOrigins,
} from '../../API/instance';
import { setLocalStorage } from '../../helpers/sifeSessionHelpers';

const SifeForm = ({ lang, translations, setForm }) => {
  const translation = translations[lang].Login;
  const { texts, buttons } = translation;

  async function handleSifeMessage(e) {
    if (
      /^react-devtools/gi.test(e.data.source) ||
      e?.data?.message === 'openedReady'
    ) {
      e.target.removeEventListener('message', handleSifeMessage);
      return null;
    }
    const messageReply = { success: false };

    try {
      const res = await SessionActions.loginSife(e);

      if (res.data.error === 'Usuario no registrado') {
        const resRegister = await SessionActions.registerSife(e);
        if (resRegister.error) {
          e.source.postMessage(messageReply, e.origin);
          return NotificationManager.warning(
            resRegister.error,
            'Advertencia',
            10000,
          );
        }
        if (resRegister.success) {
          const res = await SessionActions.loginSife(e);
          if (res.status === 200) {
            e.source.postMessage({ ...messageReply, success: true }, e.origin);
            return setLocalStorage(res, 'sife');
          }
          e.source.postMessage(messageReply, e.origin);
          return NotificationManager.warning(
            res.data.error,
            'Advertencia',
            5000,
          );
        }
      } else {
        if (res.status === 200 && res.data.token && res.data.session) {
          e.source.postMessage({ ...messageReply, success: true }, e.origin);
          return setLocalStorage(res, 'sife');
        }
        e.source.postMessage(messageReply, e.origin);
        return NotificationManager.warning(
          res.data.error ? res.data.error : 'Hubo un error al iniciar sesión',
          'Advertencia',
          5000,
        );
      }
    } catch (error) {
      /* handle error */
      e.source.postMessage(messageReply, e.origin);
      e.target.removeEventListener('message', handleSifeMessage);
      return NotificationManager.warning(
        'Hubo un error al inciar sesión',
        'Advertencia',
        5000,
      );
    }
  }

  const windowOpen = ({ url, title, w, h }) => {
    try {
      // Fixes dual-screen position
      resetListeners();

      const width = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : window.screen.width;
      const height = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : window.screen.height;

      const left = width / 2 - w / 2;
      const top = height / 2 - h / 2;
      const targetWindow = window.open(
        url,
        title,
        `
      scrollbars=yes,
      width=${w}, 
      height=${h}, 
      top=${top}, 
      left=${left}
      `,
      );

      function handleMessage(event) {
        if (!trustedOrigins.includes(event.origin)) {
          return;
        }
        targetWindow.postMessage({ message: 'openerMessage' }, sifeAuthUrl);

        window.addEventListener('message', handleSifeMessage, false);
        window.removeEventListener('message', handleMessage, false);
      }

      const closeCheck = setInterval(function () {
        try {
          if (targetWindow == null || targetWindow.closed) {
            clearInterval(closeCheck);
            resetListeners();
          }
        } catch (ex) {}
      }, 500);

      function resetListeners() {
        // Stuff to do after window has closed
        window.removeEventListener('message', handleMessage, false);
        window.removeEventListener('message', handleSifeMessage, false);
      }

      window.addEventListener('message', handleMessage, false);
      if (window.focus) targetWindow.focus();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <div style={{ margin: '0 auto', marginBottom: 20 }}>
        <img
          src="https://sora-contratos.s3.us-east-2.amazonaws.com/images/soracontratos+nuevo.png"
          alt="logo"
          style={{ width: 250 }}
        />
      </div>
      <div className="sife_info">
        <h4>{texts.h4}</h4>
        <img
          src="https://sora-contratos.s3.us-east-2.amazonaws.com/images/Icon_Color_Sife.svg"
          alt="logo"
          style={{ maxWidth: 80 }}
        />
      </div>
      <div className="no_account_sife">
        <small>{texts.small1}</small>{' '}
        <a href="/register" className="register_link">
          &nbsp;{texts.a}
        </a>
      </div>
      <div>
        <button
          className="primary_button_landing"
          type="button"
          onClick={() =>
            windowOpen({
              url: `${sifeAuthUrl}${
                sifeAuthOptions.login
              }${sifeAuthOptions.lang()}`,
              title: 'Sora Contratos',
              w: 700,
              h: 800,
            })
          }
        >
          {buttons.login}
        </button>
      </div>

      <div className="divider">
        <span className="divider-text">o</span>
      </div>

      <div className="footer_login">
        <button
          className="secondary_button_landing"
          type="button"
          onClick={() => setForm('efirma')}
        >
          {buttons.loginForm} e.firma(SAT)
        </button>
      </div>
    </Fragment>
  );
};

export default branch(
  { translations: ['translations'], lang: ['lang'] },
  SifeForm,
);
