import React from 'react';
import { branch } from 'baobab-react/higher-order';
import { sifeSingleSignUrl } from '../../API/instance';
import ContractActions from '../../actions/Contract';
import AddendumActions from '../../actions/AddendumActions';
import { NotificationManager } from 'react-notifications';

class IframeToSign extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeVerification: true,
    };
  }

  componentDidMount = () => {
    window.addEventListener('message', this.handleSignContract);
    window.addEventListener('message', this.handleOpenerMessage);
  };

  componentWillUnmount = () => {
    window.removeEventListener('message', this.handleOpenerMessage);
    window.removeEventListener('message', this.handleSignContract);
  };

  handleSignContract = async (e) => {
    const { translations, lang } = this.props;
    const { notifications } = translations[lang].Modals.IframeToSign;
    if (/^react-devtools/gi.test(e.data.source)) {
      return null;
    }

    const {
      contract,
      signer,
      originalContract,
      updateStateSignContract,
      type,
    } = this.props;

    console.log(e.data.message);
    if (e.data.message === 'SINGLE_SIGN_STARTED') {
      this.setState({ activeVerification: false });
      // this.setState({ width: '40vw', height: '50vh' });
    }

    if (e.data.message === 'DOC_SIGNED' && type === 'signer') {
      return (window.location = '/perfil');
    }

    if (type === 'signer') return;

    if (e.data.message === 'DOC_SIGNED' && type === 'contract') {
      if (signer.requestVerification) {
        signer.verified = true;
        const participants = await ContractActions.getThisSigners(contract.id);
        updateStateSignContract({
          verificationDocs: participants.verificationDocs,
        });
      }
      const contractResponse = await ContractActions.view(contract.id);
      signer.signed = 1;
      signer.signedAt = new Date();
      updateStateSignContract({
        contract: contractResponse,
        isMyTurnToSign: false,
        loading: false,
        modal: 'contract',
        showIframeToSign: false,
      });
      return NotificationManager.success(
        notifications.notifBody1,
        notifications.notifTitle1,
        5000,
      );
    }

    if (e.data.message === 'DOC_SIGNED' && type === 'addendum') {
      const addendums = await AddendumActions.getThisAddendums(
        originalContract.id,
      );
      signer.signed = true;
      updateStateSignContract({
        addendums,
        signerAddendum: signer,
        isMyTurnToSignAddendum: false,
        modal: 'addendum',
        showIframeToSignAddendum: false,
      });

      NotificationManager.success(
        notifications.notifBody2,
        notifications.notifTitle2,
        10000,
      );
    }
  };

  handleOpenerMessage = (e) => {
    if (
      /^react-devtools/gi.test(e.data.source) ||
      e.data.message !== 'openedReady'
    ) {
      window.removeEventListener('message', this.handleOpenerMessage, false);
      return null;
    }
    const iframeId = 'sife-sign-iframe';
    const iframe = document.getElementById(iframeId);
    const targetWindow = iframe?.contentWindow;

    if (targetWindow) {
      window.addEventListener('message', this.handleSignContract, false);
      window.removeEventListener('message', this.handleOpenerMessage, false);
    }
  };

  closeModal = () => {
    const { updateStateSignContract, type, closeIframe } = this.props;
    if (type === 'contract') {
      updateStateSignContract({ showIframeToSign: false });
    }
    if (type === 'addendum') {
      updateStateSignContract({ showIframeToSignAddendum: false });
    }
    if (type === 'signer') {
      closeIframe();
    }

    if (type === 'api_contract') {
      updateStateSignContract();
    }
  };

  render() {
    const { activeVerification } = this.state;
    const { contract, translations, lang } = this.props;
    const { buttons } = translations[lang].Modals.IframeToSign;
    return (
      <div className="modal-container">
        <div
          className={`modal ${
            activeVerification ? 'active_verification_sife' : ''
          }`}
        >
          <iframe
            id="sife-sign-iframe"
            title="preview"
            src={
              sifeSingleSignUrl +
              `${contract.unique_id}?ntb=1&min=1&lang=${lang}`
            }
            frameBorder="0"
            allowFullScreen
            allow="camera; microphone; geolocation"
          />
          <div className="footer_modal">
            <button
              className="color-grey"
              type="button"
              onClick={this.closeModal}
            >
              {buttons.button1}
            </button>
          </div>
        </div>
        <div className="background-screen" />
      </div>
    );
  }
}

export default branch(
  {
    session: ['session'],
    contractUrl: ['contractUrl'],
    translations: ['translations'],
    lang: ['lang'],
  },
  IframeToSign,
);
