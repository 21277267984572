export const CredentialsModalTranslations = {
  es: {
    texts: {
      h3: 'Ingresa tu e.firma (SAT)',
      p1: 'Para acceder a todas las funcionalidades de este perfil',
    },
    inputs: {
      inputPlaceholder1: 'Certificado (.cer)',
      inputPlaceholder2: 'Llave (.key)',
      inputPlaceholder3: 'Clave Privada',
    },
    buttons: {
      button1: 'Continuar',
    },
    notifications: {},
  },
  en: {
    texts: {
      h3: 'Enter your e.signature (SAT)',
      p1: 'To access all the functionalities of this profile',
    },
    inputs: {
      inputPlaceholder1: 'Certificate (.cer)',
      inputPlaceholder2: 'Key (.key)',
      inputPlaceholder3: 'Private code (password)',
    },
    buttons: {
      button1: 'Continue',
    },
    notifications: {},
  },
};
