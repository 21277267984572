import { urlBuilder } from '../helpers/apiHelpers';
import {
  ApiEPs,
  SuperUserEPs,
  SessionEPs,
  ContractEPs,
  AddendumEPs,
  ProfileEPs,
  LabelsEPs,
} from './endpoints';

const createEndpoint = (instance, url) => {
  return {
    request: (reqConfig) => {
      return instance.request({ ...reqConfig, url });
    },
    get: (reqConfig, urlVar) => {
      return instance.get(urlBuilder(url, urlVar), { ...reqConfig });
    },
    delete: (data, reqConfig, urlVar) => {
      return instance.delete(urlBuilder(url, urlVar), data, { ...reqConfig });
    },
    post: (data, reqConfig = {}, urlVar) => {
      return instance.post(urlBuilder(url, urlVar), data, {
        ...reqConfig,
      });
    },
    put: (data, reqConfig = {}, urlVar) => {
      return instance.put(urlBuilder(url, urlVar), data, {
        ...reqConfig,
      });
    },
    patch: (reqConfig, urlVar) => {
      return instance.patch(urlBuilder(url, urlVar), reqConfig.data, {
        ...reqConfig,
      });
    },
    defaults: instance.defaults,
  };
};

export const endpointHandler = () => {
  // createEndpoint(<endpoint>, <instance>)
  return {
    ...ApiEPs(createEndpoint),
    ...SuperUserEPs(createEndpoint),
    ...SessionEPs(createEndpoint),
    ...ContractEPs(createEndpoint),
    ...AddendumEPs(createEndpoint),
    ...ProfileEPs(createEndpoint),
    ...LabelsEPs(createEndpoint),
  };
};
