import state from '../state';
import {
  assignService,
  createLabelService,
  listLabelService,
  listRootService,
  myLabelsService,
  removeContractLabelsService,
  rootLabelsService,
} from '../API/services/labelsServices';
const LabelActions = {
  /**
   * Create a new Label
   * @param  {Object} properties Label properties
   * @return {Promise}
   */
  async create(properties) {
    const payload = {
      content: properties.content,
      color: properties.color,
      rootUser: properties.rootUser ? properties.rootUser : null,
    };
    const { data } = await createLabelService(payload);
    state.select(['myLabels']).push(data);
    return data;
    // return labelsAPI.post('/create', payload).then((res) => {
    //   state.select(['myLabels']).push(res.data);
    //   return res.data;
    // });
  },

  /**
   * @param properties
   * @returns {Promise}
   */
  async assign(properties) {
    const payload = {
      contract: properties.contract,
      label: properties.label,
      rootUser: properties.rootUser,
    };
    const { data } = await assignService(payload);
    return data;
    // return labelsAPI.post('/assign', payload).then((res) => res.data);
  },

  /**
   * @returns {Promise}
   */
  async list() {
    const { data } = await listLabelService();
    state.select(['labels']).set(data);
    return data;

    // return labelsAPI.get('/list').then((res) => {
    //   state.select(['labels']).set(res.data);
    //   return res.data;
    // });
  },

  /**
   * @returns {Promise}
   * @param userId
   */
  async listRoot(userId) {
    const { data } = await listRootService({}, { userId });
    state.select(['labels']).set(data);
    return data;
    // return labelsAPI.get(`/list/root/${userId}`).then((res) => res.data);
  },

  /**

   * @returns {Promise}
   */
  async myLabels(id) {
    const { data } = await myLabelsService({}, { id });
    state.select(['myLabels']).set(data);
    return data;
    // return labelsAPI.get('/mine').then((res) => {
    //   state.select(['myLabels']).set(res.data);
    //   return res.data;
    // });
  },

  /**
   * @returns {Promise}
   * @param userId
   */
  async rootLabels(userId) {
    const { data } = await rootLabelsService({}, { userId });
    state.select(['myLabels']).set(data);
    return data;
    // return labelsAPI.get(`/root/${userId}`).then((res) => res.data);
  },

  /**

   * @param contractId
   * @returns {Promise}
   */
  async removeContractLabels(contractId, labelId) {
    const payload = {
      contract: contractId,
      label: labelId,
    };
    const response = await removeContractLabelsService(payload);
    return response;

    // return labelsAPI.post('/remove', payload).then((res) => res.data);
  },
};

export default LabelActions;
