import { branch } from 'baobab-react/higher-order';
import React from 'react';
import { NotificationManager } from 'react-notifications';
import SessionActions from '../../actions/Session';
import {
  sifeAuthOptions,
  sifeAuthUrl,
  trustedOrigins,
} from '../../API/instance';
import { setLocalStorage } from '../../helpers/sifeSessionHelpers';

const SifeRegisterForm = ({ translations, lang, setForm }) => {
  const translation = translations[lang].Registration;
  const { texts, buttons } = translation;
  function setContractInLocalStorage() {
    const params = window.location.search;
    const urlParams = new URLSearchParams(params);
    //Accedemos a los valores
    const contractId = urlParams?.get('contratos');
    const publicContract = urlParams?.get('public');
    if (contractId) {
      localStorage.setItem('redirect', `/contratos/${contractId}`);
    }

    if (publicContract) {
      localStorage.setItem('redirect', `/public/contracts/${publicContract}`);
    }
    return params;
  }

  async function handleSifeMessage(e) {
    if (
      /^react-devtools/gi.test(e.data.source) ||
      e?.data?.message === 'openedReady'
    ) {
      e.target.removeEventListener('message', handleSifeMessage);
      return null;
    }

    const messageReply = { success: false };

    try {
      const response = await SessionActions.registerSife(e);
      if (response.error) {
        if (response.error === 'Usuario ya registrado en contratos') {
          const res = await SessionActions.loginSife(e);
          if (res.status === 200) {
            e.source.postMessage({ ...messageReply, success: true }, e.origin);
            return setLocalStorage(res, 'sife');
          }
          e.source.postMessage(messageReply, e.origin);
          return NotificationManager.warning(
            res.data.error,
            'Advertencia',
            5000,
          );
        }
        e.source.postMessage(messageReply, e.origin);
        return NotificationManager.warning(
          response?.data?.error || 'Error al iniciar sesión con sife en SC',
          'Advertencia',
          5000,
        );
      }

      if (response.success) {
        const res = await SessionActions.loginSife(e);
        if (res.status === 200) {
          e.source.postMessage({ ...messageReply, success: true }, e.origin);
          return setLocalStorage(res, 'sife');
        }
        e.source.postMessage(messageReply, e.origin);
        return NotificationManager.warning(res.error, 'Advertencia', 5000);
      }
    } catch (e) {
      console.log(e);
      /* handle error */
      window.removeEventListener('message', handleSifeMessage);
      return NotificationManager.warning(
        'Hubo un error al inciar sesión en contratos',
        'Advertencia',
        5000,
      );
    }
  }

  const windowOpen = ({ url, title, w, h }) => {
    // Fixes dual-screen position Most browsers Firefox
    resetListeners();
    setContractInLocalStorage();

    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.screen.width;
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : window.screen.height;

    const left = width / 2 - w / 2;
    const top = height / 2 - h / 2;
    const targetWindow = window.open(
      url,
      title,
      `
          scrollbars=yes,
          width=${w}, 
          height=${h}, 
          top=${top}, 
          left=${left}
          `,
    );
    // FIXME: for some reason loading executes onunload and not onload

    function handleMessage(event) {
      if (!trustedOrigins.includes(event.origin)) {
        return;
      }

      targetWindow.postMessage({ message: 'openerMessage' }, sifeAuthUrl);
      window.addEventListener('message', handleSifeMessage, false);
      window.removeEventListener('message', handleMessage, false);
    }

    const closeCheck = setInterval(function () {
      try {
        if (targetWindow == null || targetWindow.closed) {
          clearInterval(closeCheck);
          resetListeners();
        }
      } catch (ex) {}
    }, 500);

    function resetListeners() {
      // Stuff to do after window has closed
      window.removeEventListener('message', handleMessage, false);
      window.removeEventListener('message', handleSifeMessage, false);
    }

    window.addEventListener('message', handleMessage, false);
    // targetWindow.onunload = onLoadFrame;
    // targetWindow.onload = onLoadFrame;
    if (window.focus) targetWindow.focus();
    // setWindowObjRef(targetWindow);
  };

  return (
    <div
      onLoad={() =>
        window.location?.search?.includes('?rfc=') &&
        windowOpen({
          url: `${sifeAuthUrl}${
            window.location.search
              ? `${
                  sifeAuthOptions.signUpData
                }&${window?.location?.search?.slice(
                  1,
                )}${sifeAuthOptions.lang()}`
              : `${sifeAuthOptions.signup}${sifeAuthOptions.lang()}`
          }`,
          title: 'Sora Contratos',
          w: 700,
          h: 800,
        })
      }
    >
      <div style={{ margin: '0 auto', marginBottom: 20 }}>
        <img
          src="https://sora-contratos.s3.us-east-2.amazonaws.com/images/soracontratos+nuevo.png"
          alt="logo"
          style={{ width: 250 }}
        />
      </div>
      <div className="sife_info">
        <h4>{texts.h4}</h4>
        <img
          src="https://sora-contratos.s3.us-east-2.amazonaws.com/images/Icon_Color_Sife.svg"
          alt="logo"
          style={{ maxWidth: 80 }}
        />
      </div>
      <div className="no_account_sife">
        <small>{texts.small1}</small>{' '}
        <a href="/login" className="register_link">
          &nbsp;{texts.a}
        </a>
      </div>
      <div>
        <button
          className="primary_button_landing"
          type="button"
          onClick={() =>
            windowOpen({
              url: `${sifeAuthUrl}${
                window.location.search
                  ? `${
                      sifeAuthOptions.signUpData
                    }&${window?.location?.search?.slice(1)}` +
                    sifeAuthOptions.lang()
                  : sifeAuthOptions.signup + sifeAuthOptions.lang()
              }`,
              title: 'Sora Contratos',
              w: 700,
              h: 800,
            })
          }
        >
          {buttons.send}
        </button>
      </div>

      <div className="divider">
        <span className="divider-text">o</span>
      </div>

      <div className="footer_login">
        <button
          className="secondary_button_landing"
          type="button"
          onClick={() => setForm('efirma')}
        >
          {buttons.registerForm} e.firma(SAT)
        </button>
      </div>
    </div>
  );
};

export default branch(
  { translations: ['translations'], lang: ['lang'] },
  SifeRegisterForm,
);
