import { AuthorizersTranslations } from './Authorizers.translations';
import { ChipsTranslations } from './Chips.translations';
import { ContractInputsTranslations } from './ContractInputs.translations';
import { DropzoneTranslations } from './Dopzone.translations';
import { RecipientsTranslations } from './Recipients.translations';
import { SignersTranslations } from './Signers.translations';
import { UploadButtonTranslations } from './UploadButton.translations';

export const NewContractTranslations = {
  es: {
    texts: {
      h1: 'Nuevo Documento',
      genericModalTitle1: 'Información',
      genericModalBody1:
        'Tus cupones para crear documentos han terminado. Comunicate con soporte@sora.mx',
      genericModalBody2: (total) =>
        `Restan ${total} cupones para poder crear documentos. Si deseas adquirir más comunicate con soporte@sora.mx`,
    },
    inputs: {},
    buttons: {
      genericModalConfirmButton1: 'Continuar',
      genericModalCancelButton1: 'Cerrar',
    },
    notifications: {
      notifTitle1: 'Atención',
      notifBody1: (fileName) =>
        `El peso del documento ${fileName} debe ser menor a 10 MB`,
      notifTitle2: 'Error',
      notifBody2: 'Hubo un error al crear el documento',
    },
    ContractInputs: { ...ContractInputsTranslations.es },
    Dropzone: { ...DropzoneTranslations.es },
    Chips: { ...ChipsTranslations.es },
    Authorizers: { ...AuthorizersTranslations.es },
    Signers: { ...SignersTranslations.es },
    Recipients: { ...RecipientsTranslations.es },
    UploadButton: { ...UploadButtonTranslations.es },
  },
  en: {
    texts: {
      h1: 'New Document',
      genericModalTitle1: 'Information',
      genericModalBody1:
        'Your coupons to create documents have ended. Please contact soporte@sora.mx',
      genericModalBody2: (total) =>
        `There are ${total} coupons left to create documents. If you want to acquire more, please contact support@sora.mx.`,
    },
    inputs: {},
    buttons: {
      genericModalConfirmButton1: 'Continue',
      genericModalCancelButton1: 'Close',
    },
    notifications: {
      notifTitle1: 'Warning',
      notifBody1: (fileName) =>
        `The size of the document ${fileName} must be less than 10 MB`,
      notifTitle2: 'Error',
      notifBody2: 'There was an error creating the document',
    },
    ContractInputs: { ...ContractInputsTranslations.en },
    Dropzone: { ...DropzoneTranslations.en },
    Chips: { ...ChipsTranslations.en },
    Authorizers: { ...AuthorizersTranslations.en },
    Signers: { ...SignersTranslations.en },
    Recipients: { ...RecipientsTranslations.en },
    UploadButton: { ...UploadButtonTranslations.en },
  },
};
