import { branch } from 'baobab-react/higher-order';
import React from 'react';

class CredentialsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirming: false,
    };
  }

  showConfirmation = () => {
    this.setState({ confirming: true });
  };

  /**
   * Shows the File Input and Accepts Key & Cer
   * @param event
   */
  promptFile = (event) => {
    event.target.blur();
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    const { name } = event.target;
    if (name === 'cer') {
      input.setAttribute('accept', '.cer');
    } else {
      input.setAttribute('accept', '.key');
    }
    input.onchange = (e) => {
      const file = e.target.files[0];
      this.setState({ [name]: file });
    };
    input.click();
  };

  /**
   * Handle input changes
   * @param  {Object} event Input change event
   */
  onInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  /**
   * Can Sign the Contract if all inputs
   * @returns {*}
   */
  canSign = () => {
    const { key, cer, passphrase } = this.state;
    return passphrase && key && cer;
  };

  render() {
    const { cer, key, passphrase } = this.state;
    const { handleSubmit, translations, lang } = this.props;
    const { texts, inputs, buttons } =
      translations[lang].Modals.CredentialsModal;
    return (
      <div className="container_protected_user">
        <div className="info_protected_user">
          <i
            className="fas fa-lock"
            style={{ fontSize: 50, color: '#2851e5' }}
          ></i>
          <h3>{texts.h3}</h3>
          <p>{texts.p1}</p>
        </div>
        <div className="protected_users">
          <input
            onClick={this.promptFile}
            onChange={() => {}}
            name="cer"
            type="text"
            required
            value={cer ? cer.name : ''}
            placeholder={inputs.inputPlaceholder1}
            className="input_nc"
          />
          <input
            onClick={this.promptFile}
            onChange={() => {}}
            name="key"
            type="text"
            required
            value={key ? key.name : ''}
            placeholder={inputs.inputPlaceholder2}
            className="input_nc"
          />
          <input
            onChange={this.onInputChange}
            name="passphrase"
            type="password"
            required
            value={passphrase || ''}
            placeholder={inputs.inputPlaceholder3}
            className="input_nc"
          />
        </div>
        <div className="actions_protected_users">
          <button
            className="primary"
            type="button"
            disabled={!this.canSign()}
            onClick={() => handleSubmit(this)}
          >
            {buttons.button1}
          </button>
        </div>
      </div>
    );
  }
}

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  CredentialsModal,
);
