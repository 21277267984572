import React from 'react';
import Loader from '../Loader';
import ContractActions from '../../actions/Contract';
import { NotificationManager } from 'react-notifications';
import state from '../../state';
import SessionActions from '../../actions/Session';

class UpdateUserModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        legalRfcUpdated: true,
        soraId: false,
      },
      newRfc: '',
      loading: false,
      success: false,
    };
  }

  handleChange = (e) => {
    const { name, value, checked } = e.target;
    const { options } = this.state;
    const optionsUpdated = { ...options };

    if (name === 'legalRfc_update') {
      this.setState({ newRfc: value?.toUpperCase() });
    }

    if (value === 'legalRfc_option') {
      optionsUpdated.legalRfcUpdated = checked;
      optionsUpdated.soraId = !checked;
      return this.setState({ options: optionsUpdated });
    }

    if (value === 'soraId') {
      optionsUpdated.soraId = checked;
      optionsUpdated.legalRfcUpdated = !checked;
      return this.setState({ options: optionsUpdated });
    }
  };

  disabledButton = () => {
    const { options, newRfc } = this.state;
    if (options.legalRfcUpdated && newRfc?.length > 11) {
      return true;
    }

    if (options.soraId) {
      return true;
    }

    return false;
  };

  updateUser = async () => {
    const { options, newRfc } = this.state;
    const { session } = this.props;
    this.setState({ loading: true });
    const response = await ContractActions.updateUserSoraId({
      options,
      newRfc,
    });
    if (!response?.data?.success) {
      this.setState({ loading: false });
      return NotificationManager.error(response.data.error, 'Atención', 10000);
    }
    this.setState({ loading: false });
    if (options.soraId) {
      const sessionUpdated = { ...session?.user };
      sessionUpdated.lastLegalRfc = session?.user?.legalRfc;
      const newSession = { user: sessionUpdated };
      state.select(['session']).set(newSession);
      localStorage.setItem('session', JSON.stringify(newSession));
      return window.location.reload();
    }
    this.setState({ success: true });
    localStorage.removeItem('session');
    localStorage.removeItem('token');
    setTimeout(() => {
      SessionActions.logout();
    }, 5000);
  };

  render() {
    const { options, loading, success } = this.state;
    return (
      <div className="modal-container">
        <div className="modal">
          <div className="header_modal">
            {success ? (
              ''
            ) : (
              <h1 style={{ color: '#2851e5' }}>
                Completa los datos de tu perfil
              </h1>
            )}
          </div>
          {!loading ? (
            <div className="body_modal">
              {success ? (
                <div
                  style={{
                    textAlign: 'center',
                    width: '70%',
                    margin: '0 auto',
                  }}
                >
                  <i
                    className="fas fa-check-circle fa-2x"
                    style={{
                      color: '#7ED321',
                      marginBottom: 20,
                      marginTop: 50,
                    }}
                  />{' '}
                  <br></br>
                  <p>Tu RFC se actualizó exitosamente</p>
                </div>
              ) : (
                <>
                  <p>Elige una de las siguientes opciones para continuar</p>
                  <p style={{ textAlign: 'center' }}>¿Tienes RFC?</p>
                  <div className="choose_rfc_option">
                    <div>
                      <input
                        style={{ marginRight: 5 }}
                        type="radio"
                        id="legalRfc_option"
                        name="signerType"
                        value="legalRfc_option"
                        checked={options.legalRfcUpdated}
                        onChange={this.handleChange}
                      />
                      <label for="legalRfc_option">Si</label>
                    </div>

                    <div>
                      <input
                        style={{ marginRight: 5 }}
                        type="radio"
                        id="soraId"
                        name="signerType"
                        value="soraId"
                        checked={options.soraId}
                        onChange={this.handleChange}
                      />
                      <label for="soraId">No</label>
                    </div>
                  </div>
                  {options.legalRfcUpdated && (
                    <input
                      onChange={this.handleChange}
                      name="legalRfc_update"
                      type="text"
                      className="input_nc"
                      required
                      placeholder="Ingresa tu RFC aquí..."
                      autoFocus
                      style={{ textTransform: 'uppercase' }}
                    />
                  )}
                </>
              )}
            </div>
          ) : (
            <div className="center_loading">
              <Loader />
              <p>Asignando documentos...</p>
            </div>
          )}
          <div className="footer_modal">
            <small></small>
            <button
              className="primary"
              type="button"
              disabled={!this.disabledButton()}
              onClick={() =>
                success ? SessionActions.logout() : this.updateUser()
              }
            >
              {success ? 'Continuar' : 'Confirmar'}
            </button>
          </div>
        </div>
        <div className="background-screen" />
      </div>
    );
  }
}

export default UpdateUserModal;
