import { contratosInstance } from '../instance';
import { AddendumRoutes } from '../routes';

export const AddendumEPs = (createEndpoint) => {
  return {
    fileAddendumEP: createEndpoint(
      contratosInstance,
      AddendumRoutes.fileAddendum,
    ),
    createAddendumSifeEP: createEndpoint(
      contratosInstance,
      AddendumRoutes.createAddendumSife,
    ),
    signAddendumEP: createEndpoint(
      contratosInstance,
      AddendumRoutes.signAddendum,
    ),
    createAddendumEP: createEndpoint(
      contratosInstance,
      AddendumRoutes.createAddendum,
    ),
    getThisSignersAddendumEP: createEndpoint(
      contratosInstance,
      AddendumRoutes.getThisSignersAddendum,
    ),
    getThisAddendumsEP: createEndpoint(
      contratosInstance,
      AddendumRoutes.getThisAddendums,
    ),
    getThisAddendumsWithIdEP: createEndpoint(
      contratosInstance,
      AddendumRoutes.getThisAddendumsWithId,
    ),
  };
};
