import React from 'react';
import { branch } from 'baobab-react/higher-order';
import Checkbox from 'react-simple-checkbox';
import ProfileActions from '../../actions/Profile';
import { NotificationManager } from 'react-notifications';
import state from '../../state';

class LablesSuperUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      readPP: false,
    };
  }

  componentDidMount = () => {};

  handleChange = (value) => {
    this.setState({ readPP: value });
  };

  handleUpdate = async () => {
    const { readPP } = this.state;
    const {
      session: { user },
      translations,
      lang,
    } = this.props;
    const { notifications } = translations[lang].Modals.PrivacyPolicyModal;

    try {
      const response = await ProfileActions.updateUserData(
        { userId: user.id },
        { privacyPolicyAccepted: Boolean(readPP) },
      );

      if (!response?.success) {
        return NotificationManager.error(
          response?.message || notifications.notifBody1,
          notifications.notifTitle1,
          5000,
        );
      }
      const userUpdated = { user: { ...user } };
      userUpdated.user.privacyPolicyAccepted = Boolean(readPP);
      localStorage.setItem('session', JSON.stringify(userUpdated));
      state.select(['session']).set(userUpdated);
      state.commit();

      return NotificationManager.success(
        notifications.notifBody2 || response?.message,
        notifications.notifTitle2,
        5000,
      );
    } catch (error) {
      return NotificationManager.error(
        notifications.notifBody3,
        notifications.notifTitle3,
        5000,
      );
    }
  };

  render() {
    const { readPP } = this.state;
    const { translations, lang, width } = this.props;
    const { texts, buttons } = translations[lang].Modals.PrivacyPolicyModal;

    return (
      <>
        <div className="modal-container">
          <div
            className="modal"
            style={{
              width: 600,
              margin: '0 auto',
              paddingLeft: 24,
              paddingRight: 24,
              paddingTop: 15,
            }}
          >
            <div className="header_modal">
              <h4>{texts.h4}</h4>
            </div>
            <div className="body_modal">
              <small
                style={{ fontWeight: 400, fontSize: 15, color: '#637381' }}
              >
                {texts.small1}
              </small>
              <br></br>
              <a
                href="https://sora.mx/politica-de-privacidad/"
                target="_blank"
                rel="noreferrer"
                style={{ fontSize: 15, lineHeight: 2 }}
              >
                https://sora.mx/politica-de-privacidad/
              </a>
              <br></br>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  color={{
                    backgroundColor: '#2648E8',
                    borderColor: '#2648E8',
                    uncheckedBorderColor: '#637381',
                    tickColor: '#fff',
                  }}
                  size="2.2"
                  borderThickness="1.2"
                  tickSize="2"
                  backAnimationDuration={100}
                  tickAnimationDuration={200}
                  checked={readPP}
                  onChange={this.handleChange}
                />
                <div style={{ marginTop: 10, marginLeft: 5 }}>
                  <small style={{ fontSize: 13, lineHeight: 2 }}>
                    {texts.small2}
                  </small>
                </div>
              </div>
            </div>

            <div className="footer_modal">
              <small></small>
              <button
                className="primary"
                type="button"
                disabled={!readPP}
                onClick={this.handleUpdate}
              >
                {buttons.button1}
              </button>
            </div>
          </div>
          <div className="background-screen" />
        </div>
      </>
    );
  }
}

export default branch(
  {
    session: ['session'],
    translations: ['translations'],
    lang: ['lang'],
    width: ['width'],
  },
  LablesSuperUser,
);
