import React from 'react';
import { branch } from 'baobab-react/higher-order';
import { NotificationManager } from 'react-notifications';
import ContractActions from '../../actions/Contract';
import Loader from '../../components/Loader';

class UploadIdentityDocuments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      myDocuments: [],
      signersDocuments: [],
    };
  }

  componentDidMount = async () => {
    const { myDocuments } = this.props;
    this.setState({ myDocuments });
  };

  onInputDocumentChange = (e, index) => {
    const { lang, translations } = this.props;
    const { notifications } =
      translations[lang].ContractDetail.SignContractDisplay.UploadIdentityDocs;
    const { files } = e.target;
    const file = files[0];
    if (file.size > 10485760) {
      return NotificationManager.error(
        notifications.notifBody1,
        notifications.notifTitle1,
        5000,
      );
    }
    const { myDocuments } = this.props;
    const allMyDocuments = [...myDocuments];
    allMyDocuments[index]['selected'] = true;
    allMyDocuments[index]['fileToUpload'] = files[0];
    this.setState({ myDocuments: allMyDocuments });
  };

  onClickUploadIdentityDocuments = async () => {
    const {
      myDocuments,
      lang,
      translations,
      session: { user },
      updateStateIdentityDocuments,
    } = this.props;

    const { notifications } =
      translations[lang].ContractDetail.SignContractDisplay.UploadIdentityDocs;
    this.setState({ loading: true });
    const totalResponses = [];
    myDocuments.forEach(async (doc) => {
      const formData = new FormData();
      formData.append('file', doc.fileToUpload);
      formData.append('docType', doc.document);
      formData.append('contract', doc.contractId);
      formData.append('idDoc', doc.id);
      const response = await ContractActions.uploadIdentityDocuments(formData);
      if (response.data.error) {
        this.setState({ loading: false });
        // TODO: translate response
        return NotificationManager.error(response.data.error, 'Error', 3000);
      }
      totalResponses.push(1);
      const uploadedDocuments = response.data.filter(
        (document) => document.userId === user.id,
      );
      this.setState({
        signersDocuments: response.data,
        myDocuments: uploadedDocuments,
      });
      updateStateIdentityDocuments({
        signersDocuments: response.data,
        myDocuments: uploadedDocuments,
      });
      if (totalResponses.length === myDocuments.length) {
        this.setState({ loading: false });
        return NotificationManager.success(
          notifications.notifBody3,
          notifications.notifTitle3,
          3000,
        );
      }
    });
  };

  render() {
    const { myDocuments, loading } = this.state;
    const { showModalContractSigned, lang, translations } = this.props;
    const { texts, buttons } =
      translations[lang].ContractDetail.SignContractDisplay.UploadIdentityDocs;
    return loading ? (
      <div className="center_loading">
        <Loader />
        <p>{texts.p1}</p>
      </div>
    ) : (
      <>
        <div className="main_documents identity_docs">
          <p className="title_identity_docs" style={{ color: '#2851e5' }}>
            {texts.p2}
          </p>
          <div className="uploaded_identity_docs">
            {myDocuments.map((document, index) =>
              !document.uploaded ? (
                <label
                  className="custom-file-upload"
                  style={{
                    border: document.selected ? '1px solid #7ed321' : null,
                    color: document.selected ? '#7ed321' : null,
                  }}
                >
                  <input
                    type="file"
                    className="identityDocument"
                    onChange={(e) => this.onInputDocumentChange(e, index)}
                    accept="image/png, image/jpeg, application/pdf"
                  />
                  {document.document} <i className="fas fa-upload"></i>
                </label>
              ) : null,
            )}
          </div>

          <button
            disabled={!myDocuments.every((document) => document.selected)}
            className="primary"
            onClick={this.onClickUploadIdentityDocuments}
          >
            {buttons.button1}
          </button>
        </div>
        <button
          style={{ background: '#6C6C6C' }}
          type="button"
          onClick={showModalContractSigned}
        >
          {buttons.button2}
        </button>
      </>
    );
  }
}

export default branch(
  {
    session: ['session'],
    translations: ['translations'],
    lang: ['lang'],
  },
  UploadIdentityDocuments,
);
