import { contratosInstance } from '../instance';
import { SessionRoutes } from '../routes';

export const SessionEPs = (createEndpoint) => {
  return {
    registerEP: createEndpoint(contratosInstance, SessionRoutes.register),
    activateEP: createEndpoint(contratosInstance, SessionRoutes.activate),
    requestDemoEP: createEndpoint(contratosInstance, SessionRoutes.requestDemo),
    loginEP: createEndpoint(contratosInstance, SessionRoutes.login),
    loginSifeEP: createEndpoint(contratosInstance, SessionRoutes.loginSife),
    sendApiFormEP: createEndpoint(contratosInstance, SessionRoutes.sendApiForm),
    registerSifeEP: createEndpoint(
      contratosInstance,
      SessionRoutes.registerSife,
    ),
  };
};
