export const RegistrationTranslations = {
  es: {
    texts: {
      h1: '¡Iniciemos!',
      h4: 'Registro con',
      small1: '¿Ya tienes cuenta?',
      p2: 'Ingresa tu correo electrónico o el certificado de tu e-firma(SAT) con el que crearemos tu usuario',
      p3: '¿No tienes e-firma?',
      p4: 'o regístrate con',
      p5: 'Revisa tu correo. Recibirás un mensaje de Sora Contratos con un enlace para validar tu cuenta.',
      a: 'Inicia sesión',
      or: 'o',
      small2: 'He leído y acepto el',
      small3: 'aviso de privacidad ',
      small4: 'de Sora',
      small5:
        'Nota: El uso de la e.firma se utiliza exclusivamente con el propósito de registrarse y firmar documentos dentro de nuestra plataforma',
    },
    inputs: {
      email: 'Correo electrónico',
      cer: 'Certificado (.cer)',
      key: 'Llave privada (.key)',
      passphrase: 'Clave privada (contraseña)',
    },
    buttons: {
      send: 'Registrar',
      testDemoUser: 'Pruebala con Usuario DEMO',
      applyESignature: 'Tramita tu e-firma con el SAT',
      registerForm: 'Registrar con',
    },
  },
  en: {
    texts: {
      h1: "Let's start!",
      h2: 'Signup with',
      small1: 'Already have an account?',
      p2: 'Enter your email or certificate of your e.firma(SAT) with which we will create your user',
      p3: "Don't have an e-signature?",
      p4: 'or signup with',
      p5: 'Check your email. You will receive a Sora Contratos message with a link to validate your account.',
      a: 'Login',
      or: 'or',
      small2: 'I have read and accept the',
      small3: 'privacy notice ',
      small4: 'of Sora',
      small5:
        'Note: The use of the e-signature is exclusively for the purpose of registering and signing documents within our platform',
    },
    inputs: {
      email: 'Email',
      cer: 'Certificate (.cer)',
      key: 'Private key (.key)',
      passphrase: 'Private code (password)',
    },
    buttons: {
      send: 'Register',
      testDemoUser: 'Try it out with a demo user',
      applyESignature: 'Apply for your e-signature with SAT',
      registerForm: 'Signup with',
    },
  },
};
