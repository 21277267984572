export const NotificationsTranslations = {
  es: {
    texts: {
      label1: 'Todas las notificaciones',
    },
    inputs: {},
    buttons: {},
    notifications: {},
    mappedTexts: {
      1: {
        title: 'Alta de un administrador',
        description: 'Recibes un email cada que creas un administrador',
      },
      2: {
        title: 'Alta de un apoderado',
        description: 'Recibes un email cada que creas un apoderado',
      },
      3: {
        title: 'Se ha creado un adendum',
        description: 'Recibes un email cada que se crea adendum',
      },
      4: {
        title: 'Contrato pendiente por firmar',
        description:
          'Recibes un email cuando tienes contratos pendientes de firma',
      },
      5: {
        title: 'Turno de firma',
        description:
          'Recibes un email cuando es tu turno de firmar el documento',
      },
      6: {
        title: 'Invitación para autorizar un contrato',
        description:
          'Recibes un email cuando tienes una invitación para autorizar un contrato',
      },
      7: {
        title: 'Estatus de autorizadores',
        description:
          'Recibes un email cuando se ha autorizado o no un contrato',
      },
      8: {
        title: 'Adendum firmado',
        description: 'Recibes un email cuando se ha firmado un adendum',
      },
      9: {
        title: 'Notificaciones como copiado',
        description:
          'Recibes un correo de cualquier acción si estás como copiado por default en una empresa o en un contrato.',
      },
    },
  },
  en: {
    texts: {
      label1: 'All Notifications',
    },
    inputs: {},
    buttons: {},
    notifications: {},
    mappedTexts: {
      1: {
        title: 'New administrator',
        description:
          'You receive an email every time you create an administrator',
      },
      2: {
        title: 'New representative',
        description:
          'You receive an email every time you create a representative',
      },
      3: {
        title: 'Addendum created',
        description: 'You receive an email every time an addendum is created',
      },
      4: {
        title: 'Pending contract to sign',
        description:
          'You receive an email when you have pending contracts to sign',
      },
      5: {
        title: 'Turn to sign',
        description:
          'You receive an email when it is your turn to sign the document',
      },
      6: {
        title: 'Invitation to authorize a contract',
        description:
          'You receive an email when you have an invitation to authorize a contract',
      },
      7: {
        title: 'Status of authorizers',
        description:
          'You receive an email when a contract has been authorized or not',
      },
      8: {
        title: 'Addendum signed',
        description: 'You receive an email when an addendum has been signed',
      },
      9: {
        title: 'Notifications as copied',
        description:
          'You receive an email of any action if you are copied by default in a company or in a contract.',
      },
    },
  },
};
