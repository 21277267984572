export const AddSignersDisplayTranslations = {
  es: {
    texts: {
      spanTitle1: 'Firmantes',
      p1: 'No hay usuarios',
    },
    inputs: {
      switchLabel1: 'Firmar por orden de lista',
      inputPlaceholder1: 'RFC',
      inputPlaceholder2: 'Nombre',
      inputPlaceholder3: 'Correo electrónico',
    },
    buttons: {
      button1: 'Agregarme +',
      button2: 'Agregar firmante +',
    },
  },
  en: {
    texts: {
      spanTitle1: 'Signers',
      p1: 'No users',
    },
    inputs: {
      switchLabel1: 'Sign by list order',
      inputPlaceholder1: 'RFC',
      inputPlaceholder2: 'Name',
      inputPlaceholder3: 'Email',
    },
    buttons: {
      button1: 'Add me +',
      button2: 'Add signer +',
    },
  },
};
