export const AdminTranslations = {
  es: {
    texts: {
      h1: (adminLegalName) => `Administrador - ${adminLegalName}`,
      p1: 'Verificando cuenta...',
      tab1: 'Documentos',
      tab2: 'Nuevo Documento',
      tab3: 'Usuarios',
      tab4: 'Documentos Masivos',
      genericModalTitle1: 'Información',
      genericModalBody1: (adminLegalName) =>
        `${adminLegalName} te ha concedido permisos para acceder a su información. Por favor verifica tu cuenta con tu e.firma (SAT)`,
      alertModalTitle1: 'Atención',
      alertModalBody1:
        'Por seguridad cerramos tu sesión después de 15 minutos de inactividad.',
    },
    inputs: {},
    buttons: {
      genericModalConfirmButton1: 'Regresar',
      genericModalCancelButton1: 'Aceptar',
    },
    notifications: {
      notifTitle1: 'Advertencia',
      notifBody1: '', // TODO: translate response
    },
  },
  en: {
    texts: {
      h1: (adminLegalName) => `Administrator - ${adminLegalName}`,
      p1: 'Verifying account...',
      tab1: 'Documents',
      tab2: 'New Document',
      tab3: 'Users',
      tab4: 'Massive Documents',
      genericModalTitle1: 'Information',
      genericModalBody1: (adminLegalName) =>
        `${adminLegalName} has granted you permission to access their information. Please verify your account with your e.firma (SAT)`,
      alertModalTitle1: 'Attention',
      alertModalBody1:
        'For your security we close the session after 15 minutes of inactivity.',
    },
    inputs: {},
    buttons: {
      genericModalConfirmButton1: 'Back',
      genericModalCancelButton1: 'Accept',
    },
    notifications: {
      notifTitle1: 'Warning',
      notifBody1: '', // TODO: translate response
    },
  },
};
