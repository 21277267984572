export const ContractRoutes = {
  getAllContractsMonthly: '/contracts/:date',
  cancelContract: '/contracts/cancel-contract/:id',
  softDeleteContract: '/contracts/delete-contract/:id',
  softDeleteContractSife: '/contracts/delete-contract-sife',
  sendEmailRecipient: '/contracts/send-email-recipient',
  sendEmailSigner: '/contracts/send-email-signer',
  signContract: '/contracts/sign/:id', // sign
  addToQueue: '/contracts/queue',
  searchByRfc: '/contracts/signers/search/:legalRfc',
  getThisRecipients: '/contracts/recipients/:id',
  getThisSigners: '/contracts/signers/:id',
  getThisLabels: '/contracts/labels/:id',
  changeSigner: '/contracts/signers/update',
  addRecipient: '/contracts/recipients/create',
  addSignerToContract: '/contracts/signers/create',
  viewContract: '/contracts/get/:id', // view
  listAdmin: '/contracts/list/admin/:id',
  listContracts: '/contracts/list', // list
  createContractSignerSife: '/contracts/send-contract-signer-sife',
  getContractSife: '/contracts/get-contract-sife/:contractId',
  createContractSife: '/contracts/send-contract-sife',
  createContract: '/contracts/create', // create
  fileContract: '/contracts/file', // create
  addAuthorizerToContract: '/contracts/authorizers/create',
  addToQueueAuthorizer: '/contracts/queue-authorizer',
  sendEmailAuthorizer: '/contracts/send-email-authorizer',
  listContractAuthorizer: '/contracts/list-contract-authorizer',
  createSignerWithAuthorizer: '/contracts/signers-authorizer/create',
  createQueueSignerWithAuthorizer: '/contracts/queue-signer-authorizer',
  updateAuthorizerContract: '/contracts/update-authorizer-contract',
  getAuthorizer: '/contracts/get-authorizers/:contractId',
  updateSigner: '/contracts/update-signer',
  sendEmailToUser: '/contracts/send-email-user',
  massiveDownload: '/contracts/donwloadFiles',
  resetAllDownloads: '/contracts/reset-downloads',
  createMassiveRecipient: '/contracts/massive-recipient',
  downloadPdfByDate: '/contracts/download-by-date',
  getContractsPendingToDownload: '/contracts/get-pending-download',
  downloadContractToView: '/contracts/downloadContractToView/:id',
  uploadIdentityDocuments: '/contracts/uploadIdentityDocuments',
  verifyFrontId: '/contracts/verify-front-id',
  verifyBackId: '/contracts/verify-back-id',
  verifySelfie: '/contracts/verify-selfie',
  verifyFacesMatch: '/contracts/verify-faces-match',
  getUserSife: '/contracts/get-user-sife/:legalRfc',
  getInfoVerificationSife: '/contracts/info-sife-verification/:legalRfc',
  generateSoraId: '/contracts/generate-sora-id',
  updateUserSoraId: '/contracts/assign-contracts',
  uploadContractsMassive: '/contracts/upload-massive-contracts',
  getExcelReport: '/contracts/excel-report',
};
