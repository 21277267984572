import moment from 'moment-timezone';
import state from '../state';
import { contractsServices } from '../API/services/contractServices';

const ContractActions = {
  /**
   * Create a new contract
   * @param  {Object} properties Contract properties
   * @return {Promise}
   */
  async create(properties, checkAuthorizers) {
    const expireDate = moment
      .tz('America/Monterrey')
      .add(properties.ttl, 'years')
      .format('YYYY-MM-DD HH:mm:ss');
    const formData = new FormData();
    formData.append('file', properties.file);
    const payload = {
      title: properties.title,
      description: properties.description,
      participants: properties.participants,
      rootUser: properties.rootUser ? properties.rootUser : null,
      validUntil: expireDate,
      cvv2: properties.cvv2,
      expiresOn: properties.expiresOn,
      authorizers: properties.authorizers,
      status: properties.status,
      orderedSigners: properties.orderedSigners,
      labels: properties.labels,
      isMassive: properties.isMassive,
    };
    const { data: fileData } = await contractsServices.fileContractService(
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    payload.fileUrl = fileData.Location;
    payload.hash = fileData.hash;
    const { data: createData } = await contractsServices.createContractService({
      payload,
      checkAuthorizers,
    });
    state.select(['contracts']).push(createData);
    return createData;
  },

  // CREATE CONTRACT SIFE
  async createContractSife(data) {
    const { contract, user, type, locationUser, signer } = data;
    const email = type === 'sign' ? user.secondaryEmail : user.email;
    const rfc = user.legalRfc;
    const rfcUser = true;
    const userType = user.typeUser ? user.typeUser : 'efirma';
    const docsArray = [
      {
        docObjToHash: {
          docInfo: {
            fechaInicio: moment(contract.createdAt).format('YYYY-MM-DD'),
            fechaFinal: moment(contract.validUntil).format('YYYY-MM-DD'),
            monto: '5000',
          },
        },
        docHash: contract.lastHash || contract.hash,
        docId: contract.unique_id,
        docName: contract.title,
        userEmail: user.email,
        userRFC: user.legalRfc,
        hashAlgorithm: 'SHA256',
        //este va como true al menos que el usuario pida que no se haga
        verifReqAtSigCreation: signer.verifyCreateSign,
        verifReqAtDocSigning: signer.requestVerification,
        geolocReqAtDocSigning: false,
      },
    ];
    const payload = {
      docsArray,
      userType,
      rfcUser,
      email,
      rfc,
      locationUser,
      userIdToUpdate: user.id,
      originalContractId: contract.id,
    };
    const response = await contractsServices.createContractSifeService(payload);
    return response;
  },

  async getContractSife(contractId) {
    const response = await contractsServices.getContractSifeService(
      {},
      { contractId },
    );
    return response;
  },

  async createContractSignerSife(contract, user, userSigner) {
    const nameSplit = contract.fileUrl.split('/');
    const name = `${nameSplit[nameSplit.length - 1]}`;
    const email = user.email;
    const rfc = user.legalRfc;
    const rfcUser = true;
    const userType = user.typeUser;
    const docsArray = [
      {
        docObjToHash: {
          docInfo: {
            fechaInicio: moment(userSigner.createdAt).format('YYYY-MM-DD'),
            fechaFinal: moment(userSigner.createdAt).format('YYYY-MM-DD'),
            monto: '5000',
          },
        },
        docHash: userSigner.hash,
        docId: userSigner.unique_id,
        docName: name,
        userEmail: user.email,
        userRFC: user.legalRfc,
        hashAlgorithm: 'SHA256',
        geolocReqAtDocSigning: false,
      },
    ];
    const payload = {
      docsArray,
      userType,
      rfcUser,
      email,
      rfc,
    };
    const response = await contractsServices.createContractSignerSifeService(
      payload,
    );
    return response;
  },

  /**
   * Get all Contracts from my User
   * @returns {Promise<*>}
   */
  async list() {
    const { data } = await contractsServices.listContractsService();
    state.select(['contracts']).set(data);
    return data;
  },

  /**
   * Get all Contracts of Root User
   * @param id
   * @returns {Promise<*>}
   */
  async listAdmin(id, legalRfc) {
    const params = `${id}-${legalRfc}`;
    const { data } = await contractsServices.listAdminService(
      {},
      { id: params },
    );
    // state.select(['contracts']).set(data);
    return data;
  },

  /**
   * View a contract by Id
   * @param id
   * @returns {Promise<*>}
   */
  async view(id) {
    const { data } = await contractsServices.viewContractService({}, { id });
    state.select(['contract']).set(data);
    return data;
    // return contractsAPI.get(`/get/${id}`).then((res) => {
    //   state.select(['contract']).set(res.data);
    //   return res.data;
    // });
  },

  /**
   * Creates a Signer for a Contract
   * @param properties
   * @returns {Promise<*>}
   */
  async addSigner(properties) {
    const { data } = await contractsServices.addSignerToContractService(
      properties,
    );
    return data;
  },

  async updateSigner(signers, contract, signer, newRfc) {
    const { data } = await contractsServices.updateSignerService({
      signers,
      contract,
      signer,
      newRfc,
    });
    return data;
  },

  /**
   * @param properties
   * @param contractId
   * @returns {Promise}
   */
  async addRecipient(properties, contractId) {
    const payload = {
      contractId: properties.contractId,
      contract: contractId,
      email: properties.email,
      legalRfc: properties.legalRfc,
      legalName: properties.legalName,
      rootUser: properties.rootUser ? properties.rootUser : null,
    };
    const { data } = await contractsServices.addRecipientService(payload);
    return data;
    // return contractsAPI
    //   .post('/recipients/create', payload)
    //   .then((res) => res.data);
  },

  async addAuthorizer(properties) {
    const payload = {
      contractId: properties.contractId,
      userId: properties.userId,
      rootUser: properties.rootUser,
    };
    const { data } = await contractsServices.addAuthorizerToContractService(
      payload,
    );
    return data;
  },

  async addToQueueAuthorizer(contractId, authorizer, rootUser) {
    const payload = {
      contract: contractId,
      email: authorizer.email,
      legalRfc: authorizer.legalRfc,
      legalName: authorizer.legalName,
      rootUser,
    };
    const { data } = await contractsServices.addQueueAuthorizerService(payload);
    return data;
    // return contractsAPI.post('/queue', payload).then((res) => res.data);
  },

  async sendEmailAuthorizer(authorizer, contract, authorizers) {
    const payload = {
      email: authorizer.email,
      secondaryEmail: authorizer.secondaryEmail,
      legalName: authorizer.legalName,
      legalRfc: authorizer.legalRfc,
      contract,
      authorizers,
      // rootUser: signer.rootUser ? signer.rootUser : null,
    };

    const { data } = await contractsServices.sendEmailAuthorizerService(
      payload,
    );
    return data;
    // return contractsAPI
    //   .post('/send-email-signer', payload)
    //   .then((res) => res.data);
  },

  async listContractAuthorizers() {
    const { data } = await contractsServices.listContractAuthorizerService();
    // state.select(['contractAuthorizer']).set(data);
    return data;
  },

  async addSignerWithAuthorizer(properties) {
    const { data } = await contractsServices.createSignerWithAuthorizerService(
      properties,
    );
    return data;
  },

  async addToQueueSignerWithAuth(payload) {
    const { data } = await contractsServices.createQueueSigWithAuthService(
      payload,
    );
    return data;
    // return contractsAPI.post('/queue', payload).then((res) => res.data);
  },

  async updateAuthorizerContract(payload) {
    const { data } = await contractsServices.updateAuthorizerContractService(
      payload,
    );
    return data;
  },

  async getAuthorizers(contractId) {
    const { data } = await contractsServices.getAuthorizerService(
      {},
      { contractId },
    );
    // state.select(['contractAuthorizer']).set(data);
    return data;
  },

  /**
   * @param signer
   * @param user
   * @param contract
   * @returns {Promise}
   */
  async changeSigner(signer, user, contract) {
    const payload = {
      signer,
      user,
      contract,
    };
    const { data } = await contractsServices.changeSignerService(payload);
    return data;
    // return contractsAPI.put('/signers/update', payload).then((res) => res.data);
  },

  /**
   * Get labels for this contract
   * @returns {Promise<*>}
   */
  async getThisLabels(id) {
    const { data } = await contractsServices.getThisLabelsService({}, { id });
    return data;
    // return contractsAPI.get(`/labels/${id}`).then((res) => res.data);
  },

  /**
   * Get signers for this contract
   * @returns {Promise<*>}
   */
  async getThisSigners(id) {
    const { data } = await contractsServices.getThisSignersService({}, { id });
    return data;
    // return contractsAPI.get(`/signers/${id}`).then((res) => res.data);
  },

  /**
   *
   * @param id
   * @returns {Promise<*>}
   */
  async getThisRecipients(id) {
    const { data } = await contractsServices.getThisRecipientsService(
      {},
      { id },
    );
    return data;

    // return contractsAPI.get(`/recipients/${id}`).then((res) => res.data);
  },

  /**
   * Search a user by RFC
   * @param signer
   * @returns {Promise<*>}
   */
  async searchByRfc(signer) {
    const { legalRfc } = signer;
    const { data } = await contractsServices.searchByRfcService(
      {},
      { legalRfc },
    );
    return data;
    // return contractsAPI
    //   .get(`/signers/search/${legalRfc}`)
    //   .then((res) => res.data);
  },

  /**
   * Add this user to Queue of signers for a contract
   * @param contractId
   * @param signer
   * @param turn
   * @returns {Promise<*>}
   */
  async addToQueue(payload) {
    const { data } = await contractsServices.addToQueueService(payload);
    return data;
    // return contractsAPI.post('/queue', payload).then((res) => res.data);
  },

  /**
   * Sign with State user
   * @param contract
   * @param credentials
   * @param user
   * @param allPartiesSigned
   * @param cuteSign
   * @returns {Promise<*>}
   */
  async sign(info) {
    const { contract, credentials, user, cuteSign, locationUser } = info;
    const { id } = contract;
    const formData = new FormData();
    formData.append('cer', credentials.cer);
    formData.append('pdf', contract.fileUrl);
    formData.append('legalName', user.legalName);
    formData.append('legalRfc', user.legalRfc);
    if (user.representing) {
      formData.append('representing', user.representing);
    }
    if (user.representedRfc) {
      formData.append('representedRfc', user.representedRfc);
    }
    formData.append('cuteSign', cuteSign);
    formData.append('locationUser', JSON.stringify(locationUser));

    const { data } = await contractsServices.signContractService(
      formData, // data
      {
        // rqConfig
        headers: { 'Content-Type': 'multipart/form-data' },
      },
      { id }, // urlVar
    );
    return data;
    // return contractsAPI
    //   .post(`/sign/${contract.id}`, formData, {
    //     headers: { 'Content-Type': 'multipart/form-data' },
    //   })
    //   .then((res) => res.data);
  },

  /**
   * Sends an Email directly to User
   * @param signer
   * @param turn
   * @param contract
   * @returns {Promise<*>}
   */
  async sendEmailSigner(signer, turn, contract, signType, contractType) {
    const payload = {
      email: signer.email,
      secondaryEmail: signer.secondaryEmail,
      legalName: signer.legalName,
      legalRfc: signer.legalRfc,
      turn,
      signType,
      contract,
      rootUser: signer.rootUser ? signer.rootUser : null,
      contractType,
    };

    const { data } = await contractsServices.sendEmailSignerService(payload);
    return data;
    // return contractsAPI
    //   .post('/send-email-signer', payload)
    //   .then((res) => res.data);
  },

  /**
   * Send Email to recipient
   * @param recipient
   * @param contract
   * @returns {Promise<*>}
   */
  async sendEmailRecipient(recipient, contract) {
    const payload = {
      email: recipient.email,
      legalName: recipient.legalName,
      legalRfc: recipient.legalRfc,
      contract,
      rootUser: recipient.rootUser ? recipient.rootUser : null,
    };
    const { data } = await contractsServices.sendEmailRecipientService(payload);
    return data;
    // return contractsAPI
    //   .post('/send-email-recipient', payload)
    //   .then((res) => res.data);
  },

  async softDeleteContract(id, user) {
    /* if (user.typeUser === 'sife') {
      const payload = {
        email: user.email,
        rfc: user.legalRfc,
        rfcUser: user.legalRfc,
        docIds: [`${id}`],
      };
      await contractsServices.softDeleteContractSifeService(payload);
      // await contractsAPI.post('/delete-contract-sife', {
      //   email: user.email,
      //   rfc: user.legalRfc,
      //   rfcUser: user.legalRfc,
      //   docIds: [`${id}`],
      // });
    } */
    const { data } = await contractsServices.softDeleteContractService(
      id,
      {},
      { id },
    );
    return data;
    // return contractsAPI
    //   .put(`/delete-contract/${id}`, id)
    //   .then((res) => res.data);
  },

  async cancelContract(id) {
    const { data } = await contractsServices.cancelContractService(
      id,
      {},
      { id },
    );
    return data;
    // return contractsAPI
    //   .put(`/cancel-contract/${id}`, id)
    //   .then((res) => res.data);
  },

  async getAllContractsMonthly(date) {
    const { data } = await contractsServices.getAllContractsMonthlyService(
      date,
      {},
      { date },
    );
    return data;
    // return contractsAPI.get(`/${date}`, date).then((res) => res.data);
  },

  async sendEmailToUserWhenSignMassive(user, contracts) {
    const data = {
      user,
      contracts,
    };
    const response =
      await contractsServices.sendEmailToUserWhenSignMassiveService(data);
    return response;
  },

  async massiveDownloadFiles(contracts, signer, notCheck) {
    const data = { contracts, signer, notCheck };
    const response = await contractsServices.massiveDownloadFilesService(data);
    return response;
  },

  async resetAllDownloads(signerOrAdmin) {
    const response = await contractsServices.resetAllDownloadsService(
      signerOrAdmin,
    );
    return response;
  },

  async createMassiveRecipient(contracts, signerOrAdmin) {
    const data = {
      contracts,
      signerOrAdmin,
    };
    const response = await contractsServices.createMassiveRecipientService(
      data,
    );
    return response;
  },

  async downloadPdfByDate(data) {
    const response = await contractsServices.downloadPdfByDateService(data);
    return response;
  },

  async getContractsPendingToDownload(data) {
    const response =
      await contractsServices.getContractsPendingToDownloadService(data);
    return response;
  },

  async uploadIdentityDocuments(formData) {
    const response = await contractsServices.uploadIdentityDocumentsService(
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response;
  },

  async verifyFrontId(payload) {
    const response = await contractsServices.verifyFrontIdService(payload);
    return response;
  },

  async verifyBackId(payload) {
    const response = await contractsServices.verifyBackIdService(payload);
    return response;
  },

  async verifySelfie(payload) {
    const response = await contractsServices.verifySelfieService(payload);
    return response;
  },

  async verifyFacesMatch(payload) {
    const response = await contractsServices.verifyFacesMatchService(payload);
    return response;
  },

  async getUserSife(legalRfc) {
    const { data } = await contractsServices.getUserSifeService(
      {},
      { legalRfc },
    );
    return data;
  },

  async getInfoVerificationSife({ docId, legalRfc, type }) {
    const { data } = await contractsServices.getInfoVerificationSifeService(
      { params: { docId, type } },
      { legalRfc },
    );
    return data;
  },

  async generateSoraId(payload) {
    const response = await contractsServices.generateSoraIdService(payload);
    return response;
  },

  async updateUserSoraId(payload) {
    const response = await contractsServices.updateUserSoraIdService(payload);
    return response;
  },
  async uploadContractsMassive(formData) {
    const response = await contractsServices.uploadContractsMassiveService(
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response;
  },

  async getExcelReport(data) {
    const response = await contractsServices.getExcelReportService(data);
    return response;
  },
};

export default ContractActions;
