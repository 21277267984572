import React from 'react';
import Loader from '../components/Loader';
import { branch } from 'baobab-react/higher-order';

const Camera = ({
  refVideoElement,
  constraints,
  closeCamera,
  handleCapture,
  activeStepTakePicture,
  handleFlipImage,
  stepsTakePicture,
  loading,
  handleFlipCamera,
  flipImage,
  translations,
  lang,
}) => {
  const { texts } = translations[lang].Modals.CameraModal.Camera;
  return loading ? (
    <div className="center_loading">
      <Loader />
      <p>{texts.p1}</p>
    </div>
  ) : (
    <div className="cover_camera">
      <div className="vid_container">
        <video
          id="video"
          className="cameraFrame"
          ref={refVideoElement}
          style={{
            transform: flipImage ? 'scale(-1,1)' : 'scale(1,1)',
          }}
        />
        <div className="flip_image" onClick={handleFlipImage}>
          <i className="fas fa-exchange-alt"></i>
        </div>
      </div>
      <div className="flex_capture_photo">
        <i className="fas fa-times" onClick={closeCamera}></i>
        <i
          className="far fa-dot-circle"
          onClick={() =>
            handleCapture(
              activeStepTakePicture === 1
                ? 'photoID'
                : activeStepTakePicture === 2 && stepsTakePicture.length === 3
                ? 'photoBackID'
                : 'photoSelfie',
            )
          }
        ></i>
        <i className="fas fa-sync-alt" onClick={handleFlipCamera}></i>
      </div>
    </div>
  );
};

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  Camera,
);
