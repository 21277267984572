export const MySignerOrMyAdminsTranslations = {
  es: {
    texts: {
      label1: (legalName) => `Apoderados a nombre de ${legalName}`,
      label2: (legalName) => `Administradores a nombre de ${legalName}`,
      label3: 'Mis Apoderados',
      label4: 'Mis Administradores',
      spanTitle1: 'Permisos:',
      small1: 'Crear documentos a mi nombre -',
      small2: 'Ver documentos a mi nombre -',
      small3: 'Crear administradores a mi nombre -',
      small4: 'Crear mas Apoderados',
      p1: ' No hay usuarios registrados',
      genericModalTitle1: 'Eliminar Firmante',
      genericModalBody1: '¿Seguro que quieres eliminar este firmante?',
      genericModalTitle2: 'Cancelar',
      genericModalBody2:
        'Si regresas sin firmar, el usuario agregado se eliminará. ¿Desea continuar?',
      genericModalTitle3: 'Ubicación bloqueada',
      genericModalBody3:
        'Porfavor busca en la configuración de tu navegador como restablecer los permisos de la ubiación, de otra manera no podrás firmar tus documentos.',
      genericModalTitle4: 'Permiso de usar tu ubicación',
      genericModalBody4:
        'Para poder usar tu ubicación necesitamos que des click en permitir. Esto es necesario ya que de otra manera el navegador bloqueará nuestro acceso a tu ubicación y no podrás firmar',
      genericModalTitle5: 'Advertencia',
      genericModalBody5:
        'Existen varios RFC o ID asociados a esta cuenta de correo. Para continuar pregunta a tu firmante a que RFC o ID se debe asociar este contrato.',
    },
    inputs: {},
    buttons: {
      button1: 'Agregar +',
      genericModalConfirmButton1: 'Continuar',
      genericModalCancelButton1: 'Volver',
      genericModalConfirmButton2: 'Eliminar',
      genericModalCancelButton2: 'Cancelar',
      genericModalConfirmButton3: 'Continuar',
      genericModalCancelButton3: 'Cerrar',
      genericModalCancelButton4: 'Cancelar',
    },
    notifications: {
      notifTitle1: 'Atención',
      notifBody1: 'Error al descargar el documento, favor de volver a intentar',
      notifTitle2: 'Atención',
      notifBody2: 'Ya tienes un usuario registrado con ese RFC',
      notifTitle3: 'Atención',
      notifBody3: 'No puedes agregarte tu mismo como usuario',
      notifTitle4: 'Atención',
      notifBody4: (value) => `Ya existe '${value}'`,
      notifTitle5: 'Error',
      notifBody5: 'Hubo un error al crear el usuario y el documento',
      notifTitle6: 'Informativo',
      notifBody6: 'Firmando documento...\nEsto puede tardar unos minutos.',
      notifTitle7: 'Error',
      notifBody7: 'Hubo un error al firmar el documento.',
      notifTitle8: 'Error',
      notifBody8:
        'El RFC con el que intentas firmar es diferente al del inicio de sessión.',
      notifTitle9: 'Éxito',
      notifBody9: (legalName) =>
        `Acreditaste a ${legalName}, puedes continuar.`,
      notifTitle10: 'Atención',
      notifBody10: (legalName) => `${legalName} eliminado como Firmante`,
    },
  },
  en: {
    texts: {
      label1: (legalName) => `Representatives for ${legalName}`,
      label2: (legalName) => `Administrators for ${legalName}`,
      label3: 'My Representatives',
      label4: 'My Administrators',
      spanTitle1: 'Permissions:',
      small1: 'Create documents in my name -',
      small2: 'View documents in my name -',
      small3: 'Create administrators in my name -',
      small4: 'Create more Representatives',
      p1: 'There are no registered users',
      genericModalTitle1: 'Remove Signer',
      genericModalBody1: 'Are you sure you want to remove this signer?',
      genericModalTitle2: 'Cancel',
      genericModalBody2:
        'If you return without signing, the added user will be deleted. Do you want to continue?',
      genericModalTitle3: 'Location blocked',
      genericModalBody3:
        'Please look in your browser settings how to reset the location permissions, otherwise you will not be able to sign your documents.',
      genericModalTitle4: 'Permission to use your location',
      genericModalBody4:
        'To be able to use your location we need you to click on allow. This is necessary because otherwise the browser will block our access to your location and you will not be able to sign',
      genericModalTitle5: 'Warning',
      genericModalBody5:
        'There are several RFC or ID associated with this email account. To continue ask your signer to which RFC or ID this contract should be associated with.',
    },
    inputs: {},
    buttons: {
      button1: 'Add +',
      genericModalConfirmButton1: 'Continue',
      genericModalCancelButton1: 'Return',
      genericModalConfirmButton2: 'Delete',
      genericModalCancelButton2: 'Cancel',
      genericModalConfirmButton3: 'Continue',
      genericModalCancelButton3: 'Close',
      genericModalCancelButton4: 'Cancel',
    },
    notifications: {
      notifTitle1: 'Warning',
      notifBody1: 'Error downloading document, please try again',
      notifTitle2: 'Warning',
      notifBody2: 'You already have a registered user with that RFC',
      notifTitle3: 'Warning',
      notifBody3: 'You can not add yourself as a user',
      notifTitle4: 'Warning',
      notifBody4: (value) => `Already exists '${value}'`,
      notifTitle5: 'Error',
      notifBody5: 'There was an error creating the user and the document',
      notifTitle6: 'Info',
      notifBody6: 'Signing document...\nThis may take a few minutes.',
      notifTitle7: 'Error',
      notifBody7: 'There was an error signing the document.',
      notifTitle8: 'Error',
      notifBody8:
        'The RFC with which you try to sign is different from the one you started the session with.',
      notifTitle9: 'Success',
      notifBody9: (legalName) =>
        `You have accredited ${legalName}, you can continue.`,
      notifTitle10: 'Warning',
      notifBody10: (legalName) => `${legalName} removed as Signer`,
    },
  },
};
