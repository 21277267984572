import React from 'react';
import { branch } from 'baobab-react/higher-order';
import { NavLink } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
// Actions
import ProfileActions from '../../actions/Profile';

//Utils
import { resizedImage } from '../../utils/imageUtils.js';

class MyInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      secondaryEmail: '',
      loading: false,
      showLogoutModal: false,
      fileReader: null,
      response: null,
      uploadButton: false,
      srcEncoded: null,
      showRfcSignature: false,
    };
  }

  async componentDidMount() {
    const userUpdated = await ProfileActions.getUser(this.props.user.id);

    this.setState({
      response: userUpdated.image,
      showRfcSignature: userUpdated.showRfcSignature,
    });
  }

  handleForm = (event) => {
    event.preventDefault();
  };

  handleEmailChange(event) {
    const { value } = event.target;
    this.setState({ secondaryEmail: value });
  }

  canChangeEmail() {
    const { secondaryEmail } = this.state;
    return (
      secondaryEmail.length > 0 &&
      secondaryEmail.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
    );
  }

  handleEmail() {
    const { secondaryEmail } = this.state;
    const { user, translations, lang } = this.props;
    const { notifications } = translations[lang].Profile.MyInfo;

    this.setState({ loading: true });
    ProfileActions.addEmail(user, secondaryEmail).then(() => {
      this.forceUpdate();
      this.setState({ loading: true });
      NotificationManager.success(
        notifications.notifText1,
        notifications.notifTitle1,
        10000,
      );
    });
  }

  async handleImage() {
    const { srcEncoded } = this.state;
    const { user, translations, lang } = this.props;
    const { notifications } = translations[lang].Profile.MyInfo;
    await ProfileActions.addProfileImage(srcEncoded)
      .then(() => {
        return NotificationManager.success(
          notifications.notifText2,
          notifications.notifTitle2,
          10000,
        );
      })
      .catch(() => {
        return NotificationManager.error(
          notifications.notifText3,
          notifications.notifTitle3,
          10000,
        );
      });
    const userUpdated = await ProfileActions.getUser(user.id);
    this.setState({ response: userUpdated.image });
  }

  handleImageChange = async (e) => {
    resizedImage(e.target.files[0]);
    setTimeout(() => {
      this.setState({ srcEncoded: document.getElementById('input_new').src });
    }, 1000);
    this.setState({ fileReader: e.target.files[0] });
    this.setState({ uploadButton: true });
  };

  showRfcInSignaturePage = async () => {
    const { user } = this.props;
    const userFound = await ProfileActions.getUser(user.id);
    const response = await ProfileActions.updateUser({
      userId: userFound.id,
      currentRfcStatus: userFound.showRfcSignature,
    });
    if (!response.success) {
      // TODO: translate response
      return NotificationManager.error(response?.message, 'Atención', 5000);
    }
    this.setState({ showRfcSignature: response.newStatusRfc });
    // TODO: translate response
    NotificationManager.success(response?.message, 'Éxito', 5000);
  };

  render() {
    const { user } = this.props;
    const {
      secondaryEmail,
      loading,
      response,
      srcEncoded,
      fileReader,
      showRfcSignature,
    } = this.state;

    const { translations, lang } = this.props;
    const { texts, inputs, buttons } = translations[lang].Profile.MyInfo;

    return (
      <>
        <div className="_complete_my_profile">
          <div className="title_my_info">
            <span className="">{texts.spanTitle1}</span>
          </div>
          <form
            onSubmit={this.handleForm.bind(this)}
            className="form_my_profile"
          >
            <label htmlFor="name" className="legalName_mp">
              {inputs.inputLabel1}
              <input
                disabled
                id="name"
                name="name"
                value={user.legalName.toUpperCase()}
                type="text"
              />
            </label>

            <label htmlFor="rfc" className="legalRfc_mp">
              {inputs.inputLabel2}
              <input
                disabled
                id="rfc"
                name="rfc"
                value={user.legalRfc.toUpperCase()}
                type="text"
              />
            </label>

            <label htmlFor="email" className="email_mp">
              {inputs.inputLabel3}
              <input
                id="email"
                name="secondaryEmail"
                onChange={this.handleEmailChange.bind(this)}
                defaultValue={
                  secondaryEmail || user.secondaryEmail || user.email
                }
                type="email"
                autoComplete="nope"
              />
            </label>

            <div className="gestion_mp">
              {user.adminUser ? (
                <label>
                  {buttons.linkLabel1}
                  <br></br>
                  <NavLink
                    to={{
                      pathname: `/superadmin/${user.legalRfc}`,
                      state: {
                        /* eslint-disable */
                        from: location.pathname,
                      },
                    }}
                  >
                    <button
                      className="secondary"
                      style={{
                        color: '#2851E5',
                      }}
                    >
                      <i className="fas fa-user-shield" /> {buttons.button3}
                    </button>
                  </NavLink>
                </label>
              ) : null}
            </div>

            <div className="profile_image_mp">
              {response && !srcEncoded ? (
                <>
                  <div style={{ marginTop: 10 }}>
                    <img id="input_new" src={response} alt="Logo Sora" />
                  </div>
                </>
              ) : null}
              {srcEncoded ? (
                <div style={{ marginTop: 10 }}>
                  <img id="input_new" src={srcEncoded} alt="Logo" />
                </div>
              ) : (
                <img id="input_new" alt="" />
              )}
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  alignSelf: 'end',
                }}
              >
                <div style={{ width: '100%' }}>
                  <label htmlFor="">{inputs.inputLabel4}</label>
                  <label htmlFor="myfile">
                    {fileReader ? fileReader.name : inputs.inputLabel5}
                    <i className="fas fa-upload" style={{ marginLeft: 10 }}></i>
                  </label>
                  <input
                    type="file"
                    id="myfile"
                    className="image_profile"
                    name="myfile"
                    onChange={this.handleImageChange.bind(this)}
                  />
                </div>
              </div>
              <div
                style={{
                  textAlign: 'right',
                  cursor: 'pointer',
                  color: `${this.state.uploadButton ? '#2851e5' : ''}`,
                  pointerEvents: `${this.state.uploadButton ? '' : 'none'}`,
                  opacity: `${this.state.uploadButton ? '1' : '0.6'}`,
                }}
                onClick={this.handleImage.bind(this)}
              >
                <i className="fas fa-upload" style={{ marginRight: 10 }}></i>
                {buttons.button1}
              </div>
            </div>
          </form>
          <div style={{ textAlign: 'right' }}>
            <button
              type="submit"
              className="primary inputButton"
              onClick={this.handleEmail.bind(this)}
              disabled={!this.canChangeEmail() || loading}
              style={{ textAlign: 'right' }}
            >
              {buttons.button2}
            </button>
          </div>

          <div className="title_my_info">
            <span className="">{texts.spanTitle2}</span>
          </div>
          <small className="second_title_my_info">{texts.small1}</small>
          <div className="permission_my_info">
            <label className="switch">
              <input
                type="checkbox"
                checked={showRfcSignature}
                name="orderedSigners"
                onChange={this.showRfcInSignaturePage}
              />
              <span className="slider round"></span>
            </label>
            <div className="description_permission_my_info">
              <p>{texts.p1}</p>
              <small>{texts.small2}</small>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default branch(
  {
    session: ['session'],
    translations: ['translations'],
    lang: ['lang'],
  },
  MyInfo,
);
