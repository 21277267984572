import { endpointHandler } from '../createEndpoint';

const {
  getThisSignersAddendumEP,
  fileAddendumEP,
  createAddendumSifeEP,
  signAddendumEP,
  createAddendumEP,
  getThisAddendumsEP,
  getThisAddendumsWithIdEP,
} = endpointHandler();

// GET
export const getThisSignersAddendumService = (reqConfig, urlVar) => {
  return getThisSignersAddendumEP.get(reqConfig, urlVar);
};
export const getThisAddendumsService = (reqConfig, urlVar) => {
  return getThisAddendumsEP.get(reqConfig, urlVar);
};
export const getThisAddendumsWithIdService = (reqConfig, urlVar) => {
  return getThisAddendumsWithIdEP.get(reqConfig, urlVar);
};

// POST
export const fileAddendumService = (data, reqConfig, urlVar) => {
  return fileAddendumEP.post(data, reqConfig, urlVar);
};
export const createAddendumSifeService = (data, reqConfig, urlVar) => {
  return createAddendumSifeEP.post(data, reqConfig, urlVar);
};
export const signAddendumService = (data, reqConfig, urlVar) => {
  return signAddendumEP.post(data, reqConfig, urlVar);
};
export const createAddendumService = (data, reqConfig, urlVar) => {
  return createAddendumEP.post(data, reqConfig, urlVar);
};
