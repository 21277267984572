export const ErrorsUploadFileTranslations = {
  es: {
    texts: {
      span1: (errorsLength) => `${errorsLength} PDF's con error`,
      span2:
        'Verifica la estructura del nombre del documento. Corrige y vuelve a cargar',
      p1: 'No hay errores',
    },
    inputs: {},
    buttons: {},
    notifications: {},
  },
  en: {
    texts: {
      span1: (errorsLength) => `${errorsLength} PDF's with error`,
      span2: 'Verify the structure of the document name. Correct and reload',
      p1: 'No errors',
    },
    inputs: {},
    buttons: {},
    notifications: {},
  },
};
