import { branch } from 'baobab-react/higher-order';
import React from 'react';
import { NotificationManager } from 'react-notifications';
import ProfileActions from '../../actions/Profile';
import GenericConfirmModal from '../../components/Modals/GenericConfirmModal';
import { cleanText } from '../../utils/cleanText';
import { emailRegex } from '../../utils/constants';
import {
  getRfcByEmail,
  usersMostUsedNewContract,
} from '../../utils/newContractMassiveUtils';

class AuthorizersDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkAuthorizers: false,
      showTableAuhthorizers: true,
      authorizers: [],
      contractUser: '',
      searchAuthorizer: '',
    };
  }

  componentDidMount = async () => {
    const {
      session: { user },
      signer,
      admin,
      updateStateNewContract,
    } = this.props;
    const contractUser = signer || admin || user;
    const id = contractUser.rootUser || contractUser.id;
    const authorizersArr = await ProfileActions.getLastSigners({
      userId: id,
      typeUser: 'authorizer',
      isAdmin: signer || admin ? 'isAdmin' : 'isUser',
    });

    this.setState({
      contractUser,
      lastAuthorizers: authorizersArr,
      allAuthorizers: authorizersArr,
    });
    const authorizer = {
      legalName: contractUser.legalName,
      legalRfc: contractUser.legalRfc,
      email: contractUser?.secondaryEmail || contractUser?.email,
      disableLegalRfc: true,
    };
    this.setState({ authorizers: [authorizer] });
    updateStateNewContract({ authorizers: [authorizer] });
    document.addEventListener('mousedown', this.handleClickOutside);
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    const { searchAuthorizer, lastAuthorizers } = this.state;
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      searchAuthorizer &&
      lastAuthorizers.length
    ) {
      this.setState({ lastAuthorizers: [] });
    }
  };

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  onChangeSearchAuthorizers = (event) => {
    const { allAuthorizers } = this.state;
    const value = event.target.value;
    const lastChar = value[value.length - 1];
    if (lastChar === '\\' || lastChar === '?') return;
    this.setState({ searchAuthorizer: value });
    const limitedAuthorizers = usersMostUsedNewContract(value, allAuthorizers);
    this.setState({ lastAuthorizers: limitedAuthorizers });
  };

  onClickSelectAuthorizer = async (authorizer) => {
    const { authorizers } = this.state;
    const { updateStateNewContract } = this.props;
    authorizer.disableLegalRfc = true;
    authorizer.email = authorizer?.secondaryEmail || authorizer?.email;
    this.setState({
      authorizers: [...authorizers, authorizer],
      searchAuthorizer: '',
      lastAuthorizers: [],
    });
    updateStateNewContract({ authorizers: [...authorizers, authorizer] });
  };

  handleCheckAuthorizers = () => {
    const { checkAuthorizers } = this.state;
    const { updateStateNewContract } = this.props;
    this.setState({ checkAuthorizers: !checkAuthorizers });
    updateStateNewContract({ checkAuthorizers: !checkAuthorizers });
  };

  handleTableAuthorizers = () => {
    const { showTableAuhthorizers } = this.state;
    this.setState({ showTableAuhthorizers: !showTableAuhthorizers });
  };

  authorizerInputChange = (signerId, event) => {
    const { name, value } = event.target;
    const { authorizers, contractUser } = this.state;
    const { updateStateNewContract, translations, lang } = this.props;

    const { notifications } = translations[lang].NewContract.Authorizers;

    const array = [...authorizers];
    if (name === 'legalRfc' || name === 'email') {
      array[signerId][name] = cleanText({ removeSpace: true, string: value });
    } else {
      array[signerId][name] = cleanText({ removeSpace: false, string: value });
    }
    this.setState({ authorizers: array });
    updateStateNewContract({ authorizers: array });
    if (name === 'legalRfc') {
      const myDuplicates = authorizers.filter(
        (authorizer) =>
          authorizer.legalRfc.search(contractUser.legalRfc) !== -1,
      );
      if (myDuplicates.length > 1) {
        NotificationManager.warning(
          notifications.notif1Text,
          notifications.notif1Title,
          3000,
        );
      }
    }
  };

  removeAuthorizer = (e) => {
    const { authorizers } = this.state;
    const { updateStateNewContract } = this.props;
    const array = [...authorizers];
    const filteredArray = array.filter((authorizer) => authorizer !== e);
    this.setState({ authorizers: filteredArray });
    updateStateNewContract({ authorizers: filteredArray });
  };

  addAuthorizer = () => {
    const { authorizers } = this.state;
    const { updateStateNewContract } = this.props;
    const newAuthorizer = {
      legalRfc: '',
      legalName: '',
      email: '',
      disableLegalRfc: true,
    };
    this.setState({ authorizers: [...authorizers, newAuthorizer] });
    updateStateNewContract({ authorizers: [...authorizers, newAuthorizer] });
  };

  addMeAsAuthorizer = () => {
    const { authorizers, contractUser } = this.state;
    const { session, updateStateNewContract, translations, lang } = this.props;
    const { notifications } = translations[lang].NewContract.Authorizers;
    if (
      !authorizers.find((found) => found.legalRfc === contractUser.legalRfc)
    ) {
      const newAuthorizer = {
        legalName: contractUser.legalName,
        legalRfc: contractUser.legalRfc,
        email: session.user.secondaryEmail || session.user.email,
        disableLegalRfc: true,
      };
      this.setState({ authorizers: [...authorizers, newAuthorizer] });
      updateStateNewContract({ authorizers: [...authorizers, newAuthorizer] });
    } else {
      NotificationManager.warning(
        notifications.notif2Text,
        notifications.notif2Title,
        3000,
      );
    }
  };

  onKeyUpGenerateSoraId = async (e, authorizerId) => {
    const { value } = e.target;
    const { authorizers } = this.state;
    const { updateStateNewContract } = this.props;
    const allAuthorizers = [...authorizers];
    const data = {
      allUsers: allAuthorizers,
      participantId: authorizerId,
      participant: 'authorizer',
      value,
    };

    if (value.match(emailRegex)) {
      setTimeout(async () => {
        const allUsers = await getRfcByEmail(data);
        this.setState({ authorizers: allUsers });
        updateStateNewContract({ authorizers: allUsers });
      }, 500);
    }
  };

  onClickEnable = (authorizerId, type) => {
    const { authorizers } = this.state;
    const { updateStateNewContract } = this.props;
    const allAuthorizers = [...authorizers];
    if (type === 'closeDisclaimer') {
      allAuthorizers[authorizerId].disclaimerMultipleUsers = false;
    }

    allAuthorizers[authorizerId].disableLegalRfc = true;
    this.setState({ authorizers: allAuthorizers });
    updateStateNewContract({ authorizers: allAuthorizers });
  };

  render() {
    const {
      checkAuthorizers,
      showTableAuhthorizers,
      authorizers,
      contractUser,
      searchAuthorizer,
      lastAuthorizers,
    } = this.state;

    const { translations, lang } = this.props;
    const { texts, inputs, buttons } =
      translations[lang].NewContract.Authorizers;

    return (
      <>
        <div className="switch_nc_authorizers">
          <label className="switch">
            <input
              type="checkbox"
              checked={checkAuthorizers}
              name="orderedSigners"
              onChange={this.handleCheckAuthorizers}
            />
            <span className="slider round"></span>
          </label>
          <span className="add_auth">{inputs.span1}</span>
        </div>
        {checkAuthorizers ? (
          <>
            <div className="dropdown_tables">
              <span style={{ color: 'black' }}>{texts.span2}</span>
              {showTableAuhthorizers ? (
                <i
                  className="fas fa-chevron-up"
                  onClick={this.handleTableAuthorizers}
                ></i>
              ) : (
                <i
                  className="fas fa-chevron-down"
                  onClick={this.handleTableAuthorizers}
                ></i>
              )}
            </div>
            {showTableAuhthorizers ? (
              <>
                <div className="container_config_authorizers">
                  <div className="container_search_authorizer">
                    <div className="input_search_signer">
                      <i
                        className="fas fa-search iconoInput"
                        style={{ color: '#6C6C6C' }}
                      />
                      <input
                        id="myAuthorizers"
                        name="myAuthorizers"
                        placeholder={inputs.inputPlaceholder1}
                        onChange={(e) => this.onChangeSearchAuthorizers(e)}
                        value={searchAuthorizer}
                        type="text"
                        autoComplete="off"
                        className="input_nc search_signer"
                      />
                    </div>
                    <div className="dropdown">
                      <div
                        ref={this.setWrapperRef}
                        className="dropdown-content"
                        style={{
                          display:
                            lastAuthorizers.length && searchAuthorizer
                              ? 'block'
                              : 'none',
                        }}
                      >
                        {lastAuthorizers.map((authorizer, i) => (
                          <div
                            onClick={() =>
                              this.onClickSelectAuthorizer(authorizer)
                            }
                            key={i}
                          >
                            <p style={{ marginBottom: 4, marginTop: 0 }}>
                              {authorizer.legalName}{' '}
                            </p>
                            <small>
                              {authorizer.legalRfc} /{' '}
                              {authorizer.secondaryEmail || authorizer.email}{' '}
                            </small>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="list_authorizers_nc">
                  {authorizers.length ? (
                    authorizers.map((authorizer, index) => (
                      <div key={index} className="container_inputs_authorizer">
                        <div className="inputs_auth">
                          <div className="legalRfc_auth_nc">
                            <small>{index + 1}.</small>
                            <input
                              type="email"
                              name="email"
                              autoComplete="nope"
                              value={authorizer.email}
                              className="input_nc email_auth_nc"
                              onChange={(e) =>
                                this.authorizerInputChange(index, e)
                              }
                              required
                              placeholder={inputs.inputPlaceholder2}
                              onKeyUp={(e) =>
                                this.onKeyUpGenerateSoraId(e, index)
                              }
                            />
                          </div>
                          <input
                            type="text"
                            name="legalName"
                            value={authorizer.legalName}
                            className="input_nc legalName_auth_nc"
                            style={{ textTransform: 'capitalize' }}
                            required
                            maxLength="100"
                            minLength="2"
                            onChange={(e) =>
                              this.authorizerInputChange(index, e)
                            }
                            placeholder={inputs.inputPlaceholder3}
                          />
                          <div className="tooltip top legalRfc_signer_nc">
                            {authorizer.legalRfc.includes('@') && (
                              <span className="tiptext" style={{ padding: 10 }}>
                                {texts.tooltip1}
                              </span>
                            )}

                            <input
                              type="text"
                              name="legalRfc"
                              value={authorizer.legalRfc.toUpperCase()}
                              className="input_nc "
                              style={{
                                textTransform: 'uppercase',
                                opacity: authorizer.disableLegalRfc
                                  ? '0.5'
                                  : '1',
                                border: '2px solid #e5e8ef',
                              }}
                              disabled={authorizer.disableLegalRfc}
                              required
                              minLength="12"
                              maxLength="13"
                              onChange={(e) =>
                                this.authorizerInputChange(index, e)
                              }
                              placeholder={inputs.inputPlaceholder4}
                            />
                          </div>
                        </div>

                        <div className="action_delete_nc">
                          <small>{index + 1}.</small>
                          <div>
                            {/* {authorizer.disableLegalRfc ? null : (
                              <i
                                className="fas fa-edit"
                                onClick={() =>
                                  this.onClickEnable(index, 'edit')
                                }
                                style={{ paddingRight: 5, color: '#2851e5' }}
                              />
                            )} */}
                            <i
                              className={`fas fa-minus-circle `}
                              style={{
                                color: '#f95c5e',
                                cursor: 'pointer',
                              }}
                              onClick={() => this.removeAuthorizer(authorizer)}
                            />
                          </div>
                        </div>
                        {authorizer.disclaimerMultipleUsers ? (
                          <GenericConfirmModal
                            title={texts.genericModalTitle1}
                            message={texts.genericModalBody1}
                            type="hideButton"
                            cancelButton={buttons.genericModalButton1}
                            cancelAction={() =>
                              this.onClickEnable(index, 'closeDisclaimer')
                            }
                          />
                        ) : null}
                      </div>
                    ))
                  ) : (
                    <p>{texts.p1}</p>
                  )}
                </div>
                <div className="actions_signers" style={{ marginBottom: 10 }}>
                  {!authorizers.find(
                    (found) => found.legalRfc === contractUser.legalRfc,
                  ) ? (
                    <div className="action_signer">
                      <span onClick={this.addMeAsAuthorizer}>
                        {buttons.button2}
                      </span>
                    </div>
                  ) : null}
                  <div className="action_signer">
                    <span onClick={this.addAuthorizer}>{buttons.button1}</span>
                  </div>
                </div>
              </>
            ) : null}
          </>
        ) : null}
      </>
    );
  }
}

export default branch(
  {
    session: ['session'],
    translations: ['translations'],
    lang: ['lang'],
  },
  AuthorizersDisplay,
);
