const apiConfig = {
  local: {
    protocol: 'http',
    hostname: 'localhost',
    port: 3003,
    api: 'api',
  },
  staging: {
    protocol: 'https',
    hostname: 'api.staging.contratos.sora.mx',
    port: 3003,
    api: 'api',
  },
  dev: {
    protocol: 'https',
    hostname: 'api.dev.contratos.sora.mx',
    port: 3003,
    api: 'api',
  },
  prod: {
    protocol: 'https',
    hostname: 'api.contratos.sora.mx',
    port: 3003,
    api: 'api',
  },
};
export const contratosConfig = (env) => apiConfig[env];
