import { contratosInstance } from '../instance';
import { ContractRoutes } from '../routes';

export const ContractEPs = (createEndpoint) => {
  return {
    listAdminEP: createEndpoint(contratosInstance, ContractRoutes.listAdmin),
    addToQueueEP: createEndpoint(contratosInstance, ContractRoutes.addToQueue),
    getThisLabelsEP: createEndpoint(
      contratosInstance,
      ContractRoutes.getThisLabels,
    ),
    getThisSignersEP: createEndpoint(
      contratosInstance,
      ContractRoutes.getThisSigners,
    ),
    fileContractEP: createEndpoint(
      contratosInstance,
      ContractRoutes.fileContract,
    ),
    createContractEP: createEndpoint(
      contratosInstance,
      ContractRoutes.createContract,
    ),
    createContractSifeEP: createEndpoint(
      contratosInstance,
      ContractRoutes.createContractSife,
    ),
    createContractSignerSifeEP: createEndpoint(
      contratosInstance,
      ContractRoutes.createContractSignerSife,
    ),
    getContractSifeEP: createEndpoint(
      contratosInstance,
      ContractRoutes.getContractSife,
    ),
    listContractsEP: createEndpoint(
      contratosInstance,
      ContractRoutes.listContracts,
    ),
    viewContractEP: createEndpoint(
      contratosInstance,
      ContractRoutes.viewContract,
    ),
    addSignerToContractEP: createEndpoint(
      contratosInstance,
      ContractRoutes.addSignerToContract,
    ),
    addRecipientEP: createEndpoint(
      contratosInstance,
      ContractRoutes.addRecipient,
    ),
    changeSignerEP: createEndpoint(
      contratosInstance,
      ContractRoutes.changeSigner,
    ),
    getThisRecipientsEP: createEndpoint(
      contratosInstance,
      ContractRoutes.getThisRecipients,
    ),
    searchByRfcEP: createEndpoint(
      contratosInstance,
      ContractRoutes.searchByRfc,
    ),
    signContractEP: createEndpoint(
      contratosInstance,
      ContractRoutes.signContract,
    ),
    sendEmailSignerEP: createEndpoint(
      contratosInstance,
      ContractRoutes.sendEmailSigner,
    ),
    sendEmailRecipientEP: createEndpoint(
      contratosInstance,
      ContractRoutes.sendEmailRecipient,
    ),
    softDeleteContractSifeEP: createEndpoint(
      contratosInstance,
      ContractRoutes.softDeleteContractSife,
    ),
    softDeleteContractEP: createEndpoint(
      contratosInstance,
      ContractRoutes.softDeleteContract,
    ),
    cancelContractEP: createEndpoint(
      contratosInstance,
      ContractRoutes.cancelContract,
    ),
    getAllContractsMonthlyEP: createEndpoint(
      contratosInstance,
      ContractRoutes.getAllContractsMonthly,
    ),
    addAuthorizerToContractEP: createEndpoint(
      contratosInstance,
      ContractRoutes.addAuthorizerToContract,
    ),
    addQueueAuthorizerEP: createEndpoint(
      contratosInstance,
      ContractRoutes.addToQueueAuthorizer,
    ),
    sendEmailAuthorizerEP: createEndpoint(
      contratosInstance,
      ContractRoutes.sendEmailAuthorizer,
    ),
    listContractAuthorizerEp: createEndpoint(
      contratosInstance,
      ContractRoutes.listContractAuthorizer,
    ),
    createSignerWithAuthorizerEP: createEndpoint(
      contratosInstance,
      ContractRoutes.createSignerWithAuthorizer,
    ),
    createQueueSignerWithAuthorizerEP: createEndpoint(
      contratosInstance,
      ContractRoutes.createQueueSignerWithAuthorizer,
    ),
    updateAuthorizerContractEP: createEndpoint(
      contratosInstance,
      ContractRoutes.updateAuthorizerContract,
    ),
    getAuthorizerEP: createEndpoint(
      contratosInstance,
      ContractRoutes.getAuthorizer,
    ),
    updateSignerEP: createEndpoint(
      contratosInstance,
      ContractRoutes.updateSigner,
    ),
    sendEmailToUserEP: createEndpoint(
      contratosInstance,
      ContractRoutes.sendEmailToUser,
    ),
    massiveDownloadEP: createEndpoint(
      contratosInstance,
      ContractRoutes.massiveDownload,
    ),
    resetAllDownloadsEP: createEndpoint(
      contratosInstance,
      ContractRoutes.resetAllDownloads,
    ),

    createMassiveRecipientEP: createEndpoint(
      contratosInstance,
      ContractRoutes.createMassiveRecipient,
    ),

    downloadPdfByDateEP: createEndpoint(
      contratosInstance,
      ContractRoutes.downloadPdfByDate,
    ),
    getContractsPendingToDownloadEP: createEndpoint(
      contratosInstance,
      ContractRoutes.getContractsPendingToDownload,
    ),
    downloadContractToViewEP: createEndpoint(
      contratosInstance,
      ContractRoutes.downloadContractToView,
    ),
    uploadIdentityDocumentsEP: createEndpoint(
      contratosInstance,
      ContractRoutes.uploadIdentityDocuments,
    ),
    verifyFrontIdEP: createEndpoint(
      contratosInstance,
      ContractRoutes.verifyFrontId,
    ),
    verifyBackIdEP: createEndpoint(
      contratosInstance,
      ContractRoutes.verifyBackId,
    ),
    verifySelfieEP: createEndpoint(
      contratosInstance,
      ContractRoutes.verifySelfie,
    ),
    verifyFacesMatchEP: createEndpoint(
      contratosInstance,
      ContractRoutes.verifyFacesMatch,
    ),
    getUserSifeEP: createEndpoint(
      contratosInstance,
      ContractRoutes.getUserSife,
    ),
    getInfoVerificationSifeEP: createEndpoint(
      contratosInstance,
      ContractRoutes.getInfoVerificationSife,
    ),
    generateSoraIdEP: createEndpoint(
      contratosInstance,
      ContractRoutes.generateSoraId,
    ),
    updateUserSoraIdEP: createEndpoint(
      contratosInstance,
      ContractRoutes.updateUserSoraId,
    ),
    uploadContractsMassiveEP: createEndpoint(
      contratosInstance,
      ContractRoutes.uploadContractsMassive,
    ),
    getExcelReportEP: createEndpoint(
      contratosInstance,
      ContractRoutes.getExcelReport,
    ),
  };
};
