import { ActivationTranslations } from './Activation.translations';
import { ActivationLoginTranslations } from './ActivationLogin.translations';
import { AdminTranslations } from './AdminTranslations/Admin.translations';
import { CommonComponentsTranslations } from './CommonComponentsTranslations/CommonComponents.translations';
import { ContractAndPublicDetailHelpersTranslations } from './ContractAndPublicDetailHelpers.translations';
import { ContractDetailTranslations } from './ContractDetailTranslations/ContractDetail.translations';
import { ContractsTranslations } from './ContractsTranslations/Contracts.translations';
import { DashboardTranslations } from './Dashboard.translations';
import { DemoTranslations } from './Demo.translations';
import { HomeTranslations } from './Home.translations';
import { LoginTranslations } from './Login.translations';
import { ModalsTranslations } from './ModalsTranslations/Modals.translations';
import { NewContractMassiveTranslations } from './NewContractMassiveTranslations/NewContractMassive.translations';
import { NewContractTranslations } from './NewContractTranslations/NewContract.translations';
import { NewTemplateTranslations } from './NewTemplate.translations';
import { ProfileTranslations } from './ProfileTranslations/Profile.translations';
import { PublicContractDetailTranslations } from './PublicContractDetail.translations';
import { RegistrationTranslations } from './Registration.translations';
import { SidebarTranslations } from './Sidebar.translations';
import { SignerTranslations } from './Signer.translations';
import { UtilsTranslations } from './Utils.translations';

export const translationsState = {
  es: {
    Activation: { ...ActivationTranslations.es },
    ActivationLogin: { ...ActivationLoginTranslations.es },
    Admin: { ...AdminTranslations.es },
    CommonComponents: { ...CommonComponentsTranslations.es },
    ContractAndPublicDetailHelpers: {
      ...ContractAndPublicDetailHelpersTranslations.es,
    },
    ContractDetail: { ...ContractDetailTranslations.es },
    Contracts: { ...ContractsTranslations.es },
    Dashboard: { ...DashboardTranslations.es },
    Demo: { ...DemoTranslations.es },
    Home: { ...HomeTranslations.es },
    Login: { ...LoginTranslations.es },
    Modals: { ...ModalsTranslations.es },
    NewContract: { ...NewContractTranslations.es },
    NewContractMassive: { ...NewContractMassiveTranslations.es },
    NewTemplate: { ...NewTemplateTranslations.es },
    Profile: { ...ProfileTranslations.es },
    PublicContractDetail: { ...PublicContractDetailTranslations.es },
    Registration: { ...RegistrationTranslations.es },
    Sidebar: { ...SidebarTranslations.es },
    Signer: { ...SignerTranslations.es },
    Utils: { ...UtilsTranslations.es },
    //TODO:  NewCoupon: {...NewCouponTranslations.es}, internal use only
    //TODO:  NewApiUser: {...NewApiUserTranslations.es}, internal use only
    //TODO:  SuperUser: {...SuperUserTranslations.es}, internal use only
  },
  en: {
    Activation: { ...ActivationTranslations.en },
    ActivationLogin: { ...ActivationLoginTranslations.en },
    Admin: { ...AdminTranslations.en },
    CommonComponents: { ...CommonComponentsTranslations.en },
    ContractAndPublicDetailHelpers: {
      ...ContractAndPublicDetailHelpersTranslations.en,
    },
    ContractDetail: { ...ContractDetailTranslations.en },
    Contracts: { ...ContractsTranslations.en },
    Dashboard: { ...DashboardTranslations.en },
    Demo: { ...DemoTranslations.en },
    Home: { ...HomeTranslations.en },
    Login: { ...LoginTranslations.en },
    Modals: { ...ModalsTranslations.en },
    NewContract: { ...NewContractTranslations.en },
    NewContractMassive: { ...NewContractMassiveTranslations.en },
    NewTemplate: { ...NewTemplateTranslations.en },
    Profile: { ...ProfileTranslations.en },
    PublicContractDetail: { ...PublicContractDetailTranslations.en },
    Registration: { ...RegistrationTranslations.en },
    Sidebar: { ...SidebarTranslations.en },
    Signer: { ...SignerTranslations.en },
    Utils: { ...UtilsTranslations.en },
  },
};

// {
//   es: { texts: {}, inputs: {}, buttons: {}, notifications: {} },
//   en: { texts: {}, inputs: {}, buttons: {}, notifications: {} },
// }
