import { translationsState } from '../translations/translations';

export const namePdfRegex =
  /^[a-zA-Z0-9]+(?:;[a-zA-Z0-9@+._-]+)+(?:;[a-zA-Z0-9@._-]+)*$/;
export const emailRegex =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
export const rfcRegex =
  /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;

export const allNotifications = () => {
  // Revisar Notifications.translations.js si se quiere cambiar los textos
  return [
    {
      id: 1,
      active: false,
    },
    {
      id: 2,
      active: false,
    },
    {
      id: 3,
      active: false,
    },
    {
      id: 4,
      active: false,
    },
    {
      id: 5,
      active: false,
    },
    {
      id: 6,
      active: false,
    },
    {
      id: 7,
      active: false,
    },
    {
      id: 8,
      active: false,
    },
    {
      id: 9,
      active: false,
    },
  ];
};

// TODO: Find and translate all ocurences
export const statusInContractUtils = (status) => {
  const lang = localStorage.getItem('lang') || 'es';
  const { texts } = translationsState[lang].Utils;
  if (status === 'ready') {
    return texts.status1; //'Firmado por todas las partes';
  }

  if (status === 'forMe') {
    return texts.status2; //'Listo para mi firma';
  }

  if (status === 'copied') {
    return texts.status3; // 'Contrato con copia';
  }

  if (status === 'waitingOthers') {
    return texts.status4; //'Esperando otros firmantes';
  }

  if (status === 'cancelled') {
    return texts.status5; //'Cancelado';
  }

  if (status === 'authPending') {
    return texts.status6; // 'Esperando autorización';
  }

  return texts.status7; //'Firmado por mi';
};

// TODO: Find and translate all ocurences
export const contractStatusConstant = {
  ready: () =>
    translationsState[localStorage.getItem('lang') || 'es']?.Utils?.texts
      ?.status1,
  forMe: () =>
    translationsState[localStorage.getItem('lang') || 'es']?.Utils?.texts
      ?.status2,
  copied: () =>
    translationsState[localStorage.getItem('lang') || 'es']?.Utils?.texts
      ?.status3,
  waitingOthers: () =>
    translationsState[localStorage.getItem('lang') || 'es']?.Utils?.texts
      ?.status4,
  cancelled: () =>
    translationsState[localStorage.getItem('lang') || 'es']?.Utils?.texts
      ?.status5,
  authPending: () =>
    translationsState[localStorage.getItem('lang') || 'es']?.Utils?.texts
      ?.status6,
  readyByMe: () =>
    translationsState[localStorage.getItem('lang') || 'es']?.Utils?.texts
      ?.status7,
};
