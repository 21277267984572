import { branch } from 'baobab-react/higher-order';
import React from 'react';
import GenericConfirmModal from '../../components/Modals/GenericConfirmModal';
import {
  cancelContract,
  checkCancelOrDelete,
  softDeleteContract,
} from '../../helpers/ContractAndPublicDetailHelpers';

class ActionButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCancelPopUp: false,
      showDeletePopUp: false,
    };
  }

  handleCancelPopUp = () => {
    const { showCancelPopUp } = this.state;
    this.setState({ showCancelPopUp: !showCancelPopUp });
  };

  handleDeletePopUp = () => {
    const { showDeletePopUp } = this.state;
    this.setState({ showDeletePopUp: !showDeletePopUp });
  };

  render() {
    const { showCancelPopUp, showDeletePopUp } = this.state;
    const {
      session: { user },
      contract,
      admin,
      translations,
      lang,
    } = this.props;
    const { texts, buttons } =
      translations[lang].ContractDetail.SignContractDisplay.ActionButtons;

    return (
      <>
        <button
          hidden={checkCancelOrDelete(
            contract,
            admin ? admin.rootUser : user.id,
            'cancel',
          )}
          onClick={() => this.handleCancelPopUp(contract.id)}
          className="cancel"
        >
          {buttons.button1}
        </button>

        <button
          hidden={checkCancelOrDelete(
            contract,
            admin ? admin.rootUser : user.id,
            'delete',
          )}
          onClick={() => this.handleDeletePopUp()}
          style={{
            background: '#dc3545',
            cursor: 'pointer',
          }}
        >
          {buttons.button2}
        </button>
        {showCancelPopUp ? (
          <GenericConfirmModal
            title={texts.genericModalTitle1}
            message={texts.genericModalBody1}
            okButton={buttons.genericModalConfirmButton1}
            cancelButton={buttons.genericModalCancelButton1}
            cancelAction={() => this.handleCancelPopUp()}
            acceptAction={() => cancelContract(contract.id)}
          />
        ) : null}
        {showDeletePopUp ? (
          <GenericConfirmModal
            title={texts.genericModalTitle2}
            message={texts.genericModalBody2}
            okButton={buttons.genericModalConfirmButton2}
            cancelButton={buttons.genericModalCancelButton2}
            cancelAction={() => this.handleDeletePopUp()}
            acceptAction={() => softDeleteContract(contract.id, user)}
          />
        ) : null}
      </>
    );
  }
}

export default branch(
  {
    session: ['session'],
    translations: ['translations'],
    lang: ['lang'],
  },
  ActionButtons,
);
