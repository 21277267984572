const ChooseSignatureTypeTranslations = {
  es: {
    texts: {
      spanTitle: '¿Qué firma se debe usar para este documento?',
      p1: 'Indistinto:',
      span1: 'El firmante mantiene su firma predeterminada',
      p2: 'e.firma (SAT):',
      span2: 'El firmante solo puede firmar el documento con la e.firma (SAT)',
      p3: 'Firma electrónica criptográfica (Mexicano):',
      span3: 'Un usuario con RFC solo podrá firmar el documento con SIFE',
      p4: 'Firma electrónica criptográfica (Extranjero):',
      span4:
        'Un usuario sin RFC podrá firmar el documento con un ID generado por SORA. Solo lo podrá firmar con SIFE',
      p5: 'Multiples firmas:',
      span5: 'Puedes seleccionar un tipo de firma distinto para cada firmante',
      divWarning:
        'Ponte en contacto con tus firmantes para saber si cuentan con e.firma',
    },
    inputs: {
      selectOption1: 'Elegir firma',
      selectOption2: 'Indistinta',
      selectOption3: 'e.firma (SAT)',
      selectOption4: 'Firma electrónica criptográfica',
      selectOption5: 'Multiples firmas',
    },
    buttons: {},
  },
  en: {
    texts: {
      spanTitle: 'What signature should be used for this document?',
      p1: 'Indistinct:',
      span1: 'The signer keeps his default signature',
      p2: 'e.firma (SAT):',
      span2: 'The signer can only sign the document with the e.firma (SAT)',
      p3: 'Cryptographic electronic signature (Mexican):',
      span3: 'A user with RFC can only sign the document with SIFE',
      p4: 'Cryptographic electronic signature (Foreigner):',
      span4:
        'A user without RFC can sign the document with an ID generated by SORA. It can only be signed with SIFE',
      p5: 'Multiple signatures:',
      span5: 'You can select a different signature type for each signer',
      divWarning: 'Contact your signers to know if they have e.firma',
    },
    inputs: {
      selectOption1: 'Choose signature',
      selectOption2: 'Indistinct',
      selectOption3: 'e.firma (SAT)',
      selectOption4: 'Cryptographic electronic signature',
      selectOption5: 'Multiple signatures',
    },
    buttons: {},
  },
};

const ConfigSignersTranslations = {
  es: {
    texts: {},
    inputs: {
      inputPlaceholder1: 'Buscar firmantes',
      switchLabel1: 'Documentos de identidad',
      switchLabel2: 'Firmar por orden de lista',
    },
    buttons: {},
  },
  en: {
    texts: {},
    inputs: {
      inputPlaceholder1: 'Search signers',
      switchLabel1: 'Identity documents',
      switchLabel2: 'Sign by list order',
    },
    buttons: {},
  },
};

const SignersListNewContractTranslations = {
  es: {
    texts: {
      tooltip1: 'ID generado por SORA para tu firmante',
    },
    inputs: {
      inputPlaceholder1: 'RFC',
      inputPlaceholder2: 'Nombre',
      inputPlaceholder3: 'Correo electrónico',
      selectOption1: 'Elegir firma',
      selectOption2: 'Indistinta',
      selectOption3: 'e.firma (SAT)',
      selectOption4: 'Firma electrónica criptográfica (con RFC)',
      selectOption5: 'Firma electrónica criptográfica (Extranjero)',
    },
    buttons: {},
  },
  en: {
    texts: {
      tooltip1: 'ID generated by SORA for your signer',
    },
    inputs: {
      inputPlaceholder1: 'RFC',
      inputPlaceholder2: 'Name',
      inputPlaceholder3: 'Email',
      selectOption1: 'Choose signature',
      selectOption2: 'Indistinct',
      selectOption3: 'e.firma (SAT)',
      selectOption4: 'Cryptographic electronic signature (with RFC)',
      selectOption5: 'Cryptographic electronic signature (Foreigner)',
    },
    buttons: {},
  },
};

const InfoSignersSifeTranslations = {
  es: {
    texts: {
      genericModalTitle1: 'Advetencia',
      genericModalBody1:
        'Si desactivas esta opción, no se solicitará la verificación de identidad del usuario cuando creen su firma electrónica.',
      genericModalInfo1:
        'SORA se deslinda de cualquier responsabilidad legal que la no verificación de identidad del firmante pudiera causar entre las partes.',
      genericModalTitle2: 'Advertencia',
      genericModalBody2:
        'Existen varios RFC o ID asociados a esta cuenta de correo. Para continuar pregunta a tu firmante a que RFC o ID se debe asociar este contrato.',
    },
    inputs: {
      checkboxLabel1: 'Verificar identidad cuando firme este documento',
      checkboxLabel2: 'Este usuario debe verificar identidad al crear firma',
    },
    buttons: {
      genericModalConfirmButton1: 'Aceptar',
      genericModalCancelButton1: 'Cancelar',
      genericModalCancelButton2: 'Cancelar',
    },
  },
  en: {
    texts: {
      genericModalTitle1: 'Warning',
      genericModalBody1:
        'If you disable this option, the user will not be asked to verify their identity when they create their electronic signature.',
      genericModalInfo1:
        "SORA is not responsible for any legal responsibility that the non-verification of the signer's identity could cause between the parties.",
      genericModalTitle2: 'Warning',
      genericModalBody2:
        'There are several RFC or ID associated with this email account. To continue, ask your signer which RFC or ID this contract should be associated with.',
    },
    inputs: {
      checkboxLabel1: 'Verify identity when signing this document',
      checkboxLabel2: 'This user must verify identity when creating signature',
    },
    buttons: {
      genericModalConfirmButton1: 'Accept',
      genericModalCancelButton1: 'Cancel',
      genericModalCancelButton2: 'Cancel',
    },
  },
};

export const ChooseDocumentModalTranslations = {
  es: {
    texts: {
      h3: 'Solicitud de documentos',
      small1: 'Puedes seleccionar máximo 3 documentos',
      p1: 'Otro',
      small2: "(Presiona 'Enter' para agregar)",
      small3: 'Documentos: ',
    },
    inputs: {
      documentsList: [
        { id: 'INE-IFE', text: 'INE-IFE' },
        { id: 'CURP', text: 'CURP' },
        { id: 'Acta Constitutiva', text: 'Acta Constitutiva' },
        { id: 'Poder Notarial', text: 'Poder Notarial' },
      ],
      inputPlaceholder1: 'Agregar documento',
    },
    buttons: {
      button1: 'Confirmar',
    },
  },
  en: {
    texts: {
      h3: 'Document request',
      small1: 'You can select a maximum of 3 documents',
      p1: 'Other',
      small2: "(Press 'Enter' to add)",
      small3: 'Documents: ',
    },
    inputs: {
      documentsList: [
        { id: 'INE-IFE', text: 'INE-IFE' },
        { id: 'CURP', text: 'CURP' },
        { id: 'Acta Constitutiva', text: 'Constitutive Act' },
        { id: 'Poder Notarial', text: 'Power of attorney' },
      ],
      inputPlaceholder1: 'Add document',
    },
    buttons: {
      button1: 'Confirm',
    },
  },
};

export const SignersTranslations = {
  es: {
    texts: {
      spanTitle: 'Firmantes',
      link1: 'Documentos +',
      genericModalTitle: 'Advertencia',
      genericModalBody:
        'Este tipo de firma solo es para usuarios extranjeros, si tu usuario es mexicano y cuenta con RFC, selecciona otro tipo de firma.',
      tooltipTitle1: 'Marca esta casilla para añadir seguridad extra.',
      tooltipTitle2:
        'Al ser un usuario nuevo, se recomienda dejar marcada esta casilla para que verifique su identidad al crear su firma.',
      tooltipInfo1:
        'La verificación de identidad es la evidencia de que el usuario es quien firma. Se le solicita una captura de su selfie y su identificación oficial',
      genericModalTitle2: 'Advertencia',
      genericModalBody2:
        'Si desactivas esta opción para los firmantes, tu contrato tendrá menos validez legal ante las autoridades correspondientes',
      genericModalConfirmButton2: 'Aceptar',
      genericModalCancelButton2: 'Cancelar',
      p1: 'No hay firmantes',
    },
    inputs: {},
    buttons: {
      genericModalConfirmButton: 'Es extranjero',
      genericModalCancelButton: 'Cerrar',
      genericModalConfirmButton2: 'Aceptar',
      genericModalCancelButton2: 'Cancelar',
      button1: 'Agregarme +',
      button2: 'Agregar firmante +',
    },
    notifications: {
      notifTitle1: 'Atención',
      notifBody1: 'No puedes firmar por dos partes',
    },
    ChooseSignatureType: { ...ChooseSignatureTypeTranslations.es },
    ConfigSigners: { ...ConfigSignersTranslations.es },
    ChooseDocumentModal: { ...ChooseDocumentModalTranslations.es },
    SignersListNewContract: { ...SignersListNewContractTranslations.es },
    InfoSignersSife: { ...InfoSignersSifeTranslations.es },
  },
  en: {
    texts: {
      spanTitle: 'Signers',
      link1: 'Documents +',
      genericModalTitle: 'Warning',
      genericModalBody:
        'This type of signature is only for foreign users, if your user is Mexican and has RFC, select another type of signature.',
      tooltipTitle1: 'Check this box to add extra security.',
      tooltipTitle2:
        'As a new user, it is recommended to leave this box checked so that the signer verifies its identity when creating its signature.',
      tooltipInfo1:
        'Identity verification is the evidence that the user is who signs. A selfie and official identification capture is requested',
      genericModalTitle2: 'Warning',
      genericModalBody2:
        'If you deactivate this option for the signers, your contract will have less legal validity before the corresponding authorities',
      p1: 'No signers',
    },
    inputs: {},
    buttons: {
      genericModalConfirmButton: 'Is foreigner',
      genericModalCancelButton: 'Close',
      genericModalConfirmButton2: 'Accept',
      genericModalCancelButton2: 'Cancel',
      button1: 'Add me +',
      button2: 'Add new signer +',
    },
    notifications: {
      notifTitle1: 'Warning',
      notifBody1: 'You cannot sign for two parties',
    },
    ChooseSignatureType: { ...ChooseSignatureTypeTranslations.en },
    ConfigSigners: { ...ConfigSignersTranslations.en },
    ChooseDocumentModal: { ...ChooseDocumentModalTranslations.en },
    SignersListNewContract: { ...SignersListNewContractTranslations.en },
    InfoSignersSife: { ...InfoSignersSifeTranslations.en },
  },
};
