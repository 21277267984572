import { branch } from 'baobab-react/higher-order';
import React from 'react';

const StatusContractDisplay = ({
  allAuthorized,
  signer,
  renderStatus,
  renderStatusAuthorizer,
  contract,
  authorizers,
  translations,
  lang,
}) => {
  const { texts } = translations[lang].ContractDetail.StatusContractDisplay;
  return (
    <>
      <div className="status_cd">
        <span className="title_status">
          {texts.spanTitle1}
          <small>:</small>
        </span>
        {allAuthorized ? (
          signer ? (
            renderStatus(contract.lastSignedTurn, signer, contract)
          ) : (
            <span className="status pending mt-15">{texts.span1}</span>
          )
        ) : (
          renderStatusAuthorizer(authorizers, 'global')
        )}
      </div>
      <div className="description_cd">
        <span>{texts.span2}</span>
        <p>{contract.description}</p>
      </div>
    </>
  );
};

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  StatusContractDisplay,
);
