export const PrivacyPolicyModalTranslations = {
  es: {
    texts: {
      h4: 'Atención',
      small1:
        'Actualizamos nuestro aviso de privacidad, y puedes leerlo dando clic en el siguiente enlace: ',
      small2: 'He leído y acepto el aviso de privacidad de Sora',
    },
    inputs: {},
    buttons: {
      button1: 'Continuar',
    },
    notifications: {
      notifTitle1: 'Error',
      notifBody1: 'Error al actualizar la información',
      notifTitle2: 'Éxito',
      notifBody2: 'Politica de privacidad aceptada.',
      notifTitle3: 'Error',
      notifBody3: 'Hubo un error al actualizar', // TODO: translate response
    },
  },
  en: {
    texts: {
      h4: 'Attention',
      small1:
        'We have updated our privacy notice, and you can read it by clicking on the following link: ',
      small2: 'I have read and accept the privacy notice of Sora',
    },
    inputs: {},
    buttons: {
      button1: 'Continue',
    },
    notifications: {
      notifTitle1: 'Error',
      notifBody1: 'Error updating information',
      notifTitle2: 'Success',
      notifBody2: 'Privacy policy accepted',
      notifTitle3: 'Error',
      notifBody3: 'There was an error while updating',
    },
  },
};
