import { branch } from 'baobab-react/higher-order';
import React from 'react';

class ContractInputsDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      description: '',
      date: null,
      checkDate: false,
    };
  }

  onInputChange = (event) => {
    const { name, value } = event.target;
    const { updateStateNewContract } = this.props;
    this.setState({ [name]: value });
    updateStateNewContract({ [name]: value });
  };

  handleCheckDate = (e) => {
    const { checked, date } = e.target;
    const { updateStateNewContract } = this.props;
    this.setState({ checkDate: checked, date: checked ? date : null });
    updateStateNewContract({ date: checked ? date : null });
  };

  render() {
    const { title, description, checkDate, date } = this.state;
    const { translations, lang } = this.props;
    const { inputs } = translations[lang].NewContract.ContractInputs;
    return (
      <>
        <label htmlFor="title" className="name_nc label_nc">
          {inputs.inputLabel1}
          <input
            id="title"
            name="title"
            onChange={this.onInputChange}
            value={title}
            type="text"
            className="input_nc"
          />
        </label>
        <label htmlFor="description" className="description_nc label_nc">
          {inputs.textarea1}
          <textarea
            id="description"
            name="description"
            onChange={this.onInputChange}
            value={description}
            rows={2}
            className="input_nc"
          />
        </label>

        <label htmlFor="date" className="expires_nc label_nc">
          {inputs.inputLabel2}
          <div className="expiresOn">
            <div className="switch_expires">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={checkDate}
                  name="date"
                  onChange={this.handleCheckDate}
                />
                <span className="slider round"></span>
              </label>
              <span style={{ paddingLeft: 5 }}>
                {' '}
                {checkDate
                  ? inputs.inputSpanActive
                  : inputs.inputSpanInactive}{' '}
              </span>
            </div>
            {checkDate ? (
              <input
                id="date"
                name="date"
                onChange={this.onInputChange}
                value={date}
                disabled={!checkDate}
                type="date"
                className="input_nc date"
              />
            ) : null}
          </div>
        </label>
      </>
    );
  }
}

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  ContractInputsDisplay,
);
