import { branch } from 'baobab-react/higher-order';
import React from 'react';
import Dropzone from 'react-dropzone';

class DropzoneDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
    this.state = {
      file: null,
    };
  }

  showFileDialog = () => {
    this.fileInput.current.click();
  };

  onInputChange = (event) => {
    const { name, files } = event.target;
    const { updateStateNewContract } = this.props;
    if (event.target.type === 'file') {
      this.setState({ [name]: files[0] });
      updateStateNewContract({ [name]: files[0] });
    }
  };

  onDropFile = (acceptedFiles) => {
    const { updateStateNewContract } = this.props;
    this.setState({ file: acceptedFiles[0] });
    updateStateNewContract({ file: acceptedFiles[0] });
  };

  render() {
    const { fileInput, file } = this.state;
    const { translations, lang } = this.props;
    const { inputs, texts } = translations[lang].NewContract.Dropzone;
    return (
      <label className="dropzone_nc label_nc">
        {inputs.label1}
        <Dropzone
          onDrop={this.onDropFile}
          accept="application/pdf"
          multiple={false}
          ref={fileInput}
        >
          {({ getRootProps, getInputProps }) => (
            <button
              type="button"
              className="file-drop-zone"
              style={{
                background: file ? 'rgba(40, 81, 229, .05)' : null,
              }}
              onClick={this.showFileDialog}
              {...getRootProps()}
            >
              {file ? (
                <i
                  className="fas fa-check-circle fa-2x"
                  style={{ color: '#7ED321' }}
                />
              ) : (
                <i
                  className="fas fa-cloud-upload-alt fa-2x"
                  style={{ color: '#6C6C6C' }}
                />
              )}
              <input
                name="file"
                {...getInputProps()}
                onChange={this.onInputChange}
                type="file"
                accept=".pdf"
              />
              {file ? <span>{file.name}</span> : <span>{texts.span1}</span>}
            </button>
          )}
        </Dropzone>
      </label>
    );
  }
}

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  DropzoneDisplay,
);
