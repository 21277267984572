import React from 'react';
import LanguageSwitcher from '../Translations/LanguageSwitcher';
import { navStyle } from './Nav.style';

const Nav = () => {
  return (
    <nav style={navStyle}>
      <img
        src="https://sora-contratos.s3.us-east-2.amazonaws.com/images/soracontratos+nuevo.png"
        alt="logo"
        style={{ maxWidth: 200 }}
      />
      <LanguageSwitcher />
    </nav>
  );
};

export default Nav;
