import React from 'react';
import { branch } from 'baobab-react/higher-order';
import { Document, pdfjs, Page } from 'react-pdf';
import { deviceUtil, isIOS, isIpadOS } from '../../utils/deviceUtil';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class ViewPdf extends React.Component {
  /**
   * Builds the component
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      numPages: null,
    };
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  render() {
    const { contractUrl, width } = this.props;
    const { numPages } = this.state;

    if (deviceUtil?.isMobile() || width?.width < 600 || isIOS() || isIpadOS()) {
      return (
        <div
          style={{
            width: width?.width > 992 ? '100%' : '95%',
            height: '100%',
            margin: '0 auto',
            overflow: 'auto',
          }}
        >
          <Document
            file={contractUrl}
            onLoadSuccess={this.onDocumentLoadSuccess}
            onLoadError={(e) => console.log(e)}
            className="'pdf-document"
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                width={Math.min(0.95 * width?.width, 650)}
              />
            ))}
          </Document>
        </div>
      );
    }

    return (
      <div
        style={{
          width: width?.width > 992 ? '100%' : '95%',
          height: '100%',
          margin: '0 auto',
          overflow: 'auto',
        }}
      >
        <object data={contractUrl} type="application/pdf">
          <Document
            file={contractUrl}
            onLoadSuccess={this.onDocumentLoadSuccess}
            onLoadError={(e) => console.log(e)}
            className="'pdf-document"
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                width={Math.min(0.95 * width?.width, 650)}
              />
            ))}
          </Document>
        </object>
      </div>
    );
  }
}

export default branch(
  {
    width: ['width'],
  },
  ViewPdf,
);
