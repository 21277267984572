import { branch } from 'baobab-react/higher-order';
import ContractRow from '../../components/ContractRow';

const ContractListDisplay = ({
  onSort,
  titleOrder,
  createdAtOrder,
  legalNameOrder,
  expiresOnOrder,
  contracts,
  generateShortId,
  user,
  labelsForThisContract,
  labels,
  history,
  selectContractToSign,
  onClickSelectAllCheckBox,
  downloadFile,
  /* selectAllPdfToDownload,
  activeAllDownload, */
  signerOrAdmin,
  actualPage,
  translations,
  lang,
}) => {
  const { texts } = translations[lang].Contracts.ContractList;
  return (
    <table className={`table_list_contracts ${signerOrAdmin ? 'isAdmin' : ''}`}>
      <thead>
        <tr>
          {!signerOrAdmin ? (
            <th className="center_table_th">
              <input
                type="checkbox"
                name="check"
                id={`myCheckOnly`}
                onChange={onClickSelectAllCheckBox}
              />
            </th>
          ) : (
            <th></th>
          )}
          <th>{texts.th1}</th>
          <th>{texts.th2}</th>
          <th>{texts.th3}</th>
          <th>{texts.th4}</th>
          {!signerOrAdmin ? <th>{texts.th5}</th> : null}
          <th className="center_table_th">{texts.th6}</th>
        </tr>
      </thead>
      <tbody id="contractsTable" className="scrollable">
        {contracts.length
          ? contracts.map((contract) => (
              <ContractRow
                contract={contract}
                key={`${generateShortId()}-${contract.id}`}
                user={user}
                history={history}
                labels={labelsForThisContract(labels, contract.id)}
                selectContractToSign={selectContractToSign}
                downloadFile={downloadFile}
                signerOrAdmin={signerOrAdmin}
                actualPage={actualPage}
              />
            ))
          : null}
      </tbody>
    </table>
  );
};

export default branch(
  {
    lang: ['lang'],
    translations: ['translations'],
  },
  ContractListDisplay,
);
