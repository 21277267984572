import React from 'react';
import { branch } from 'baobab-react/higher-order';
import ProfileActions from '../../actions/Profile';
import { allNotifications } from '../../utils/constants';

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: allNotifications(),
      activeAllNotifications: false,
    };
  }

  async componentDidMount() {
    const {
      session: { user },
    } = this.props;
    const { notifications } = this.state;
    const userUpdated = await ProfileActions.getUser(user.id);
    const notificationsParsed = JSON.parse(userUpdated.notifications);

    notificationsParsed.forEach((notification, i) => {
      if (i !== notifications.length) {
        notifications[i]['active'] = notification.active;
      }
    });

    /* await ProfileActions.updateNotificationUser({
      notifications,
      user,
    }); */
    this.verifyIfAllIsActive();
    this.setState({ notifications });
  }

  verifyIfAllIsActive = () => {
    const { notifications } = this.state;
    this.setState({
      activeAllNotifications: notifications.every(
        (notification) => notification.active,
      ),
    });
  };

  handleForm = (event) => {
    event.preventDefault();
  };

  handleChange = async () => {
    const { notifications, activeAllNotifications } = this.state;
    const {
      session: { user },
    } = this.props;
    const notificationsModified = notifications.map((notification) => {
      notification.active = !notification.active;
      return {
        ...notification,
        active: !activeAllNotifications,
      };
    });
    this.setState({ activeAllNotifications: !activeAllNotifications });
    this.setState({ notifications: notificationsModified });
    await ProfileActions.updateNotificationUser({
      notifications: notificationsModified,
      user,
    });
  };

  handleChangeIndividualCheckbox = async (id, active) => {
    const { notifications } = this.state;
    const {
      session: { user },
    } = this.props;

    const array = [...notifications];
    array[id]['active'] = active;
    this.setState({ notifications: array });
    this.verifyIfAllIsActive();
    await ProfileActions.updateNotificationUser({
      notifications: array,
      user,
    });
  };

  render() {
    const { notifications, activeAllNotifications } = this.state;
    const { translations, lang } = this.props;
    const { texts, mappedTexts } = translations[lang].Profile.Notifications;
    if (!notifications) {
      return null;
    }
    return (
      <>
        <div
          className="activate_all_notifications"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <label style={{ marginRight: 10 }}>{texts.label1}</label>
          <label className="switch">
            <input
              type="checkbox"
              checked={activeAllNotifications}
              name="orderedSigners"
              onChange={(e) => this.handleChange(e)}
            />
            <span className="slider round"></span>
          </label>
        </div>

        <div className="notifications">
          {notifications.map((notification, i) => {
            const { title, description } = mappedTexts[notification.id];
            return (
              <div
                key={notification.id}
                className="notification_individual"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div className="title">
                  <p style={{ margin: 0, marginTop: 20, color: '#41435b' }}>
                    {title}
                  </p>
                  <small style={{ color: '#9294a5', fontSize: 12 }}>
                    {description}
                  </small>
                </div>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={notification.active}
                    name="active"
                    onChange={() =>
                      this.handleChangeIndividualCheckbox(
                        i,
                        !notification.active,
                      )
                    }
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            );
          })}
        </div>
      </>
    );
  }
}

export default branch(
  {
    session: ['session'],
    translations: ['translations'],
    lang: ['lang'],
  },
  Notifications,
);
