import moment from 'moment';
import React from 'react';

export const ListCoincidencesContract = ({
  allContracts,
  contractId,
  searchContractsById,
}) => {
  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <p style={{ color: '#2851e5' }}>
          Se encontraron {allContracts.length} documentos con titulo similar a "
          {contractId}". Selecciona un contrato para ver su información
        </p>
      </div>
      <div
        className="page scrollable"
        style={{ border: '1px solid #2851e5', maxHeight: 250 }}
      >
        <div className="table_contract_sp">
          <div
            className="header_table_contract"
            style={{ gridTemplateColumns: 'repeat(3, 1fr)' }}
          >
            <small className="bold">ID</small>
            <small
              style={{
                cursor: 'pointer',
              }}
              className="bold"
            >
              Titulo
            </small>
            <small className="bold">Fecha creación</small>
          </div>
          <div>
            {allContracts?.map((contract) => (
              <div
                key={contract.id}
                className="body_table_contract search_sp"
                style={{
                  gridTemplateColumns: 'repeat(3, 1fr)',
                  padding: 10,
                }}
                onClick={(e) => searchContractsById(e, contract.id)}
              >
                <small
                  className={`${
                    contract.status === 'forMe'
                      ? 'contract_for_me'
                      : 'other_status'
                  }`}
                >
                  {contract.id}
                </small>
                <small
                  className={`${
                    contract.status === 'forMe'
                      ? 'contract_for_me'
                      : 'other_status'
                  }`}
                >
                  {contract.title}
                </small>
                <small
                  className={`${
                    contract.status === 'forMe'
                      ? 'contract_for_me'
                      : 'other_status'
                  }`}
                >
                  {`${moment(contract.createdAt).format('DD MMM YYYY h:mm a')}`}
                </small>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
