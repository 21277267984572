export const intercpetorHandler = (env, instance) => {
  instance.interceptors.request.use(
    (config) => {
      if (env === 'dev' || env === 'local') {
        console.log('Request -->', config);
      }
      const token = localStorage.getItem('token');
      if (token) {
        config.headers = { ...config.headers, 'X-Jwt-Token': token };
      }
      return config;
    },
    (error) => Promise.reject(error),
  );
  instance.interceptors.response.use(
    (res) => {
      if (env === 'dev' || env === 'local') {
        console.log('Response <--', res);
      }

      return res;
    },
    (error) => {
      Promise.reject(error);
    },
  );
};
